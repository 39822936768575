import React, { useState, useEffect, useRef } from "react";
import { KeyboardAvoidingView, StatusBar, View } from "react-native";
import { SideRegistration } from "@/components/Layouts/SideRegistration";
import { useDeviceContext } from "twrnc";
import tw from "@/lib/tailwind";
import parsePhoneNumber from "libphonenumber-js";
import { TextInput } from "@/components/Inputs/TextInput";
import { Button, Text } from "@/components/Commons";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import {
  colors,
  phoneMasK,
  showFriendlyErrorFromHTMLCode,
  useFieldControl,
} from "@/lib";
import { isValidPhoneNumber, max, min, number, required } from "@/lib/Rules";
import { useNavigate } from "@/routes";
import { ApiService, FirebaseService, MailVerification } from "@/services";
import { store } from "@/store";
import { AxiosError } from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAlert } from "@/hooks";
import { ResponsiveLayout } from "@/components/Layouts/ResponsiveLayout";
import MaskInputs from "@/components/Inputs/MaskInput";
import { setAdmin } from "@/store/register.store";
import {
  BUTTON_SMS_CHECK_CODE,
  BUTTON_SMS_SEND_CODE,
} from "@/services/firebase.service";

export const VerificationSms = () => {
  useDeviceContext(tw);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneValid, changePhoneValid] = useState<boolean | undefined>(true);
  const [sendOne, setSendOne] = useState<boolean>(false);
  const errorInitialState = { show: false, msg: "" };
  //Error indicator
  const [errorMsg, setErrorMsg] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );
  const { showSwal } = useAlert();
  const registerState = store.getState();
  const adminInput = {
    phone: useFieldControl<string>(registerState.register.admin.phone!, [
      required,
      isValidPhoneNumber("CA"),
    ]),
    code: useFieldControl<string>("", [number, min(0), max(6)]),
  };

  const [seconds, setSeconds] = useState(0);
  let interval = useRef<any>();

  useEffect(() => {
    valueSeconds();
  }, []);

  //Hook to check if the seconds are greather than 0
  // and if it is less than 0 clear the interval
  useEffect(() => {
    if (seconds <= 0) {
      setSeconds(0);
      clearSeconds();
    }
  }, [seconds]);

  const valueSeconds = () => {
    const decreaseNum = () => setSeconds((prev) => prev - 1);
    interval.current = setInterval(decreaseNum, 1000);
  };
  const clearSeconds = () => clearInterval(interval.current);

  const onCheckCode = async () => {
    FirebaseService.logFirebaseEvent(BUTTON_SMS_CHECK_CODE);
    const phone: string = store.getState().register.admin.phone;
    try {
      setIsLoading(true);
      const codeResponse = await MailVerification.confirmWorkspaceVerifyCodeSMS(
        phone,
        adminInput.code.value
      );

      const input = {
        ...registerState.register.admin,
        phoneSecret: adminInput.code.value,
      };
      store.dispatch(setAdmin(input));

      console.log(codeResponse);
      clearSeconds();
      setIsLoading(false);
      navigate("registerPhone");
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  };

  const resendCode = async () => {
    FirebaseService.logFirebaseEvent(BUTTON_SMS_SEND_CODE);
    const phone = parsePhoneNumber(adminInput.phone.value, "CA")?.format(
      "E.164"
    );
    if (!phone) {
      changePhoneValid(false);
      return;
    }
    const input = {
      ...registerState.register.admin,
      phone,
    };
    store.dispatch(setAdmin(input));
    try {
      setIsLoading(true);
      await MailVerification.sendWorkspaceVerifySMS(phone);
      setSeconds(60);
      valueSeconds();
      navigate("registerSms");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    }
    setSendOne(true);
  };

  const setError = (err: any) => {
    setIsLoading(false);
    //Show error
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    showSwal({ msg, type: "error" });
  };

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => {
      checkPhone();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [adminInput.phone.value]);

  const checkPhone = async () => {
    if (adminInput.phone.value) {
      const parsed = parsePhoneNumber(adminInput.phone.value, "CA");
      if (parsed && parsed.isValid()) {
        if (parsed?.country == "CA" || parsed?.country == "US") {
          ApiService.checkPhone(adminInput.phone.value).then((r) => {
            changePhoneValid(r.data.result == true);
          });
        } else {
          changePhoneValid(false);
        }
      }
    }
  };

  let parsedPhone;
  try {
    parsedPhone = parsePhoneNumber(adminInput.phone.value, "CA");
  } catch (e) {}
  const phoneInput = parsedPhone?.nationalNumber || adminInput.phone.value;
  const phoneError =
    phoneValid === false && !adminInput.phone.error
      ? "This number is already in use"
      : adminInput.phone.error;
  return (
    <KeyboardAvoidingView behavior="padding" style={tw`flex-grow-1`}>
      <StatusBar
        // @ts-ignore
        style="light"
      />
      <View
        style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}]`}
      >
        <SideRegistration step={4} />
        <AuthLayout>
          <View style={tw`flex sm:hidden my-4`}>
            <ResponsiveLayout />
          </View>
          <View
            style={tw`flex flex-grow-1 flex-col justify-center  w-full max-w-[380px] items-center`}
          >
            <View style={tw`flex flex-col items-center justify-center mx-5`}>
              <Text type="h2" weight="bold" style={tw`text-center`}>
                {" "}
                Please Enter Your Phone Number
              </Text>
              <Text style={tw`my-5 text-gray-500 max-w-[380px] text-center`}>
                A verified phone number is required before your free business
                phone number and Nucleus workspace is activated.
              </Text>
            </View>
            <View
              style={[
                tw`w-full w-[10rem] mt-10 -mx-10 flex flex-row flex-nowrap`,
              ]}
            >
              <View style={tw`w-[20px]`}>
                <Text style={tw`my-8 text-gray-500`}>+1</Text>
              </View>
              <View style={tw`max-w-[160px]`}>
                <MaskInputs
                  label="Cell Phone Number :"
                  errorMessage={phoneError}
                  value={phoneInput}
                  onChangeText={adminInput.phone.handleInputValue}
                  mask={phoneMasK}
                  onBlur={checkPhone}
                />
              </View>
            </View>
            <View
              style={[tw`w-full w-[10rem] mt-2 mb-5 flex flex-row flex-nowrap`]}
            >
              <View style={tw`w-[20px]`} />
              <View style={tw`max-w-[160px]`}>
                <TextInput
                  label="Verification Code :"
                  value={adminInput.code.value}
                  errorMessage={adminInput.code.error}
                  type={"number"}
                  onChangeText={adminInput.code.handleInputValue}
                />
              </View>
            </View>

            <View
              style={tw`flex w-full max-w-[350px] items-center justify-center mx-5`}
            >
              {adminInput.code.value ? (
                <Button
                  disabled={false || isLoading || adminInput.code.invalid}
                  style={tw`sm:pb-6 pb-16 sm:pt-6 pt-16`}
                  onPress={onCheckCode}
                  loading={isLoading}
                >
                  <Text>Continue</Text>
                </Button>
              ) : seconds != 0 ? (
                <Button disabled={true} style={tw`sm:pb-6 pb-16 sm:pt-6 pt-16`}>
                  {seconds}...Resend Confirmation
                </Button>
              ) : (
                <Button
                  style={tw`sm:pb-6 pb-16 sm:pt-6 pt-16 w-[340px]`}
                  onPress={resendCode}
                  disabled={
                    false ||
                    isLoading ||
                    !(phoneValid && adminInput.phone.valid)
                  }
                >
                  {sendOne ? "Resend" : "Send"} Confirmation
                </Button>
              )}
            </View>

            {errorMsg.show && (
              <SweetAlert
                error
                show={errorMsg.show}
                title={errorMsg.msg}
                onConfirm={() => setErrorMsg({ show: false, msg: "" })}
              />
            )}
          </View>
        </AuthLayout>
      </View>
    </KeyboardAvoidingView>
  );
};
