import React, { FC } from "react";
import { Pressable, View } from "react-native";
import { useDeviceContext } from "twrnc";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { useUserLogged } from "@/hooks";

interface Props {
  children: JSX.Element | JSX.Element[];
  handlePress?: () => void;
  buttonText?: string;
  showButton?: boolean;
  buttonTextColor?: string;
}

export const Banner: FC<Props> = ({
  children,
  handlePress,
  buttonText,
  showButton,
  buttonTextColor = colors.blue,
}) => {
  useDeviceContext(tw);

  return (
    <View style={tw`flex xl:flex-row items-center py-3 px-10 bg-btn`}>
      {/* Text cotent */}
      <View style={tw`flex xl:flex-row flex-1 items-center justify-center`}>
        {children}
      </View>
      {/* Request access */}
      {showButton && (
        <Pressable onPress={handlePress}>
          <View
            style={tw`bg-white rounded-3 w-[170px] h-[40px] flex items-center justify-center`}
          >
            <Text color={buttonTextColor} weight="semiBold">
              {buttonText}
            </Text>
          </View>
        </Pressable>
      )}
    </View>
  );
};
