import React, { FC, useState } from "react";
import { View, TouchableOpacity } from "react-native";

import { TextInput } from "@/components/Inputs";
import { Text, Button } from "@/components/Commons";
import { Close, Plus } from "../Icons";

import { colors, mail, Modal, required, tw, useFieldControl } from "@/lib";
import { AuthService, TokenService, WorkspaceService } from "@/services";
import { AxiosError } from "axios";
import { showFriendlyErrorFromHTMLCode } from "../../lib/errorResponses";

interface Props {
  onAddedInvitation: (invitations: string[]) => void;
  onError?: (error: string) => void;
  workspaceId: number;
}

export const InviteUser: FC<Props> = ({
  onAddedInvitation,
  onError,
  workspaceId,
}) => {
  const emailInput = useFieldControl<string>("", [required, mail]);

  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);

  const [invitations, changeInvitations] = useState<string[]>([]);

  const [isLoading, setisLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const removeInvitation = (email: string) => {
    setErrorMessage("");
    changeInvitations(invitations.filter((e) => e != email));
  };

  const addInvitation = (email: string) =>
    !invitations.includes(email) &&
    (changeInvitations([...invitations, email]), emailInput.resetField());

  const invitation = (email: string) => (
    <View
      key={`invitation-${email}`}
      style={tw.style(
        "flex flex-row items-center justify-center mt-1 py-1 px-3 rounded-3 mr-1 bg-light-blue",
        invalidEmails.includes(email) ? "border border-red-500" : ""
      )}
    >
      <View>
        <Text style={tw`text-btn`}>{email}</Text>
      </View>
      <TouchableOpacity
        style={tw`ml-2`}
        onPress={() => removeInvitation(email)}
      >
        <Close size={14} />
      </TouchableOpacity>
    </View>
  );

  //Send invitations to the api
  const sendInvitations = async () => {
    try {
      setisLoading(true);

      await WorkspaceService.inviteCollegues(workspaceId, invitations);
      Modal.dismiss();
      onAddedInvitation(invitations);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      const err = error as AxiosError;
      if (err.response) {
        if (err.response.status === 422) {
          const allEmails = Object.keys(
            err.response.data.error.errorJsonObject
          );

          setInvalidEmails(allEmails);

          if (allEmails.length === 1)
            setErrorMessage("The user has been already invited");

          if (allEmails.length > 1)
            setErrorMessage("The users have been already invited");
        } else {
          const errorText = showFriendlyErrorFromHTMLCode(err);
          onError && onError(errorText);
          Modal.dismiss();
        }
      }
    }
  };

  const EMAIL_CHECK_INTERVAL = 1000
  let emailCheckInterval = EMAIL_CHECK_INTERVAL
  const emailHandleInputValue = (input: string) => {
    emailInput.setValue(input);
    if (input != "") {
      setTimeout(() => {
        emailInput.validate(input);
        if (emailInput.valid) {
          emailCheckInterval = EMAIL_CHECK_INTERVAL
        } else {
          emailCheckInterval = 0
        }
      }, emailCheckInterval)

    } else {
      emailInput.setError("")
      emailCheckInterval = EMAIL_CHECK_INTERVAL
    }
  };

  return (
    <View>
      <Text type="h2">Invite Colleague</Text>
      <View style={tw``}>
        <TextInput
          value={emailInput.value}
          padding={false}
          label="Enter emails and we will send the invitations"
          placeholder="mail@mail.com"
          rightIcon={
            <TouchableOpacity
              style={tw`rounded-3 p-1 bg-light-blue`}
              onPress={() =>
                emailInput.valid && addInvitation(emailInput.value)
              }
            >
              <Plus color="#0E2989" />
            </TouchableOpacity>
          }
          type="email"
          errorMessage={emailInput.error}
          onChangeText={emailHandleInputValue}
        />
      </View>
      <View style={tw`flex-row flex-wrap`}>
        {invitations.map((e) => invitation(e))}
      </View>
      <View style={tw`mt-5 pb-8`}>
        <Text color={colors.red}>{errorMessage}</Text>
      </View>
      <View style={tw`flex-row justify-end`}>
        <View style={tw`w-12/12 sm:w-5/12`}>
          <Button
            onPress={sendInvitations}
            disabled={invitations.length <= 0 || isLoading}
            loading={isLoading}
          >
            <Text>Invite</Text>
          </Button>
        </View>
      </View>
    </View>
  );
};
