export const blankEmail = "Please enter your email";
export const formatEmail = "Please follow the format 'mail@mail.com'";
export const blankPassword = "Please enter your password";

export const blankCompany = "The company should not be blank";
export const blankAccountname = "The account name should not be blank";

///Company details//
export const blankAddress = "The address shoud not be blank";
export const blankCountry = "The country shoud not be blank";
export const blankProvince = "The province must be selectable";
export const blankPhone = "The phone should not be blank";
export const blankPostalCode = "The postal code should not be blank";
export const blankCity = "The city should not be blank";

export const capacityPassword =
  "The password should be between 8 to 30 characters";

export const blankField = "This field is required";
export const equalPassword = "Passwords do not match";

export const invalidCode = "The code is invalid. Please try again";
export const invalidCredentials = "Invalid credentials";
export const messages = "You have recieved a new code please check in";
export const invalidInput="There has been an invalid input please check in"
export const equalCredentials= "Passwords should not be like the oldest"
export const passwordCredential="Password should be the same"
export const dataCompleted="Please complete all data"