import React, { FC } from "react";
import { View, Image, TouchableOpacity, Linking } from "react-native";

import { useDeviceContext } from "twrnc";

import { tw } from "@/lib";
import { Text } from "@/components/Commons";

export interface IAppStore {
  image: JSX.Element;
  name: string;
  qrCode: JSX.Element;
  appUrl: string;
}

interface AppStoreProps {
  store: IAppStore;
}

export const AppStore: FC<AppStoreProps> = ({
  store: { image, name, qrCode, appUrl },
}) => {
  useDeviceContext(tw);

  return (
    <View style={tw`flex items-center my-5 md:px-20 md:my-10`}>
      <View style={tw`flex flex-row items-center`}>
        <View style={tw`mr-5`}>{image}</View>
        <Text weight="extraBold" style={{ fontSize: 32, lineHeight: 48 }}>
          {name}
        </Text>
      </View>
      <View style={tw`my-5`}>{qrCode}</View>

      <TouchableOpacity
        onPress={() => Linking.openURL(appUrl)}
        style={tw`max-w-[14rem]`}
      >
        <Text weight="extraBold" type="h3" style={tw`text-btn text-center`}>
          {appUrl}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
