import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";

import { AdminLayout } from "@/components/Layouts/AdminLayout";
import { CheckAuth } from "@/components/Layouts/CheckAuth";
import { ClientLayout } from "@/components/Layouts/ClientLayout";
import { SuperAdminLayout } from "@/components/Layouts/SuperAdminLayout";
import { SupportLayout } from "@/components/Layouts/SupportLayout";
import { useUserLogged } from "@/hooks";
import {
  AuthGuard,
  RegisterGuard,
  SuperAdminGuard,
  SystemUserCompanyGuard,
} from "@/routes/guards";
import { CustomerList } from "@/screens/Admin/Customer";
import { Dashboard as AdminDashboard } from "@/screens/Admin/Dashboard";
import { Partners } from "@/screens/Admin/PartnerAdmin/Partners";
import { PartnersDetails } from "@/screens/Admin/PartnerAdmin/PartnersDetails";
import { ProductPromotion } from "@/screens/Admin/Product";
import ProductAddOnList from "@/screens/Admin/Product/ProductAddOnList";
import ProductList from "@/screens/Admin/Product/ProductList";
import { Users_Priviledge } from "@/screens/Admin/Users/Users_Priviledge";
import { LandingPage } from "@/screens/AppPage/LandingPage";
import { Check } from "@/screens/Auth/Check/Check";
import { WorkspaceCheck } from "@/screens/Auth/Check/WorkspaceCheck";
import { RedirectLogin } from "@/screens/Auth/Login/RedirectLogin";
import { AccountActivation } from "@/screens/Auth/Registration/AccountActivation";
import { AdministrationAccount } from "@/screens/Auth/Registration/AdministrationAccount";
import { CompanyDetails } from "@/screens/Auth/Registration/CompanyDetails";
import { VerificationEmail } from "@/screens/Auth/Registration/EmailValidation";
import { Registration } from "@/screens/Auth/Registration/Registration";
import { RegisterSuccess } from "@/screens/Auth/Registration/Success";
import { NotFound } from "@/screens/NotFound";
import { SuperAdminList } from "@/screens/SuperAdmin/Admin";
import { SuperAdminCustomers } from "@/screens/SuperAdmin/Customers";
import { SuperAdminDashboard } from "@/screens/SuperAdmin/Dashboard";
import { SuperAdminPartners } from "@/screens/SuperAdmin/Partners";
import { SuperAdminProducts } from "@/screens/SuperAdmin/Products";
import { SuperAdminResources } from "@/screens/SuperAdmin/Resources";
import { SuperAdminWorkspacesList } from "@/screens/SuperAdmin/Workspaces/SuperAdminWorkspacesList";
import {
  BugsAndErrors as SupportBugsAErrors,
  Customers as SupportCustomers,
  Dashboard as SupportDashboard,
  Resources as SupportResources,
} from "@/screens/Support";
import {
  InfoFaq,
  RequestAccess,
  SystemStatus,
  TicketList,
} from "@/screens/client/Info";
import { AuditLogs } from "@/screens/client/Info/AuditLogs";
import { DeviceDetails } from "@/screens/client/Info/ClientDeviceDetails";
import Diagnostic from "@/screens/client/Info/ClientDiagnostic";
import NewTickets from "@/screens/client/Info/NewTickets";
import ChangeEmail from "@/screens/client/Profile/ChangeEmail";
import { ChangePassword } from "@/screens/client/Profile/ChangePassword";
import Profile from "@/screens/client/Profile/Profile";
import Cdr_Reports from "@/screens/client/Reports/Cdr_Reports";
import Devices from "@/screens/client/Reports/Devices";
import QosOverview from "@/screens/client/Reports/QosOverview";
import Usage from "@/screens/client/Reports/Usage";
import {
  AfterPlan,
  PaymentHistory,
  SubscriptionDetails,
  Subscriptiond,
} from "@/screens/client/Subscriptions";
import Addsons from "@/screens/client/Subscriptions/Addsons";
import PromoCode from "@/screens/client/Subscriptions/PromoCode";
import {
  CompanyInfoDetails,
  CompanyInfoUsers,
} from "@/screens/client/companyInfo";
import { WorkspaceLayout } from "../components/Layouts/WorkspaceLayout";
import { PhoneAccount } from "@/screens/Auth/Registration/PhoneAccount";
import { VerificationSms } from "@/screens/Auth/Registration/SmsValidation";
import { WhmcsStore } from "@/screens/store/WhmcsStore";

const Routes = () => {
  const { checkUser } = useUserLogged();

  useEffect(() => {
    const path = location.pathname;
    if (
      path.includes("/auth") ||
      path.includes("/download") ||
      path.includes("/check")
    )
      return;
    //checkUser();
  }, []);

  const routes = useRoutes([
    {
      path: "",
      children: [
        {
          index: true,
          element: <CheckAuth />,
        },
        {
          path: "login",
          element: <RedirectLogin />,
        },
        {
          path: "auth",
          children: [
            {
              index: true,
              element: <RedirectLogin />,
            },
            {
              path: "register",
              children: [
                {
                  index: true,
                  element: <Registration />,
                },
                {
                  path: "mail",
                  element: (
                    <RegisterGuard>
                      <VerificationEmail />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "sms",
                  element: (
                    <RegisterGuard>
                      <VerificationSms />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "company",
                  element: (
                    <RegisterGuard>
                      <CompanyDetails />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "admin",
                  element: (
                    <RegisterGuard>
                      <AdministrationAccount />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "activations",
                  element: (
                    <RegisterGuard>
                      <AccountActivation />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "phone",
                  element: (
                    <RegisterGuard>
                      <PhoneAccount />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "success",
                  element: (
                    <RegisterGuard>
                      <RegisterSuccess />
                    </RegisterGuard>
                  ),
                },
                {
                  path: "complete",
                  element: <WorkspaceCheck />,
                },
              ],
            },
          ],
        },
        {
          path: "company",
          element: (
            <AuthGuard section="admin">
              <ClientLayout states={0} />
            </AuthGuard>
          ),
          children: [
            {
              path: "details",
              element: <CompanyInfoDetails />,
            },
            {
              path: "users",
              element: <CompanyInfoUsers />,
            },
            {
              path: "password",
              element: <ChangePassword />,
            },
            {
              path: "email",
              element: <ChangeEmail />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
            {
              path: "details/:workspaceId",
              element: (
                <SystemUserCompanyGuard>
                  <CompanyInfoDetails />
                </SystemUserCompanyGuard>
              ),
            },
            {
              path: "users/:workspaceId",
              element: (
                <SystemUserCompanyGuard>
                  <CompanyInfoUsers />
                </SystemUserCompanyGuard>
              ),
            },
          ],
        },
        {
          path: "reports",
          element: (
            <AuthGuard section="reports">
              <ClientLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: "usage",
              element: <Usage />,
            },
            {
              path: "cdrReport",
              element: <Cdr_Reports />,
            },
            {
              path: "qosOverview",
              element: <QosOverview />,
            },
            {
              path: "devices",
              element: <Devices />,
            },
          ],
        },
        {
          path: "store",
          element: (
            <AuthGuard section="store">
              <ClientLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: "main",
              element: <WhmcsStore />,
            },
          ],
        },
        {
          path: "info",
          element: (
            <AuthGuard section="info">
              <ClientLayout states={0} />
            </AuthGuard>
          ),
          children: [
            {
              path: "status",
              element: <SystemStatus />,
            },
            {
              path: "tickets",
              element: <TicketList />,
            },
            {
              path: "newTickets",
              element: <NewTickets />,
            },
            {
              path: "diagnostic",
              element: <Diagnostic />,
            },
            {
              path: "reports",
              element: <AuditLogs />,
            },
            {
              path: "details",
              element: <DeviceDetails />,
            },
            {
              path: "faq",
              element: <InfoFaq />,
            },
            {
              path: "access",
              element: <RequestAccess />,
            },
            {
              path: "access/:workspaceId",
              element: (
                <SystemUserCompanyGuard>
                  <RequestAccess />
                </SystemUserCompanyGuard>
              ),
            },
          ],
        },
        {
          path: "admin",
          element: (
            <AuthGuard section="admin">
              <AdminLayout states={0} />
            </AuthGuard>
          ),
          children: [
            {
              index: true,
              //path:"dashboard",
              element: <AdminDashboard />,
            },
            {
              path: "customer",
              element: <CustomerList />,
            },
            {
              path: "partners",
              element: <Partners />,
            },
            {
              path: "partnersprofile",
              element: <PartnersDetails />,
            },
            {
              path: "product",
              element: <ProductList />,
            },
            {
              path: "addOns",
              element: <ProductAddOnList />,
            },
            {
              path: "promotions",
              element: <ProductPromotion />,
            },

            {
              path: "resources",
              element: <Users_Priviledge />,
            },
          ],
        },
        {
          path: "support",
          element: (
            <AuthGuard section="support">
              <SupportLayout />
            </AuthGuard>
          ),
          children: [
            {
              index: true,
              element: <SupportDashboard />,
            },
            {
              path: "customers",
              element: <SupportCustomers />,
            },
            {
              path: "bugs-and-errors",
              element: <SupportBugsAErrors />,
            },
            {
              path: "resources",
              element: <SupportResources />,
            },
            {
              path: "password",
              element: <ChangePassword />,
            },
            {
              path: "email",
              element: <ChangeEmail />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
          ],
        },
        {
          path: "super-admin",
          element: (
            <AuthGuard section="super-admin">
              <SuperAdminLayout states={0} />
            </AuthGuard>
          ),
          children: [
            {
              index: true,
              element: <SuperAdminDashboard />,
            },
            {
              path: "customer",
              element: (
                <SuperAdminGuard>
                  <SuperAdminCustomers />
                </SuperAdminGuard>
              ),
            },
            {
              path: "admin",
              element: <SuperAdminList />,
            },
            {
              path: "user-list",
              element: (
                <SuperAdminGuard>
                  <SuperAdminPartners />
                </SuperAdminGuard>
              ),
            },
            {
              path: "product",
              element: <SuperAdminProducts />,
            },
            {
              path: "resources",
              element: <SuperAdminResources />,
            },
            {
              path: "workspaces",
              element: <SuperAdminWorkspacesList />,
            },
            {
              path: "password",
              element: <ChangePassword />,
            },
            {
              path: "email",
              element: <ChangeEmail />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
          ],
        },
        {
          path: "workspaces",
          element: (
            <AuthGuard section="workspaces">
              <WorkspaceLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: "subscriptions/details/:workspaceId",
              element: <Subscriptiond />,
            },
            {
              path: "password",
              element: <ChangePassword />,
            },
            {
              path: "email",
              element: <ChangeEmail />,
            },
            {
              path: "profile",
              element: <Profile />,
            },
          ],
        },
        {
          path: "check/:refreshToken",
          element: <Check />,
        },
        {
          path: "download",
          element: <LandingPage />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);
  return routes;
};

export default Routes;
