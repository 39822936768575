import { useRef } from "react";
import { Socket, io as socketIOClient } from "socket.io-client";

//@ts-ignore
import { SOCKET_SERVER_URL } from "@env";
import { capitalizeAll, socketEvents } from "@/lib";
import { useAppDispatch } from "@/store/hooks";
import { setInfo } from "@/store/banner.store";

interface Listener {
  event: string;
  callback: (data: any) => void;
}

interface Params {
  jwt: string;
}

const useSocket = () => {
  const socketRef = useRef<Socket | undefined>();
  const dispatch = useAppDispatch();

  const connectSocket = ({ jwt }: Params) => {
    disconnectSocket();
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      transports: ["websocket"],
      query: {
        jwt,
      },
    });
  };

  const disconnectSocket = () => {
    socketRef.current?.disconnect();
  };

  const addListener = ({ event, callback }: Listener) => {
    socketRef.current?.on(event, callback);
  };

  const removeListener = ({ event, callback }: Listener) => {
    socketRef.current?.off(event, callback);
  };

  const connectAndAddCommonListeners = ({ jwt }: Params) => {
    connectSocket({ jwt });

    addListener({
      event: "connect",
      callback: () => console.log("connected"),
    });

    addListener({
      event: socketEvents.WORKSPACE_SUPPORT_IN,
      callback: (user: {
        firstName: string;
        lastName: string;
        accessType: string;
      }) => {
        dispatch(
          setInfo({
            rol: "Support",
            accessLevel: capitalizeAll(user.accessType),
            name: user.firstName,
            isInside: true,
          })
        );
      },
    });
  };

  return {
    connectSocket,
    disconnectSocket,
    addListener,
    removeListener,
    connectAndAddCommonListeners,
  };
};

export default useSocket;
