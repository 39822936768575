import { View } from "react-native";
import React, { useState } from "react";
import { useDeviceContext } from "twrnc";

import { TextInput, Dropdown } from "@/components/Inputs";
import { tw, useFieldControl, number, required, addPrefixToInput } from "@/lib";

//@ts-ignore
export const ProductAddOnForm = () => {
  //Use tailwind
  useDeviceContext(tw);

  //Handle payment value
  const [paymentValue, setPaymentValue] = useState<string>("");

  //Form Inputs
  const planForm = {
    name: useFieldControl<string>("", [required]),
    paymentType: useFieldControl<string>("", [number]),
    paymentUser: useFieldControl<string>("", [number]),
    freeUser: useFieldControl<string>("", [number]),
    description: useFieldControl<string>("", []),
  };

  //Add $ symbol to the value
  const handlePaymentUChange = (value: string) => {
    //@ts-ignore
    const { cleanValue, prefixValue } = addPrefixToInput(value, "$");

    planForm.paymentUser.handleInputValue(cleanValue);

    setPaymentValue(prefixValue);
  };

  return (
    <View style={tw`flex-1`}>
      <TextInput
        label="Name"
        value={planForm.name.value}
        errorMessage={planForm.name.error}
        autoCapitalize="words"
        placeholder="Monthly"
        onChangeText={planForm.name.handleInputValue}
      />
      <Dropdown
        autoCorrect={false}
        placeholder="per month"
        label="Payment type"
        value={planForm.paymentType.value}
        onChangeText={planForm.paymentType.handleInputValue}
        data={[
          { label: "Month", value: "month" },
          { label: "3 months", value: "3months" },
          { label: "Year", value: "year" },
        ]}
      />
      <TextInput
        label="Payment for user"
        value={paymentValue}
        errorMessage={planForm.paymentUser.error}
        placeholder="$10"
        type="number"
        onChangeText={handlePaymentUChange}
      />
      <TextInput
        label="Free Users"
        value={planForm.freeUser.value}
        errorMessage={planForm.freeUser.error}
        placeholder="25"
        type="number"
        onChangeText={planForm.freeUser.handleInputValue}
      />
      <TextInput
        style={tw`h-20`}
        multiline={true}
        numberOfLines={4}
        label="Description"
        value={planForm.description.value}
        errorMessage={planForm.description.error}
        autoCapitalize="words"
        placeholder="Monthly"
        onChangeText={planForm.description.handleInputValue}
      />
    </View>
  );
};
