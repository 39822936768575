import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Fire = (props: { color?: string,style?: StyleProp<ViewStyle>; }) => {
  const { color = "white",style } = props;
  return (
    <Svg width="95" height="150" viewBox="0 0 95 150" fill="none" style={style}>
      <Path
        d="M36.5376 68.7354C36.5376 66.7393 34.9 65.1179 32.8839 65.1179C30.8679 65.1179 29.2302 66.7393 29.2302 68.7354C29.2302 70.7315 30.8679 72.3529 32.8839 72.3529C34.9 72.3529 36.5376 70.7315 36.5376 68.7354ZM77.8823 41.073C70.6889 33.3257 64.4803 26.6307 64.4803 16.1521C64.4803 10.1923 65.6794 4.41252 65.6901 4.35591C65.9147 3.28882 65.6436 2.17979 64.948 1.33549C64.2557 0.491185 63.2137 0 62.115 0C55.9315 0 38.2797 4.53267 38.2797 24.6701C38.2797 37.5403 35.6462 41.5039 34.5117 42.6134C33.8728 43.2422 33.0201 43.5954 32.3278 43.4931C28.9629 43.4544 28.3277 38.6847 28.3063 38.5047C28.1706 37.2751 27.4071 36.1978 26.2833 35.6537C25.1594 35.1026 23.8427 35.1625 22.7686 35.8125C8.51386 44.4364 2.38216e-08 59.4473 2.38216e-08 75.9704C-0.000822691 101.902 21.3087 123 47.499 123C73.6894 123 94.9989 101.902 94.9989 75.9704C94.9989 59.5251 85.9034 49.7213 77.8823 41.073V41.073ZM21.9221 68.7354C21.9221 62.7508 26.839 57.8825 32.8835 57.8825C38.928 57.8825 43.845 62.7508 43.845 68.7354C43.845 74.7201 38.928 79.5883 32.8835 79.5883C26.8394 79.5883 21.9221 74.7201 21.9221 68.7354ZM35.7383 99.9375C34.4664 101.501 32.1706 101.745 30.6001 100.503C29.0266 99.252 28.7699 96.9769 30.0295 95.4154L59.2602 59.2388C60.5267 57.6915 62.8213 57.43 64.398 58.6734C65.9719 59.9242 66.2286 62.1993 64.969 63.7608L35.7383 99.9375ZM62.1142 101.294C56.0697 101.294 51.1527 96.4259 51.1527 90.4412C51.1527 84.4566 56.0697 79.5883 62.1142 79.5883C68.1586 79.5883 73.0756 84.4566 73.0756 90.4412C73.076 96.4259 68.1586 101.294 62.1142 101.294ZM62.1142 86.8233C60.0981 86.8233 58.4605 88.4447 58.4605 90.4408C58.4605 92.4369 60.0981 94.0583 62.1142 94.0583C64.1302 94.0583 65.7678 92.4369 65.7678 90.4408C65.7683 88.4452 64.1302 86.8233 62.1142 86.8233Z"
        fill={color}
        stroke={color}
        strokeWidth={"0.1"}
        fillOpacity={"0.1"}
      />
         
    </Svg>
  );
};
