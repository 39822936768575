import { ApiObjectResponse, PlanResponse, PlanSubscription } from "@/types";
import { Axios, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";
import { ApiService } from "./";
//@ts-ignore
import { BASIC_PLAN_NAME } from "@env";

const endpoint = "plans";

const subscriptions = "subscriptions/new";
const cancel = "subscriptions/cancel";

class PlanService {
  //Get all plans that belong to newt_cloud and are enabled
  async listPlans(): Promise<PlanResponse[]> {
    const request = await ApiService.api.get<{
      result: { list: PlanResponse[]; totalCount: number };
    }>(endpoint);
    const data = request.data.result.list
      .filter((e) => e.is_enabled)
      .sort(
        (n1: PlanResponse, n2: PlanResponse) =>
          n1.display_order - n2.display_order
      );

    return data;
  }

  //Get plan by id
  async getPlanById(
    planId: string
  ): Promise<AxiosResponse<{ result: PlanResponse }>> {
    return ApiService.api.get(`${endpoint}/${planId}`);
  }

  //Change Plan Subscription
  changePlanSubscriptions(
    input: PlanSubscription
  ): Promise<AxiosResponse<ApiObjectResponse<any>>> {
    return ApiService.api.post(subscriptions, input);
  }

  cancelSubscription(
    id: number
  ): Promise<AxiosResponse<ApiObjectResponse<any>>> {
    return ApiService.api.post(`${cancel}/${id}`);
  }

  //Get plan basic
  async getPlanBasic(): Promise<PlanResponse | undefined> {
    const request = await ApiService.api.get<{
      result: { list: PlanResponse[]; totalCount: number };
    }>(endpoint);

    const data = request.data.result.list.filter(
      (plan) => plan.name.toLowerCase() === BASIC_PLAN_NAME
    );

    return data.length >= 1 ? data[0] : undefined;
  }
}

const singleton = new PlanService();

export default singleton;
