import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Close = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
  size?: number;
  strokeWidth?: number;
}) => {
  const { color = "#A1A1A9", style, size = 24, strokeWidth = 0 } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8132 5.39888C18.5594 5.14504 18.1478 5.14504 17.894 5.39888L12.0657 11.2271L6.37891 5.54031C6.12507 5.28647 5.71351 5.28647 5.45967 5.54031C5.20583 5.79415 5.20583 6.2057 5.45967 6.45955L11.1465 12.1464L5.45967 17.8332C5.20583 18.087 5.20583 18.4986 5.45967 18.7524C5.71351 19.0063 6.12507 19.0063 6.37891 18.7524L12.0657 13.0656L17.894 18.8939C18.1478 19.1477 18.5594 19.1477 18.8132 18.8939C19.0671 18.64 19.0671 18.2285 18.8132 17.9746L12.985 12.1464L18.8132 6.31812C19.0671 6.06427 19.0671 5.65272 18.8132 5.39888Z"
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
