import axios, { AxiosInstance } from "axios";

import { AxiosResponse } from "axios";
import { EmailResponse, DomainResponse } from "@/types";
import { AuthService, AxiosHeaders, TokenService } from "@/services";
//@ts-ignore
import { API_SERVICE_TEST, API_SERVICE, API_SERVICE_TESTING } from "@env";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const endpoint = "available";

const baseURL = API_SERVICE_TESTING;
class ApiService {
  api: AxiosInstance;
  called: boolean = false;

  constructor() {
    this.api = axios.create({
      baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    //Define interceptors
    this.api.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        if (err.response) {
          // Access Token was expired
          if (
            err.response.status === 401 &&
            !originalConfig._retry &&
            !originalConfig?.url?.includes("auth")
          ) {
            //Check if refreshToken exists and hasn't expired
            const expire = TokenService.getFullRefreshToken();
            if (expire != undefined) {
              if (dayjs() <= dayjs(expire?.expiresAt)) {
                //Tell the request to retry
                originalConfig._retry = true;
                try {
                  //Call service
                  const rs = await AuthService.tokenrefresh();
                  //Get the response
                  const newTokens = rs.data.result;
                  //Update tokens
                  TokenService.updateLocalAccessToken(newTokens.accessToken);
                  TokenService.updateLocalRefreshToken(newTokens.refreshToken);
                  //Call the original request modified
                  originalConfig.headers[
                    "Authorization"
                  ] = `Bearer ${newTokens?.accessToken?.token}`;
                  return this.api(originalConfig);
                } catch (_error) {
                  TokenService.removeUser();
                  useNavigate("/login");
                }
              } else {
                TokenService.removeUser();
                useNavigate("/login");
              }
            } else {
              useNavigate("/login");
            }
          }
        }
        return Promise.reject(err);
      }
    );
  }

  //Check if the email can be used for register
  async checkEmail(
    email: string
  ): Promise<AxiosResponse<{ result: EmailResponse }>> {
    return this.api.get(`${endpoint}/email`, { params: { email } });
  }

  async checkPhone(phone: string): Promise<AxiosResponse<{ result: boolean }>> {
    return this.api.get(`${endpoint}/phone`, { params: { phone } });
  }

  //Check if the account name can be used for register
  async checkAccountName(
    accountName: string
  ): Promise<AxiosResponse<{ result: DomainResponse }>> {
    return this.api.get(`${endpoint}/account`, {
      params: { account: accountName },
    });
  }
}

const singleton = new ApiService();
export default singleton;
