import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Bell = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { color = "white", style } = props;
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
      <Path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M3.88504 6.65019C4.29249 4.53141 6.14646 3 8.30407 3H11.6959C13.8535 3 15.7075 4.53141 16.115 6.65019L17.991 16.4056C18.0192 16.552 17.9805 16.7034 17.8855 16.8184C17.7905 16.9334 17.6492 17 17.5 17H2.5C2.35086 17 2.20949 16.9334 2.11451 16.8184C2.01952 16.7034 1.98083 16.552 2.009 16.4056L3.88504 6.65019ZM8.30407 4C6.62593 4 5.18396 5.1911 4.86704 6.83904L3.10532 16H16.8947L15.133 6.83903C14.8161 5.19109 13.3741 4 11.6959 4H8.30407Z"
        }
        fill={"white"}
      />
      <Path
        fillRule={"evenodd"}
      
        clipRule={"evenodd"}
        d={"M20 17H0V16H20V17Z"}
        fill={"white"}
      />
      <Path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6.5 16.5C6.5 16.2239 6.72386 16 7 16H13C13.2761 16 13.5 16.2239 13.5 16.5V17.5C13.5 18.8807 12.3807 20 11 20H9C7.61929 20 6.5 18.8807 6.5 17.5V16.5ZM7.5 17V17.5C7.5 18.3284 8.17157 19 9 19H11C11.8284 19 12.5 18.3284 12.5 17.5V17H7.5Z"
        }
        fill={"white"}
      />

      <Path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M7.5 3.5C7.5 3.77614 7.72386 4 8 4H12C12.2761 4 12.5 3.77614 12.5 3.5V2.5C12.5 1.11929 11.3807 0 10 0C8.61929 0 7.5 1.11929 7.5 2.5V3.5ZM8.5 3V2.5C8.5 1.67157 9.17157 1 10 1C10.8284 1 11.5 1.67157 11.5 2.5V3H8.5Z"
        }
        fill={"white"}
      />
    </Svg>
  );
};
