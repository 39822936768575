import { Pagination, UserEventLogsResponse } from "@/types";
import { ApiService } from "./";
import { AxiosResponse } from "axios";
import { AxiosHeaders } from "@/services";
import dayjs from "dayjs";

class AuditLogsService {
  async getUserEventLogs(
    searchText: string,
    type: string,
    status: string,
    dateRange: string,
    pagination: Pagination
  ): Promise<AxiosResponse<{ result: UserEventLogsResponse }>> {
    const query = new URLSearchParams();

    if (searchText) {
      query.append("search", searchText);
    }

    if (type !== "all") {
      query.append("type", type);
    }

    if (status !== "" && status !== "all") {
      query.append("status", status);
    }

    if (dateRange !== "" && dateRange !== "any") {
      const startAndEndDateFromRange = getStartAndEndDateFromRange(dateRange);

      query.append("start_date", startAndEndDateFromRange.startDate);
      query.append("end_date", startAndEndDateFromRange.endDate);
    }

    query.append("page", String(pagination.page));
    query.append("limit", String(pagination.limit));

    return ApiService.api.get(`archive/user-event-log?${query}`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
}

function getStartAndEndDateFromRange(dateRange: string) {
  let startDate = "";
  let endDate = "";

  if (dateRange === "today") {
    startDate = dayjs().startOf("date").format("YYYY-MM-DD");
    endDate = dayjs().endOf("date").format("YYYY-MM-DD");
  } else if (dateRange === "lastSevenDays") {
    startDate = dayjs().subtract(7, "day").startOf("date").format("YYYY-MM-DD");
    endDate = dayjs().endOf("date").format("YYYY-MM-DD");
  } else if (dateRange === "lastFourWeeks") {
    startDate = dayjs()
      .subtract(4, "week")
      .startOf("date")
      .format("YYYY-MM-DD");
    endDate = dayjs().endOf("date").format("YYYY-MM-DD");
  } else if (dateRange === "previousMonth") {
    startDate = dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
  }

  return { startDate, endDate };
}

const singleton = new AuditLogsService();
export default singleton;
