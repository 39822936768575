import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import { Modal, tw } from '@/lib'
import { Button, Text } from '../Commons'
import { TouchableOpacity } from 'react-native-gesture-handler';
import FirstTab from '@/screens/Admin/Product/TabsProduct/FirstTab';
import { SecondTab } from '@/screens/Admin/Product/TabsProduct/SecondTab';
import ThirdTab from '@/screens/Admin/Product/TabsProduct/ThirdTab';
interface Edit {
  id: string;
  
}

const EditPromotions = ({ id }: Edit) => {

  const [tab, changeTab] = useState<"General" | "Access" | "Content">(
    "General"
  );

  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();

    return isActive
      ? {
        btn: tw`bg-white rounded-3`,
        text: tw`text-primary`,
      }
      : {
        text: tw`text-dark-gray`,
      };
  };

  //Show the buttons and the styles
  const tabs = () => {
    const selectedSuscription = getTabStyle(() => tab == "General");
    const selectedAddon = getTabStyle(() => tab == "Access");
    const selectedPromotion = getTabStyle(() => tab == "Content");


    return (
      <View
        style={tw`flex-row bg-[#EBEBF0] max-w-[304px] w-full h-10 rounded-3`}
      >
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            selectedSuscription.btn,
          ]}
          onPress={() => {
            tab != "General" && changeTab("General");
          }}
        >

          <Text weight="semiBold" style={selectedSuscription.text}>
            General
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            selectedAddon.btn,
          ]}
          onPress={() => {

            tab != "Access" && changeTab("Access");
          }}
        >
          <Text weight="semiBold" style={selectedAddon.text}>
            Access
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            selectedPromotion.btn,
          ]}
          onPress={() => {
            tab != "Content" && changeTab("Content");

          }}
        >

          <Text weight="semiBold" style={selectedPromotion.text}>
            Content
          </Text>
        </TouchableOpacity>

      </View>
    );
  };
  return (
    <View style={tw`flex-1`}>
      <View style={tw`flex-row justify-between mb-1 pt-8 px-8 mb-5`}>
        <Text type="h2" weight="bold">
          {id ? "Edit Promotion" : "Add Promotion"}
        </Text>
        {tabs()}
        <TouchableOpacity ></TouchableOpacity>
      </View>
      <View style={tw`flex items-center justify-around`}>
        {tab === 'General' ?
          <FirstTab />
          : tab === 'Access' ?
            <SecondTab /> : <ThirdTab />}

      </View>




      <View style={tw`flex flex-row justify-end mt-10 pb-8 px-8`}>
        <View style={tw`flex flex-row w-1/2`}>
          <Button
            style={tw`w-42`}
            type="outlined"
            onPress={()=>Modal.dismiss()}

          >
            <Text weight="semiBold">Cancel</Text>
          </Button>

          <Button style={tw`w-42`} onPress={()=>Modal.dismiss()}>
            <Text weight="semiBold">Save</Text>
          </Button>
        </View>
      </View>

    </View>




  )
}

export default EditPromotions

const styles = StyleSheet.create({})