import { StyleSheet, TouchableOpacity, View } from "react-native";
import React from "react";
import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { useNavigate } from "@/routes";
import { Back, Image } from "@/components/Icons";

import Tickets from "@/components/Tickets/Tickets";
interface FileState {
  status: boolean;
  qty: number;
}
interface stateArrays {
  id: string;
  name?: string;
  time?: string;
  description?: string;
  file?: string;
  active?: boolean;
}

const NewTickets = () => {
  const navigate = useNavigate();

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex flex-row justify-between `}>
        <View style={tw`flex flex-row items-center justify-around`}>
          <Text type="h2">
            <TouchableOpacity
              style={tw`bg-white p-2 rounded-3 relative z-10`}
              onPress={() => {
                return navigate(-1);
              }}
            >
              <Back color={colors.blue} />
            </TouchableOpacity>
            {"   "}New Ticket
          </Text>
          <View style={tw` w-1/12`}>
            <Text
              type="caption"
              style={[
                tw`px-1 bg-white text-dark-black rounded`,
                { width: "fit-content" },
              ]}
            >
              №0523
            </Text>
          </View>
        </View>
      </View>
      <Tickets />
    </View>
  );
};

export default NewTickets;

const styles = StyleSheet.create({});
