export const regexMail =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const regEmail = /^[^@]+@[^@]+\.[a-zA-Z0-9]{2,}$/;
export const regexZip =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
export const regexPhoneNumber =
  /^(\+\d{1,2}\s)?((\(\d{3}\))|(\d{3}))[\s.-]\d{3}[\s.-]\d{4}$/;
export const regexAmericanPhone = /^[0-9]{3}\s{1}[0-9]{3}\s{1}[0-9]{4}$/;
export const accountNameRegx = /^[A-Z]{1}[a-zA-Z]*([-.]{1}[a-zA-Z]+)*$/;
export const regexPhoneNumberAmerican =
  /^(((\+1\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
export const regexPhoneNumberInvalid = /^(\d{3})(\d{3})(\d{4})$/;
export const regexNumbers = /^\d*$/;
export const regexLetter = /.[a-zA-Z]./;
export const regexLettersThree = /^(?:\W*\w){3,4}\W*$/;
export const expireDates = /^(0[1-9]|1[012])(\/)(\d{2})$/;
export const expireDateInvalid = /^(\d{2})(\d{2})$/;
export const passwordsExpresion =
  /^(?=.*[a-zA-Z])(?=.*[0-9-+_!@#$%^&*.,?])(.){8,}$/;
export const accountNamere = /^[a-z,A-Z.]*$/;

export const fieldNumber = /^\d*$/;
export const decimalRegex = /^\d{1,3}(,\d{3})$/;
export const creditCardRegex =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
export const zipMask = [/\w/, /\d/, /\w/, " ", /\d/, /\w/, /\d/];
export const phoneMasK = [
  "",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const creditCardMasK = [
  "",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const expireDateMask = ["", /\d/, /\d/, "/", /\d/, /\d/];
export const expireDateLongMask = [
  "",
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
