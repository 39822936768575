import React, { useState } from "react";
import { StyleSheet, Text as Texts, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Icon } from "react-native-elements";
import { colors, tw, useFieldControl } from "@/lib";
import { Text } from "@/components/Commons";
import { SearchBar } from "@/components/Inputs";
import { RightArrow } from "../../../components/Icons/RightArrow";
import { DownArrow } from "../../../components/Icons/DownArrow";
import { useDeviceContext } from "twrnc";
import { getTabStyle } from "./helpers/getTabStyle";

const Diagnostic = () => {
  useDeviceContext(tw)
  const [tab, changeTab] = useState<"my" | "all">("all");

  const navigate = useNavigate();

  const [globalState, setglobalState] = useState({ id: 0, statesData: false });

  const [data, setData] = useState({
    value: [
      {
        id: 1,
        name: "Maria Baptista",

        email: "mariabaptista@mail.com",
        extension: ["#231", "#563", "#664"],
        devices: [
          {
            id: 1,
            deviceName: "deviceName1",
            active: false,
          },
          {
            id: 2,
            deviceName: "deviceName2",
            active: false,
          },
          {
            id: 3,
            deviceName: "deviceName3",
            active: true,
          },
          {
            id: 4,
            deviceName: "deviceName4",
            active: false,
          },
        ],
      },
      {
        id: 2,
        name: "Maria Baptista",

        email: "mariabaptista@mail.com",
        extension: [
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
        ],
        devices: [
          {
            id: 1,
            deviceName: "deviceName1",
            active: true,
          },
          {
            id: 2,
            deviceName: "deviceName2",
            active: false,
          },
        ],
      },
      {
        id: 3,
        name: "Maria Baptista",

        email: "mariabaptista@mail.com",
        extension: [
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
        ],
        devices: [
          {
            id: 1,
            deviceName: "deviceName1",
            active: true,
          },
          {
            id: 2,
            deviceName: "deviceName2",
            active: false,
          },
          {
            id: 3,
            deviceName: "deviceName3",
            active: true,
          },
          {
            id: 4,
            deviceName: "deviceName4",
            active: false,
          },
        ],
      },
      {
        id: 4,
        name: "Maria Baptista",

        email: "madqw@mail.com",
        extension: [
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
        ],
        devices: [
          {
            id: 1,
            deviceName: "deviceName1",
            active: true,
          },
          {
            id: 2,
            deviceName: "deviceName2",
            active: false,
          },
          {
            id: 3,
            deviceName: "deviceName3",
            active: true,
          },
          {
            id: 4,
            deviceName: "deviceName4",
            active: false,
          },
        ],
      },

      {
        id: 5,
        name: "Maria Baptista",
        email: "mariabaptista@mail.com",
        extension: [
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
          "#231",
          "#563",
          "#664",
        ],
        devices: [
          {
            id: 1,
            deviceName: "deviceName1",
            active: true,
          },
          {
            id: 2,
            deviceName: "deviceName2",
            active: false,
          },
          {
            id: 3,
            deviceName: "deviceName3",
            active: true,
          },
          {
            id: 4,
            deviceName: "deviceName4",
            active: false,
          },
          {
            id: 5,
            deviceName: "deviceName4",
            active: false,
          },
          {
            id: 6,
            deviceName: "deviceName4",
            active: false,
          },
          {
            id: 7,
            deviceName: "deviceName4",
            active: false,
          },
        ],
      },
    ],



  });

  const [dataSeach, setDataSearch] = useState({
    value: [...data.value]
  })

  const [data1, setData1] = useState({
    value: [
      {
        id: 1,
        no: 123,
        time: "153 4.425654",
        source: "192.168.9.198",
        destination: "192.168.0.120",
        protocol: "RTP",
        length: 214,
        info: ["Fames facilisi at velit ultrices amet", "pharetra non cu"],
      },
      {
        id: 2,
        no: 123,
        time: "153 4.425654",
        source: "192.168.9.198",
        destination: "192.168.0.120",
        protocol: "RTP",
        length: 214,
        info: ["Fames facilisi at velit ultrices amet", "pharetra non cu"],
      },
      ,
      {
        id: 3,
        no: 123,
        time: "153 4.425654",
        source: "192.168.9.198",
        destination: "192.168.0.120",
        protocol: "RTP",
        length: 214,
        info: ["Fames facilisi at velit ultrices amet", "pharetra non cu"],
      },
    ],
  });

  //Form Inputs
  const search = useFieldControl<string>("", []);
  //@ts-ignore
  let array = []

  const tabs = () => {
    const myTickets = getTabStyle(() => tab == "all");
    const allTickets = getTabStyle(() => tab == "my");
    return (
      <View
        style={tw`flex-row bg-[#EBEBF0] max-w-[304px] w-full h-10 rounded-3`}
      >
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            myTickets.btn,
          ]}
          onPress={() => {
            tab != "all" && changeTab("all");
          }}
        >
          <Text weight="semiBold" style={myTickets.text}>
            Device Summary
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            allTickets.btn,
          ]}
          onPress={() => {
            tab != "my" && changeTab("my");
          }}
        >
          <Text weight="semiBold" style={allTickets.text}>
            Customer Call Logs
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex-row justify-between items-center`}>
        <View>
          <Text type="h2">Diagnostic</Text>
        </View>

        {tabs()}

        <View style={tw`max-w-[200px] w-full h-10`}>
          <SearchBar value={search} textStyles={tw`bg-transparent`} clearable={true}
                     onelementChange={(sea) => {
                       let newList: any = []
                       data.value.filter((element) => {
                         if (element.email.includes(sea.toLowerCase())) {
                           newList.push(element);
                         }
                       })
                       setDataSearch({value: newList})
                     }}/>
        </View>
      </View>

      {tab === "all" ? (
        <View style={tw`flex-col mt-8  w-full flex-initial`}>
          <View style={tw`flex-row px-4 mb-4 `}>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray}>Name </Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray}>Email</Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray}>Extensions</Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray}>Devices</Text>
            </View>
          </View>

          {dataSeach!.value.map((response) => {
            return (
              <View
                style={tw`flex-row bg-white px-4 pr-8 py-3 rounded-3 items-between justify-between my-2 `}
                key={response.id}
              >
                <View style={tw`w-3/12  `}>
                  <Text style={tw`pr-4`} color={colors.dark}>{response.name}</Text>
                </View>
                <View style={tw`w-3/12  `}>
                  <Text style={tw`pr-4`} color={colors.gray}>{response.email}</Text>
                </View>
                <View
                  style={tw`flex w-3/12   ${globalState.id === response.id ? `flex-cols` : `flex-row`
                    } `}
                >
                  <View
                    style={tw`flex  lg:max-w-[200px] items-start justify-start `}
                  >
                    {globalState.id === response.id &&
                      globalState.statesData ? (
                      response.extension.map(
                        (responseExtensions, s: number) => {
                          return (
                            <Text
                              color={colors.dark}
                              weight="semiBold"
                              key={s}
                              numberOfLines={1}
                              ellipsizeMode="tail"
                            >
                              {responseExtensions}
                            </Text>
                          );
                        }
                      )
                    ) : (
                      <Texts
                        style={tw`text-gray-800 font-semibold`}
                        key={response.id}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {response.extension.map((data) => {
                          return data + "," + " ";
                        })}
                      </Texts>
                    )}
                  </View>
                </View>
                <View
                  style={tw`flex w-3/12  ${globalState.id === response.id ? `flex-cols` : `flex-row`
                    } `}
                >
                  {globalState.id === response.id && globalState.statesData ? (
                    response.devices.map((data, index: number) => {
                      return (
                        <View
                          style={tw`flex items-between justify-between bottom-4`}
                          key={index}
                        >
                          <TouchableOpacity
                            onPress={() =>
                              navigate("../details", {
                                state: data.deviceName,
                              })
                            }
                          >
                            <Text
                              style={tw`text-gray-800 font-semibold `}
                              key={index}
                            >
                              <Text
                                style={tw`${data.active
                                  ? `text-green-400`
                                  : `text-red-400`
                                  }
                    text-4xl`}
                                key={index}
                              >
                                .
                              </Text>
                              {data.deviceName}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      );
                    })
                  ) : (
                    <Texts
                      style={tw`text-gray-800 font-semibold`}
                      key={response.id}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {response.devices.map((data) => {
                        return data.deviceName + "," + " ";
                      })}
                    </Texts>
                  )}
                </View>

                <View style={tw`relative`}>
                  <View style={tw`flex  flex-row items-end absolute -mx-2  `}>
                    {globalState.id != response.id ? (
                      <TouchableOpacity
                        onPress={() =>
                          setglobalState({
                            id: response.id,
                            statesData: true,
                          })
                        }
                      >
                        <RightArrow color="gray" />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() =>
                          setglobalState({
                            id: 0,
                            statesData: false,
                          })
                        }
                      >
                        <DownArrow color="gray" />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      ) : (
        <View style={tw`flex-col mt-8  w-full flex-initial`}>
          <View style={tw`flex-row px-4 mb-4 `}>
            <View style={tw`flex w-1/12`}>
              <Text color={colors.gray}>No </Text>
            </View>
            <View style={tw`flex w-1/12 items-center justify-center`}>
              <Text style={tw`text-gray-400 pl-4`}>Time </Text>
            </View>
            <View style={tw`flex w-2/12 items-center justify-center`}>
              <Text style={tw`text-gray-400 pl-4`}>Source </Text>
            </View>

            <View style={tw`flex w-2/12 items-center  `}>
              <Text style={tw`text-gray-400 `}>Destination </Text>
            </View>
            <View style={tw`flex w-1/12 items-center  `}>
              <Text style={tw`text-gray-400 `}>Protocol </Text>
            </View>
            <View style={tw`flex w-1/12 items-center justify-center `}>
              <Text style={tw`text-gray-400 `}>Length </Text>
            </View>
            <View style={tw`flex w-1/12 items-center justify-center  `}>
              <Text style={tw`text-gray-400 `}>Info </Text>
            </View>
          </View>

          {data1.value.map((responseda) => {
            return (
              <View
                style={tw`flex-row bg-white px-4 py-3 rounded-3 items-between justify-between my-2 `}
                key={responseda?.id}
              >
                <View style={tw`flex w-1/12 items-start`}>
                  <Text style={tw`text-gray-800 `}>{responseda?.no}</Text>
                </View>
                <View style={tw`flex w-1/12 items-center justify-center `}>
                  <Text style={tw`text-gray-500 text-center `}>
                    {responseda?.time}
                  </Text>
                </View>
                <View style={tw`flex w-2/12 items-center justify-center  `}>
                  <Text style={tw`text-gray-500 pr-4  `}>
                    {responseda?.source}
                  </Text>
                </View>
                <View style={tw`flex w-1/12 items-center justify-center `}>
                  <Text style={tw`text-gray-500 pl-4 `}>
                    {responseda?.destination}
                  </Text>
                </View>
                <View style={tw`flex w-1/12 items-center justify-center `}>
                  <Text style={tw`text-gray-500 lg:pl-16 xl:pl-24    `}>
                    {responseda?.protocol}
                  </Text>
                </View>
                <View style={tw`flex w-1/12 items-center justify-center `}>
                  <Text style={tw`text-gray-500 lg:pl-0 xl:pl-16  `}>
                    {responseda?.length}
                  </Text>
                </View>

                <View
                  style={tw`flex w-full lg:max-w-4/12  flex-row items-center justify-center `}
                >
                  {responseda?.info.map((responseInfo, s: number) => {
                    return (
                      <Text style={tw`text-gray-800 text-sm `} key={s}>
                        {s === responseda?.info.slice(0, 2).length - 1
                          ? responseInfo + `...` + ` `
                          : responseInfo + `,` + ` `}
                      </Text>
                    );
                  })}
                </View>

                <View style={tw`flex relative items-end justify-end`}>
                  <TouchableOpacity>
                    <Icon
                      color="#C8C8C8"
                      name="more-vert"
                      size={20}
                      tvParallaxProperties={undefined}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};

export default Diagnostic;

const styles = StyleSheet.create({
  button1: {
    fontFamily: "OpenSans_400Regular",
    color: "#0E2989",
    fontSize: 14,
    lineHeight: 21,
  },
  button2: {
    fontFamily: "OpenSans_400Regular",
    color: "#717185",
    fontSize: 14,
    lineHeight: 21,
  },
});
