import { StyleSheet, Text as Texts, View } from "react-native";
import React, { useState } from "react";
import { Button, Text } from "@/components/Commons";
import { colors, tw } from "@/lib";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import { Dropdown } from "react-bootstrap";

import { DownArrow } from "@/components/Icons/DownArrow";
import Usage_Report from "@/components/Reports/UsageComponent";
import { Card } from "react-native-elements";
import { useDeviceContext } from "twrnc";

const Usage = () => {
  useDeviceContext(tw);
  const [tab, changeTab] = useState<"Extension" | "Destination" | "Total">(
    "Extension"
  );
  const [data, setData] = useState({
    value: [
      {
        id: 1,
        extension: "112",
        calls: "48",
        totalDuration: "1:16:31",
        totalCost: "0.00",
        avgDuration: "1:35",
        avgCost: "0.00",
      },
    ],
  });
  const [dataExtension, setDataExtension] = useState({
    value: [
      {
        id: 1,
        extension: "011 Switzerland",
        calls: "48",
        totalDuration: "1:16:31",
        totalCost: "0.00",
        avgDuration: "1:35",
        avgCost: "0.00",
      },
    ],
  });

  const [dataTotals, setDataTotals] = useState({
    value: [
      {
        id: 1,
        name: "Duration",
        outCommingCalls: "02:02:43",
        incommingCalls: "06:54:33",
        Summary: "08:55:23",
      },
      {
        id: 2,
        name: "Charges",
        outCommingCalls: "$2112",
        incommingCalls: "$2327",
        Summary: "$4439",
      },
    ],
  });
  const [dataTotal, setDataTotal] = useState({
    value: [
      {
        id: 1,
        name: "TF Inbound",
        totalMinutes: "165",
        totalCost: "$5.86",
      },
      {
        id: 2,
        name: "Total LD",
        totalMinutes: "48",
        totalCost: "1:16:31",
      },
      {
        id: 3,
        name: "North America LD",
        totalMinutes: "48",
        totalCost: "1:16:31",
      },
    ],
  });

  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();
    return isActive
      ? {
          btn: tw`bg-white rounded-3`,
          text: tw`text-primary`,
        }
      : {
          text: tw`text-dark-gray`,
        };
  };
  const tabs = () => {
    const extensionTickets = getTabStyle(() => tab == "Extension");
    const destinationTickets = getTabStyle(() => tab == "Destination");
    const totalTickets = getTabStyle(() => tab == "Total");
    return (
      <View
        style={tw`flex-row bg-[#EBEBF0] max-w-[500px] w-full h-10 rounded-3 my-5 md:my-0`}
      >
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            extensionTickets.btn,
          ]}
          onPress={() => {
            tab != "Extension" && changeTab("Extension");
          }}
        >
          <Text weight="semiBold" style={extensionTickets.text}>
            Extension
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            destinationTickets.btn,
          ]}
          onPress={() => {
            tab != "Destination" && changeTab("Destination");
          }}
        >
          <Text weight="semiBold" style={destinationTickets.text}>
            Destination
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            totalTickets.btn,
          ]}
          onPress={() => {
            tab != "Total" && changeTab("Total");
          }}
        >
          <Text weight="semiBold" style={totalTickets.text}>
            Total
          </Text>
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex md:flex-row md:justify-between `}>
        <View
          style={tw`flex md:flex-row md:items-center md:justify-around lg:pl-6`}
        >
          <Text type="h2" weight="extraBold">
            CDR
          </Text>
        </View>
        {tabs()}
        <View style={tw`max-w-[172px] w-full`}>
          <Button type="outlined">
            <Text weight="semiBold" type="regular">
              Export{" "}
            </Text>
          </Button>
        </View>
      </View>
      <View style={[tw`flex-row py-16 h-[150px] `, { zIndex: "auto" }]}>
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            style={{
              lineHeight: 0,
              ...tw`no-underline bg-white py-1 px-4 rounded-2`,
            }}
            size="sm"
            id="dropdown-basic"
            bsPrefix="btn"
          >
            <View style={tw`flex-row items-center`}>
              <Text style={tw`pr-6`}>Month</Text>
              <DownArrow color="black" />
            </View>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              style={tw`text-black text-btn-menu-item font-semibold`}
            >
              Month
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </View>
      {tab === "Extension" && <Usage_Report data={data} />}
      {tab === "Destination" && (
        <Usage_Report data={dataExtension} extension={"Destination"} />
      )}
      {tab === "Total" && (
        <>
          <Card containerStyle={tw`w-full lg:max-w-[511px] rounded-3`}>
            <ScrollView
              horizontal
              contentContainerStyle={tw`flex flex-col w-full`}
            >
              <View style={tw`flex flex-row w-118 items-center justify-end`}>
                <Text
                  weight="semiBold"
                  style={tw`text-dark-gray w-[150px]`}
                ></Text>
                <Text
                  weight="semiBold"
                  style={tw`text-dark-gray w-[150px] pl-4`}
                >
                  Total Minutes
                </Text>
                <Text
                  weight="semiBold"
                  style={tw`text-dark-gray w-[150px] pl-4`}
                >
                  Total Cost
                </Text>
              </View>

              <View
                style={tw`flex bg-white mt-4  py-6  rounded-3  lg:max-w-full`}
              >
                {dataTotal.value.map((item, index: number) => {
                  return (
                    <View style={tw`flex flex-row my-4`} key={index}>
                      <View style={tw`w-[150px]`}>
                        <Text weight="semiBold" style={tw`text-dark-gray`}>
                          {item.name}
                        </Text>
                      </View>
                      <View style={tw`w-[150px]  items-center justify-center`}>
                        <Text weight="semiBold">{item.totalCost}</Text>
                      </View>
                      <View style={tw`w-[150px] items-center justify-center`}>
                        <Text weight="semiBold">{item.totalMinutes}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </ScrollView>
          </Card>

          <View style={tw`flex md:flex-row md:justify-between  my-2`}>
            <View
              style={tw`flex md:flex-row md:items-center md:justify-around lg:pl-6 pl-6 md:pl-0 my-3 md:my-0`}
            >
              <Text type="h2" weight="extraBold">
                CDR Report
              </Text>
            </View>

            <View style={tw`max-w-[172px] w-full`}>
              <Button type="outlined">
                <Text weight="semiBold" type="regular">
                  Export{" "}
                </Text>
              </Button>
            </View>
          </View>

          <Card containerStyle={tw`w-full rounded-3 pt-5 lg:max-w-1/2`}>
            <ScrollView
              horizontal
              contentContainerStyle={tw`flex-col w-full min-w-80`}
            >
              <View style={tw`flex flex-col w-full  my-2 `}>
                <View style={tw`flex flex-row  `}>
                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[110px]  py-2  items-center justify-start   `}
                  ></View>

                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[180px] py-2  items-center justify-start pl-2   `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.gray}>
                      Outcoming Calls
                    </Text>
                  </View>
                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[180px]  py-2  items-center justify-start pl-2  `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.gray}>
                      Incoming Calls
                    </Text>
                  </View>

                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[80px]  py-2  items-center justify-center   `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.gray}>
                      Summary
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={tw`flex flex-col w-full border-t-2 border-gray-200 my-2 `}
              >
                {dataTotals.value.map((item, index: number) => {
                  return (
                    <View style={tw`flex flex-row  `} key={index}>
                      <View
                        style={tw`flex-1 flex-row   lg:max-w-[110px]  py-2 border-r-2 border-gray-200 items-center justify-start   `}
                      >
                        <Text
                          type="regular"
                          weight="semiBold"
                          color={colors.gray}
                        >
                          {item.name}
                        </Text>
                      </View>

                      <View
                        style={tw`flex-1 flex-row   lg:max-w-[180px] py-2  items-center justify-start pl-2   `}
                      >
                        <Text
                          type="regular"
                          weight="semiBold"
                          color={colors.black}
                        >
                          {item.outCommingCalls}
                        </Text>
                      </View>
                      <View
                        style={tw`flex-1 flex-row   lg:max-w-[180px]  py-2  items-center justify-start pl-2  `}
                      >
                        <Text
                          type="regular"
                          weight="semiBold"
                          color={colors.black}
                        >
                          {item.incommingCalls}
                        </Text>
                      </View>

                      <View
                        style={tw`flex-1 flex-row   lg:max-w-[80px]  py-2  items-center justify-center   `}
                      >
                        <Text
                          type="regular"
                          weight="semiBold"
                          color={colors.black}
                        >
                          {item.Summary}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </ScrollView>
          </Card>
        </>
      )}
    </View>
  );
};

export default Usage;

const styles = StyleSheet.create({});
