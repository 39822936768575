import { Dimensions } from "react-native";

const width = () => Dimensions.get("window").width;

export const Breakpoints = {
  sm: 640,

  md: 768,

  lg: 1024,

  xl: 1360,

  xxl: 1536,
};

/**
 * Check if display size is bigger than small
 * @returns {Boolean}
 */
export const sm = () => Breakpoints.sm <= width();
/**
 * Check if display size is bigger than medium
 * @returns {Boolean}
 */
export const md = () => Breakpoints.md <= width();
/**
 * Check if display size is bigger than large
 * @returns {Boolean}
 */
export const lg = () => Breakpoints.lg <= width();
/**
 * Check if display size is bigger than extra large
 * @returns {Boolean}
 */
export const xl = () => Breakpoints.xl <= width();
/**
 * Check if display size is bigger than extra extra large
 * @returns {Boolean}
 */
export const xxl = () => Breakpoints.xxl <= width();

/**
 * Check if display size is mobile size
 * @returns {Boolean}
 */
export const mobile = () => Breakpoints.sm >= width();
