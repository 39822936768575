import { View } from "react-native";
import React from "react";
import { useDeviceContext } from "twrnc";

import { colors, tw } from "@/lib";
import { Button, Text } from "@/components/Commons";
import { useNavigate } from "@/routes";

export const NotFound = () => {
  useDeviceContext(tw);
  const navigate = useNavigate();
  return (
    <View
      style={tw`w-full h-full flex items-center justify-center bg-light-green`}
    >
      <Text color={colors.blue} weight="bold" style={tw`text-5xl`}>
        Page Not Found
      </Text>
      <Button style={tw`max-w-[200px] mt-5`} onPress={() => navigate(-1)}>
        <Text>Go Back</Text>
      </Button>
    </View>
  );
};
