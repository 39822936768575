import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Info = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { color = "white", style } = props;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3341 2.75009H7.6651C4.6441 2.75009 2.7501 4.88909 2.7501 7.91609V16.0841C2.7501 19.1111 4.6351 21.2501 7.6651 21.2501H16.3331C19.3641 21.2501 21.2501 19.1111 21.2501 16.0841V7.91609C21.2501 4.88909 19.3641 2.75009 16.3341 2.75009Z"
        stroke={color}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.9947 16.0001V12.0001"
        stroke={color}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.9899 8.20428H11.9999"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
