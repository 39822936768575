import { IExposedConfig } from "@/common/config/interfaces/config.interface";
import { ApiService } from "@/services";

const config = "config";
let allConfig: IExposedConfig;

class ConfigService {
  async getAll(): Promise<IExposedConfig> {
    if (allConfig && Object.keys(allConfig).length > 0) return allConfig;
    const response = await ApiService.api.get(`${config}`, {});
    allConfig = response.data.result;
    return allConfig;
  }
}
const singleton = new ConfigService();
export default singleton;
