import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getAnalytics,
  initializeAnalytics,
  isSupported,
  logEvent,
  setUserId,
} from "firebase/analytics";
import TagManager from "react-gtm-module";
import { ConfigService } from "@/services";

export const BUTTON_ADDRESS_INPUT = "button_address_input"; //click on address in the precisely box
export const BUTTON_BACK = "button_back"; //click any back button in the admin / registration site
export const BUTTON_COMPANY_DETAIL = "button_company_detail";
export const BUTTON_PHONE_CREATE_WORKSPACE = "button_phone_create_workspace";
export const BUTTON_PHONE_MODAL_OK = "button_phone_modal_ok"; //click modal popup ok on phone number screen
export const BUTTON_REGISTRATION_CLICK = "button_registration_click"; //When the user clicks to send the email but before the email check
export const BUTTON_SMS_CHECK_CODE = "button_sms_check_code";
export const BUTTON_SMS_SEND_CODE = "button_sms_send_code";
export const BUTTON_SUCCESS_ONBOARD = "button_sucess_onboard";
export const ERROR_AUTH_CHECK = "error_auth_check"; // failure to

class FirebaseService {
  private firebase: FirebaseApp | undefined;
  async getFirebaseConfig() {
    const config = await ConfigService.getAll();
    return {
      apiKey: config.firebaseApiKey,
      authDomain: config.firebaseAuthDomain,
      projectId: config.firebaseProjectId,
      storageBucket: config.firebaseStorageBucket,
      messagingSenderId: config.firebaseMessageSenderId,
      appId: config.firebaseAppId,
      measurementId: config.firebaseMeasurementId,
      gtmId: config.gtmId,
      firebaseDebug: config.firebaseDebug,
    };
  }

  async getFirebase() {
    if (!this.firebase) {
      const isSupport = await isSupported();
      const firebaseConfig = await this.getFirebaseConfig();
      if (firebaseConfig.measurementId && isSupport) {
        this.firebase = initializeApp(firebaseConfig);
        initializeAnalytics(this.firebase, {
          config: {
            send_page_view: false,
            debug_mode: firebaseConfig.firebaseDebug,
          },
        });

        if (firebaseConfig.gtmId) {
          const tagManagerArgs = {
            gtmId: firebaseConfig.gtmId,
          };
          TagManager.initialize(tagManagerArgs);
        }
      }
    }
    return this.firebase;
  }

  async logPage(page_path: string) {
    this.logFirebaseEvent("screen_view", {
      firebase_screen: page_path,
      firebase_screen_class: "FirebaseAnalytics",
    });
    this.logFirebaseEvent("page_view", {
      page_location: page_path,
      page_title: page_path,
      page_path,
    });
  }

  async logFirebaseEvent(
    eventName: string,
    eventProps?: { [key: string]: any } | undefined
  ) {
    if (typeof window !== "undefined") {
      const firebaseApp = await this.getFirebase();
      const isSupport = await isSupported();
      if (firebaseApp && isSupport) {
        const analytics = getAnalytics(firebaseApp);
        if (analytics) {
          logEvent(analytics, eventName, eventProps);
        }
      }
    }
  }

  async setFirebaseUserId(userId: string | null) {
    if (typeof window !== "undefined") {
      const firebaseApp = await this.getFirebase();
      const isSupport = await isSupported();
      if (firebaseApp && isSupport) {
        const analytics = getAnalytics(firebaseApp);
        if (analytics) {
          setUserId(analytics, userId);
        }
      }
    }
  }
}
const singleton = new FirebaseService();
export default singleton;
