import { TouchableOpacity, View } from "react-native";
import React, { useState } from "react";

import {
  tw,
  useFieldControl,
  mail,
  number,
  required,
  Modal,
  extensions,
  password,
  min,
  max,
  colors,
} from "@/lib";
import { Text, Button } from "@/components/Commons";
import { TextInput } from "@/components/Inputs";
import { Icon } from "react-native-elements";
import { equalPassword } from "@/lib/errorMessage";
import { PasswordService, SuperAdminService } from "@/services";
import { SuperAdminPassword } from "@/types/superAdmin.types";
import { PasswordChangeSuperAdmin } from "@/types";
import { useToast } from "react-native-toast-notifications";

//@ts-ignore
export const ChangeSuperAdminPassword = ({ modal, id }) => {
  //Form Inputs

  const toastShow = (message: string) => {
    toast.show(message, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: (
        <Icon
          name="check"
          size={18}
          color={colors.white}
          tvParallaxProperties={undefined}
        />
      ),
    });
  };
  const superAdminForm = {
    password1: useFieldControl<string>("", [
      required,
      password,
    ]),
    password2: useFieldControl<string>("", [
      required,
      password,
    ]),
  };
  const toast = useToast();
  const [isSecureEntry1, setIsSecureEntry1] = useState<boolean>(true);
  const [isSecureEntry2, setIsSecureEntry2] = useState<boolean>(true);
  const [error, setError] = useState("");

  const values =
    superAdminForm.password1.value === superAdminForm.password2.value;
  const newPassword = async () => {
    if (values) {
      //navigate("recoveryLoginSuccess");
      try {
        const input: PasswordChangeSuperAdmin = {
          userId: id,
          newPassword: superAdminForm.password2.value,
        };
        await PasswordService.changePasswordfromSuperAdmin(input);
        Modal.dismiss();
        toastShow("The password was changed successfully");
      } catch (error) {
        console.log(error);
        Modal.dismiss();
      }
    } else {
      setError(equalPassword);
    }
  };

  return (
    <>
      <View style={tw`flex-row justify-start mb-2 `}>
        <Text style={tw`font-normal text-sm text-gray-400`}>
          Please fill in fields below, after that we will change the password of
          the superadmin
        </Text>
      </View>
      <View style={tw`flex-grow-1 mb-4 w-full justify-between `}>
        <TextInput
          label="New Password"
          rightIcon={
            <TouchableOpacity>
              <Icon
                // @ts-ignore

                name={isSecureEntry1 ? "eye-slash" : "eye"}
                size={20}
                type="font-awesome"
                color="#9CA3AF"
                onPress={() => {
                  setIsSecureEntry1((prev) => !prev);
                }}
                tvParallaxProperties={undefined}
              />
            </TouchableOpacity>
          }
          placeholder="********"
          value={superAdminForm.password1.value}
          secureTextEntry={isSecureEntry1}
          errorMessage={superAdminForm.password1.error}
          onChangeText={superAdminForm.password1.handleInputValue}
        />

        <TextInput
          label="Confirm New Password"
          rightIcon={
            <TouchableOpacity>
              <Icon
                // @ts-ignore

                name={isSecureEntry2 ? "eye-slash" : "eye"}
                size={20}
                type="font-awesome"
                color="#9CA3AF"
                onPress={() => {
                  setIsSecureEntry2((prev) => !prev);
                }}
                tvParallaxProperties={undefined}
              />
            </TouchableOpacity>
          }
          placeholder="********"
          value={superAdminForm.password2.value}
          secureTextEntry={isSecureEntry2}
          errorMessage={superAdminForm.password2.error}
          onChangeText={superAdminForm.password2.handleInputValue}
        />

        <View style={tw` flex items-center justify-center`}>
          <Text style={tw` text-red-500 text-center`}>{error}</Text>
        </View>
      </View>

      <View style={tw`flex flex-row items-end justify-end my-2 `}>
        <Button
          style={tw`max-w-1/2`}
          type="outlined"
          onPressOut={modal.dismiss}
        >
          Cancel
        </Button>

        <Button style={tw`max-w-1/2`} onPress={newPassword}>
          Change
        </Button>
      </View>
    </>
  );
};
