import { WorkspaceInterface } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: WorkspaceInterface[] = [];

const notificationsSlice = createSlice({
  name: "notificationsStore",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<WorkspaceInterface[]>) => [...action.payload],
    updateNotification: (state, action: PayloadAction<WorkspaceInterface>) => {
      const index = state.findIndex( item =>  item.id === action.payload.id);
      if (index>=0 && index<state.length) state[index] = action.payload;
      return state; 
    },
    clearInfo: (state) => {
      return initialState;
    },
  },
});

export default notificationsSlice.reducer;

export const {
  setNotifications,
  clearInfo,
  updateNotification,
} = notificationsSlice.actions;
