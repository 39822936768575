import { AxiosResponse, Axios } from "axios";
import { ApiService } from "./";
import {
  AccessLevel,
  AllWorkspaces,
  CurrentWorkspaceRequest,
  CurrentWorkspaceResponse,
  GlobalAccessResponse,
  InvitedUser,
  IUpdateWorkspaceAccessControl,
  PermitionRequest,
  Status,
  UserResp,
  WorkSpace,
  WorkSpaceClone,
  WorkspaceInterface,
  WorkspaceResponse,
} from "@/types";
import { ApiObjectResponse } from "@/types";
import { AxiosHeaders } from "@/services";

const endpoint = "workspaces";

class WorkspaceService {
  //Create Workspace
  async create(
    input: WorkSpace
  ): Promise<AxiosResponse<ApiObjectResponse<any>>> {
    return ApiService.api.post(endpoint, input);
  }

  //Get the current Workspace
  async getCurrent(): Promise<AxiosResponse<{ result: WorkspaceResponse }>> {
    return ApiService.api.get(`${endpoint}/current`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Send invitation to users
  async inviteCollegues(workspaceId: number, emails: string[]) {
    return ApiService.api.post(
      `${endpoint}/invite/collegues`,
      { workspaceId, emails },
      { headers: AxiosHeaders.headersWithCredentials() }
    );
  }

  //Remove invitation to users
  async removeInviteCollegues(workspaceId: number, userIds: number[]) {
    return ApiService.api.delete(`${endpoint}/invite/collegues`, {
      data: { workspaceId, userIds },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Resend invitation to users
  async resendInviteCollegues(workspaceId: number, userIds: number[]) {
    return ApiService.api.put(
      `${endpoint}/invite/collegues`,
      { workspaceId, userIds },
      {
        headers: AxiosHeaders.headersWithCredentials(),
      }
    );
  }

  //Get all users with { pagination, offset, limit }
  async getAllUsersWithPagination(
    wpId: number,
    offset: number,
    limit: number,
    query?: string
  ): Promise<
    AxiosResponse<{ result: { list: UserResp[]; totalCount: number } }>
  > {
    return ApiService.api.get(`${endpoint}/users`, {
      params: { offset, limit, query, wpId },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async getAccessRequest(
    workspaceId: number,
    allStatuses: boolean = true,
    order: number = 1
  ): Promise<AxiosResponse<{ result: WorkspaceInterface[] }>> {
    return ApiService.api.get(`${endpoint}/access-request`, {
      params: { workspaceId, allStatuses, order },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async requestPermition(
    input: PermitionRequest
  ): Promise<AxiosResponse<ApiObjectResponse<any>>> {
    return ApiService.api.post(`${endpoint}/access-request`, input, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async updateAccess(accessRequest: IUpdateWorkspaceAccessControl) {
    return ApiService.api.put(`${endpoint}/access-request`, accessRequest, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async revokeAccess(workspaceId: number, supportUserId: number) {
    return ApiService.api.delete(`${endpoint}/revoke-access`, {
      data: { workspaceId, supportUserId },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
  //Get all invited users with { pagination, offset, limit }
  async getAllInviteUsersWithPagination(
    id: number,
    offset: number,
    limit: number,
    query: string
  ): Promise<
    AxiosResponse<{ result: { list: InvitedUser[]; totalCount: number } }>
  > {
    return ApiService.api.get(`${endpoint}/invite/collegues`, {
      params: { wpId: id, offset, limit, query },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Send invitation to users
  async updateCoverPhoto(
    photo: File
  ): Promise<AxiosResponse<{ result: WorkspaceResponse }>> {
    var data = new FormData();
    data.append("file", photo);

    return ApiService.api.put(`${endpoint}/cover-photo`, data, {
      headers: AxiosHeaders.headersWithCredentialsFile(),
    });
  }

  //Send invitation to users
  async deleteCoverPhoto() {
    return ApiService.api.delete(`${endpoint}/cover-photo`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Send invitation to users
  async updateCoverPhotoById(
    id: number,
    photo: File
  ): Promise<AxiosResponse<{ result: WorkspaceResponse }>> {
    var data = new FormData();
    data.append("file", photo);

    return ApiService.api.put(`${endpoint}/${id}/cover-photo`, data, {
      headers: AxiosHeaders.headersWithCredentialsFile(),
    });
  }

  //Send invitation to users
  async deleteCoverPhotoById(id: number) {
    return ApiService.api.delete(`${endpoint}/${id}/cover-photo`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Get Workspace Info
  async getWorkspaceInfo(
    id: number
  ): Promise<AxiosResponse<{ result: CurrentWorkspaceResponse }>> {
    return ApiService.api.get(`${endpoint}/${id}`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  // Modify Workspace Info
  async setWorkspaceInfo(id: number, data: CurrentWorkspaceRequest) {
    return ApiService.api.patch(`${endpoint}/${id}`, data, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Get all users with { pagination, offset, limit }
  async searchWorkspace(
    offset: number,
    limit?: number,
    search?: string
  ): Promise<
    AxiosResponse<{ result: { list: AllWorkspaces[]; totalCount: number } }>
  > {
    return ApiService.api.get(`${endpoint}/search`, {
      params: { search, offset, limit },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
  async globalAccess(
    input: WorkspaceInterface
  ): Promise<AxiosResponse<ApiObjectResponse<any>>> {
    return ApiService.api.post(`${endpoint}/global-access`, input, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
  async globalAccessData(
    workspaceId: number,
    type?: string
  ): Promise<AxiosResponse<{ result: GlobalAccessResponse[] }>> {
    return ApiService.api.get(`${endpoint}/global-access`, {
      params: { workspaceId, type },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
}

const singleton = new WorkspaceService();
export default singleton;
