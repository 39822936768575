import { View, ActivityIndicator } from "react-native";
import React, { useEffect, useState } from "react";
import { useDeviceContext } from "twrnc";
import { useParams } from "react-router-dom";

import { ApiService, AuthService, TokenService } from "@/services";
import { AxiosError } from "axios";
import { colors, showFriendlyErrorFromHTMLCode, tw } from "@/lib";
import { useAlert } from "@/hooks";
import { AuthResponse } from "@/types";
import { useNavigate } from "@/routes";
import { Text } from "@/components/Commons";
import { useAppDispatch } from "@/store/hooks";
import { setUser } from "@/store/user.store";

export const Check = () => {
  useDeviceContext(tw);
  const { refreshToken } = useParams();
  const { showSwal } = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const checkUser = async () => {
    try {
      const res = await ApiService.api.put<{
        result: AuthResponse;
      }>("/auth", { token: refreshToken });

      const user: AuthResponse = res.data.result;

      // if (TokenService.getUser()?.user) {
      //   await AuthService.Logout();

      // }
      dispatch(
        setUser({
          rol: user.user.rol,
        })
      );

      TokenService.setUser(user);

      //showSwal({ msg: "User logged in successfully", type: "success" });
      if (!user.user.rol?.includes("super_admin")) navigate("/company/users");
      else navigate("SuperAdminWorkspaces");
    } catch (err) {
      const error = err as AxiosError;
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <View
      style={tw`flex-grow-1 flex items-center justify-center bg-[${colors.rebrandLayoutBGColor}]`}
    >
      {loading ? (
        <ActivityIndicator size="large" />
      ) : (
        <View style={tw`flex md:flex-row`}>
          <Text style={tw`text-2xl text-center md:text-3xl`}>
            Please check your credentials and
          </Text>
          <Text
            style={tw`text-2xl text-center ml-1 text-btn md:text-3xl`}
            weight="semiBold"
          >
            Try again
          </Text>
        </View>
      )}
    </View>
  );
};
