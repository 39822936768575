import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AlertStatus = "error" | "success" | "info" | "warning" | "danger";

export interface Alert {
  msg: string;
  type: AlertStatus;
  showAlert?: boolean;
}

const initialState: Alert = {
  msg: "",
  type: "error",
  showAlert: false,
};

const alertSlice = createSlice({
  name: "alertStore",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert>) => {
      return {
        ...state,
        ...action.payload,
        showAlert: true,
      };
    },
    resetAlert: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export default alertSlice.reducer;

export const { setAlert, resetAlert } = alertSlice.actions;
