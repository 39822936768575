import { View } from "react-native";
import React from "react";

import { tw, useFieldControl, mail, number, required } from "@/lib";
import { Text, Button } from "@/components/Commons";
import { TextInput } from "@/components/Inputs";

//@ts-ignore
export const AddAdminForm = ({ modal }) => {
  //Form Inputs
  const compensationForm = {
    email: useFieldControl<string>("", [mail, required]),
    compensation: useFieldControl<string>("", [number, required]),
  };

  return (
    <>
      <View style={tw`flex-row justify-start mb-2 `}>
        <Text style={tw`font-normal text-sm text-gray-400`}>
          Please fill in fields below, after that we send this admin invite
          link.
        </Text>
      </View>
      <View style={tw`flex-grow-1 mb-4 w-full justify-between `}>
        <TextInput
          label="Email"
          value={compensationForm.email.value}
          errorMessage={compensationForm.email.error}
          autoCapitalize="none"
          placeholder="email@email.com"
          type="email"
          onChangeText={compensationForm.email.handleInputValue}
        />

        <TextInput
          label="Compensation %"
          value={compensationForm.compensation.value}
          errorMessage={compensationForm.compensation.error}
          onChangeText={compensationForm.compensation.handleInputValue}
          placeholder="10 %"
          type="number"
        />
      </View>

      <View style={tw`flex flex-row items-end justify-end my-2 `}>
        <Button
          style={tw`max-w-1/2`}
          type="outlined"
          onPressOut={modal.dismiss}
        >
          Cancel
        </Button>

        <Button
          style={tw`max-w-1/2`}
          onPressOut={() => {
            modal.dismiss();
          }}
        >
          Send Invite
        </Button>
      </View>
    </>
  );
};
