import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Circle, Path } from "react-native-svg";

export const Stat = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { color = "white", style } = props;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <Path
        d="M7.24485 14.7815L10.238 10.8913L13.6522 13.5732L16.5812 9.79291"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle
        cx="19.9954"
        cy="4.20024"
        r="1.9222"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.9245 3.12015H7.65675C4.64531 3.12015 2.77803 5.25287 2.77803 8.26431V16.3467C2.77803 19.3581 4.6087 21.4817 7.65675 21.4817H16.2609C19.2723 21.4817 21.1396 19.3581 21.1396 16.3467V9.30779"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
