import parsePhoneNumber, { CountryCode } from "libphonenumber-js";

interface Props {
  phone: string;
  country?: CountryCode;
}

export const usePhoneNumber = ({ phone, country = "CA" }: Props) => {
  const phoneNumber = parsePhoneNumber(phone, country);
  let errorPhoneMsg = "";

  if (phoneNumber == undefined) {
    errorPhoneMsg = "The field is required";
  } else {
    if (!phoneNumber.isValid()) errorPhoneMsg = "The format is invalid";
  }

  return {
    isValidPhone: phoneNumber?.isValid(),
    formattedNumber: phoneNumber?.formatInternational(),
    errorPhoneMsg,
  };
};
