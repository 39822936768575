import { useCallback, useEffect, useRef, useState } from "react";

import { debounce, useFieldControl } from "@/lib";

interface Props {
  fetchData: (
    isScroll: boolean,
    currentPage: number,
    keyToSearch?: string
  ) => Promise<void>;
  data: any[];
  dataLimit: number
}

export const useInfinityScroll = ({ fetchData, data, dataLimit }: Props) => {

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const search = useFieldControl<string>("");
  const isFirstRender = useRef<boolean | null>(null);
  const [isLoadingMore, setLoadingMore] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    getInitialData();
  }, []);

  const fetchMoreData = useCallback(() => {
    if (data.length < dataLimit) {
      console.log("scroll", data.length, dataLimit)
      setCurrentPage(currentPage + 1);
      setLoadingMore(true);
    }
  }, [data, dataLimit, currentPage]);

  useEffect(() => {
    const keyToSearch = search.value !== "" ? search.value : undefined;
    if (currentPage > 0) getData(true, keyToSearch);
  }, [currentPage]);

  useEffect(() => {
    if (!isFirstRender.current) {
      if (search.value !== "") {
        getData(false, search.value);
      } else {
        getData(false);
      }
      setFocus(true);
    }
  }, [search.value]);

  // Methods
  const getInitialData = async () => {
    isFirstRender.current = true;
    await getData(false);
    isFirstRender.current = false;
  };

  const getData = async (isScroll: boolean, keyToSearch = "") => {
    !isScroll ? setIsLoading(true) : setLoadingMore(true);
    await fetchData(isScroll, currentPage, keyToSearch);
    !isScroll ? setIsLoading(false) : setLoadingMore(false);
  };

  const changeSearchValue = debounce((query: string) => {
    setCurrentPage(0);
    search.handleInputValue(query);
  });

  return {
    search,
    isLoading,
    isLoadingMore,
    focus,
    ///Methods
    changeSearchValue,
    fetchMoreData
  }
}