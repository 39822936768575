import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import { ProductSuscription } from "./ProductSuscription";
import { PlanResponse } from "@/types";
import { Modal, tw } from "@/lib";
import { Button, Text } from "@/components/Commons";
import { ProductAddPlanForm } from "./ProductAddPlanForm";

const plans: PlanResponse[] = [
  {
    id: "6164a44e4d9271674a01a193",
    name: "Pro",
    price_per_unit: "$34.99",
    unit: "per user",
    updated_at: "2022-04-18T16:59:50.562000Z",
    is_enabled: true,
    product_name: "newt_cloud",
    description: [
      "Access all NEWT Cloud Pro features on a monthly plan.",
      "Up to 10 users",
      "Unlimited calling in Canada and the U.S.*",
      "Unlimited internet fax",
      "Call recording with unlimited storage",
      "Toll-free business phone number*",
    ],
    display_order: 10,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 1,
      max: 10,
    },
    term: "month",
  },
  {
    id: "623b4751b6fa865e266585f2",
    name: "Enterprise",
    product_name: "newt_cloud",
    is_enabled: false,
    updated_at: "2022-04-14T18:20:09.969000Z",
    description: [
      "Contact our sales team to get the solution that works best for your company!",
      "Unlimited users",
      "Unlimited calling in Canada and the U.S.*",
      "Unlimited internet fax",
      "Call recording with unlimited storage*",
      "Toll-free business phone number*",
    ],
    display_order: 100,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 11,
      max: "unlimited",
    },
    price_per_unit: "Contact Sales",
    term: "",
    unit: "",
  },
  {
    id: "723b4751b6fa865e266585f2",
    name: "Enterprise",
    product_name: "newt_cloud",
    is_enabled: true,
    updated_at: "2022-04-14T18:20:09.969000Z",
    description: [
      "Contact our sales team to get the solution that works best for your company!",
      "Unlimited users",
      "Unlimited calling in Canada and the U.S.*",
      "Unlimited internet fax",
      "Call recording with unlimited storage*",
      "Toll-free business phone number*",
    ],
    display_order: 100,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 11,
      max: "unlimited",
    },
    price_per_unit: "$100.99",
    term: "month",
    unit: "per user",
  },
];
function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}

const ProductList = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [data, setData] = useState<PlanResponse[]>([]);
  useEffect(() => {
    setData(plans);
  }, [plans]);
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [getWindowSize]);

  const showModalForRemove = (id: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove Subscription?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to remove subscription plan? </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw`flex-1`}>
              <Text weight="semiBold">Remove</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const showModalForAddEdit = (id?: string) => {
    Modal.show(
      <View style={tw`flex w-full max-h-[160]    `}>
        <View style={tw`flex flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            {id ? "Edit Plan" : "Add Plan"}
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <ProductAddPlanForm />

        {id && (
          <TouchableOpacity
            onPress={() => {
              Modal.dismiss();
              showModalForRemove(id);
            }}
            style={tw`flex my-2 `}
          >
            <Text weight="semiBold" style={tw`text-red-500  ml-2`}>
              Delete Plan
            </Text>
          </TouchableOpacity>
        )}

        <View style={tw`flex w-full  flex-row justify-end  `}>
          <View style={tw`flex flex-row `}>
            <Button
              style={tw`w-30 lg:w-45`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw` w-30 lg:w-45 `} onPressOut={Modal.dismiss}>
              <Text weight="semiBold">Save</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: windowSize.innerWidth < 752 ? windowSize.innerWidth : 752 }
    );
  };

  //Function to handle which button show

  return (
    <View style={tw``}>
      <View style={tw`flex-row justify-between lg:my-4 `}>
        <View style={tw`flex ml-4`}>
          <Text type="h2">Subscription</Text>
        </View>
        <View style={tw`w-full max-w-[170px] w-full`}>
          <Button type="outlined" onPress={() => showModalForAddEdit()}>
            Add Plan
          </Button>
        </View>
      </View>
      <ProductSuscription
        data={data}
        handleEditSuscription={showModalForAddEdit}
        showModalForRemove={showModalForRemove}
      />
    </View>
  );
};

export default ProductList;

const styles = StyleSheet.create({});
