import React, { useEffect, useState } from "react";
import { View } from "react-native";

import { Banner } from "@/components/Workspace/Banner";
import { Text } from "@/components/Commons";
import { tw } from "@/lib";
import { useDeviceContext } from "twrnc";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { clearInfo } from "@/store/banner.store";
import { RootState } from "@/store";

export function AdminBanner() {
  useDeviceContext(tw);
  const dispatch = useAppDispatch();
  const { accessLevel, name, rol } = useAppSelector(
    (state: RootState) => state.banner
  );

  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    setShowBanner(true);

    const timeout = setTimeout(() => {
      setShowBanner(false);
      dispatch(clearInfo());
    }, 8000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <View style={tw`sticky`}>
      {showBanner && (
        <Banner buttonText="Request Edit Access" showButton={false}>
          <View style={tw`flex flex-row`}>
            <Text
              style={tw`text-white text-lg tracking-wide mr-1`}
              weight="semiBold"
            >
              {rol}
            </Text>
            <Text style={tw`text-white text-lg text-center mr-1`}>user</Text>
            <Text
              style={tw`text-white text-lg tracking-wide mr-1`}
              weight="semiBold"
            >
              {name}
            </Text>
          </View>
          <Text style={tw`text-white text-lg text-center mr-1`}>
            is currently viewing your workspace with
          </Text>
          <View style={tw`flex flex-row`}>
            <Text
              style={tw`text-white text-lg tracking-wide mr-1`}
              weight="semiBold"
            >
              {accessLevel}
            </Text>
            <Text style={tw`text-white text-lg text-center`}>access</Text>
          </View>
        </Banner>
      )}
    </View>
  );
}
