import { View } from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useDeviceContext } from "twrnc";
import { Dropdown } from "react-bootstrap";

import { Text } from "@/components/Commons";
import { tw } from "@/lib";
import { AdminCustomerList } from "@/components/AdminCustomer";

// Dummy Data
const data = [
  {
    id: 1,
    email: "astanton@mail.com",
    name: "Ashlynn Stanton",
    subscription: "Monthly",
    status: true,
  },
  {
    id: 2,
    email: "astanton@mail.com",
    name: "Ashlynn Stanton",
    subscription: "Monthly",
    status: true,
  },
  {
    id: 3,
    email: "astanton@mail.com",
    name: "Ashlynn Stanton",
    subscription: "Monthly",
    status: true,
  },
];

export const Customers = () => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View style={tw`flex-grow-1 h-full`}>
      {/* Title */}
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text type="h2">Customers</Text>
        </View>
      </View>
      <View style={tw`flex-col mt-5  w-full flex-initial`}>
        {/* Map Data */}
        <AdminCustomerList
          data={data}
          dropdown={
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{ lineHeight: 0, ...tw`no-underline` }}
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <Icon color="#C8C8C8" name="more-vert" size={20} />
              </Dropdown.Toggle>
            </Dropdown>
          }
        />
      </View>
    </View>
  );
};
