import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path,G,Defs, ClipPath, Rect } from "react-native-svg";

export const Churchs = (props: { color?: string,style?: StyleProp<ViewStyle>; }) => {
  const { color = "white",style } = props;
  return (
    <Svg width="52" height="52" viewBox="0 0 52 52" fill="none" style={style}>
      <G clipPath="url(#clip0_3_10180)">
      <Path
        d="M42.3179 11.2051C43.1592 11.2051 43.8413 10.523 43.8413 9.68164C43.8413 8.84027 43.1592 8.1582 42.3179 8.1582C41.4765 8.1582 40.7944 8.84027 40.7944 9.68164C40.7944 10.523 41.4765 11.2051 42.3179 11.2051Z"
        fill={color}
        stroke={color}
        strokeWidth={"0.1"}
       
      />
      <Path
        d="M47.2131 0H28.9838C27.705 0 26.5027 0.498063 25.5984 1.40248L1.40268 25.5982C0.498063 26.5027 0 27.7049 0 28.9834C0 30.2621 0.498266 31.4643 1.40268 32.3681L19.6315 50.597C20.5356 51.5017 21.7378 52 23.0165 52C24.2951 52 25.4973 51.5019 26.4018 50.5973L50.5975 26.4016C51.5019 25.4973 52 24.295 52 23.0162V4.78695C52 2.11484 49.8308 0 47.2131 0V0ZM48.9531 23.0162C48.9531 23.4811 48.772 23.9183 48.4432 24.2471L24.2473 48.4429C23.5665 49.1236 22.4669 49.1239 21.7864 48.4429L3.55682 30.2133C2.87584 29.5327 2.87686 28.433 3.55712 27.7527L27.7529 3.55682C28.0817 3.22796 28.519 3.04688 28.9838 3.04688H47.2131C48.1635 3.04688 48.9531 3.8154 48.9531 4.78695V23.0162Z"
        fill={color}
        
        strokeWidth={"0.1"}
       
      />
        <Path
        d="M26.0003 21.5563C28.6398 21.5563 30.7873 19.4089 30.7873 16.7694C30.7873 14.1299 28.6398 11.9824 26.0003 11.9824C23.3608 11.9824 21.2134 14.1299 21.2134 16.7694C21.2134 19.4089 23.3608 21.5563 26.0003 21.5563ZM26.0003 15.0292C26.9598 15.0292 27.7404 15.8098 27.7404 16.7693C27.7404 17.7287 26.9598 18.5093 26.0003 18.5093C25.0409 18.5093 24.2603 17.7287 24.2603 16.7693C24.2603 15.8098 25.0409 15.0292 26.0003 15.0292Z"
        fill={color}
        
        strokeWidth={"0.1"}
       
      />
       <Path
        d="M26.0003 30.4434C23.3608 30.4434 21.2134 32.5908 21.2134 35.2303C21.2134 37.8698 23.3608 40.0172 26.0003 40.0172C28.6398 40.0172 30.7873 37.8698 30.7873 35.2303C30.7873 32.5908 28.6398 30.4434 26.0003 30.4434ZM26.0003 36.9705C25.0409 36.9705 24.2603 36.1899 24.2603 35.2304C24.2603 34.2709 25.0409 33.4903 26.0003 33.4903C26.9598 33.4903 27.7404 34.2709 27.7404 35.2304C27.7404 36.1899 26.9598 36.9705 26.0003 36.9705Z"
        fill={color}
        
        strokeWidth={"0.1"}
       
      />
      <Path
        d="M35.7905 24.4766H16.2095C15.3681 24.4766 14.686 25.1587 14.686 26C14.686 26.8413 15.3681 27.5234 16.2095 27.5234H35.7905C36.6319 27.5234 37.314 26.8413 37.314 26C37.314 25.1587 36.632 24.4766 35.7905 24.4766Z"
        fill={color}
        
        strokeWidth={"0.1"}
       
      />

      </G>
      <Defs>
        <ClipPath id="clip0_3_10180">
          <Rect width="52" height="52" fill={color}>
            
          </Rect>

        </ClipPath>
      </Defs>
      
         
    </Svg>
  );
};
