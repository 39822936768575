const { colors } = require("@/lib");
module.exports = {
  purge: [],
  darkMode: false, // or 'media' or 'class',
  theme: {
    extend: {
      screens: {
        sm: "425px",

        md: "768px",

        lg: "1024px",

        xl: "1360px",

        "2xl": "1536px",
      },
      colors: {
        ...colors,
        "newt-green": "#8CC542",
        btn: "#5E6CB2",
        primary: "#0E2989",
        "btn-disabled": "#BBBAC3",
        "light-blue": "#F2F6FF",
        "light-gray": "#C2C2CD",
        "rebrand-gray": "#6C6C77",
        "gray-lightest": "#EBEBF0",
        "dark-gray": "#717185",
        "light-green": "#F8FAFE",
        "not-new": "#5E6CB226",
        "hov-not-new": "#5E6CB219",
        light: "#E2E6F1",
      },

      fontFamily: {
        display: ["Bahnschrift", "sans-serif", "open-sans"],
        body: ["Barlow", "sans-serif"],
        h1: ["Barlow", "sans-serif", "bold-700"],
      },
      fontSize: {
        "input-label": ["14px", "21px"],
        "input-error": ["10px", "10px"],
        h2: ["24px", "32px"],
        "btn-menu-item": "14px",
        h1: ["48px", "57px"],
      },
      height: {
        input: "40px",
        "menu-btn": "56px",
        "menu-btn-icon": "24px",
      },
      width: {
        "menu-btn": "56px",
        "menu-btn-icon": "24px",
      },
      borderRadius: {
        input: "12px",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
};
