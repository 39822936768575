import axios, { AxiosResponse } from "axios";
import { ApiService } from "./";
import { AuthInput, AuthResponse, PasswordChange, PasswordChangeSuperAdmin } from "@/types";
import { AxiosHeaders } from "@/services";

const endpoint = "users";

class PasswordService {
  //Authenticate user on Nucleus


  /**
   * Reset Password of any user
   *
   * @returns {AxiosResponse<any>}
   */

  async changePassword(input:PasswordChange):
  Promise<AxiosResponse<{ result: PasswordChange }>>{
    return ApiService.api.put(`${endpoint}/password/reset`,input);
  }

  
  /**
   * Change Password of a user from the superadmin
   *
   * @returns {AxiosResponse<any>}
   */

  async changePasswordfromSuperAdmin(input:PasswordChangeSuperAdmin):
  Promise<AxiosResponse<{ result: PasswordChangeSuperAdmin }>>{
    return ApiService.api.patch(`${endpoint}/password/change`,input,{
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }


}

const singleton = new PasswordService();
export default singleton;
