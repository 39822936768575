import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Text } from "../Commons";
import { colors, Modal, tw } from "@/lib";
import Option from "./Option";
import { CheckBox } from "react-native-elements";
import { Check } from "../Icons";
import { Checkdasda } from "../Icons/Check/Check";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { RootState, store } from "@/store";
import accessRequestStore, {
  setAccess,
  setEndlessDuration,
} from "@/store/accessRequest.store";
import ModalButtons from "@/screens/client/Info/ModalButtons";

interface Prop {
  state?: boolean;
}

const RadioButtones = ({ state }: Prop) => {
  const dispatch = useAppDispatch();
  const userLogged = useAppSelector((state: RootState) => state.user);

  console.log('Estado Endless',state)
  const [states, setStates] = useState<boolean>(state != undefined && state);
  //dispatch when the user loads the page
  

  //dispatch when the user change the state

  useEffect(() => {
    dispatch(setEndlessDuration({ endless: states }));
  }, [states]);

  const handleModalCheck = () => {
    //setState(!state);
    if (!states) {
      setStates(true);
      if(!(userLogged.rol === 'admin' || userLogged.rol === 'super_admin')) return;
      Modal.show(
        <View style={tw`flex w-full items-center justify-around`}>
          <View style={tw`flex-row items-center justify-center mb-1`}>
            <Text type="h2" weight="bold">
              Warning
            </Text>
            <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
          </View>

          <View
            style={tw`flex w-full items-center justify-center lg:max-w-[210px] mt-2`}
          >
            <Text color={colors.black}>
              You're about to grant unlimited access.{"\n"}
              This access request will not expire and{"\n"}
              <View style={tw`flex items-center justify-center`}>
                <Text color={colors.black}>must be manually revoked.</Text>
              </View>
            </Text>
          </View>
          <View style={tw`flex w-full `}>
            <ModalButtons text={"Approve"} />
          </View>
        </View>
      );
    } else {
      setStates(false);
    }
  };

  return (
    <CheckBox
      center
      containerStyle={[tw`bg-transparent border-0 text-[${colors.blue}]`]}
      title={
        <Text color={colors.blue} weight={"regular"}>
          Endless Access
        </Text>
      }
      checkedIcon={<Checkdasda />}
      uncheckedIcon="circle-o"
      uncheckedColor={colors.grayLightest}
      checkedColor={colors.blue}
      checked={states}
      onPress={handleModalCheck}
    />
  );
};

export default RadioButtones;

const styles = StyleSheet.create({});
