import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CarouselItem, Dropdown } from "react-bootstrap";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
} from "react-native";
import { TouchableOpacity as Tgesture } from "react-native-gesture-handler";

import Icon from "react-native-vector-icons/FontAwesome";
import { useToast } from "react-native-toast-notifications";
import { useParams } from "react-router-dom";

import { Plus } from "@/components/Icons";
import { ClientLayout } from "@/components/Layouts/ClientLayout";
import tw from "@/lib/tailwind";
import {
  colors,
  debounce,
  formatRole,
  Modal,
  showFriendlyErrorFromHTMLCode,
  sortByHeader,
  useFieldControl,
} from "@/lib";
import {
  Accordion,
  Button,
  InfinityScroll,
  Loading,
  Text,
} from "@/components/Commons";
import { InviteUser } from "@/components/ClientCompanyInfo";
import { SuperAdminService, UserService, WorkspaceService } from "@/services";
import { AxiosError } from "axios";
import {
  CurrentWorkspaceResponse,
  InvitedUser,
  UserResp,
  UserResponse,
  WorkspaceResponse,
} from "@/types";
import { useAlert, useApi, usePhoneNumber, useUserLogged } from "@/hooks";
import { useDeviceContext } from "twrnc";
import { SearchBar } from "@/components/Inputs";
import { ActivityIndicator } from "react-native";
import { previousDay } from "date-fns";

enum Tabs {
  "USERS",
  "INVITED",
}
const limit: number = 20;
type formated = "USERS" | "INVITED";

export const CompanyInfoUsers = () => {
  useDeviceContext(tw);
  //Initialize toasts
  const toast = useToast();

  const { workspaceId } = useParams();

  const { showSwal } = useAlert();

  const { userLogged } = useUserLogged();

  //SortHeaders
  const [iEMail, setIEMail] = useState<boolean>(false);
  //SortHeaders Workspace
  const [wEMail, setWEMail] = useState<boolean>(false);
  const [idHeader, setIdHeader] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<boolean>(false);
  const [role, setRole] = useState<boolean>(false);

  const search = useFieldControl<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentPageInvited, setCurrentPageInvited] = useState<number>(0);
  const [totalWorkspacesUsers, setTotalWorkspacesUsers] = useState<number>(0);
  const [totalInvitedUsers, setTotalInvitedUsers] = useState<number>(0);
  const [tabsas, changeTabsas] = useState<formated>("USERS");

  const [workspace, setWorkspace] = useState<WorkspaceResponse>();

  //Loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Array to get the workspace users
  const [workspaceUsers, setWorkspaceUsers] = useState<UserResponse[]>([]);

  //Handle invitations
  //const [invitations, changeInvitations] = useState<string[]>([]);

  const [handler, setHandler] = useState<number>(0);
  //Handle invitations
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);

  const [tab, setTab] = useState<Tabs>(Tabs.USERS);

  const removeUser = async (user: UserResponse, remove?: boolean) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove User?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>
            {`Are you sure you want to remove ${user?.email} from the workspace?`}
          </Text>
          <Text>User will not be able to log in anymore.</Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold" onPress={() => Modal.dismiss()}>
                Cancel
              </Text>
            </Button>

            <Button
              style={tw`flex-1`}
              onPress={() =>
                remove
                  ? callServerAnDeleteUser(user.userId)
                  : removeUsers(user.userId)
              }
            >
              <Text weight="semiBold">Remove</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 560 }
    );
  };

  const removeUsers = async (id: number) => {
    setIsLoading(true);
    try {
      await UserService.deleteUser(id ?? 0);
      Modal.dismiss();
      setHandler(handler + 1);
      toast.show("The user was deleted succesfully", {
        placement: "bottom",
        type: "success",
        duration: 3000,
        successColor: colors.successAlert,
        successIcon: <Icon name="check" size={18} color={colors.white} />,
      });
      setIsLoading(false);
      getInitialData({ initial: true, offset: 0 });
    } catch (error) {
      const err = error as AxiosError;
      setIsLoading(false);
      Modal.dismiss();
      //If user exists show custom error
      if (err.response && err.response.status === 422) {
        showSwal({ msg: "The user couldn't be removed", type: "error" });
        return;
      }
      //If doesn't exist show the corresponding code error
      showSwal({ msg: showFriendlyErrorFromHTMLCode(err), type: "error" });
    }
  };

  const callServerAnDeleteUser = async (id: number) => {
    setIsLoading(true);
    try {
      const res = await WorkspaceService.removeInviteCollegues(
        workspace?.id || 0,
        [id]
      );
      setInvitedUsers(invitedUsers.filter((user) => user.userId !== id));

      Modal.dismiss();
      toast.show("The invitation was deleted succesfully", {
        placement: "bottom",
        type: "success",
        duration: 3000,
        successColor: colors.successAlert,
        successIcon: <Icon name="check" size={18} color={colors.white} />,
      });
      getInitialData({ initial: true, offset: 0 });
      setIsLoading(false);
    } catch (error) {
      const err = error as AxiosError;
      setIsLoading(false);
      Modal.dismiss();
      //If user exists show custom error
      if (err.response && err.response.status === 422) {
        showSwal({ msg: "The invitation couldn't be removed", type: "error" });
        return;
      }
      //If doesn't exist show the corresponding code error
      showSwal({ msg: showFriendlyErrorFromHTMLCode(err), type: "error" });
    }
  };

  //Function to watch the invitations
  const handleInvitations = (emails: string[]) => {
    getInitialData({ initial: true, offset: 0 });
    //Message for the alert
    let message: string =
      emails.length !== 1
        ? "The invitations were sent successfully"
        : "The invitation was sent successfully";

    toast.show(message, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <Icon name="check" size={18} color={colors.white} />,
    });
  };

  const inviteUserAttempt = () => {
    Modal.show(
      <InviteUser
        onAddedInvitation={handleInvitations}
        onError={(errorMessage) => {
          showSwal({ msg: errorMessage, type: "error" });
        }}
        workspaceId={workspace?.id || 0}
      />,
      {
        width: Dimensions.get("window").width >= 728 ? 560 : "",
      }
    );
  };

  const resendInvitation = async (userId: number) => {
    try {
      const res = await WorkspaceService.resendInviteCollegues(
        workspace?.id || 0,
        [userId]
      );
      toast.show("The invitation has been sent successfully", {
        placement: "bottom",
        type: "success",
        duration: 3000,
        successColor: colors.successAlert,
        successIcon: <Icon name="check" size={18} color={colors.white} />,
      });
    } catch (err) {
      setIsLoading(false);
      //Show error
      const error = err as AxiosError;
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    }
  };

  const formattedUserPhone = (phone: string) => {
    const { formattedNumber: phoneNumber } = usePhoneNumber({
      phone,
    });

    return phoneNumber;
  };

  const showInviteUser = () => {
    if (
      userLogged.rol === "admin" ||
      userLogged.rol === "super_admin" ||
      (userLogged.accessType === "read and write" &&
        userLogged.accessStatus === "granted")
    )
      return (
        <TouchableOpacity
          style={tw`bg-white p-2 rounded-3`}
          onPress={inviteUserAttempt}
        >
          <Plus color={colors.blue} />
        </TouchableOpacity>
      );
  };

  const renderWorkspaceUsers = ({ item }: { item: UserResponse }) => {
    return (
      <View
        style={[
          tw`flex-row w-full bg-white px-4 pb-3 mb-1 rounded-3 items-center`,
          { zIndex: "auto" },
        ]}
        key={item.userId}
      >
        <View style={tw`w-1/12 `}>
          <Text color={colors.dark}>{item.userId}</Text>
        </View>
        <View style={tw`w-2/12 pr-2`}>
          <Text color={colors.dark}>{item.email}</Text>
        </View>
        <View style={tw`pl-2 w-2/12 `}>
          <Text color={colors.dark}>
            {item.firstName + " " + item.lastName}
          </Text>
        </View>
        <View style={tw`pl-2 w-2/12 `}>
          <Text color={colors.dark}>
            {formatRole(item.workspaceRole) || "N/A"}
          </Text>
        </View>
        <View style={tw`pl-4 w-2/12 `}>
          <Text color={colors.dark}>
            {item.extension && "#"}
            {item.extension || "N/A"}
          </Text>
        </View>
        <View style={tw`pl-4 w-2/12 `}>
          <Text color={colors.dark}>
            {formattedUserPhone(item.ownPhoneNumber || "") || "N/A"}
          </Text>
        </View>
        <View style={[tw`w-auto ml-auto`, { zIndex: "auto" }]}>
          {item.id !== workspace?.id && (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{ lineHeight: 0, ...tw`no-underline` }}
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <Icon name="ellipsis-v" size={12} color={colors.gray} />
              </Dropdown.Toggle>

              <Dropdown.Menu style={tw`rounded-3`} flip={false}>
                <Dropdown.Item
                  as="button"
                  onClick={() => removeUser(item, false)}
                  style={tw`text-red-500 text-btn-menu-item font-semibold`}
                >
                  Remove User
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </View>
      </View>
    );
  };

  const renderInvitedUsers = ({ item }: { item: InvitedUser }) => {
    return (
      <View
        style={[
          tw`flex-row bg-white px-4 pb-3 mb-1 rounded-3 items-center`,
          { zIndex: "auto" },
        ]}
        key={item.userId}
      >
        <View>
          <Text color={colors.dark}>{item.email}</Text>
        </View>
        <View style={[tw`relative w-auto ml-auto`, { zIndex: "auto" }]}>
          {item.id !== workspace?.id && (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{ lineHeight: 0, ...tw`no-underline` }}
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <Icon name="ellipsis-v" size={12} color={colors.gray} />
              </Dropdown.Toggle>

              <Dropdown.Menu style={tw`rounded-3`} flip={false}>
                <Dropdown.Item
                  as="button"
                  onClick={() => resendInvitation(item?.userId)}
                  style={tw`text-btn-menu-item font-semibold`}
                >
                  Resend Invitation
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => removeUser(item, true)}
                  style={tw`text-red-500 text-btn-menu-item font-semibold`}
                >
                  Cancel Invitation
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </View>
      </View>
    );
  };

  const handleTab = (changeTab: Tabs) => {
    if (changeTab === tab) return;
    setTab(changeTab);
  };

  const [userInfinite, setUserInfinite] = useState<boolean>(false);
  const [inviteInfinite, setInviteInfinite] = useState<boolean>(false);

  //Api calls
  const { request: getWorkspaceById } = useApi(
    WorkspaceService.getWorkspaceInfo
  );
  const { request: getCurrent } = useApi(WorkspaceService.getCurrent);
  const { request: getAllWpUsers } = useApi(
    WorkspaceService.getAllUsersWithPagination
  );
  const { request: getAllInvitedUser } = useApi(
    WorkspaceService.getAllInviteUsersWithPagination
  );

  //Methods
  const getWorkspace = async (): Promise<number> => {
    let currentW;

    if (workspaceId != undefined) {
      currentW = await getWorkspaceById<{ result: CurrentWorkspaceResponse }>(
        parseInt(workspaceId)
      );
      const workspaceById = { ...currentW.data.result };
      const { workspace, admin } = workspaceById;
      currentW = {
        name: workspace.company_name,
        domain: workspace.domain_name,
        accountName: "",
        address: workspace.address,
        admin,
        city: workspace.city,
        company_address: "",
        users: workspace.users,
        coverPhoto: workspace.cover_photo,
        createdAt: "",
        id: workspace.id,
        phoneNumber: "",
        postalCode: workspace.postal_code,
        province: workspace.province,
        street_name: "",
        street_number: "",
        unit_number: "",
        user_access: { ...workspace.user_access },
      };
    } else {
      currentW = await getCurrent<{ result: WorkspaceResponse }>();
      currentW = { ...currentW?.data?.result };
    }
    setWorkspace(currentW);

    return currentW.id;
  };

  const getInitialData = async ({
    showloader = true,
    initial,
    isFetchingMore = false,
    offset,
    query = "",
    isSearching = false,
  }: {
    initial: boolean;
    showloader?: boolean;
    isSearching?: boolean;
    isFetchingMore?: boolean;
    offset: number;
    query?: string;
  }) => {
    if (showloader) setIsLoading(true);

    const wpId = await getWorkspace();

    let users, emailUsers;
    let invUsers: {
      list: InvitedUser[];
      totalCount: number;
    } = { list: [], totalCount: 0 };

    let usersFromWp: {
      list: UserResp[];
      totalCount: number;
    } = { list: [], totalCount: 0 };
    let usersWp: UserResponse[] = [];
    // query on the  workspace list and returns a list with all the data needed
    if (
      initial ||
      (tabsas === "USERS" && isFetchingMore) ||
      (tabsas === "USERS" && isSearching)
    ) {
      users = await getAllWpUsers<{
        result: { list: UserResp[]; totalCount: number };
      }>(wpId, offset, limit, query);
      usersFromWp = users?.data?.result;
      // aca hago la consulta para ver si me trae todos los usuarios

      usersWp = usersFromWp?.list.map((user) => ({
        ...user,
        workspaceRole: user.rol || "",
      }));
      setUserInfinite(false);
    }

    // query on the  invited college  and returns a list with all the data needed
    if (
      initial ||
      (tabsas === "INVITED" && isFetchingMore) ||
      (tabsas === "INVITED" && isSearching)
    ) {
      emailUsers = await getAllInvitedUser<{
        result: { list: InvitedUser[]; totalCount: number };
      }>(wpId, offset, limit, query);
      invUsers = emailUsers?.data?.result;

      setInviteInfinite(false);
    }
    if (initial) {
      setTotalWorkspacesUsers(usersFromWp?.totalCount);
      setWorkspaceUsers(usersWp);
      setTotalInvitedUsers(invUsers?.totalCount);
      setInvitedUsers(invUsers?.list);
    } else if (isFetchingMore) {
      if (tabsas === "USERS") {
        setWorkspaceUsers([...workspaceUsers, ...usersWp]);
      } else {
        setInvitedUsers([...invitedUsers, ...invUsers.list]);
      }
    } else if (isSearching) {
      if (tabsas === "USERS") {
        setWorkspaceUsers(usersWp);
      } else {
        setInvitedUsers(invUsers.list);
      }
    }

    if (showloader) setIsLoading(false);
  };

  useEffect(() => {
    getInitialData({ initial: true, offset: 0 });
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      getInitialData({
        initial: false,
        offset: currentPage * limit,
        isFetchingMore: true,
        showloader: false,
        query: search.value,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPageInvited > 0) {
      getInitialData({
        initial: false,
        offset: currentPageInvited * limit,
        isFetchingMore: true,
        showloader: false,
        query: search.value,
      });
    }
  }, [currentPageInvited]);

  const fetchMoreData = () => {
    if (tabsas === "USERS") {
      if (workspaceUsers.length < totalWorkspacesUsers) {
        setUserInfinite(true);
      }
      setCurrentPage(currentPage + 1);
    } else if (tabsas === "INVITED") {
      if (invitedUsers.length < totalInvitedUsers) {
        setInviteInfinite(true);

        console.log("Hello true");
      }
      setCurrentPageInvited(currentPageInvited + 1);
    }
  };

  const fetchDataByKey = (keyToSearch: string) => {
    search.handleInputValue(keyToSearch);
    if (keyToSearch !== "") {
      if (tabsas === "USERS") {
        setCurrentPage(0);
        getInitialData({
          initial: false,
          offset: 0,
          query: keyToSearch,
          isSearching: true,
        });
      } else if (tabsas === "INVITED") {
        setCurrentPageInvited(0);
        getInitialData({
          initial: false,
          offset: 0,
          query: keyToSearch,
          isSearching: true,
        });
      }
    } else {
      setCurrentPage(0);
      setCurrentPageInvited(0);
      getInitialData({ initial: true, offset: 0 });
    }
  };

  const optimizedFn = debounce(fetchDataByKey);
  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();
    return isActive
      ? {
          btn: tw`bg-white rounded-3`,
          text: tw`text-[${colors.blue}] lg:text-sm`,
        }
      : {
          text: tw`text-dark-gray lg:text-sm`,
        };
  };

  const tabs = () => {
    const selectedTickets = getTabStyle(() => tabsas === "USERS");
    const selectedUTickets = getTabStyle(() => tabsas === "INVITED");

    return (
      <View
        style={tw` flex lg:flex-row lg:bg-[#EBEBF0]  lg:h-10 rounded-3 lg:max-w-[350px] lg:my-8 `}
      >
        <Tgesture
          style={tw`flex flex-row`}
          containerStyle={[
            tw`flex-grow-1 items-center justify-center px-4`,
            selectedTickets.btn,
          ]}
          onPress={() => {
            tabsas != "USERS" && changeTabsas("USERS");
          }}
        >
          <Text type="openLogin" weight="regular" style={selectedTickets.text}>
            Workspace User
          </Text>
        </Tgesture>
        <Tgesture
          style={tw`flex flex-row`}
          containerStyle={[
            tw`flex-grow-1 items-center justify-center px-4`,
            selectedUTickets.btn,
          ]}
          onPress={() => {
            tabsas != "INVITED" && changeTabsas("INVITED");
          }}
        >
          <Text type="openLogin" weight="regular" style={selectedUTickets.text}>
            Invitation User
          </Text>
        </Tgesture>
      </View>
    );
  };

  return (
    <>
      <View style={tw`mx-8 py-7`}>
        {/* Header */}
        <View style={tw`lg:flex-row justify-between`}>
          <View
            style={tw`flex   lg:flex-row items-center justify-around -mt-9 sm:flex lg:mb-8  `}
          >
            <Text style={tw`text-h2 font-bold pr-4`}>Users</Text>
            {showInviteUser()}
          </View>

          {tabs()}

          <View style={tw`flex sm:flex lg:my-0 sm:my-[40] `}>
            <SearchBar
              value={search}
              onelementChange={(string) => {
                optimizedFn(string);
              }}
            />
          </View>
        </View>
        {/* Tabs */}
        <View style={{ zIndex: -10, ...tw`w-full bg-white p-5 rounded-3` }}>
          {tabsas == "USERS" ? (
            <ScrollView
              style={tw`mt-5`}
              horizontal
              contentContainerStyle={tw`flex flex-col min-w-200 w-full`}
            >
              <View style={tw`flex-row px-4 mb-4`}>
                <TouchableOpacity
                  style={tw`w-1/12 flex flex-row items-center`}
                  onPress={() => {
                    setWorkspaceUsers(
                      sortByHeader<UserResponse>(workspaceUsers, "id", idHeader)
                    );
                    setIdHeader(!idHeader);
                  }}
                >
                  <Text style={tw`mr-2`} color={colors.gray}>
                    ID
                  </Text>

                  <Icon
                    name={idHeader ? "chevron-down" : "chevron-up"}
                    // @ts-ignore

                    type="font-awesome"
                    color="#9CA3AF"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={tw`w-2/12 flex flex-row items-center`}
                  onPress={() => {
                    setWorkspaceUsers(
                      sortByHeader<UserResponse>(
                        workspaceUsers,
                        "email",
                        wEMail
                      )
                    );
                    setWEMail(!wEMail);
                  }}
                >
                  <Text style={tw`mr-2`} color={colors.gray}>
                    Email
                  </Text>
                  <Icon
                    name={wEMail ? "chevron-down" : "chevron-up"}
                    // @ts-ignore
                    type="font-awesome"
                    color="#9CA3AF"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={tw`w-2/12 flex flex-row items-center`}
                  onPress={() => {
                    setWorkspaceUsers(
                      sortByHeader<UserResponse>(
                        workspaceUsers,
                        "firstName",
                        firstName
                      )
                    );
                    setFirstName(!firstName);
                  }}
                >
                  <Text style={tw`mr-2`} color={colors.gray}>
                    Name
                  </Text>
                  <Icon
                    name={firstName ? "chevron-down" : "chevron-up"}
                    // @ts-ignore
                    type="font-awesome"
                    color="#9CA3AF"
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={tw`w-2/12 flex flex-row items-center`}
                  onPress={() => {
                    setWorkspaceUsers(
                      sortByHeader<UserResponse>(
                        workspaceUsers,
                        "workspaceRole",
                        role
                      )
                    );
                    setRole(!role);
                  }}
                >
                  <Text style={tw`mr-2`} color={colors.gray}>
                    Role
                  </Text>
                  <Icon
                    name={role ? "chevron-down" : "chevron-up"}
                    // @ts-ignore
                    type="font-awesome"
                    color="#9CA3AF"
                  />
                </TouchableOpacity>
                <View style={tw`w-2/12 `}>
                  <Text color={colors.gray}>Extension</Text>
                </View>
                <View style={tw`w-2/12 `}>
                  <Text color={colors.gray}>Direct Number</Text>
                </View>
                <View style={tw`w-auto `}></View>
              </View>
              {isLoading ? (
                <>
                  <Loading />
                  <View style={tw`mb-10`}></View>
                </>
              ) : !workspaceUsers || workspaceUsers.length <= 0 ? (
                <View
                  style={tw`flex-row bg-white px-4 py-4 mb-1 rounded-3 items-center mt-2 `}
                >
                  <Text
                    style={tw`w-full text-center`}
                    color={colors.red}
                    weight="semiBold"
                  >
                    There aren't users added to this Workspace
                  </Text>
                </View>
              ) : (
                <InfinityScroll
                  style={tw`pb-10`}
                  data={workspaceUsers}
                  contentContainerStyle={tw`h-100`}
                  Component={renderWorkspaceUsers}
                  getMoreData={fetchMoreData}
                  showsVerticalScrollIndicator
                  showSpinner={userInfinite}
                />
              )}
            </ScrollView>
          ) : (
            <ScrollView
              style={tw`mt-5`}
              horizontal
              contentContainerStyle={tw`flex flex-col min-w-100 w-full`}
            >
              <View style={tw`flex-row px-4 mb-4`}>
                <TouchableOpacity
                  style={tw`w-1/12 flex flex-row items-center`}
                  onPress={() => {
                    setInvitedUsers(
                      sortByHeader<InvitedUser>(invitedUsers, "email", iEMail)
                    );
                    setIEMail(!iEMail);
                  }}
                >
                  <Text style={tw`mr-2`} color={colors.gray}>
                    Email
                  </Text>
                  <Icon
                    name={iEMail ? "chevron-down" : "chevron-up"}
                    // @ts-ignore
                    type="font-awesome"
                    color="#9CA3AF"
                  />
                </TouchableOpacity>
              </View>
              {isLoading ? (
                <>
                  <Loading />
                  <View style={tw`mb-10`}></View>
                </>
              ) : invitedUsers.length <= 0 ? (
                <View
                  style={tw`flex-row bg-white px-4 py-4 mb-1 rounded-3 items-center mt-2`}
                >
                  <Text
                    style={tw`w-full text-center`}
                    color={colors.red}
                    weight="semiBold"
                  >
                    There aren't users invited
                  </Text>
                </View>
              ) : (
                <InfinityScroll
                  data={invitedUsers}
                  contentContainerStyle={tw`h-100`}
                  style={tw`pb-10`}
                  Component={renderInvitedUsers}
                  getMoreData={fetchMoreData}
                  showsVerticalScrollIndicator
                  showSpinner={inviteInfinite}
                />
              )}
            </ScrollView>
          )}
        </View>
      </View>
    </>
  );
};
