import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { colors, tw } from "@/lib";
import { Text } from "../Commons";
import { useDeviceContext } from "twrnc";

import TreeErrordas from "./TreeError";
import { cleanErrors, formatErrors } from "../FormatError/FormatError";
import { ErrorFixed, ErrorResponse, ErrorThird } from "@/types";

const TreeError = (props: any) => {
  useDeviceContext(tw);

  //Handle all errors
  const { error } = props;
  const [allErrors, setAllErrors] = useState<ErrorFixed[]>([]);
  const errors: ErrorResponse[] = [...error];

  //Put it on cache because it's an expensive calculation
  const showMe = useCallback(() => {
    cleanErrors();
    return formatErrors(errors);
  }, [errors]);

  useEffect(() => {
    setAllErrors(showMe());
  }, []);

  return (
    <View style={tw`ml-10`}>
      {allErrors.map((err, index) => (
        <Text key={index}>{err}</Text>
      ))}
    </View>
  );
};

export default TreeError;

const styles = StyleSheet.create({});
