import { View, TouchableOpacity, Image } from "react-native";
import { TouchableOpacity as Opacity } from "react-native-gesture-handler";
import React, { useState } from "react";
import { useDeviceContext } from "twrnc";
import { LinearGradient } from "expo-linear-gradient";
import { Dropdown } from "react-bootstrap";
import Icon from "react-native-vector-icons/MaterialIcons";

import { Modal, tw, colors, useFieldControl } from "@/lib";
import { Text, Button } from "@/components/Commons";
import { Plus } from "@/components/Icons";
import {
  ProductCodesTabs,
  ProductPromotionForm,
} from "@/screens/Admin/Product";
import CardPromotions from "@/components/CreditCard/CardPromotions";
//import { Admin } from "@/components/Icons/Admin";
import {
  Church,
  Churchs,
  Rullet,
  Rullete,
  Lantern,
  Fire,
  Fires,
  Lanterns,
} from "@/components/Icons";
import ContentTabs from "@/components/Tabs/ContentTabs";
import FirstTab from "./TabsProduct/FirstTab";
import EditPromotions from "@/components/Tabs/EditPromotions";
const codes = [
  {
    _id: "1",
    gradient: "#5E6CB2",
    is_enabled: true,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "2",
    gradient: "#f2b6c4",
    is_enabled: false,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "3",
    gradient: "#19B665",
    is_enabled: true,
    objectBackground: <Rullet />,
    object: <Rullete />,
  },

  {
    _id: "4",
    gradient: "#faddb2",
    is_enabled: false,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "5",
    gradient: "#1DCEE7",
    is_enabled: true,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "6",
    gradient: "#E3153A",
    is_enabled: false,
    objectBackground: <Lantern />,
    object: <Lanterns />,
  },
  {
    _id: "7",
    gradient: "#FFC700",
    is_enabled: true,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "8",
    gradient: "#C34AEE",
    is_enabled: false,
    objectBackground: <Fire />,
    object: <Fires />,
  },
];

const promos = [
  {
    _id: "1",
    gradient: "#5E6CB2",
    is_enabled: true,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "2",
    gradient: "#f2b6c4",
    is_enabled: false,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "3",
    gradient: "#19B665",
    is_enabled: true,
    objectBackground: <Rullet />,
    object: <Rullete />,
  },

  {
    _id: "4",
    gradient: "#faddb2",
    is_enabled: false,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "5",
    gradient: "#1DCEE7",
    is_enabled: true,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "6",
    gradient: "#E3153A",
    is_enabled: false,
    objectBackground: <Lantern />,
    object: <Lanterns />,
  },
  {
    _id: "7",
    gradient: "#FFC700",
    is_enabled: true,
    objectBackground: <Church />,
    object: <Churchs />,
  },
  {
    _id: "8",
    gradient: "#C34AEE",
    is_enabled: false,
    objectBackground: <Fire />,
    object: <Fires />,
  },
];

export const ProductPromotion = () => {
  //Use tailwind
  useDeviceContext(tw);

  const [state, setState] = useState<string>();

  //Modal to remove plan
  const showModalForRemove = (id: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Delete Promotion?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to delete promotion?</Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw`flex-1`}>
              <Text weight="semiBold">Delete</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 560 }
    );
  };

  //Modal Add/Edit Promocode
  const showModalForAddEditPCode = (id?: string) => {
    Modal.show(<EditPromotions id={id!} />, { width: 749, styles: "p-0" });
  };

  //Edit/Add Plan Modal
  const showModalForAddEditPromo = (id?: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            {id ? "Edit Promocode" : "Add Promocode"}
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <ProductPromotionForm />

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row`}>
            <Button style={tw`w-42`} type="outlined" onPressOut={Modal.dismiss}>
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw`w-42`} onPressOut={Modal.dismiss}>
              <Text weight="semiBold">Save</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 752 }
    );
  };

  //Show View
  return (
    <View style={tw`mt-5 lg:ml-4`}>
      <View style={tw`flex-row justify-between lg:my-4 `}>
        <View style={tw`flex `}>
          <Text type="h2">Promotion</Text>
        </View>
      </View>
      {/* Promocodes + */}
      <View style={tw`flex-row items-center`}>
        <Text color={colors.gray} weight="semiBold">
          Promocodes
        </Text>
        <TouchableOpacity
          style={tw`bg-white h-[32px] p-1 rounded-2 ml-3`}
          onPress={() => showModalForAddEditPromo()}
        >
          <Plus color={colors.blue} />
        </TouchableOpacity>
      </View>

      {/* Gradient Cards */}
      <View style={tw`flex-row flex-wrap`}>
        {promos.map((promo, identifier: number) => {
          const identif = identifier.toString();
          return (
            <CardPromotions
              color={promo.gradient}
              enabled={promo.is_enabled}
              identifier={identif}
              showModalForRemove={showModalForRemove}
              showModalForEdit={showModalForAddEditPromo}
              objectBackground={promo.objectBackground}
              object={promo.object}
              key={identifier}
            />
          );
        })}
      </View>

      {/* Promotions + */}
      <View style={tw`flex-row items-center mt-5`}>
        <Text color={colors.gray} weight="semiBold">
          Promotions
        </Text>
        <TouchableOpacity
          style={tw`bg-white h-[32px] p-1 rounded-2 ml-3`}
          onPress={() => showModalForAddEditPCode()}
        >
          <Plus color={colors.blue} />
        </TouchableOpacity>
      </View>

      {/* Gradient Cards */}
      <View style={tw`flex-row flex-wrap`}>
        {codes.map((code, identi: number) => (
          <CardPromotions
            color={code.gradient}
            enabled={code.is_enabled}
            identifier={identi.toString()}
            showModalForRemove={showModalForRemove}
            showModalForEdit={showModalForAddEditPCode}
            objectBackground={code.objectBackground}
            object={code.object}
            key={identi}
          />
        ))}
      </View>
    </View>
  );
};
