import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";
import { useDeviceContext } from "twrnc";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";

interface Props {
  text: string | number;
  style?: StyleProp<ViewStyle>;
}

export const Badge = ({ text, style }: Props) => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View
      style={[
        {
          width: "auto",
          alignSelf: "flex-start",
          ...tw`block h-[17px] rounded-full bg-[${colors.red}] p-2 items-center justify-center`,
        },
        style,
      ]}
    >
      <Text color={colors.white} weight="bold" style={{ fontSize: 12 }}>
        {text}
      </Text>
    </View>
  );
};
