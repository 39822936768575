import React from "react";
import Svg, { Path } from "react-native-svg";

export const Back = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="25" height="20" viewBox="0 0 24 24" fill="none">
      <Path
        d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"
        fill={color}
      />
    </Svg>
  );
};
