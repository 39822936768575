import { Pressable, View, Dimensions } from "react-native";
import React, { useEffect, useState } from "react";
import { Avatar } from "react-native-elements";
import { useDeviceContext } from "twrnc";

import { colors, tw, Modal, showFriendlyErrorFromHTMLCode } from "@/lib";
import { Text, EditProfile } from "@/components/Commons";
import { Pencil, Image } from "@/components/Icons";
import { TokenService, WorkspaceService } from "@/services";
import { WorkspaceResponse } from "@/types";
import { AxiosError } from "axios";
import { useAlert } from "@/hooks";

interface UserInfo {
  image?: File | string;
  firstName?: string;
  email?: string;
  lastName?: string;
  address?: string;
  cellphone?: string;
  companyName?: string;
  companyNumber?: string;
  billingInfo?: string;
  direct?: string;
}

export const UserProfile = () => {
  //Enable breakpoints
  useDeviceContext(tw);
  const token = TokenService.getUser()?.user;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const errorInitialState = { show: false, msg: "" };

  const [errorMsg, setErrorMs] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );
  const { showSwal } = useAlert();

  const [workspaceInfo, ChangeWorkspaceInfo] = useState<WorkspaceResponse>();
  const [userInfo, changeUserInfo] = useState<UserInfo>({
    firstName: workspaceInfo?.users[0].firstName,
    email: token?.email,
    lastName: token?.lastName!,
    address: "",
    cellphone: workspaceInfo?.phoneNumber,
    companyName: "",
    companyNumber: "",
    billingInfo: "*** *** *** 999",
    direct: token?.ownPhoneNumber!,
  });

  const getWorkspace = async () => {
    try {
      const data = (await WorkspaceService.getCurrent()).data.result;
      ChangeWorkspaceInfo(data);
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const setErrorMsg = (err: any) => {
    setIsLoading(false);
    //Show error
    const error = err as AxiosError;
    if (error.response?.status === 403) {
      showSwal({ msg: "Invalid Token", type: "error" });
      return;
    }

    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    //setErrorMs({ show: true, msg: showFriendlyErrorFromHTMLCode(error) });
  };

  useEffect(() => {
    getWorkspace();
  }, []);

  //Edit Profile
  const editAttempt = () => {
    Modal.show(
      <EditProfile
        value={userInfo}
        onChange={(input) => {
          changeUserInfo(input);
        }}
      />,
      { width: Dimensions.get("window").width >= 728 ? 728 : "" }
    );
  };

  return (
    <View style={tw`lg:flex-row pt-4 items-center md:items-stretch`}>
      <View style={tw`bg-white flex-grow-0 px-11 pt-4 mx-2 pb-11 rounded-3`}>
        <Avatar
          rounded
          source={
            userInfo.image ? { uri: userInfo.image.toString() } : undefined
          }
          size={128}
          renderPlaceholderContent={
            <View
              style={tw`bg-gray-500 h-full w-full justify-center items-center`}
            >
              <Image />
            </View>
          }
        />
        <Text
          type="h2"
          style={tw`pt-4 pb-2 w-full flex flex-row justify-center`}
        >
          {workspaceInfo?.accountName}
        </Text>
        <View style={tw`flex-row items-end justify-between w-full`}>
          <Text color={colors.gray}>Monthly revenue</Text>
          <Text weight="bold" color={colors.dark}>
            10%
          </Text>
        </View>
      </View>
      <View
        style={[
          tw`flex w-full bg-white  px-8 py-6 mx-2 rounded-3 flex-initial `,
        ]}
      >
        <Text type="h2">Contacts</Text>
        <Pressable
          style={{ position: "absolute", right: 24, top: 24 }}
          onPress={editAttempt}
        >
          <Pencil color="#0E2989" />
        </Pressable>
        <View style={[tw`md:flex-row md:flex-wrap md:pt-4 h-full`]}>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Email</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {userInfo.email}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Company Name</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {workspaceInfo?.name}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Company Number</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {workspaceInfo?.phoneNumber}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Billing info</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {userInfo.billingInfo}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Cell Phone Number</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {workspaceInfo?.ownPhoneNumber}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Address</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {workspaceInfo?.address}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Direct</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              {userInfo.direct}
            </Text>
          </View>
          <View style={tw`md:w-1/2 flex-row`}>
            <Text style={tw`text-dark-gray w-1/2`}>Company address</Text>
            <Text weight="bold" style={tw`w-1/2`}>
              Lorem ipsum dolor sit amet.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
