

//@ts-ignore
import React from "react";
import { useUserLogged } from "@/hooks";
import { Navigate } from "react-router-dom";
import { FRONTEND_LOGIN_PAGE } from "@env";

export const RedirectLogin = () => {

  const { isLogged, navigateTo } = useUserLogged();

  if (isLogged)
    return <Navigate to={navigateTo} replace />;

  window.location.href = FRONTEND_LOGIN_PAGE;
  return
};


