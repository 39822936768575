import { Image, View } from "react-native";
import React from "react";
import { Text } from "@/components/Commons";

import { useDeviceContext } from "twrnc";
import { tw } from "@/lib";

export const BugsAndErrors = () => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View style={tw`flex-grow-1 h-full`}>
      <Text type="h2">Bugs & Errors</Text>
      <View style={tw`w-full justify-center items-center`}>
        <Image source={require("@/assets/images/Zendesk/zendesk_logo.png")} style={tw`w-[698px] h-[316px] rounded-sm`} />
      </View>
    </View>
  );
};
