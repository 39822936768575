import { RootState } from "@/store";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

interface Props {
  children: JSX.Element;
}

export const RegisterGuard: FC<Props> = ({ children }) => {
  const { email } = useSelector(
    (state: RootState) => state.register.companyInfo
  );

  if (email === "") return <Navigate to="/auth/register" replace />;

  return children;
};
