import { ScrollView, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { Button, Text } from "@/components/Commons";
import {
  colors,
  mail,
  Modal,
  number,
  regexLetter,
  regexNumbers,
  tw,
  useFieldControl,
} from "@/lib";
import { Dropdown } from "react-bootstrap";
import { Icon } from "react-native-elements";
import {
  TouchableHighlight,
  TouchableOpacity,
} from "react-native-gesture-handler";
import { TextInput } from "@/components/Inputs";
import { useNavigate } from "react-router-dom";
import { useFonts, OpenSans_400Regular } from "@expo-google-fonts/open-sans";
import { useDeviceContext } from "twrnc";
import { Circle } from "@/components/Icons";

export const Partners = () => {
  useDeviceContext(tw);
  const navigate = useNavigate();

  const [globalState, setglobalState] = useState({ id: 0, statesData: false });
  const partnerInput = {
    email: useFieldControl<string>("", [mail]),
    compensation: useFieldControl<string>("", [number]),
  };

  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
  });

  const [data, setData] = useState({
    value: [
      {
        id: 1,
        name: "Maria Baptista",
        email: {
          emails: "leonora@mail.com",
          status: true,
        },

        companies: [
          {
            id: 1,
            name: "Newt",
            users: [
              "Kierra Esam Bothman",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
            ],
          },
          {
            id: 2,
            name: "NewtCloud",
            users: ["Leo Curtis"],
          },
          {
            id: 3,
            name: "Ucaas",
            users: ["Kianna Schleifer", "Leo Curtis"],
          },
          {
            id: 4,
            name: "NewtCloud",
            users: [
              "Kianna Dorwart",
              "Kierra Ekstrom Bothman",
              "Kianna Schleifer",
              "Leo Curtis",
              "Kianna Schleifer",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
            ],
          },
        ],
      },
      {
        id: 2,
        name: "Maria Baptista",
        email: {
          emails: "leonora@mail.com",
          status: true,
        },

        companies: [
          {
            id: 1,
            name: "Newt",
            users: [
              "Kierra Esam Bothman",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
              "Kianna Schleifer",
            ],
          },

          {
            id: 2,
            name: "NewtCloud",
            users: ["Leo Curtis"],
          },

          {
            id: 3,
            name: "Ucaas",
            users: ["Kianna Schleifer", "Leo Curtis"],
          },
          {
            id: 4,
            name: "NewtCloud",
            users: [
              "Kianna Dorwart",
              "Kierra Ekstrom Bothman",
              "Kianna Schleifer",
              "Leo Curtis",
              "Kianna Schleifer",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
              "Leo Curtis",
            ],
          },
        ],
      },
    ],
    fetching: true,
  });

  const addPartner = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end`}>
          <Text style={tw`font-bold text-2xl`}>Add Partners</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex-row justify-start  `}>
          <Text style={tw`font-normal text-sm text-gray-400`}>
            Please fill in fields below, after that we send this partner invite
            link.
          </Text>
        </View>
        <View style={tw`flex-grow-1  w-full justify-between `}>
          <TextInput
            label="Email"
            value={partnerInput.email.value}
            errorMessage={partnerInput.email.error}
            onChangeText={partnerInput.email.handleInputValue}
            keyboardType="email-address"
            placeholder="email@email.com"
          />
          <TextInput
            label="Compensation"
            value={partnerInput.compensation.value}
            errorMessage={partnerInput.compensation.error}
            type={"number"}
            onChangeText={partnerInput.compensation.handleInputValue}
            placeholder="Compensation%"
          />
        </View>

        <View style={tw`flex w-full my-4`}></View>
        <View style={tw`flex flex-row items-end justify-end my-2 `}>
          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/3`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/3`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Send Invite
          </Button>
        </View>
      </View>
    );
  };

  const partnersDetailsname = (response: any) => {
    if (!globalState.statesData) {
      setglobalState({
        id: response.id,
        statesData: true,
      });
    } else {
      setglobalState({
        id: response.id,
        statesData: false,
      });
    }
  };

  const deletePartner = (email: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove Partners?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to remove this Partner? </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button
              style={tw`flex-1`}
              onPress={() => {
                Modal.dismiss();
              }}
            >
              <Text weight="semiBold">Delete</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const blockPartner = (email: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end`}>
          <Text style={tw`font-bold text-2xl`}>Block Partners</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex-row justify-start  `}>
          <Text style={tw`font-normal text-sm text-gray-400`}>{email}</Text>
        </View>
        <View style={tw`flex-grow-1  w-full justify-between my-2 `}>
          <Text>
            Are you sure you want to block this partner? Blocked partner cannot
            log in and will not receive any compensation.
          </Text>
        </View>

        <View style={tw`flex w-full my-4`}></View>
        <View style={tw`flex flex-row items-end justify-end my-2 `}>
          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/3`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/3`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Delete
          </Button>
        </View>
      </View>
    );
  };

  const editCompensation = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end`}>
          <Text style={tw`font-bold text-2xl`}>Edit compensation</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex-row justify-start  `}>
          <Text style={tw`font-normal text-sm text-gray-400`}>
            New compensation amount will be applied for new clients only.
          </Text>
        </View>
        <View style={tw`flex-grow-1  w-full items-center my-4`}>
          <TextInput
            label="Compensation %"
            value={partnerInput.compensation.value}
            errorMessage={partnerInput.compensation.error}
            type={"number"}
            onChangeText={partnerInput.compensation.handleInputValue}
            placeholder="10 %"
          />
        </View>

        <View style={tw`flex w-full my-4`}></View>
        <View style={tw`flex flex-row items-end justify-end `}>
          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/3`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/3`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Delete
          </Button>
        </View>
      </View>
    );
  };

  const dataCompanies = (response: any) => {
    ///test if the state is false and keeps it in false otherwise link it to partnersprofiles
    if (!globalState.statesData) {
      setglobalState({
        id: response.id,
        statesData: false,
      });
    } else if (globalState.id === response.id) {
      navigate("../partnersprofile", {
        state: {
          name: response.name,
          tabdata: "details",
        },
      });
    }
  };

  const dataUsers = (response: any) => {
    ///test if the state is false and keeps it in false otherwise link it to partnersprofiles
    if (!globalState.statesData) {
      setglobalState({
        id: response.id,
        statesData: false,
      });
    } else if (globalState.id === response.id) {
      navigate("../partnersprofile", {
        state: {
          name: response.name,
          tabdata: "users",
        },
      });
    }
  };

  const compannies = (response: any) => {
    //loop all companies and assign it to the string companny
    let companies = "";
    response.companies.map((returnCompa: any, s: number) => {
      companies += returnCompa.name + "," + " ";
    });
    return companies;
  };

  const users = (response: any) => {
    //loop all companies and assign it to the string user
    let user = "";
    response.map((data: any) => {
      data.users.map((info: any) => {
        user += info;
      });
    });
    return user;
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text type="h2">Partners</Text>
        </View>
        <View style={tw`max-w-[172px] w-full`}>
          <Button type="outlined" onPress={addPartner}>
            Add Partner
          </Button>
        </View>
      </View>

      {/* Headers */}

      <ScrollView
        //horizontal
        showsHorizontalScrollIndicator
        horizontal
        contentContainerStyle={[
          tw`md:w-full  flex-col md:min-w-[200] my-4 px-5`,
        ]}
      >
        <View style={tw`flex flex-row w-full  px-5`}>
          <Text
            color={colors.gray}
            style={{ flex: 1, ...tw`flex flex-row justify-start` }}
          >
            Name{" "}
          </Text>
          <Text
            color={colors.gray}
            style={{ flex: 1, ...tw`flex flex-row justify-start` }}
          >
            Email{" "}
          </Text>
          <Text
            color={colors.gray}
            style={{ flex: 1, ...tw`flex flex-row justify-start` }}
          >
            Companies{" "}
          </Text>
          <Text
            color={colors.gray}
            style={{ flex: 1, ...tw`flex flex-row justify-start` }}
          >
            Users{" "}
          </Text>
        </View>
        {data.value.map((response, index) => {
          return (
            <View
              style={[
                tw`flex w-full flex-row bg-white py-3 mb-1 rounded-3 items-center justify-between my-2`,

                { zIndex: "auto" },
              ]}
              key={response.id}
            >
              <TouchableHighlight
                underlayColor="none"
                containerStyle={tw`w-full  px-6`}
                onPress={() => partnersDetailsname(response)}
                key={response.id}
              >
                <View
                  style={[
                    tw`flex flex-row  w-full  py-2  items-start justify-start`,
                    { zIndex: "auto" },
                  ]}
                >
                  {/* Name */}
                  <View style={tw`flex flex-1`}>
                    <TouchableOpacity onPress={() => dataCompanies(response)}>
                      <Text color={colors.dark}>{response.name}</Text>
                    </TouchableOpacity>
                  </View>

                  {/* Email */}
                  <View style={tw`flex flex-1`}>
                    <TouchableOpacity onPress={() => dataUsers(response)}>
                      <Text
                        style={tw`flex flex-row items-center`}
                        key={response.id}
                        color={colors.gray}
                      >
                        <View style={tw`mr-1 mt-1`}>
                          {response.email.status ? (
                            <Circle color={colors.blue} />
                          ) : (
                            <Circle color={colors.red} />
                          )}
                        </View>
                        {response.email.emails}
                      </Text>
                    </TouchableOpacity>
                  </View>

                  {response.id === globalState.id && globalState.statesData ? (
                    <>
                      {/* Companies */}
                      <View style={tw`flex-1`}>
                        <Text color={colors.dark} weight="semiBold">
                          {response.companies
                            .map((company) => company.name)
                            .join("\n")}
                        </Text>
                      </View>

                      {/* Users */}
                      <View style={tw`flex-1`}>
                        <Text
                          color={colors.dark}
                          weight="semiBold"
                          style={tw`lg:items-center`}
                        >
                          {response.companies
                            .map((company) => company.users.join(", "))
                            .join("")}
                        </Text>
                      </View>
                    </>
                  ) : (
                    <>
                      {/* Companies */}
                      <View style={tw`flex-1`}>
                        <Text
                          color={colors.dark}
                          weight="semiBold"
                          numberOfLines={1}
                          ellipsizeMode="tail"
                        >
                          {compannies(response)}
                        </Text>
                      </View>

                      {/* Users */}
                      <View style={tw`flex-1  flex-row`}>
                        <Text
                          color={colors.dark}
                          weight="semiBold"
                          style={tw`lg:items-center`}
                          numberOfLines={1}
                          ellipsizeMode="tail"
                        >
                          {users(response.companies)}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
              </TouchableHighlight>
              {/* Dropdown */}
              <View style={[tw`-ml-8`, { zIndex: "auto" }]}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ lineHeight: 0, ...tw`no-underline` }}
                    id="dropdown-basic"
                    bsPrefix="btn"
                  >
                    {!globalState.statesData && (
                      <Icon
                        color={colors.gray}
                        name="more-vert"
                        size={20}
                        tvParallaxProperties={undefined}
                      />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={tw`rounded-3`}>
                    <Dropdown.Item
                      as="button"
                      style={tw` text-btn-menu-item font-semibold`}
                      onClick={() => {
                        return editCompensation();
                      }}
                    >
                      Edit Compensation
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        return deletePartner(response.email.emails);
                      }}
                      style={tw` text-btn-menu-item font-semibold`}
                    >
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        return blockPartner(response.email.emails);
                      }}
                      style={tw`text-red-500 text-btn-menu-item font-semibold`}
                    >
                      Block
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </View>
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

//export default Partners

const styles = StyleSheet.create({});
