import { Modal, colors } from "@/lib";
import { tw, useFieldControl } from "@/lib";
import { WorkspaceService } from "@/services";
import React, { useEffect } from "react";
import { View } from "react-native";
import { Button, Loading, Text } from "../Commons";

import { DropFileInput } from "../Inputs";
import { CheckBig } from "../Icons/CheckBig";
import { ClockIcon } from "../Icons/ClockIcon";
import { ModalProps } from "react-native-modals";

interface ThankYouDialogProp {
  loading: boolean;
  onCanel: () => void;
}

export function ThankYouDialog(props: ThankYouDialogProp) {
  const { loading, onCanel } = props;
  return (
    <View style={tw`flext flex-column items-center p-5  shadow-md`}>
      <Text type="h2">Thank You</Text>
      <View style={tw`w-full flex-row items-center my-2`}>
        <View style={tw`w-3/12`}>
          <ClockIcon style={tw`m-auto`} />
        </View>
        <View style={tw`w-9/12 `}>
          <View style={tw`flex-column`}>
            <Text type="regular">
              Please wait while we create your new workspace.
            </Text>
            <Text type="regular" weight="semiBold" color={colors.red}>
              Please be advised that it can take about 1 hour for inbound
              calling and SMS messages to start working on your new phone
              number.
            </Text>
          </View>
        </View>
      </View>
      <View style={tw`mb-2 h-[45px] justify-center`}>
        {loading ? (
          <View style={[tw`flex-grow-1 w-auto`]}>
            <Loading />
          </View>
        ) : (
          <View style={tw`flex-row`}>
            <CheckBig />
            <Text color={colors.greenicon}>Workspace Created!</Text>
          </View>
        )}
      </View>
      <Button style={tw`w-[156px]`} disabled={loading} onPress={onCanel}>
        Ok
      </Button>
    </View>
  );
}
