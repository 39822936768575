import { ApiService } from "./";
import { AxiosResponse } from "axios";
import { SubscriptionResponse } from "@/types";

const subscriptionCurrent = "/subscriptions/current/";

class SubscriptionService {
  subscriptionCurrent(
    id: number
  ): Promise<AxiosResponse<{ result: SubscriptionResponse }>> {
    return ApiService.api.get(`${subscriptionCurrent}${id}`);
  }
}
const singleton = new SubscriptionService();

export default singleton;
