import { View, Text } from "react-native";
import React, { FC } from "react";
import Svg, { Path, Ellipse } from "react-native-svg";

interface Props {
  width: number;
  height: number;
}

export const Nucleus: FC<Props> = ({ width, height }) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <Path
        d="M42.1395 51.2081V34.7249C42.1395 32.7156 41.8462 31.2042 41.2595 30.1907C40.6907 29.1594 39.7664 28.6437 38.4865 28.6437C36.7267 28.6437 35.4557 29.3461 34.6735 30.7508C33.8914 32.1555 33.5003 34.5649 33.5003 37.9789V51.2081H23.0747V20.9889H30.9406L32.2471 24.723H32.8604C33.7492 23.3005 34.9757 22.2336 36.54 21.5224C38.1221 20.8111 39.9352 20.4555 41.9795 20.4555C45.3214 20.4555 47.9167 21.4246 49.7654 23.3627C51.6318 25.3009 52.5651 28.0303 52.5651 31.551V51.2081H42.1395Z"
        fill="white"
      ></Path>
      <Path
        d="M81.1755 51.2081L79.869 47.4474H79.2557C78.3847 48.8343 77.1671 49.9012 75.6028 50.648C74.0563 51.377 72.2342 51.7415 70.1367 51.7415C66.8125 51.7415 64.2261 50.7813 62.3774 48.861C60.5287 46.9228 59.6044 44.1934 59.6044 40.6727V20.9889H70.03V37.4988C70.03 39.4725 70.3055 40.9661 70.8566 41.9796C71.4254 42.9932 72.3498 43.4999 73.6296 43.4999C75.425 43.4999 76.7049 42.7976 77.4692 41.3929C78.2336 39.9704 78.6158 37.5788 78.6158 34.2181V20.9889H89.0414V51.2081H81.1755Z"
        fill="white"
      ></Path>
      <Path
        d="M109.999 51.7415C105.058 51.7415 101.307 50.4079 98.7471 47.7407C96.2051 45.0736 94.9342 41.2417 94.9342 36.2452C94.9342 31.2309 96.3118 27.3457 99.0671 24.5896C101.822 21.8335 105.742 20.4555 110.826 20.4555C114.328 20.4555 117.625 21.2201 120.718 22.7493L117.652 30.4574C116.39 29.9062 115.225 29.4706 114.159 29.1505C113.092 28.8126 111.981 28.6437 110.826 28.6437C109.137 28.6437 107.831 29.3016 106.906 30.6174C105.982 31.9333 105.52 33.7914 105.52 36.1919C105.52 41.0283 107.306 43.4466 110.879 43.4466C113.937 43.4466 116.87 42.5575 119.678 40.7794V49.0743C116.994 50.8525 113.768 51.7415 109.999 51.7415Z"
        fill="white"
      ></Path>
      <Path
        d="M136.103 51.2081H125.678V9.70674H136.103V51.2081Z"
        fill="white"
      ></Path>
      <Path
        d="M157.915 51.7415C152.884 51.7415 148.973 50.4079 146.182 47.7407C143.409 45.0558 142.023 41.2417 142.023 36.2985C142.023 31.1953 143.312 27.2835 145.889 24.5629C148.467 21.8246 152.146 20.4555 156.928 20.4555C161.479 20.4555 164.989 21.6468 167.46 24.0295C169.949 26.3944 171.193 29.8084 171.193 34.2715V38.9124H152.555C152.626 40.6016 153.239 41.9263 154.395 42.8865C155.568 43.8467 157.159 44.3268 159.168 44.3268C160.999 44.3268 162.678 44.1578 164.207 43.82C165.754 43.4644 167.425 42.8687 169.22 42.033V49.5278C167.585 50.3635 165.896 50.9414 164.154 51.2614C162.412 51.5815 160.332 51.7415 157.915 51.7415ZM157.301 27.5502C156.075 27.5502 155.044 27.9414 154.208 28.7237C153.391 29.4883 152.911 30.6975 152.768 32.3511H161.727C161.692 30.893 161.274 29.7284 160.474 28.8571C159.692 27.9858 158.634 27.5502 157.301 27.5502Z"
        fill="white"
      ></Path>
      <Path
        d="M198.604 51.2081L197.297 47.4474H196.684C195.813 48.8343 194.595 49.9012 193.031 50.648C191.484 51.377 189.662 51.7415 187.565 51.7415C184.241 51.7415 181.654 50.7813 179.806 48.861C177.957 46.9228 177.033 44.1934 177.033 40.6727V20.9889H187.458V37.4988C187.458 39.4725 187.734 40.9661 188.285 41.9796C188.854 42.9932 189.778 43.4999 191.058 43.4999C192.853 43.4999 194.133 42.7976 194.897 41.3929C195.662 39.9704 196.044 37.5788 196.044 34.2181V20.9889H206.47V51.2081H198.604Z"
        fill="white"
      ></Path>
      <Path
        d="M237 41.9263C237 45.1625 235.898 47.6074 233.694 49.261C231.489 50.9147 228.325 51.7415 224.201 51.7415C221.961 51.7415 220.015 51.617 218.362 51.3681C216.709 51.137 215.02 50.7013 213.296 50.0612V41.7129C214.913 42.4242 216.682 43.0109 218.602 43.4733C220.539 43.9356 222.264 44.1667 223.775 44.1667C226.032 44.1667 227.161 43.6511 227.161 42.6198C227.161 42.0863 226.841 41.6151 226.201 41.2062C225.579 40.7794 223.748 39.9615 220.708 38.7523C217.935 37.6143 215.998 36.3252 214.895 34.8849C213.793 33.4269 213.242 31.5865 213.242 29.3639C213.242 26.5544 214.327 24.3673 216.495 22.8026C218.664 21.2379 221.73 20.4555 225.694 20.4555C227.685 20.4555 229.552 20.6777 231.294 21.1223C233.054 21.549 234.876 22.1803 236.76 23.016L233.934 29.7373C232.547 29.1149 231.081 28.5904 229.534 28.1636C227.987 27.7191 226.725 27.4968 225.748 27.4968C224.041 27.4968 223.188 27.9147 223.188 28.7504C223.188 29.2661 223.481 29.7106 224.068 30.084C224.672 30.4396 226.397 31.1775 229.241 32.2978C231.356 33.169 232.929 34.0225 233.96 34.8583C235.009 35.694 235.773 36.6808 236.253 37.8188C236.751 38.939 237 40.3082 237 41.9263Z"
        fill="white"
      ></Path>
      <Ellipse
        cx="7.89462"
        cy="14.7215"
        rx="7.89462"
        ry="7.89693"
        fill="white"
      ></Ellipse>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.5233 60.2841C57.1624 61.0345 57.075 62.1648 56.2932 62.765C51.4824 66.4585 45.7853 68.8443 39.7572 69.6736C33.1388 70.5841 26.3982 69.5804 20.332 66.7809C14.2658 63.9815 9.12757 59.5034 5.52462 53.8759C1.92167 48.2484 0.0046099 41.7068 8.30993e-06 35.0241C-0.00251433 31.3607 0.569782 27.7388 1.67974 24.2859C2.67692 24.9355 3.78199 25.4333 4.96218 25.7465C4.04142 28.7362 3.56734 31.8617 3.56951 35.0217C3.57364 41.0226 5.29513 46.8968 8.53052 51.9502C11.7659 57.0036 16.38 61.0249 21.8273 63.5388C27.2747 66.0526 33.3276 66.954 39.2708 66.1364C44.6233 65.4 49.6852 63.2971 53.9743 60.0436C54.76 59.4477 55.884 59.5334 56.5233 60.2841ZM17.6411 8.79589C18.9578 7.92363 20.3435 7.14853 21.788 6.47949C27.2319 3.95812 33.2836 3.0484 39.2279 3.85785C44.5814 4.58685 49.6462 6.68279 53.9398 9.9303C54.7263 10.5251 55.8502 10.4379 56.4885 9.68636C57.1266 8.93509 57.0376 7.80487 56.2549 7.20571C51.4391 3.5189 45.7386 1.14095 39.7094 0.319933C33.0898 -0.581475 26.3506 0.431592 20.2882 3.2394C18.5488 4.04504 16.8859 4.98878 15.3139 6.05809C16.226 6.84042 17.013 7.76439 17.6411 8.79589Z"
        fill="white"
      ></Path>
    </Svg>
  );
};
