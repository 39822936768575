import React, { useEffect, useState } from "react";
import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native";
import { Card, Icon } from "react-native-elements";
import { useDeviceContext } from "twrnc";
import { CardSubscriptions } from "@/components/Subscriptions";
import { Button, Text, Loading } from "@/components/Commons";
import { ClientLayout } from "@/components/Layouts/ClientLayout";
import tw from "@/lib/tailwind";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Modal, { ModalContent } from "react-native-modals";
import { Close } from "@/components/Icons";
import { Image } from "react-native-elements";
import { Back } from "@/components/Icons/Back";
import { Dropdown, TextInput } from "@/components/Inputs";
import { colors, useFieldControl } from "@/lib";
import { required } from "@/lib/Rules";
import { useNavigate } from "@/routes";
import { PlanService, SubscriptionService, WorkspaceService } from "@/services";
import { PlanResponse, PlanSubscription } from "@/types";
//@ts-ignore
import { BASIC_PLAN_NAME } from "@env";

export const SubscriptionDetails = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({ modalVisible: false });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentId, setCurrentId] = useState<string>("");
  const [stateId, setStateIid] = useState<number>(0);
  const [subscription, setSubscription] = useState<any>([]);
  const [description, setDescription] = useState<string>("");
  const codes = [
    {
      _id: "1",
      gradient: ["#7BC915", "#A9E35E"],
      is_enabled: false,
    },
    {
      _id: "2",
      gradient: ["#6980FF", "#BABAFF"],
      is_enabled: true,
    },
    {
      _id: "3",
      gradient: ["#CAF2EB", "#CECEFF"],
      is_enabled: true,
    },
    {
      _id: "4",
      gradient: ["#4DE1E3", "#66C1F0", "#BABAFF"],
      is_enabled: false,
    },
  ];

  const errorInitialState = { show: false, msg: "" };

  //Error indicator
  const [errorMsg, setErrorMsg] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );

  const ccardInput = {
    provinceState: useFieldControl<string>("", [required]),
    postalCode: useFieldControl<string>("", [required]),
    city: useFieldControl<string>("", [required]),
    addressLine1: useFieldControl<string>("", [required]),
    addressLine2: useFieldControl<string>("", [required]),
    plans: useFieldControl<PlanResponse[]>([]),
    workspaceId: useFieldControl<number>(0),
  };
  useEffect(() => {
    PlanService.listPlans()
      .then((r) => {
        ccardInput.plans.handleInputValue(r);
        WorkspaceService.getCurrent().then((r) => {
          //setStateIid(r.data.result.id)
          const id = r.data?.result?.id;
          SubscriptionService.subscriptionCurrent(id).then((s) => {
            setCurrentId(s.data?.result?.planId);
            Object.values(s.data).map((l) => {
              //@ts-ignore
              setDescription(l.description);
            });
            setIsLoading(false);
            //setDescription(s.data.description)
          });
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const carousel = [...ccardInput.plans.value];

  const planSubscriptions = async (order: PlanResponse) => {
    navigate("subscriptionDetailsplan", {
      state: {
        plan: order.name,
        amount: order.license_range.max,
        min: order.license_range.min,
        plan_id: order.id,
        month: order.term,
        user: order.unit,
        text: order.description,
        price: order.price_per_unit,
      },
    });
  };

  useDeviceContext(tw);
  // @ts-ignore
  const renderItem = ({ item }) => (
    <View style={tw` hidden flex   flex-col w-full  sm:flex    `}>
      <View style={tw`flex flex-row items-start sm:h-[15px] lg:h-[24px]   `}>
        <Text type="regular" weight="extraBold">
          .
        </Text>
        <Text type="regular" weight="regular">
          {" "}
          {item}
        </Text>
      </View>
    </View>
  );
  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
          <View style={tw`flex flex-row items-center lg:pl-4`}>
            <Text type="h2" weight="bold" style={tw` pr-4`}>
              Details
            </Text>
          </View>
          <View style={tw` flex flex-row  `}>
            <CardSubscriptions data={codes} visible={false} />
          </View>
          <View style={tw`flex lg:flex-row sm:my-6 lg:pl-4`}>
            <Text style={tw`text-gray-500 mt-4`}>Subscription Overview</Text>
          </View>

          <Carousel
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            customTransition="all .3"
            transitionDuration={500}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {carousel.map((order) => {
              const price = Number(
                order.price_per_unit.includes("$") &&
                  order.price_per_unit.replace("$", "")
              );

              return (
                <Card
                  key={order.id}
                  containerStyle={[
                    tw`flex flex-col  pt-6 h-[438px]  bg-white rounded-3 sm:max-w-md   `,
                  ]}
                >
                  <View style={tw`flex flex-row justify-between`}>
                    <Text style={tw` font-bold text-blue-600 `}>
                      {order.name.toUpperCase()}
                    </Text>

                    <View>
                      {description.includes(order.name) ? (
                        <TouchableOpacity onPress={() => {}}>
                          <Text color={colors.green}>Your Plan</Text>
                        </TouchableOpacity>
                      ) : (
                        order.name.toLowerCase().includes(BASIC_PLAN_NAME) &&
                        description.includes("Free") && (
                          <TouchableOpacity onPress={() => {}}>
                            <Text color={colors.green}>Your Plan</Text>
                          </TouchableOpacity>
                        )
                      )}
                    </View>
                  </View>

                  {order.price_per_unit.includes("$") ? (
                    <View style={tw`flex  pl-2 flex-row my-2 items-center`}>
                      <Text type="h1" weight="bold">
                        ${price} {""}
                      </Text>
                      <View style={tw`flex  flex-col `}>
                        <Text
                          type="regular"
                          weight="regular"
                          color={colors.gray}
                        >
                          /{order.term || "year"}
                        </Text>
                        <Text
                          type="regular"
                          weight="regular"
                          color={colors.gray}
                        >
                          /{order.unit || "user"}
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <Text type="h1" weight="bold" style={tw`mb-2`}>
                      {order.price_per_unit}
                    </Text>
                  )}

                  <ScrollView
                    style={tw`flex sm:flex  ${
                      !order.plan_description ? `lg:h-[200px]` : ``
                    } `}
                  >
                    <Text style={tw`sm:text-sm md:text-md text-left`}>
                      {order.description}{" "}
                    </Text>
                  </ScrollView>

                  <FlatList
                    style={tw` pl-6  `}
                    data={order.plan_description}
                    renderItem={renderItem}
                  />
                  <View style={[tw`flex  my-2 `]}>
                    <Button
                      style={tw`sm:pb-6 pb-2 w-full max-w-1/2 sm:w-auto`}
                      onPress={() => planSubscriptions(order)}
                    >
                      {currentId === order.id ? "Ver Plan" : "Start plan"}
                    </Button>
                  </View>
                </Card>
              );
            })}
          </Carousel>

          {/* <Modal
            // @ts-ignore
            width={Math.min(650, screen.width)}
            modalStyle={tw`rounded-3  max-h-full  `}
            visible={state.modalVisible}
            onTouchOutside={() => {
              setState({ modalVisible: false });
            }}
          >
            <ModalContent>
              <View>
                <View style={tw`flex-row justify-between items-end`}>
                  <Text style={tw`font-bold text-2xl`}>Add Credit Card</Text>
                  <TouchableOpacity
                    onPressOut={() => {
                      setState({ modalVisible: false });
                    }}
                  >
                    <Close />
                  </TouchableOpacity>
                </View>
                <View style={tw`flex-row justify-between `}>
                  <Text style={tw`font-normal text-lg`}>
                    To start plan, please fill in the credit card details
                  </Text>
                </View>

                <View style={tw`flex w-full    `}>
                  <Card
                    containerStyle={tw`flex w-full    max-w-6/9    bg-blue-900 rounded-xl  overflow-hidden shadow-md relative z-10 `}
                  >
                    <View style={tw`flex pl-6`}>
                      <Text style={tw`text-gray-400 text-lg  `}>
                        Cardholder name
                      </Text>
                      <Text style={tw`text-white text-lg font-normal  my-2 `}>
                        James Frank
                      </Text>
                      <View
                        style={{
                          borderStyle: "solid",
                          borderWidth: 1,
                          shadowColor: "#778899",
                          borderRadius: 0,
                          borderColor: "#FFFFFF",
                        }}
                      ></View>
                      <Text style={tw`text-gray-400 text-lg  `}>
                        Card number
                      </Text>
                      <Text style={tw`text-white text-lg font-normal  my-2`}>
                        xxxx xxxx xxxx xxxx 0932
                        <View
                          style={tw`flex items-end justify-end sm:flex  lg:bottom-7   `}
                        >
                          <Image
                            style={tw`w-10 h-7 `}
                            source={require("@/assets/images/LOGO/chipcard.png")}
                          />
                        </View>
                      </Text>

                      {window.innerWidth < 900 ? (
                        <View
                          style={{
                            borderStyle: "solid",
                            borderWidth: 1,
                            shadowColor: "#778899",
                            borderRadius: 0,
                            borderColor: "#FFFFFF",
                            width: 120,
                            bottom: 30,
                          }}
                        />
                      ) : (
                        <View
                          style={{
                            borderStyle: "solid",
                            borderWidth: 1,
                            shadowColor: "#778899",
                            borderRadius: 0,
                            borderColor: "#FFFFFF",
                            width: 200,
                            bottom: 30,
                          }}
                        />
                      )}

                      <Text style={tw`text-gray-400 text-lg   `}>
                        Valid Thru
                      </Text>
                      <Text style={tw`text-white text-lg font-normal `}>
                        06/23
                      </Text>
                      <View
                        style={{
                          borderStyle: "solid",
                          borderWidth: 1,
                          shadowColor: "#778899",
                          borderRadius: 0,
                          borderColor: "#FFFFFF",
                          width: 50,
                        }}
                      ></View>
                    </View>
                  </Card>

                  <View
                    style={tw`flex w-full h-auto  items-end justify-end my-4  `}
                  >
                    <Card
                      containerStyle={tw`flex  w-2/3 h-60   bg-gray-700 rounded-xl  overflow-hidden shadow-md absolute   `}
                    >
                      <Card
                        containerStyle={tw`flex w-full items-end justify-end bg-gray-500 overflow-hidden shadow-md `}
                      ></Card>

                      <View style={tw`flex top-10`}>
                        <Text style={tw`text-sm text-right text-gray-500`}>
                          CVV
                        </Text>
                        <Text style={tw`text-white text-sm text-right `}>
                          {" "}
                          XXX
                        </Text>
                        <View style={tw`flex items-end justify-end`}>
                          <View
                            style={{
                              borderStyle: "solid",
                              borderWidth: 1,
                              shadowColor: "#778899",
                              borderRadius: 0,
                              borderColor: "#778899",
                              width: 30,
                            }}
                          ></View>
                        </View>
                      </View>
                    </Card>
                  </View>
                  <View style={tw`flex items-end justify-end     `}>
                    <Text style={tw` text-sm sm:flex `}>
                      We do not need your card data -it's kept in{" "}
                      <Text style={tw`text-blue-800`}> One Bill </Text>{" "}
                    </Text>
                  </View>
                </View>

                <View style={tw`flex flex-row items-start `}>
                  <View style={tw`flex flex-col w-full max-w-1/2 `}>
                    <Dropdown
                      label="Country"
                      data={[
                        { value: "1", label: "Option 1" },
                        { value: "2", label: "Option 2" },
                        { value: "3", label: "Option 3" },
                      ]}
                    />
                    <TextInput
                      label="Province/State"
                      value={ccardInput.provinceState.value}
                      errorMessage={ccardInput.provinceState.error}
                      onChangeText={ccardInput.provinceState.handleInputValue}
                    />

                    <TextInput
                      label="Address Line 1"
                      value={ccardInput.addressLine1.value}
                      errorMessage={ccardInput.addressLine1.error}
                      onChangeText={ccardInput.addressLine1.handleInputValue}
                    />
                  </View>
                  <View style={tw`flex flex-col w-full max-w-1/2 `}>
                    <TextInput
                      label="Postal Code/Zip Code"
                      value={ccardInput.postalCode.value}
                      errorMessage={ccardInput.postalCode.error}
                      onChangeText={ccardInput.postalCode.handleInputValue}
                    />

                    <TextInput
                      label="City"
                      value={ccardInput.city.value}
                      errorMessage={ccardInput.city.error}
                      onChangeText={ccardInput.city.handleInputValue}
                    />

                    <TextInput
                      label="Address Line 2"
                      value={ccardInput.addressLine2.value}
                      errorMessage={ccardInput.addressLine2.error}
                      onChangeText={ccardInput.addressLine2.handleInputValue}
                    />
                  </View>
                </View>

                <View style={tw`flex flex-row items-end justify-end  my-2 `}>
                  <Button
                    style={tw`sm:pb-6 pb-2 max-w-1/4`}
                    type="outlined"
                    onPressOut={() => {
                      setState({ modalVisible: false });
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={tw`sm:pb-6 pb-2 max-w-1/4 `}
                    onPressOut={() => {
                      setState({ modalVisible: false });
                    }}
                  >
                    Save
                  </Button>
                </View>
              </View>
            </ModalContent>
          </Modal>

          <View style={tw`flex lg:flex-row sm:my-2 pl-4`}>
            <Text style={tw`text-gray-500 mt-2`}>Credit Card</Text>
          </View>
          <View
            style={tw`flex flex-row  w-full items-start sm:flex justify-start  lg:max-w-1/7   `}
          >
            <Button
              style={tw`w-full  sm:max-w-full `}
              onPress={() => setState({ modalVisible: true })}
            >
              Add credit card
            </Button>
          </View> */}
        </View>
      )}
    </>
  );
};

//export default Details

const styles = StyleSheet.create({});
