import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export function CheckBig(props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) {
  const { color = "#19B665", style } = props;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <Path
        d="M6 11.5L10.5 16L18 8"
        stroke={color}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
}
