import React, { useEffect, useRef, useState } from "react";
import {
  Dimensions,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { useDeviceContext } from "twrnc";
import Icon from "react-native-vector-icons/FontAwesome";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { CheckBox } from "react-native-elements";
import { AppModal, Button, Loading, Text } from "@/components/Commons";

import {
  capitalize,
  colors,
  Modal,
  number,
  required,
  showFriendlyErrorFromHTMLCode,
  sortByHeader,
  tw,
  useFieldControl,
} from "@/lib";
import {
  FancyDropdown,
  TextInput,
  Dropdown as DropDown,
} from "@/components/Inputs";
import RequestAccessItem from "@/components/RequestAccess/RequestAccessItem";
import RequestAccessListHeader from "@/components/RequestAccess/RequestAccessListHeader";
import {
  AccessLevel,
  AccessResponse,
  GlobalAccessResponse,
  IUpdateWorkspaceAccessControl,
  Status,
  WorkspaceInterface,
  WorkspaceResponse,
} from "@/types";
import { SuperAdminService, WorkspaceService } from "@/services";
import { AxiosError } from "axios";
import { useAlert } from "@/hooks";
import ModalBody from "./ModalBody";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import {
  clearInfo,
  setAccess,
  setDateGlobalRange,
} from "@/store/accessRequest.store";
import { RootState, store } from "@/store";
import ModalButtons from "./ModalButtons";
import DurationPermition from "@/components/DurationPermition/DurationPermition";
import { Check, Close } from "@/components/Icons";
import { AppCalendar } from "@/components/Calendars";
import { updateNotification } from "@/store/notifications.store";
import { setNotifications } from "@/store/notifications.store";

const MODAL_SIZE: number = 587;

export const RequestAccess = () => {
  useDeviceContext(tw);

  const accessStore = useAppSelector((state: RootState) => state.accessRequest);
  
  const dispatch = useAppDispatch();

  const { width } = useWindowDimensions();

  const { showSwal } = useAlert();

  const { workspaceId } = useParams();

  const [requestedAcces, setRequestedAcces] = useState<AccessResponse[]>([]);
  const [nameHeader, setNameHeader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workId, setWorkId] = useState<number>(0);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [globalAccess, setGlobalAccess] = useState<WorkspaceResponse>();
  const [accessLevel, setAccessLevel] = useState<GlobalAccessResponse[]>();

  const globalForm = {
    accessLevel: useFieldControl<string>("", [required]),
    temporaryAccess: useFieldControl<string>("", [required]),
    temporaryAccessLeve: useFieldControl<string>("", [required]),
    duration: useFieldControl<string>("", [number]),
  };

  //console.log(accessStore.endless)
  const formData = {
    from: useFieldControl<string>(
      (accessStore.from_global &&
        dayjs(accessStore.from_global).format("YYYY-MM-DD")) ||
      ""
    ),
    to: useFieldControl<string>(
      (accessStore.to_global &&
        dayjs(accessStore.to_global).format("YYYY-MM-DD")) ||
      ""
    ),
  };

  const handleRangeDate = () => {
    dispatch(
      setDateGlobalRange({
        from_global: formData.from.value,
        to_global: formData.to.value,
      })
    );
    handleModal();
  };

  const getWorkspace = async () => {
    let wpId: number = 0;
    try {
      setIsLoading(true);
      if (workspaceId) {
        const wp = await SuperAdminService.getWorkspace(parseInt(workspaceId));

        wpId = wp.data.result.id;
        setGlobalAccess(wp.data.result);
      } else {
        const current = await WorkspaceService.getCurrent();
        wpId = current.data.result.id;
        setGlobalAccess(current.data.result);

      }
      
      setWorkId(wpId);
      const data = (await WorkspaceService.globalAccessData(wpId)).data.result;
      dispatch(setDateGlobalRange({
        from_global: data.find(item => item.type == 'temporal')?.from ? 
        data.find(item => item.type == 'temporal')?.from : 
        data.find(item => item.type == 'global')?.from,
        to_global: data.find(item => item.type == 'temporal')?.to ? 
        data.find(item => item.type == 'temporal')?.to : data.find(item => item.type == 'global')?.to
      }))
      setAccessLevel(data);

      const acessRequest = await WorkspaceService.getAccessRequest(wpId);

      const acceses: WorkspaceInterface[] = acessRequest.data.result;

      const formatResult: AccessResponse[] = acceses.map((item) => {
        return {
          ...item,
          name: `${item.user?.firstName} ${item.user?.lastName}` || "",
          id: item?.user?.id || 0,
        };
      });
      globalForm.accessLevel.handleInputValue(
        globalAccess?.global_access?.accessType as Status
      );
      globalForm.temporaryAccessLeve.handleInputValue(
        globalAccess?.global_access?.accessType as Status
      );

      setRequestedAcces(formatResult);
      dispatch(setNotifications(acceses));
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const grantOrDenyAccess = async (accessResponse: AccessResponse) => {
    const accessRequest: IUpdateWorkspaceAccessControl = {
      supportUserId: accessResponse.id || 0,
      workspaceId: workId,
      status: accessResponse.status || "requested",
      endless: store.getState().accessRequest.endless,
    };

    if (
      !(
        accessResponse.status === "denied" ||
        accessResponse.status === "rejected" ||
        accessResponse.status === "expired" ||
        accessResponse.status === "revoked"
      )
    ) {
      accessRequest.from = accessResponse.from
        ? dayjs(accessResponse.from).toDate()
        : undefined;
      accessRequest.to = accessResponse.to
        ? dayjs(accessResponse.to).toDate()
        : undefined;
      accessRequest.accessType = accessResponse.accessType || "read only";
      accessRequest.duration = accessResponse.duration;
      accessRequest.durationType = accessResponse.durationType;
    }
    try {
      
      const res = await WorkspaceService.updateAccess(accessRequest);
      dispatch(setAccess({ fromTemporal: accessResponse.from }));
      Modal.dismiss();
      getWorkspace();
    } catch (err) {
      handleError(err);
    }
  };

  const changeAccess = () => {
    setShowChangeModal(true);
  };

  const grantPermition = (user: AccessResponse, userFrom?: string) => {
    let a: string;

    dispatch(clearInfo());

    user.durationType
      ? dispatch(setAccess(user))
      : dispatch(setAccess({ ...user, durationType: "days" }));

    Modal.show(
      <View>
        <ModalBody
          data={[
            { label: "No Access", value: "no access" },
            { label: "Read Only", value: "read only" },
            { label: "Read and Write", value: "read and write" },
          ]}
          visible
          types="local"
        />

        <View style={[tw`flex flex-row justify-end mt-10`, { zIndex: 5 }]}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              onPress={() => {
                changeAccess();
                Modal.dismiss();
              }}
            >
              <Text weight="semiBold">Approve</Text>
            </Button>

            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 500 }
    );
  };

  const approvePermition = (user: AccessResponse) => {
    let a: string;

    console.log('Endless',user.endless)

    dispatch(clearInfo());

    user.durationType
      ? dispatch(setAccess(user))
      : dispatch(setAccess({ ...user, durationType: "days" }));

    Modal.show(
      <View>
        <ModalBody
          data={[
            { label: "Read Only", value: "read only" },
            { label: "Read and Write", value: "read and write" },
          ]}
          
          text="Approve Access"
          visible={false}
          textarea={false}
          types={"local"}
        />

        <ModalButtons onCallBack={grantOrDenyAccess} text={"Approve"} />
      </View>,
      { width: 500 }
    );
  };

  const revokeAccess = (user: AccessResponse, status: Status) => {
    Modal.show(
      <View>
        {/* Title */}
        <Text type="h2" weight="semiBold" style={tw`text-center`}>
          Reject Access
        </Text>
        {/* Body */}
        <Text type="h3" weight="light" style={tw`text-center mt-8 mb-12`}>
          Are you sure you want to reject this {"\n"} access request?
        </Text>
        {/* Actions */}
        <View style={tw`flex md:flex-row items-center md:justify-center`}>
          <Button
            style={tw`w-[170px] mb-2 md:mb-0`}
            onPress={() => grantOrDenyAccess({ ...user, status })}
          >
            Accept
          </Button>
          <Button
            type="outlined"
            style={tw`w-[170px]`}
            onPress={() => Modal.dismiss()}
          >
            Cancel
          </Button>
        </View>
      </View>,
      { width: width < MODAL_SIZE ? width : MODAL_SIZE }
    );
  };

  const rejectAccess = (user: AccessResponse, status: Status) => {
    Modal.show(
      <View>
        {/* Title */}
        <Text type="h2" weight="semiBold" style={tw`text-center`}>
          Revoke Access
        </Text>
        {/* Body */}
        <Text type="h3" weight="light" style={tw`text-center mt-8 mb-12`}>
          Are you sure you want to revoke access? {"\n"} This support access
          request will end {"\n"} immediately
        </Text>
        {/* Actions */}
        <View style={tw`flex md:flex-row items-center md:justify-center`}>
          <Button
            style={tw`w-[170px] mb-2 md:mb-0`}
            onPress={() => grantOrDenyAccess({ ...user, status })}
          >
            Accept
          </Button>
          <Button
            type="outlined"
            style={tw`w-[170px]`}
            onPress={() => Modal.dismiss()}
          >
            Cancel
          </Button>
        </View>
      </View>,
      { width: width < MODAL_SIZE ? width : MODAL_SIZE }
    );
  };
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleError = (err: any) => {
    const error = err as AxiosError;
    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
  };

  const handleSortPress = (nameColumn: keyof AccessResponse) => {
    setRequestedAcces(
      sortByHeader<AccessResponse>(requestedAcces, nameColumn, nameHeader)
    );
    setNameHeader(!nameHeader);
  };

  useEffect(() => {
    getWorkspace();
  }, []);
  

  

  const globalPermition = async (element:string,e?:boolean) => {
    
    const global: WorkspaceInterface = {
      workspaceId: workId,
      accessType: element as AccessLevel || "read only",
      type: e   ?`global`:`temporal`,
      status: !e ?`expired`:`active`,
    };
    try {
      await WorkspaceService.globalAccess(global);          
      getWorkspace();
    } catch (error) {
      handleError(error);
    }
  };

  

  const temporalPermition = async () => {
    const temporal: WorkspaceInterface = {
      workspaceId: workId,
      accessType:
        (globalForm.temporaryAccessLeve.value as AccessLevel) || "read only",
      from: accessStore.from_global || undefined,
      to: accessStore.to_global || undefined,
      duration: accessStore.durationGlobal || 0,
      durationType: accessStore.durationGlobalType,
      type: `temporal`,
    };

    try {
      await WorkspaceService.globalAccess(temporal);

      getWorkspace();
    } catch (error) {
      handleError(error);
    }
  };

  console.log("From Global",accessLevel)

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      {/* Header */}
      <Text type="h2" weight="openBold" style={tw`ml-5`}>
        Support Access
      </Text>
      {/* Top Card */}
      <View style={tw`rounded-3 bg-white px-10 py-5 mt-5 shadow-sm`}>
        <Text
          type="h2"
          weight="openBold"
          style={tw`mb-5 text-center md:text-left`}
        >
          Global Support Access
        </Text>

        
        
        <View style={tw` flex  sm:flex  lg:flex-row items-center justify-between  z-100`}>
          <Text color={colors.rebrandGray}>Access Level</Text>
          <View style={tw`h-[40px] lg:w-[350px] -mt-12`}>
            <FancyDropdown
              autoCorrect={false}
              label=" "
              placeholder="Select access"
              value={
            //  accessLevel?.length
               accessLevel &&  accessLevel?.find((item) => item.type === "global")?.accessType?.length?
                accessLevel?.find((item) => item.type === "global")?.accessType as AccessLevel:
                accessLevel?.find((item) => item.type === "temporal")?.accessType as AccessLevel 
                  
              }
              onSelect={(e)=>globalPermition(e,true)}
              data={[
                { label: "Read Only", value: "read only" },
                { label: "Read and Write", value: "read and write" },
                { label: "No Access", value: "no access" },
              ]}
            />
          </View>
        </View>
        <Text
          type="h2"
          weight="openBold"
          style={tw`my-8 md:my-5 text-center md:text-left`}
        >
          Temporary Global Support Access Override
        </Text>
        <View
          style={tw`flex flex-col xl:flex-row items-center justify-between `}
        >
          <View
            style={{
              zIndex: 100,
              ...tw`flex flex-row my-5 xl:my-0 items-center `,
            }}
          >
            <Text color={colors.rebrandGray}>Access Level</Text>
            <View style={tw`h-[40px] -mt-10`}>
              <FancyDropdown
                autoCorrect={false}
                label=" "
                placeholder="Edit"
                value={
                  (globalAccess?.global_access?.type == "temporal" &&
                    globalAccess.global_access.accessType) ||
                `Read Only`
                }
                onSelect={globalForm.temporaryAccessLeve.handleInputValue}
                data={[
                  { label: "Read Only", value: "read only" },
                  { label: "Read and Write", value: "read and write" },
                  { label: "No Access", value: "no access" },
                ]}
              />
            </View>
          </View>
          <View
            style={tw`flex flex-row`}
          >
            <DurationPermition
              onModalChange={handleModal}
              type={"global"}
              isModalActive={showModal}
            />
            {showModal && (
              <View
                style={{
                  zIndex: 1200,
                  ...tw`absolute -top-[-10] lg:right-20 `,
                }}
              >
                <View style={[tw`top-3 right-1 absolute z-2000 w-10`]}>
                  <View style={tw`flex flex-row items-center gap-5`}>
                    <TouchableOpacity
                      style={tw`mr-2`}
                      onPress={handleRangeDate}
                    >
                      <Check color={colors.blue} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleModal}>
                      <Close size={20} />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={tw`flex w-full items-center justify-center  lg:mt-2 `}>
                  <AppCalendar
                    fromDate={accessStore.from_global}
                    toDate={accessStore.to_global}

                    minDate={ dayjs().format("YYYY-MM-DD") }
                    width={"264px"}
                    height={"281px"}
                    onEvent={(from, to) => {
                      formData.from.handleInputValue(from);
                      formData.to.handleInputValue(to);
                    }}
                  />
                </View>
              </View>
            )}
          </View>
          <View style={[tw`w-[170px] my-5 xl:my-0 xl:w-[110px]`,{zIndex:-1}]}>
            
            <Button
              
              onPress={() => {
                (globalAccess?.global_access?.from === "" &&
                  globalAccess.global_access.to === "") ||
                  (globalAccess?.global_access?.from === undefined &&
                    globalAccess?.global_access?.to === undefined) ||
                  (globalAccess?.global_access?.from === null &&
                    globalAccess?.global_access?.to === null) ||
                  globalAccess.global_access.status === "expired" ||
                  globalAccess.global_access.status === "approved"
                  ? temporalPermition()
                  : globalPermition(
                    accessLevel?.find(item=>item.type==='global')?.accessType as string,
                    false

                  );
              }}
            >
              {(globalAccess?.global_access?.from === "" &&
                globalAccess.global_access.to === "") ||
                (globalAccess?.global_access?.from === undefined &&
                  globalAccess?.global_access?.to === undefined) ||
                (globalAccess?.global_access?.from === null &&
                  globalAccess?.global_access?.to === null) ||
                globalAccess.global_access.status === "expired" ||
                globalAccess.global_access.status === "approved"
                ? `Enable`
                : `Disable`}
            </Button>
          </View>
      
      
        </View>
      
      
      
      </View>







      {/* List */}
      <Text type="h2" weight="openBold" style={tw`ml-5 my-5`}>
        Temporary Support Access Log
      </Text>
      <ScrollView
        style={{ zIndex: -1 }}
        horizontal
        contentContainerStyle={tw`flex flex-col min-w-[856px] w-full`}
      >
        <RequestAccessListHeader
          nameHeader={nameHeader}
          onPressSorted={handleSortPress}
        />
        {isLoading ? (
          <>
            <Loading />
            <View style={tw`mb-10`}></View>
          </>
        ) : requestedAcces.length <= 0 ? (
          <View
            style={tw`flex-row bg-white px-4 py-4 mb-1 rounded-3 items-center mt-2 `}
          >
            <Text
              style={tw`w-full text-center`}
              color={colors.red}
              weight="semiBold"
            >
              There aren't permissions requested
            </Text>
          </View>
        ) : (
          <>
            {requestedAcces.map((user: AccessResponse) => (
              <RequestAccessItem
                user={user}
                revokeAccess={revokeAccess}
                grantPermition={grantPermition}
                grantOrDenyAccess={grantOrDenyAccess}
                rejectAccess={rejectAccess}
                approvePermition={approvePermition}
                key={user.id}
              />
            ))}
          </>
        )}
      </ScrollView>
      {/* Change Access Modal */}
      <AppModal
        visible={showChangeModal}
        onTouchOutside={() => setShowChangeModal(false)}
        width={550}
      >
        <View>
          <View style={tw`flex-row items-center justify-center mb-1`}>
            <Text type="h2" weight="bold">
              Change Access
            </Text>
            <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
          </View>

          <View style={tw`flex w-full items-center justify-center mt-2  `}>
            <Text>
              Changing the start date to a future date will revoke support
            </Text>
            <Text>
              access and create a new request starting on{" "}
              {accessStore.from
                ? dayjs(accessStore.from).format("DD/MM/YYYY")
                : dayjs().format("DD/MM/YYYY")}
            </Text>
            <Text>Are you sure you want to proceed?</Text>
          </View>

          <ModalButtons
            onButtonPress={() => setShowChangeModal(false)}
            onCallBack={grantOrDenyAccess}
            status="granted"
            text={"Approve"}
          />
        </View>
      </AppModal>
    </View>
  );
};
