import { useState } from "react";

import { AxiosError, AxiosResponse } from "axios";

import { useAlert } from "@/hooks/useAlert";
import { showFriendlyErrorFromHTMLCode } from "@/lib";

export const useApi = (apiFunc: Function) => {
  const { showSwal } = useAlert();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const request = async <T>(...args: any): Promise<AxiosResponse<T>> => {
    let response;
    try {
      setLoading(true);
      response = await apiFunc(...args);
      setData(response.data);
    } catch (err) {
      const error = err as AxiosError;
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    } finally {
      setLoading(false);
    }
    return response;
  };

  return { data, loading, request };
};
