import React from "react";
import Svg, { Path } from "react-native-svg";

export const Trash2 = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="30" height="25" viewBox="0 0 24 24" fill="none" >
      <Path
        d="M16.3249 7.46826C16.3249 7.46826 15.7819 14.2033 15.4669 17.0403C15.3169 18.3953 14.4799 19.1893 13.1089 19.2143C10.4999 19.2613 7.88791 19.2643 5.27991 19.2093C3.96091 19.1823 3.13791 18.3783 2.99091 17.0473C2.67391 14.1853 2.13391 7.46826 2.13391 7.46826"
         stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <Path
        d="M17.7082 4.23975H0.750183"        
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}      
        
      />
      <Path
        d="M14.4406 4.23973C13.6556 4.23973 12.9796 3.68473 12.8256 2.91573L12.5826 1.69973C12.4326 1.13873 11.9246 0.750732 11.3456 0.750732H7.11258C6.53358 0.750732 6.02558 1.13873 5.87558 1.69973L5.63258 2.91573C5.47858 3.68473 4.80258 4.23973 4.01758 4.23973"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </Svg>
  );
};
