import ReactPixel from "react-facebook-pixel";
import { ConfigService } from "@/services";

export const BUTTON_ADDRESS_INPUT = "button_address_input"; //click on address in the precisely box
export const BUTTON_BACK = "button_back"; //click any back button in the admin / registration site
export const BUTTON_COMPANY_DETAIL = "button_company_detail";
export const BUTTON_PHONE_CREATE_WORKSPACE = "button_phone_create_workspace";
export const BUTTON_PHONE_MODAL_OK = "button_phone_modal_ok"; //click modal popup ok on phone number screen
export const BUTTON_REGISTRATION_CLICK = "button_registration_click"; //When the user clicks to send the email but before the email check
export const BUTTON_SMS_CHECK_CODE = "button_sms_check_code";
export const BUTTON_SMS_SEND_CODE = "button_sms_send_code";
export const BUTTON_SUCCESS_ONBOARD = "button_sucess_onboard";
export const ERROR_AUTH_CHECK = "error_auth_check"; // failure to

export const DEFAULT_EVENT_PURCHASE = "Purchase"; // failure to
export const DEFAULT_EVENT_COMPLETE_REGISTRATION = "CompleteRegistration"; // failure to

class FacebookPixelService {
  private isInit: boolean = false;
  private async init() {
    if (!this.isInit) {
      const config = await ConfigService.getAll();
      const pixelId = config.facebookPixelId;
      ReactPixel.init(pixelId);
      this.isInit = true;
    }
    return ReactPixel;
  }

  async logPage(page_path: string) {
    this.init();
    ReactPixel.pageView();
    ReactPixel.trackCustom("screen_view", {
      page_path: page_path,
    });
  }

  async logCustomEvent(
    eventName: string,
    eventProps?: { [key: string]: any } | undefined
  ) {
    if (typeof window !== "undefined") {
      this.init();
      ReactPixel.pageView();
      ReactPixel.trackCustom(eventName, eventProps);
    }
  }

  async logDefaultEvent(title: string, data: any) {
    if (typeof window !== "undefined") {
      this.init();
      ReactPixel.track(title, data);
    }
  }
}
const singleton = new FacebookPixelService();
export default singleton;
