import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";

import { useDeviceContext } from "twrnc";

import { AppCalendar } from "@/components/Calendars";
import { Text } from "@/components/Commons";
import DurationPermition from "@/components/DurationPermition/DurationPermition";
import { Check, Close } from "@/components/Icons";
import Permitions from "@/components/Permitions/Permitions";
import RadioButtones from "@/components/RadioButton/RadioButton";
import { colors, Modal, tw, useFieldControl } from "@/lib";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { RootState, store } from "@/store";
import { capitalizeAll } from "../../../lib/utils";
import dayjs from "dayjs";
import setTextArea, {
  setAccess,
  setDateRange,
  setMessage,
} from "@/store/accessRequest.store";
import { TextInput } from "@/components/Inputs";
import { AccessResponse } from "@/types";
interface ModalProps {
  text?: string;
  visible?: boolean;
  textarea?: boolean;
  data: DataType[];
  elements?: AccessResponse;
  types?: string;
}

export type DataType = { label: string; value: string };

export default function ModalBody({
  text,
  visible,
  textarea,
  data,
  types,
}: ModalProps) {
  useDeviceContext(tw);
  const { from, to, accessType, from_global, to_global, status, endless } =
    useAppSelector((state: RootState) => state.accessRequest);
  const isUserActive =
    status === "active" || status === "approved" || status === "granted";

  const dispatch = useAppDispatch();

  const [currentAccess, setCurrentAccess] = useState<string>("read only");

  const [showModal, setShowModal] = useState<boolean>(false);

  const formData = {
    from: useFieldControl<string>(from?.toString() || ""),
    to: useFieldControl<string>(to?.toString() || ""),
    form_global: useFieldControl<string>(from_global?.toString() || ""),
    to_global: useFieldControl<string>(to_global?.toString() || ""),
    message: useFieldControl<string>(""),
  };

  useEffect(() => {
    dispatch(
      setMessage({
        message: formData.message.value,
      })
    );
  }, [formData.message.value]);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleRangeDate = () => {
    dispatch(
      setDateRange({ from: formData.from.value, to: formData.to.value })
    );
    setShowModal(false);
  };

  const remainingTime = () => {
    //3d 4h 25m
    if (from === null || to === null) return;

    const date1 = dayjs(to);
    const date2 = dayjs(from);

    const day = date1.diff(date2, "day");
    const hour = date1.diff(date2, "hour");
    const minute = date1.diff(date2, "minute");

    return `${day}d ${hour}h ${minute}m`;
  };

  useEffect(() => {
    setCurrentAccess(capitalizeAll(accessType || "read only"));
  }, []);

  return (
    <>
      {showModal && (
        <View style={tw`absolute -top-5 right-17 z-1200`}>
          <View style={[tw`top-3 right-1 absolute z-2000 w-10`]}>
            <View style={tw`flex flex-row items-center gap-5`}>
              <TouchableOpacity style={tw`mr-2`} onPress={handleRangeDate}>
                <Check color={colors.blue} />
              </TouchableOpacity>
              <TouchableOpacity onPress={handleModal}>
                <Close size={20} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={tw`flex items-center justify-center mt-2`}>
            <AppCalendar
              fromDate={from}
              toDate={to}
              width={"264px"}
              height={"281px"}
              minDate={isUserActive ? dayjs().format("YYYY-MM-DD") : undefined}
              onEvent={(from, to) => {
                formData.from.handleInputValue(from);
                formData.to.handleInputValue(to);
              }}
            />
          </View>
        </View>
      )}

      <View style={tw`flex flex-row w-full   items-center justify-center mb-1`}>
        <Text type="h2" weight="bold">
          {text || `Change Access`}
        </Text>

        <TouchableOpacity
          onPressOut={Modal.dismiss}
          style={tw`absolute  bottom-[0.5] right-[0.5] items-end justify-end `}
        >
          <Close size={20} />
        </TouchableOpacity>
      </View>

      <View
        style={tw`flex sm:flex w-full lg:items-center lg:justify-center lg:max-w-[350]`}
      >
        <View style={tw`flex lg:flex-row lg:mb-2`}>
          <View
            style={tw`flex sm:flex  flex-row mt-2 items-center sm:justify-center`}
          >
            <Text color={colors.gray} style={tw`mr-5`}>
              {visible ? "Current Access Level" : " "}
            </Text>
          </View>

          {visible && (
            <Text type="h6">
              <Text weight="bold">{currentAccess}</Text> <Text>access</Text>{" "}
              <Text>from</Text>{" "}
              <Text weight="bold">
                {from ? dayjs(from).format("M/D/YYYY hh:mm:s") : "-"}
              </Text>{" "}
              <Text>through</Text>{" "}
              <Text weight="bold">
                {to ? dayjs(to).format("M/D/YYYY hh:mm:s") : "-"}
              </Text>{" "}
              <Text>with</Text>{" "}
              <Text weight="bold">
                {from !== null && to !== null ? remainingTime() : "-"}
              </Text>{" "}
              <Text>remaining</Text>
            </Text>
          )}
        </View>
      </View>

      <View style={[tw`flex sm:flex  lg:max-w-[450px]  `, { zIndex: 523 }]}>
        <Permitions data={data} />
      </View>

      <View style={[tw`flex justify-around lg:pl-6`, { zIndex: 458 }]}>
        <DurationPermition onModalChange={handleModal} type={types} />
      </View>
      <View
        style={[
          tw`flex float-right w-full  items-center justify-evenly  lg:pr-[170]   `,
          { zIndex: 459 },
        ]}
      >
        <RadioButtones state={endless} />
      </View>

      {textarea && (
        <View>
          <TextInput
            style={tw`h-[96px] w-full lg:max-w-[410px]`}
            multiline={true}
            numberOfLines={4}
            label="Message"
            value={formData.message.value}
            errorMessage={formData.message.error}
            autoCapitalize="words"
            placeholder=""
            onChangeText={formData.message.handleInputValue}
          />
        </View>
      )}
    </>
  );
}
