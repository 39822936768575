import React from "react";
import { useDeviceContext } from "twrnc";
import { useLocation } from "react-router-dom";

import { isRegisterPage, useNavigate } from "@/routes";
import {
  View,
  Image,
  TouchableOpacity,
  Linking,
  ViewProps,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";

import { LeftArrow } from "../Icons/LeftArrow";
import { Logo } from "../Icons/Logo/Logo";

import { Hoverable } from "react-native-web-hover";
import { Nucleus } from "../Icons";

export const ResponsiveLayout = (props: ViewProps & { noBack?: boolean,uri?:string }) => {
  const { noBack = false,uri } = props;
  useDeviceContext(tw);

  const location = useLocation();
  const navigate=useNavigate()
 

  return (
    
     
        <View style={[tw`flex w-full  pr-[300px] flex-col  `]}>
          {!noBack  && location.pathname != "/auth" && (
            <View style={tw`flex w-full flex-row items-center justify-center`}>
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    style={tw`flex w-full w-3/4 ml-4 lg:ml-16  pt-8 rounded-3 
                  ${hovered ? "bg-gray-100 " : ""} `}
                    onPress={() =>uri? navigate(uri):history.back()}
                  >
                    <View
                      style={tw`flex flex-row w-full bottom-2  items-center justify-center `}
                    >
                      <LeftArrow
                        color={colors.blue}
                        width={"30"}
                        height={"25"}
                      />
                      <View style={tw`flex bottom-1`}>
                        <Text
                          type="h3"
                          weight="semiBold"
                          style={tw`text-[${colors.blue}] bottom-6 `}
                        >
                          Back
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
          )}
        </View>

        
        
      

      
      
    
  );
};
