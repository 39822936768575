import React, { useState } from "react";
import { Pressable, View, ScrollView } from "react-native";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { StatusHistory } from "@/components/Info";
import { SystemGraphics } from "@/screens/client/Info";

//Options to show on the top of graphic
const options: string[] = ["Day", "Week", "Month"];

export const SystemStatus = () => {
  const first: boolean[] = new Array<boolean>(30).fill(true);
  const second: boolean[] = new Array<boolean>(5).fill(false);
  const third: boolean[] = new Array<boolean>(25).fill(true);
  const apiHistoryOne: boolean[] = [...first, ...second, ...third];
  const apiHistoryTwo: boolean[] = [...second, ...first, ...third];
  const apiHistoryThree: boolean[] = [...first, ...third, ...second];

  //Set active element
  const [active, setActive] = useState<number>(0);

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <Text type="h2">System status</Text>
      <ScrollView
        horizontal
        contentContainerStyle={tw`flex-col md:px-16 py-8 min-w-200`}
      >
        <View style={tw`md:flex-row md:justify-end md:mb-1`}>
          <Text color={colors.gray}>Uptime over the past 90 days.</Text>
          <Text color={colors.blueLightLightest} style={tw`ml-1`}>
            View historical uptime.
          </Text>
        </View>
        <StatusHistory
          style={tw`border-t rounded-t`}
          serviceName="API"
          history={apiHistoryOne}
          tooltipInfo="API status"
        />
        <StatusHistory
          serviceName="Media Proxy"
          history={apiHistoryTwo}
          tooltipInfo="Media Proxy status"
        />
        <StatusHistory
          serviceName="Push Notifications"
          history={apiHistoryThree}
          tooltipInfo="Push Notifications status"
        />
        <StatusHistory
          style={tw`rounded-b`}
          serviceName="Search"
          history={apiHistoryTwo}
        />
        <StatusHistory
          style={tw`rounded-b`}
          serviceName="Voice"
          history={apiHistoryOne}
        />
        <StatusHistory
          style={tw`rounded-b`}
          serviceName="Third Party"
          history={apiHistoryThree}
        />

        {/* Graphic Area */}
        {/* Top Section */}
        <View style={tw`flex flex-row justify-between mt-10`}>
          <Text weight="semiBold">SYSTEM METRICS</Text>
          <View style={tw`flex flex-row`}>
            {options.map((option, index) => (
              <Pressable
                key={index}
                onPress={() => setActive(index)}
                style={tw.style([
                  "py-1 px-4 mx-1 rounded-4",
                  active == index ? `bg-[${colors.blueLightLightest}]` : "",
                ])}
              >
                <Text
                  style={tw.style([
                    "px-2",
                    active == index ? "text-white" : `text-[${colors.gray}]`,
                  ])}
                >
                  {option}
                </Text>
              </Pressable>
            ))}
          </View>
        </View>
        {/* Graphics */}
        <SystemGraphics title="Api Response Time" />
        <SystemGraphics title="Media Proxy" />
        <SystemGraphics title="Push Notifications" />
      </ScrollView>
    </View>
  );
};
