import { max, required, mail, min, number } from "@/lib/Rules";

interface CsvParseResult {
  firstName: string;
  lastName: string;
  mail: string;
  extension: number;
}

/**
 * Parse csv to generate colleagues info for company
 * invalid CSV format will provoke error "CSV column header names missing or format incorrect."
 * @param data {string} csv format string
 * @returns {CsvParseResult}
 */
export const csvParse = (data: string) => {
  const _data = data.split("\n");
  const headers = _data[0];
  const rows = _data.slice(1);
  const _headers = headers.split(",").map((e) => e.trim());
  const _rows = rows.map((e) => e.split(",").map((e) => e.trim()));
  const headerValid =
    _headers[0] == "first_name" &&
    _headers[1] == "last_name" &&
    _headers[2] == "email" &&
    _headers[3] == "extension" &&
    _headers.length == 4;

  if (!headerValid) {
    throw "CSV column header names missing or format incorrect.";
  }

  const result = _rows.map((e) => {
    const [firstName, lastName, email, extension] = e;

    const validation = {
      firstName: (input: string) =>
        required.rule(input) && max(128).rule(input),
      lastName: (input: string) => required.rule(input) && max(128).rule(input),
      email: (input: string) => required.rule(input) && mail.rule(input),
      extension: (input: string) =>
        required.rule(input) &&
        number.rule(input) &&
        min(2).rule(input) &&
        max(6).rule(input),
    };

    const valid =
      validation.firstName(firstName) &&
      validation.lastName(lastName) &&
      validation.email(email) &&
      validation.extension(extension);

    if (!valid) {
      throw "CSV column header names missing or format incorrect.";
    }

    return {
      firstName,
      lastName,
      mail: email,
      extension: Number(extension),
    };
  });

  return result;
};
