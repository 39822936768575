import React from "react";
import { Image, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Card, Icon } from "react-native-elements";
import { useDeviceContext } from "twrnc";
import { tw } from "@/lib";
import { Button, Text } from "@/components/Commons";
import { LinearGradient } from "expo-linear-gradient";


interface Properties{
  data:any,
  visible?:boolean
}


export const CardSubscriptions = ({data,visible}:Properties) => {
  useDeviceContext(tw);
  
  
  //const { data,visible=false } = props
  
  return (
    <View style={tw`hidden flex  lg:flex-row   my-auto xl:w-full justify-between lg:flex-wrap  `}>
      {visible && data.map((code: any) => (
        <LinearGradient
          start={{ x: -1, y: 0 }}
          end={{ x: 1, y: 0 }}
          key={code._id}
          colors={code.gradient}
          style={tw`hidden rounded-3 sm:flex  w-full lg:max-w-1/5 bg-red-500 py-3 px-5 mr-5 relative mt-5 `}
        >
          <View>
            <Text weight="bold" type="bigger">Amet lectus 10%</Text>
            <Text weight="bold" type="bigger">Aliquet nibh</Text>
            <View style={tw`flex-row justify-between items-center mt-3`}>
              <TouchableOpacity style={tw`rounded bg-white py-2`}>
                <View style={tw`w-[83px] flex items-center justify-center`}>
                  <Text weight="semiBold">ALIQUET</Text>
                </View>
              </TouchableOpacity>
              <Image
                style={tw`w-[49px] h-[49px]`}
                source={require("@/assets/images/LOGO/face.png")} />
            </View>
          </View>
        </LinearGradient>

      ))}


    </View>
  );
};

//export default CardSubscriptions
const styles = StyleSheet.create({
  color1: {
    backgroundColor: '#7BC915',
  },
  color2: {
    backgroundColor: '#6980FF',
  },
  color3: {
    backgroundColor: '#CAF2EB'
  },
  color4: {
    backgroundColor: '#4DE1E3'
  }

});
