import { Image, View } from "react-native";
import React from "react";
import { AppStore, IAppStore } from "./AppStore";

import { useDeviceContext } from "twrnc";

import { tw } from "@/lib";

const AppleLogo = (): JSX.Element => (
  <Image
    source={require("@/assets/images/LOGO/apple-logo.png")}
    style={tw`w-[2.43rem] h-[3rem] rounded-sm`}
  />
);

const AppleQR = (): JSX.Element => (
  <Image
    source={require("@/assets/images/QR/apple-qr.png")}
    style={tw`w-[16rem] h-[16rem] rounded-sm`}
  />
);

const GoogleLogo = (): JSX.Element => (
  <Image
    source={require("@/assets/images/LOGO/google-logo.png")}
    style={tw`w-[2.43rem] h-[3rem] rounded-sm`}
  />
);

const GoogleQR = (): JSX.Element => (
  <Image
    source={require("@/assets/images/QR/google-qr.png")}
    style={tw`w-[16rem] h-[16rem] rounded-sm`}
  />
);

const appStores: IAppStore[] = [
  {
    image: <AppleLogo />,
    name: "App Store",
    appUrl: "https://apps.apple.com/ca/app/nucleus-works/id1631371394",
    qrCode: <AppleQR />,
  },
  {
    image: <GoogleLogo />,
    name: "Google Play",
    appUrl:
      "https://play.google.com/store/apps/details?id=com.newtcloud.nucleus",
    qrCode: <GoogleQR />,
  },
];

export const Stores = () => {
  useDeviceContext(tw);

  return (
    <View style={tw`flex justify-center md:flex-row flex-wrap`}>
      {appStores.map((store) => (
        <AppStore store={store} key={store.name} />
      ))}
    </View>
  );
};
