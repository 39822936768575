import { colors } from "@/lib";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Circle = (props: {
  color?: string;
  size?: number;
}) => {
  const { color = colors.green, size = 8, } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={color} />
    </svg>
  );
};
