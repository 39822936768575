import { InsightResponse } from "@/types";
import { ApiService } from "./";
import { AxiosResponse } from "axios";
import { AxiosHeaders } from "@/services";

class InsightService {
    async get(): Promise<AxiosResponse<{ result: InsightResponse }>> {
        return ApiService.api.get('insight', {
            headers: AxiosHeaders.headersWithCredentials(),
        })
    }
}

const singleton = new InsightService();
export default singleton;
