import { StyleSheet, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { ProductAddOn } from './ProductAddOn'
import { Modal, tw } from '@/lib';
import { Button, Text } from '@/components/Commons';
import { ProductAddOnForm } from './ProductAddOnForm';
import { ProductAddPlanForm } from './ProductAddPlanForm';

const ProductAddOnList = () => {
  const showModalForEditAddOn = (id?: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-4`}>
          <Text type="h2" weight="bold">
            {id ? "Edit Add-on" : "Add Add-on"}
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <ProductAddOnForm />

        <View style={tw`flex flex-row justify-center mt-16`}>
          <View style={tw`flex flex-row w-full`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw`flex-1`} onPressOut={Modal.dismiss}>
              <Text weight="semiBold">Save</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 384 }
    );
  };
  const showModalForAddEdit = (id?: string) => {
    Modal.show(
      <View style={tw`flex w-full max-h-[160]`}>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            {id ? "Edit Plan" : "Add Plan"}
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <ProductAddPlanForm />

        {id && (
          <TouchableOpacity
            onPress={() => {
              Modal.dismiss();
              showModalForRemove(id);
            }}
          >
            <Text weight="semiBold" style={tw`text-red-500 mt-3 ml-2`}>
              Delete Plan
            </Text>
          </TouchableOpacity>
        )}

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row`}>
            <Button style={tw`w-45`} type="outlined" onPressOut={Modal.dismiss}>
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw`w-45`} onPressOut={Modal.dismiss}>
              <Text weight="semiBold">Save</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width:  windowSize.innerWidth < 752 ?windowSize.innerWidth : 752 }
    );
  };
  const showModalForRemove = (id?: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove Subscription?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to remove subscription plan? </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button style={tw`flex-1`}>
              <Text weight="semiBold">Remove</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 560 }
    );
  };
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }


    window.addEventListener('resize', handleWindowResize);
   
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    
  }, [getWindowSize])
  return (
    <View>
      
      <View style={tw`flex-row justify-between lg:my-4 `}>
        <View style={tw`flex ml-4`}>
          <Text type="h2">Add-ons</Text>
        </View>
        <View style={tw`max-w-[172px] w-full`}>
          <Button type="outlined" onPress={() => showModalForAddEdit()} >
            Add Plan
          </Button>
        </View>
      </View>
      <ProductAddOn 
      showModalForEditAddOn={showModalForEditAddOn}
       showModalForRemoveAddOn={showModalForRemove} />
    </View>
    
  )
}

export default ProductAddOnList

const styles = StyleSheet.create({})