import { FlatList, StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import dayjs from 'dayjs';
import { colors, FieldControlType, required, tw, useFieldControl } from '@/lib';
import { Avatar, Icon } from 'react-native-elements';
import { Image } from '../Icons';
import { Text } from '../Commons';
import { Documents } from '../Icons/Documents';
import { TextInput } from '../Inputs';
import { AttachmentInput } from '../Inputs/AttachmentInput';
interface stateArrays {
  id: string;
  name?: string;
  time?: string;
  description?: string;
  file?: string;
  active?: boolean
}


interface FileState {
  status: boolean;
  qty: number;
}


const Tickets = () => {
  
  const inputControl = {
    text: useFieldControl<string>("", [required]),
    attachment: useFieldControl<File[] | null>(null, [required])
  }

  const [state, setState] = useState({
    typing: false,
    typingTimeout: 0,

  });
  const [stateArray, setStateArray] = useState<stateArrays[]>([])
  const initialFileState: FileState = { status: false, qty: 0 };

  //Handle the files
  const [isLoadedFile, setIsLoadedFile] = useState<FileState>(initialFileState);


  const changeState = (e: string) => {
    if (state.typingTimeout) {
      clearTimeout(state.typingTimeout);
    }
    setState({
      typing: true,
      //@ts-ignore
      typingTimeout: setTimeout(() => {
        const a = { ...state, typing: false }
        setState(a)
      }, 1000),
    })
    return inputControl.text.handleInputValue(e)
  }

  const handlekeypress = () => {
    if (inputControl.text.valid) {
      //@ts-ignore
      setStateArray(element => {
        return [
          ...element,
          {
            id: (stateArray.length + 1).toString(),
            name: "Manuel",
            time: dayjs(new Date()).format('hh:mm A'),
            description: inputControl.text.value,
            active: true,
            file: inputControl.attachment.value?.map((e: any) => e.name)
          }
        ]
      })
      inputControl.text.resetField()
      inputControl.attachment.resetField()
      setIsLoadedFile({ status: false, qty: 0 })

    }




  }

  //@ts-ignore

  const renderItemChat = ({ item }) => {
    return (
      <View style={tw`flex  flex-col w-full my-8   `}>

        <View style={tw`flex flex-row items-center justify-start  `}>
          <View style={tw`w-full max-w-[60px] `}>
            <Avatar
              size={40}
              rounded
              renderPlaceholderContent={
                <View
                  style={tw`bg-gray-400 w-full h-full items-center justify-center`}
                >
                  <Image size={20} />

                </View>
              }
            />
            <View style={tw`flex flex-row relative`}>
              <View style={tw`flex absolute bottom-0 pl-7`}>
                {item.active && <Text style={tw`text-green-400 font-bold text-5xl`}>.</Text>}
              </View>
            </View>
          </View>
          <View style={tw`flex flex-row items-center justify-around `}>
            <Text type='bigger' weight='bold' >{item.name} {" "}</Text>
            <Text style={tw`text-gray-500`}>{item.time}</Text>
          </View>
        </View>
        <View style={tw`flex items-start pl-16 justify-around`}>
          <Text>{item.description}</Text>
          {item.file &&
            <View >

              {item.file.map((data: string, index: number) => {
                return (
                  <View style={tw`flex flex-row items-center justify-around  `} key={index}>
                    <Documents color='#0E2989' />
                    <Text style={tw`text-blue-800`}>{data + " "}</Text>
                  </View>
                )
              })}


            </View>
          }
        </View>
      </View>
    )

  }
  return (

    <><View
      style={[
        tw`flex flex-grow-1 flex-col ${stateArray.length ? `justify-between` : `justify-around`}  w-full  `,
      ]}
    >
      {!stateArray.length ?
        <View style={tw`flex flex-col items-center `}>
          <View>
            <Text type="h2"> There is nothing here yet </Text>
            <Text style={tw`my-4 text-gray-500`}>
              Start the dialogue first by writing a message
            </Text>
          </View>
        </View>
        : <View style={tw`flex max-h-[760px] rounded-3`}>
          <View style={tw`flex w-full items-center justify-center`}>
            <Text color={colors.gray}>{dayjs(new Date()).format('dddd, MMMM D')}</Text>
          </View>
          <FlatList
            data={stateArray}
            renderItem={renderItemChat}
            keyExtractor={(item) => item.id} />
        </View>}
    </View><View
      style={tw`flex flex-col items-center w-full lg:max-w-[800px] mx-auto top-7  `}
    >
        <View style={tw`w-full flex flex-row mb-2 px-5`}>
          {/* Show typing indicator */}
          <View style={tw`flex flex-1 flex-row justify-start items-end`}>

            {state.typing && (
              <Text color={colors.blue}>Manuel is typing...</Text>
            )}
          </View>
          {/* Show icon if file loads */}
          {isLoadedFile.qty > 0 && (
            <View style={tw`flex flex-1 flex-row justify-end`}>
              <View
                style={{
                  backgroundColor: colors.white,
                  ...tw`border p-2 rounded-3 border-gray-400 flex flex-row`,
                }}
              >
                <Documents color="#0E2989" size={40} />
                <Text weight="semiBold" color={colors.blue}>{isLoadedFile.qty}</Text>
              </View>
            </View>
          )}
        </View>
        <TextInput
          placeholder="Start typing"
          style={tw`rounded-3 `}
          value={inputControl.text.value}
          onChangeText={(e: string) => changeState(e)}
          onKeyPress={(e) => {
            //@ts-ignore
            if (e.keyCode === 13) {

              return handlekeypress();
            }
          }}

          rightIcon={<View style={tw`flex flex-row w-full`}>
            <View style={tw`flex flex-row items-center justify-around pr-2`}>
              <AttachmentInput
                style={{ transform: [{ rotateY: "180deg" }] }}
                color={colors.blue}
                sizes={25}
                onSelect={(...e: File[]) => {
                  setIsLoadedFile({ status: true, qty: e.length });
                  inputControl.attachment.handleInputValue(e.map((s) => s));

                }} />
            </View>
            <Icon
              // @ts-ignore
              onPressIn={() => {

                return handlekeypress();
              }}
              name={"paper-plane-o"}
              size={20}
              type="font-awesome"
              color={colors.blue}
              onPress={() => { }}
              tvParallaxProperties={undefined} />
          </View>} />
      </View></>
  )
}

export default Tickets

const styles = StyleSheet.create({})