import React from "react";

import { Platform, TouchableOpacity, View } from "react-native";
// @ts-ignore
import { ModalPortal } from "react-native-modals";

import { tw } from "@/lib";
import { Close } from "@/components/Icons";

class Modal {
  show(body: JSX.Element, props: any = { width: 380 }) {
    const { width = 380, styles } = props;

    return ModalPortal.show(
      <View style={tw`p-8 ${styles}`}>
        {!props.noClose && (
          <TouchableOpacity
            style={[
              tw`top-9   right-8 absolute`,
              Platform.select({
                web: {
                  zIndex: "1000",
                },
              }),
            ]}
            onPress={this.dismiss}
          >
            <Close />
          </TouchableOpacity>
        )}
        {body}
      </View>,
      {
        onTouchOutside: props.onTouchOutside ? undefined : this.dismiss,
        width: Math.min(width, screen.width),

        modalStyle: tw`rounded-3`,
        ...props,
      }
    );
  }

  update(key: any, props: any) {
    ModalPortal.update(key, props);
  }

  dismiss() {
    ModalPortal.dismiss();
  }

  dismissAll() {
    ModalPortal.dismissAll();
  }
}

const singleton = new Modal();

export default singleton;
