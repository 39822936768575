import { StyleSheet, View } from "react-native";
import React from "react";
import { colors, mail, required, tw, useFieldControl } from "@/lib";
import { Button, SuccessAndEmptyList, Text } from "@/components/Commons";
import { Card } from "react-native-elements";
import { TextInput } from "@/components/Inputs";
import { CircleCheck } from "@/components/Icons/CircleCheck";
import { Success } from "@/components/Icons";

const ChangeEmail = () => {
  const emailInput = {
    currentEmail: useFieldControl<string>("", [required, mail]),
    newEmail: useFieldControl<string>("", [required, mail]),
    correct: useFieldControl(false),
  };
  const valid = emailInput.currentEmail.valid && emailInput.newEmail.valid;
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text type="h2">Change email</Text>
        </View>
      </View>

      {!emailInput.correct.value ? (
        <View style={tw`flex lg:flex-row mt-3`}>
          <Card
            containerStyle={tw`flex-initial w-full flex-col pt-6 min-h-[276px] rounded-3 max-w-[400px]`}
          >
            <View style={[tw`w-full`]}>
              <TextInput
                label="Current email"
                value={emailInput.currentEmail.value}
                placeholder={"mail@mail.com"}
                errorMessage={emailInput.currentEmail.error}
                onChangeText={emailInput.currentEmail.handleInputValue}
              />
              <TextInput
                label="New email"
                placeholder="mail@mail.com"
                value={emailInput.newEmail.value}
                errorMessage={emailInput.newEmail.error}
                onChangeText={emailInput.newEmail.handleInputValue}
              />

              <View style={tw`flex flex-cols pl-3 py-8 `}>
                <Text style={tw`text-black `}>
                  You will receive an email with a link to the new email address
                  to confirm this change, after that new email will be saved and
                  applied.
                </Text>
              </View>

              <View style={tw`flex w-full `}>
                <Button
                  disabled={!valid}
                  onPress={() => emailInput.correct.handleInputValue(true)}
                >
                  Change Email
                </Button>
              </View>
            </View>
          </Card>
        </View>
      ) : (
        <SuccessAndEmptyList>
          <Success />
          <View style={tw`mt-4`}>
            <Text type="h3" color={colors.greenicon}>
              Success
            </Text>
          </View>
          <View>
            <Text color={colors.grayLightLightest}>
              {" "}
              Email was successfully changed{" "}
            </Text>
          </View>
        </SuccessAndEmptyList>
      )}
    </View>
  );
};

export default ChangeEmail;

const styles = StyleSheet.create({});
