import { StyleSheet, View } from "react-native";
import React, { Fragment, useState } from "react";

import { tw } from "@/lib";
import { Text } from "../Commons";

import NestedListView, { NestedRow } from "react-native-nested-listview";
import { Icon, Tooltip } from "react-native-elements";
import { useDeviceContext } from "twrnc";
import { WorkSpace } from "types/client.types";

export interface DropDownItem {
  name: string;
  quantiy: string;
  children?: DropDownItem[];
}

//@ts-ignore
const TreeDevices = (props: TreeSelectProps & { data: DropDownItem[] }) => {
  useDeviceContext(tw);
  const { data, element } = props;

  const [state, setState] = useState({ value: false });
  //@ts-ignore
  return (
    <View>
      <View>
        <NestedListView
          data={data}
          getChildrenName={(node) => "children"}
          onNodePressed={(node) => {
            setState({ value: node.opened });
          }}
          renderNode={(node, level, islastnode) => (
            <>
              <NestedRow
                level={level}
                style={tw` ${
                  element != "QOS" ? `border-l-2 border-gray-200` : `border-l-0`
                }`}
              >
                <View
                  style={tw`flex flex-row w-full  py-2  ${
                    islastnode && level != 1
                      ? `border-l-2 border-gray-200 pl-4`
                      : `border-l-0`
                  }  `}
                >
                  <View
                    style={tw`flex flex-row w-full  lg:max-w-1/2  items-start justify-start  `}
                  >
                    <Text
                      type="regular"
                      weight="regular"
                      style={[tw`flex`, { color: "#717185" }]}
                    >
                      {node.name}
                    </Text>
                  </View>

                  <View
                    style={tw`flex flex-row w-full  justify-around ${
                      element === "QOS"
                        ? `items-center max-w-1/2`
                        : `max-w-[315px] ${
                            islastnode ? `pr-10` : level % 2 === 0 && "pr-2"
                          }`
                    } `}
                  >
                    <Text
                      weight="semiBold"
                      type="regular"
                      style={[tw`flex text-lg`, { color: "#1F1D1D" }]}
                    >
                      {node.quantity}
                    </Text>
                  </View>
                </View>
              </NestedRow>
            </>
          )}
        />
      </View>
    </View>
  );
};

export default TreeDevices;

const styles = StyleSheet.create({});
