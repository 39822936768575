import isEmail from "validator/lib/isEmail";

import { regexZip, creditCardRegex } from "@/lib";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { FieldRule } from "./Forms";
import {
  accountNamere,
  accountNameRegx,
  expireDates,
  fieldNumber,
  passwordsExpresion,
  regexAmericanPhone,
  regexNumbers,
} from "./regularExpression";

const parseDate = (input: string) => {
  //Today's date
  let dataDate = new Date();

  //Split input by "/"
  let inputParts: string[] = input.split("/");

  //Input
  let inputMonth = Number(inputParts[0]);
  let inputYear = Number(inputParts[1]);

  //Today date
  let dateMonth = dataDate.getMonth() + 2;

  //Get fullYear
  let fullYear = dataDate.getFullYear().toString();

  let dateYear = Number(fullYear.substring(fullYear.length - 2));

  if (inputYear < dateYear) return false;

  if (dateMonth >= 13) dateMonth = dateMonth - 12;

  if (inputYear === dateYear && inputMonth < dateMonth) return false;

  return true;
};

const parseExtension = (input: number) => {
  const isInRange = input >= 100 && input <= 9000;

  if (!isInRange) return false;

  return true;
};

const parsePassword = (input: string) => {
  const value = passwordsExpresion.test(input);
  return value;
};
export const mail: FieldRule<string> = {
  errorMessage: "Please enter a valid email address.",
  rule: (input) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g.test(input),
};

export const accountNames: FieldRule<string> = {
  errorMessage: "Invalid account name format",
  rule: (input) => accountNamere.test(input),
};

export const accountName: FieldRule<string> = {
  errorMessage: "Invalid account name format",
  rule: (input) => accountNameRegx.test(input),
};

export const extensions: FieldRule<number> = {
  errorMessage: "Please enter an extension number  between 100-9000",
  rule: (input) => parseExtension(input),
};
export const expireDate: FieldRule<string> = {
  errorMessage: "Invalid date, date expired",
  rule: (input) => parseDate(input),
};

export const required: FieldRule<any> = {
  errorMessage: "This field is required",
  rule: (input) => Boolean(input),
};

export const requiredDefault: (defaultValue: string) => FieldRule<string> = (
  defaultValue
) => ({
  errorMessage: `This field is required`,
  rule: (input) => input != defaultValue,
});

export const number: FieldRule<string> = {
  errorMessage: "This field must be number",
  rule: (input) => fieldNumber.test(input),
};

export const password: FieldRule<string> = {
  errorMessage:
    "Your password must be at least 8 characters and include a letter and a number or symbol.",
  rule: (input) => parsePassword(input),
};

export const date: FieldRule<string> = {
  errorMessage: "Expire date should be this format mm/yy",
  rule: (input) => expireDates.test(input),
};

export const min: <T>(minValue: number) => FieldRule<string | Array<T>> = (
  minValue: number
) => ({
  errorMessage: `This field must contain at least ${minValue} characters`,
  rule: (input) => input.length >= minValue,
});

export const max: <T>(maxValue: number) => FieldRule<string | Array<T>> = (
  maxValue: number
) => ({
  errorMessage: `This field must contain less than ${maxValue + 1} characters`,
  rule: (input) => input.length <= maxValue,
});

export const letters: FieldRule<string> = {
  errorMessage: `This field must contain just letters`,
  rule: (input) => /^[a-zA-Z\s]*$/g.test(input),
};

export const cadPostal: FieldRule<string> = {
  errorMessage: "Invalid Canadian postal Code",
  rule: (input) => regexZip.test(input),
};

export const cadPhoneNumber: FieldRule<string> = {
  errorMessage: "Phone Number format is incorrect ",
  rule: (input) => regexAmericanPhone.test(input),
};

export const creditCardNumber: FieldRule<string> = {
  errorMessage: "Invalid credit card",
  rule: (input) => {
    //@ts-ignore
    //replace all " " by ""
    const inputSlice = input.replaceAll(" ", "");
    return creditCardRegex.test(inputSlice);
  },
};

export const isValidPhoneNumber: (country: CountryCode) => FieldRule<string> = (
  country
) => ({
  errorMessage: "Phone number format is incorrect ",
  rule: (input) => {
    if (input.length >= 4) {
      const parsed = parsePhoneNumber(input, country);
      return parsed.isValid();
    }
    return false;
  },
});

export const unique: <T>(
  elements: Array<T>,
  pred: (elemArray: T, elem: T) => boolean
) => FieldRule<T> = <T>(
  elements: Array<T>,
  pred: (elemArray: T, elem: T) => boolean
) => ({
  errorMessage: "This field must be unique",
  rule: (input: T) => Boolean(!elements.find((e) => pred(e, input))),
});

export const match: (element: string, name?: string) => FieldRule<string> = (
  element,
  name = "input"
) => ({
  errorMessage: `${name} don't match`,
  rule: (input: string) => input == element,
});

export const addPrefixToInput = (
  value: string,
  prefix: string
): { cleanValue: string; prefixValue: string } | undefined => {
  if (value === "") return;

  let cleanValue: string = value;
  let prefixValue: string = "";

  if (cleanValue.includes(prefix)) cleanValue = cleanValue.split(prefix)[1];

  prefixValue = `${prefix}${cleanValue}`;

  return {
    cleanValue,
    prefixValue,
  };
};
