import React from "react";
import { useDeviceContext } from "twrnc";
import { useLocation } from "react-router-dom";

import { isRegisterPage } from "@/routes";
import {
  View,
  Image,
  TouchableOpacity,
  Linking,
  ViewProps,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";

import { LeftArrow } from "../Icons/LeftArrow";
import { Logo } from "../Icons/Logo/Logo";

import { Hoverable } from "react-native-web-hover";
import { Nucleus } from "../Icons";
//@ts-ignore
import { CONDITIONS, PRIVACY } from "@env";
import { TermAndConditions } from "../AppPage";
import { FirebaseService } from "@/services";
import { BUTTON_BACK } from "@/services/firebase.service";

const REGISTER_URL = "/auth/register";

export const AuthLayout = (
  props: ViewProps & { noBack?: boolean; alwaysBack?: boolean; color?: string }
) => {
  const {
    noBack = false,
    alwaysBack = false,
    color = colors.rebrandLayoutBGColor,
  } = props;
  useDeviceContext(tw);

  const location = useLocation();
  const register = isRegisterPage(location.pathname);

  const showBackButtonCondition = !noBack && location.pathname !== "/auth";
  const showBackButtonRegisterCondition = location.state?.fromLogin;

  const canShowBackButton =
    alwaysBack ||
    (REGISTER_URL === location.pathname
      ? showBackButtonCondition && showBackButtonRegisterCondition
      : showBackButtonCondition);

  return (
    <View
      style={[
        tw`flex flex-col bg-[${color}] flex-1 items-center justify-center pb-4`,
        { overflow: "auto" },
      ]}
    >
      <View style={[tw`flex w-full flex-row justify-center`]}>
        <View style={[tw`absolute top-0 left-0`]}>
          {canShowBackButton && (
            <View style={tw`flex w-full flex-row items-center justify-center`}>
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    style={tw`flex w-full w-3/4 ml-4 lg:ml-16  pt-8 rounded-3
                  ${hovered ? "bg-gray-100 " : ""} `}
                    onPress={() => {
                      FirebaseService.logFirebaseEvent(BUTTON_BACK, {
                        is_register: register,
                        page_path: location.pathname,
                      });
                      history.back();
                    }}
                  >
                    <View style={tw`flex flex-row w-full items-center`}>
                      <View
                        style={{
                          borderRadius: 12,
                          ...tw`bg-white w-[2.5rem] h-[2.5rem] shadow-[0_5px_10px_2px_rgba(242, 242, 249, 1)] flex items-center justify-center mr-2`,
                        }}
                      >
                        <LeftArrow
                          color={colors.blue}
                          width={"15"}
                          height={"15"}
                        />
                      </View>
                      <Text color={colors.rebrandGray}>Back</Text>
                    </View>
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
          )}
        </View>

        {/* <Image
          style={tw`w-48 h-8 sm:hidden`}
          source={require("@/assets/images/LOGO/logo_black.png")}
          ></Image>

        <Logo style={tw`flex sm:hidden my-4 `} />*/}

        <View
          style={[
            tw`flex md:hidden bg-btn w-full p-10 items-center justify-center`,
          ]}
        >
          <Image
            source={require("@/assets/images/LOGO/nucleus.png")}
            style={tw`w-[130px] h-[38px]`}
          />
        </View>
      </View>

      {props.children}
      <TermAndConditions isLoggin />
    </View>
  );
};
