import React, { useState } from "react";
import {
  Platform,
  Pressable,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import { Avatar } from "react-native-elements";

import { colors, convertToBase64, Modal } from "@/lib";
import { required, tw, useFieldControl } from "@/lib";
import { Button, Text } from "../Commons";
import { Close, Image } from "../Icons";
import { TextInput } from "../Inputs";
import { ChangeWSImage } from "./ChangeImage";
import { WorkspaceResponse, AdminInfo } from "@/types";
import { MediaService, WorkspaceService } from "@/services";
import { AxiosError } from "axios";
import { showFriendlyErrorFromHTMLCode } from "../../lib/errorResponses";

interface CompanyInfoFormProp {
  value?: WorkspaceResponse;
  profileImg: string;
  currentWorkspaceId?: number;
  workspaceId?: number;
  onChange?: (input: WorkspaceResponse) => void;
  onChangeImg?: (updatedImg: string) => void;
  onError?: (errorMessage: string) => void;
  onAdminInfo?: (input: AdminInfo) => void;
}

export const CompanyInfoForm = (props: CompanyInfoFormProp) => {
  const form = {
    image: useFieldControl<File | undefined | string>(props.profileImg),
    companyName: useFieldControl(props.value?.name, [required]),
  };

  const [userImage, setUserImage] = useState<string>(props.profileImg);

  const [imgError, setImgError] = useState<boolean>(false);

  //Check if the image is being changing
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const changeImageAttempt = () => {
    setImgError(false);
    Modal.show(
      <ChangeWSImage
        //@ts-ignore
        image={form.image.value}
        //@ts-ignore
        onChange={(file) => {
          changeImg(file);
        }}
      />,
      { width: 230 }
    );
  };

  const changeImg = async (file: File | undefined) => {
    form.image.handleInputValue(file);
    if (
      (file?.type?.includes("image/png") ||
        file?.type?.includes("image/jpg") ||
        file?.type?.includes("image/jpeg")) &&
      props.value?.id
    ) {
      try {
        setIsLoading(true);
        const res = await WorkspaceService.updateCoverPhotoById(
          props.value?.id,
          file
        );

        //Set user image
        MediaService.getMediaFromType(
          "image",
          typeof res.data.result.coverPhoto === "string"
            ? res.data.result.coverPhoto
            : res.data.result.coverPhoto?.m
        )
          .then((res) => {
            let base64ImageString: string = convertToBase64(res);
            setUserImage(`data:image/png;base64,${base64ImageString}`);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } catch (error) {
        handleError(error);
      }
    } else {
      setImgError(true);
    }
  };

  const saveForm = async () => {
    if (valid) {
      try {
        setIsLoading(true);
        const workId = props.value?.id;
        if (
          workId &&
          form.companyName.value &&
          form.companyName.value !== props.value?.name
        ) {
          //Update the workspace
          const response = await WorkspaceService.setWorkspaceInfo(workId, {
            company_name: form.companyName.value,
          });
          const workspace = response?.data?.result?.workspace;
          if (workspace) props.onChange && props.onChange(workspace);
        }

        props.onChangeImg && props.onChangeImg(userImage);

        setIsLoading(false);
        Modal.dismiss();
      } catch (error) {
        setIsLoading(false);
        handleError(error);
      }
    }
  };

  const valid = form.companyName.valid && form.image.valid;

  const removeImg = async () => {
    if (!props.value?.id) return;

    try {
      setIsLoading(true);
      await WorkspaceService.deleteCoverPhotoById(props.value?.id);
      form.image.handleInputValue(undefined);
      setUserImage("");
      props.onChangeImg && props.onChangeImg(userImage);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    const errors = error as AxiosError;
    setIsLoading(false);
    Modal.dismiss();
    if (props.onError && errors.response) {
      if (errors.response.status == 403) return props.onError("Unauthorized");
      props.onError(showFriendlyErrorFromHTMLCode(errors));
    }
  };

  const closeAndUpdateImg = () => {
    Modal.dismiss();
    props.onChangeImg && props.onChangeImg(userImage);
  };

  return (
    <View>
      <TouchableOpacity
        style={[
          tw`top-2 right-2 absolute`,
          Platform.select({
            web: {
              zIndex: "1000",
            },
          }),
        ]}
        onPress={closeAndUpdateImg}
      >
        <Close />
      </TouchableOpacity>
      <View style={tw`flex-row justify-between items-start`}>
        <Text weight="bold" color={colors.black} type="h2">
          Company Info
        </Text>
      </View>
      <ScrollView contentContainerStyle={tw`w-full h-60 lg:min-h-[70px]  `}>
        <View style={tw`flex  lg:flex-row  justify-center`}>
          <View
            style={{ flex: 0.3, ...tw` items-center justify-center  pb-10 ` }}
          >
            <View style={tw`w-auto mb-2`}>
              <Avatar
                rounded
                source={userImage.length > 0 ? { uri: userImage } : undefined}
                size={128}
                renderPlaceholderContent={
                  <View
                    style={tw`bg-gray-500 h-full w-full justify-center items-center`}
                  >
                    <Image />
                  </View>
                }
              />
            </View>
            <View style={tw`items-center`}>
              <Pressable onPress={changeImageAttempt} style={tw`mb-2`}>
                <Text color={colors.blue} weight="semiBold">
                  Upload Photo
                </Text>
              </Pressable>
              {!!userImage && (
                <Pressable onPress={() => removeImg()}>
                  <Text color={colors.red} weight="semiBold">
                    Delete Photo
                  </Text>
                </Pressable>
              )}
            </View>
            {imgError && (
              <Text style={tw`ml-3`} color={colors.red}>
                Invalid image format
              </Text>
            )}
          </View>
          <View style={{ flex: 0.5, ...tw`justify-start` }}>
            <TextInput
              value={form.companyName.value}
              label="Company Name"
              onChangeText={form.companyName.handleInputValue}
              errorMessage={form.companyName.error}
            />
          </View>
        </View>
      </ScrollView>

      <View style={tw`flex-row justify-end`}>
        <Button
          style={tw`w-[156px]`}
          disabled={!valid || isLoading}
          onPress={saveForm}
          loading={isLoading}
        >
          <Text>Save Changes</Text>
        </Button>
      </View>
    </View>
  );
};
