import React, { useEffect, useRef, useState } from "react";

import {
  View,
  ViewProps,
  Platform,
  NativeSyntheticEvent,
  Pressable,
  TextInputChangeEventData,
} from "react-native";
import { Input, InputProps } from "react-native-elements";
import { useDeviceContext } from "twrnc";

import { tw, colors } from "@/lib";

import { Close } from "@/components/Icons";
import { Text } from "@/components/Commons";

import { useFonts, Roboto_400Regular } from "@expo-google-fonts/roboto";

export const TextInput = (
  props: ViewProps &
    InputProps & {
      padding?: boolean;
      clearable?: boolean;
      clear?: () => void;
      type?:
        | "email"
        | "number"
        | "letters"
        | "lettersdots"
        | "letternumbers"
        | "extension"
        | "phone";
      align?: "center";
      enableError?: boolean;
      isFocus?: boolean;
    }
) => {
  useDeviceContext(tw);
  const [state, setState] = useState<{
    isFocused: null | boolean;
    wasActive: boolean;
  }>({
    isFocused: null,
    wasActive: false,
  });

  const focus = (e: any) => {
    if (props.onFocus)
      //@ts-ignore
      props.onFocus(e);
    setState({ ...state, isFocused: true });
  };
  const blur = (e: any) => {
    if (props.onBlur)
      //@ts-ignore
      props.onBlur(e);
    setState({ isFocused: false, wasActive: true });
  };

  const showError = state.wasActive || props.enableError;

  const icon = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement | undefined>();

  const [innerValue, setInnerValue] = useState(props.value);

  const { maxLength, isFocus = false } = props;

  const [words, setWords] = useState(0);

  useEffect(() => {
    setInnerValue(props.value);
    if (maxLength != undefined && props.value != undefined) {
      setWords(maxLength - props.value.length);
    }
  }, [props.value]);

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isFocus]);

  if (icon.current && icon.current.children?.length > 0) {
    icon.current.children[0].setAttribute("tabindex", "-1");
  }

  const { clearable = false } = props;
  const { padding = true } = props;
  const { align } = props;

  const onClear = () => {
    (props.clear && props.clear()) ||
      (props.onChangeText && props.onChangeText(""));

    inputRef.current?.focus();
  };

  const onChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    let _value = e.nativeEvent.text;
    let _e = e;
    switch (props.type) {
      case "email":
        _value = _value.toLowerCase();
        //_value=[..._value].filter((e)=>e.match(regEmail)).join("").toLowerCase()
        break;
      case "number":
        _value = [..._value].filter((e) => e.match(/\d/)).join("");
        break;
      case "letters":
        _value = [..._value].filter((e) => e.match(/[a-zA-Z\s]/)).join("");
        break;
      case "letternumbers":
        _value = [..._value].filter((e) => e.match(/[a-zA-Z\s\d]/)).join("");
        break;
      case "phone":
        _value = [..._value].filter((e) => e.match(/[\s\d+()-.#+]/)).join("");
        break;
      case "extension":
        _value = [..._value]
          .filter((e) => e.match(/\d/))
          .join("")
          .slice(0, 4);
        break;

      default:
        break;
    }
    setInnerValue(_value);
    props.onChange &&
      props.onChange({
        ...e,
        nativeEvent: { ...e.nativeEvent, text: _value.toLowerCase() },
      });
  };

  const onChangeText = (e: string) => {
    let _value = e;
    switch (props.type) {
      case "email":
        _value = _value.toLowerCase();
        break;
      case "number":
        _value = [..._value].filter((e) => e.match(/\d/)).join("");
        break;
      case "letters":
        _value = [..._value].filter((e) => e.match(/[a-zA-Z\s]/)).join("");
        break;
      case "letternumbers":
        _value = [..._value].filter((e) => e.match(/[a-zA-Z\s\d]/)).join("");
        break;
      case "phone":
        _value = [..._value].filter((e) => e.match(/[\s\d+()-.#+]/)).join("");
        break;
      case "extension":
        _value = [..._value]
          .filter((e) => e.match(/\d/))
          .join("")
          .slice(0, 4);
        break;

      default:
        break;
    }
    setInnerValue(_value);
    props.onChangeText && props.onChangeText(_value);
  };

  const appendIcon = (
    <View ref={icon} focusable={false}>
      {props.rightIcon ||
        (clearable && (
          <Pressable onPress={onClear} focusable={false}>
            <Close />
          </Pressable>
        ))}
    </View>
  );

  // @ts-ignore
  useFonts(Roboto_400Regular);

  return (
    <View
      style={tw.style("w-full ", "min-h-[85px]", {
        "px-2": padding,
      })}
    >
      <Text style={[tw`text-rebrand-gray `, { fontSize: 15 }]}>
        {props.label}
      </Text>

      <Input
        {...{
          ...props,
          style: null,
          label: undefined,
          errorMessage: undefined,
          children: undefined,
        }}
        value={innerValue}
        //@ts-ignore
        ref={inputRef}
        autoCompleteType={undefined}
        containerStyle={tw`w-full px-0 h-auto  `}
        errorStyle={tw`m-0 p-0`}
        inputContainerStyle={[
          tw.style(
            "w-full",
            "h-input",
            "border",
            "border-gray-100",
            "rounded-3",
            "px-2",
            "bg-white",
            "relative"
          ),
          {
            backgroundColor: props.disabled
              ? colors.grayLightest
              : colors.white,
            borderColor: colors.grayLightest,
          },
          props.style,
        ]}
        inputStyle={[
          Platform.select({
            web: {
              outline: "none",
              fontFamily: "Roboto_400Regular",
              fontSize: 14,
            },
          }),
          tw`overflow-hidden`,
          props.inputStyle,
        ]}
        rightIcon={appendIcon}
        onFocus={focus}
        onBlur={blur}
        onChange={onChange}
        onChangeText={onChangeText}
        autoComplete="off"
        clearButtonMode="never"
        inputMode="text"
      />

      {maxLength && (
        <Text
          color={colors.grayLight}
          style={[
            tw.style(
              "absolute",
              props.errorMessage ? "bottom-5" : "bottom-3",
              "right-6"
            ),
          ]}
        >
          {words}
        </Text>
      )}

      <Text type="caption" color={colors.red}>
        {props.errorMessage}
      </Text>
      {props.children}
    </View>
  );
};
