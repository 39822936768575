import { colors, sortByHeader, tw } from '@/lib';
import { AccessResponse } from '@/types';

import {Text } from "@/components/Commons";
import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Icon from "react-native-vector-icons/FontAwesome";

interface IRequestAccessListHeaderProps {
  nameHeader: boolean,
  onPressSorted: (columnName: keyof AccessResponse) => void,
}

const RequestAccessListHeader: React.FunctionComponent<IRequestAccessListHeaderProps> = ({
  nameHeader,
  onPressSorted,
}) => {
  return (<View style={tw`flex-row px-4 mb-4`}>
  <TouchableOpacity
    style={tw`w-2/12 flex flex-row items-center`}
    onPress={() => onPressSorted('name')}
  >
    <Text weight="minibold" style={tw`mr-2`} color={colors.gray}>
      Name
    </Text>
    <Icon
      name={nameHeader ? "chevron-down" : "chevron-up"}
      // @ts-ignore
      type="font-awesome"
      color="#9CA3AF"
    />
  </TouchableOpacity>
  <View style={tw`w-1.8/12 px-1`}>
    <Text weight="minibold" color={colors.gray}>Access Level</Text>
  </View>
  <View style={tw`w-2.2/12 px-1`}>
    <Text weight="minibold" color={colors.gray}>Requested Date</Text>
  </View>
  <View style={tw`w-2.2/12 px-1`}>
    <Text weight="minibold" color={colors.gray}>Start Date</Text>
  </View>
  <View style={tw`w-2.2/12 px-1`}>
    <Text weight="minibold" color={colors.gray}>Expiry Date</Text>
  </View>
  <View style={tw`w-1.5/12 px-1`}>
    <Text weight="minibold" color={colors.gray}>Status</Text>
  </View>
  <View style={tw`w-auto `}></View>
</View>);

};

export default RequestAccessListHeader;
