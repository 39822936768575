import { MessageStatus } from './Notifications';
import { View } from "react-native";
import React from "react";
import { tw } from "@/lib";
import { Button} from "@/components/Commons";

interface IMessageStatusBarProps {
  status: MessageStatus,
  setStatus: (value: MessageStatus) => void,
}

const MessageStatusBar: React.FunctionComponent<IMessageStatusBarProps> = ({status, setStatus}) => {
  return (<View 
      style={[tw`flex flex-row w-full pl-[25px] py-[25px]`,]}
    >
      <Button
          type={status==='All' ? 'button':'outlined'}
          style={tw`w-[110px] mb-2 md:mb-0`}
          onPress={() => setStatus("All")}
      >
        All
      </Button>
      <Button
          type={status==='Read' ? 'button':'outlined'}
          style={tw`w-[110px] mb-2 md:mb-0`}
          onPress={() => setStatus("Read")}
      >
        Read
      </Button>
      <Button
          type={status==='Unread' ? 'button':'outlined'}
          style={tw`w-[110px] mb-2 md:mb-0`}
          onPress={() => setStatus("Unread")}
      >
        Unread
      </Button>
    </View>);
};

export default MessageStatusBar;
