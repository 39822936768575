import { AxiosResponse } from "axios";
import { ApiService } from "./";
import { WorkSpace, WorkspaceResponse } from "@/types";
import { ApiObjectResponse } from "@/types";
import { AxiosHeaders } from "@/services";

const endpoint = "media-storage";

class MediaService {
  //Get the current Workspace
  async getMediaFromType(
    type: "image" | "video" | "document",
    filename: string | undefined
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.get(`${endpoint}/${type}/${filename}`, {
      responseType: "arraybuffer",
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
}

const singleton = new MediaService();
export default singleton;
