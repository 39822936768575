import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";

interface RouteInfo {
  name?: string;
  path: string;
}

const register: RouteInfo[] = [
  {
    name: "register",
    path: "/auth/register",
  },
  {
    name: "registerMail",
    path: "/auth/register/mail",
  },
  {
    name: "registerSms",
    path: "/auth/register/sms",
  },
  {
    name: "registerCompany",
    path: "/auth/register/company",
  },
  {
    name: "registerAdmin",
    path: "/auth/register/admin",
  },
  {
    name: "registerPhone",
    path: "/auth/register/phone",
  },
  {
    name: "registerSuccess",
    path: "/auth/register/success",
  },
  {
    name: "registerCards",
    path: "/auth/register/cards",
  },
  {
    name: "activations",
    path: "/auth/register/activations",
  },
];

const authPages: RouteInfo[] = [
  ...register,
  {
    name: "login",
    path: "/login",
  },
  {
    name: "auth",
    path: "/auth",
  },
  {
    name: "recoveryLogin",
    path: "/auth/recovery",
  },
  {
    name: "recoveryLoginCode",
    path: "/auth/recovery/code",
  },
  {
    name: "recoveryLoginReset",
    path: "/auth/recovery/reset",
  },
  {
    name: "recoveryLoginSuccess",
    path: "/auth/recovery/success",
  },
  {
    name: "recoveryLoginExpire",
    path: "/auth/recovery/expire",
  },
];

const clientPages: RouteInfo[] = [
  {
    name: "clientCompany",
    path: "/company/details",
  },
  {
    name: "clientCompanyUsers",
    path: "/company/users",
  },
  {
    name: "clientChangePassword",
    path: "/company/password",
  },
  {
    name: "clientChangeEmail",
    path: "/company/email",
  },
  {
    name: "clientProfile",
    path: "/company/profile",
  },
  {
    name: "clientDetailSubscriptionsdas",
    path: "/subscriptions/details",
  },
  {
    name: "subscriptionPlans",
    path: "/subscriptions/plans",
  },

  {
    name: "subscriptionDetailsplan",
    path: "/subscriptions/details-plan",
  },

  {
    name: "clientSubscriptionsPayment",
    path: "/subscriptions/payment",
  },
  {
    name: "clientAddsOns",
    path: "/subscriptions/addOns",
  },
  {
    name: "promocode",
    path: "/subscriptions/promocode",
  },

  {
    name: "clientReportsDetails",
    path: "/reports/usage",
  },
  {
    name: "clientcdrReport",
    path: "/reports/cdrReport",
  },
  {
    name: "qosOverview",
    path: "/reports/qosOverview",
  },
  {
    name: "clientReportDevices",
    path: "/reports/devices",
  },
  {
    name: "clientInfoSystemStatus",
    path: "/info/status",
  },
  {
    name: "clientInfoDiagnostic",
    path: "/info/diagnostic",
  },
  {
    name: "clientInfoDiagnosticDetails",
    path: "/info/details",
  },
  {
    name: "clientInfoTicketList",
    path: "/info/tickets",
  },
  {
    name: "clientNewTicket",
    path: "/info/newTickets",
  },
  {
    name: "clientInfoFaq",
    path: "/info/faq",
  },
  {
    name: "clientInfoSupportAccess",
    path: "/info/access",
  },
  {
    name: "clientInfoReports",
    path: "/info/reports",
  },
  {
    name: "clientInfoSystemInsight",
    path: "/info/insight",
  },
  {
    name: "store",
    path: "/store/main",
  },
];

const partnerMap: RouteInfo[] = [
  {
    path: "/partner/dashboard",
    name: "PartnerDashboard",
  },
  {
    path: "/partner/clients",
    name: "PartnerClientList",
  },
  {
    path: "/partner/clientAdd",
    name: "PartnerAdd",
  },
  {
    path: "/partner/clientPlan",
    name: "PartnerClientPlan",
  },
  {
    path: "/partner/clientEmail",
    name: "PartnerClientEmail",
  },
  {
    path: "/partner/clientAdmin",
    name: "PartnerClientAdmin",
  },
  {
    path: "/partner/reports",
    name: "PartnerReports",
  },
  {
    path: "/partner/resources",
    name: "PartnerResources",
  },
  {
    path: "/partner/help",
    name: "PartnerHelp",
  },
  {
    path: "/partner/tickets",
    name: "PartnerHelpTickets",
  },
  {
    name: "PartnerChangePassword",
    path: "/partner/password",
  },
  {
    name: "PartnerChangeEmail",
    path: "/partner/email",
  },
  {
    name: "PartnerProfile",
    path: "/partner/profile",
  },
  {
    name: "PartnerInfoFaq",
    path: "/partner/faq",
  },
  {
    name: "PartnerNewTicket",
    path: "/partner/tickets/new-ticket",
  },
];

const adminMap: RouteInfo[] = [
  {
    path: "/admin/customer",
    name: "AdminCustomerList",
  },
  {
    path: "/admin/partners",
    name: "AdminPartnerList",
  },
  {
    path: "/admin/partnersprofile",
    name: "AdminPartnerDetails",
  },
  {
    path: "/admin/product",
    name: "AdminProductList",
  },
  {
    path: "/admin/addOns",
    name: "ProductAddons",
  },
  {
    path: "/admin/promotions",
    name: "promotions",
  },

  {
    path: "/admin/resources",
    name: "Resources",
  },
];

const superAdminMap: RouteInfo[] = [
  {
    path: "/super-admin",
    name: "SuperAdminDashboard",
  },
  {
    path: "/super-admin/customer",
    name: "SuperAdminCustomerList",
  },
  {
    path: "/super-admin/admin",
    name: "SuperAdminAdminList",
  },
  {
    path: "/super-admin/partners",
    name: "SuperAdminPartnerList",
  },
  {
    path: "/super-admin/product",
    name: "SuperAdminProductList",
  },
  {
    path: "/super-admin/resources",
    name: "SuperAdminResources",
  },
  {
    path: "/super-admin/workspaces",
    name: "SuperAdminWorkspaces",
  },

  {
    path: "/super-admin/details",
    name: "SuperAdminCompany",
  },
  {
    name: "SuperAdminCompanyUsers",
    path: "/super-admin/users",
  },
  {
    name: "SuperAdminChangePassword",
    path: "/super-admin/password",
  },
  {
    name: "SuperAdminChangeEmail",
    path: "/super-admin/email",
  },
  {
    name: "SuperAdminProfile",
    path: "/super-admin/profile",
  },
  {
    name: "SuperAdminSubscriptions",
    path: "/super-admin/subscriptions/details",
  },
];

const supportMap: RouteInfo[] = [
  {
    path: "/support",
    name: "SupportDashboard",
  },
  {
    path: "/support/customers",
    name: "SupportCustomers",
  },
  {
    path: "/support/bugs-and-errors",
    name: "SupportBugsAndErrors",
  },
  {
    path: "/support/resources",
    name: "SupportResources",
  },
  {
    path: "/support/password",
    name: "SupportChangePassword",
  },
  {
    path: "/support/email",
    name: "SupportChangeEmail",
  },
  {
    path: "/support/profile",
    name: "SupportProfile",
  },
];

/*const workspaceMap: RouteInfo[] = [
  {
    name: "workspaceCompany",
    path: "/super-admin/details",
  },
  {
    name: "workspaceCompanyUsers",
    path: "/super-admin/users",
  },
  {
    name: "workspaceChangePassword",
    path: "/super-admin/password",
  },
  {
    name: "workspaceChangeEmail",
    path: "/super-admin/email",
  },
  {
    name: "workspaceProfile",
    path: "/super-admin/profile",
  },
  {
    name: "workspaceSubscriptions",
    path: "/super-admin/subscriptions/details",
  },
];*/

const routeMap: RouteInfo[] = [
  ...authPages,
  ...clientPages,
  ...partnerMap,
  ...adminMap,
  ...supportMap,
  ...superAdminMap,
  //...workspaceMap,
];

const getRoutePath = (name: string) =>
  routeMap.find((e) => e.name == name)?.path || "";

const getRoute = (name?: string) => {
  if (name) {
    return routeMap.find((e) => e.name == name);
  }
  const location = useLocation();
  return routeMap.find((e) => e.path == location.pathname);
};

const isRegisterPage: (url: string) => boolean = (url) =>
  Boolean(register.find((e) => e.path == url));

function customUseNavigate(): NavigateFunction {
  const navigate = useNavigate();

  const customNavigate = (to: To | Number, options?: NavigateOptions) => {
    const target = routeMap.find((e) => e.name == to)?.path || to;

    if (typeof target == "number") {
      navigate(to as number);
    } else {
      navigate(target as To, options);
    }
  };

  return customNavigate;
}

export {
  routeMap,
  isRegisterPage,
  getRoutePath,
  customUseNavigate as useNavigate,
  getRoute,
};
