import React from "react";
import { TouchableOpacity, View } from "react-native";

import { useDeviceContext } from "twrnc";
import dayjs from "dayjs";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { RightArrow } from "@/components/Icons";

interface ICalendarHeader {
  date: any;
  onArrowPress: (month: number) => void;
}

export const CalendarHeader = ({ date, onArrowPress }: ICalendarHeader) => {
  useDeviceContext(tw);

  return (
    <View style={tw`flex flex-row items-center justify-between py-2 w-55`}>
      <Text weight="minibold" type="h6">
        {dayjs(date).format("MMMM YYYY")}
      </Text>
      <View style={tw`flex flex-row items-center`}>
        <TouchableOpacity
          onPress={() => onArrowPress(-1)}
          style={{
            transform: [{ rotate: "180deg" }],
          }}
        >
          <RightArrow color={colors.blue} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => onArrowPress(1)}>
          <RightArrow color={colors.blue} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
