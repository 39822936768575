import React from "react";
import Svg, { Defs, G, Path, Rect } from "react-native-svg";

export const Clock = (props: { color?: string }) => {
  const { color = "#5E6CB2" } = props;
  return (
    <>
      <Svg width="22px" height="22px" viewBox="0 0 24 24" fill="none">
        <Path
          d="M9 4L15 4"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M12 10L12 14"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M12 22C16.4183 22 20 18.4183 20 14C20 9.58172 16.4183 6 12 6C7.58172 6 4 9.58172 4 14C4 18.4183 7.58172 22 12 22Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </Svg>

      {/* <Svg width="42" height="42" viewBox="0 0 40 40" fill="none">
      {/* <G >
        <Rect x="52" y="47" width="40" height="40" rx="12" transform="rotate(-180 52 47)" fill="white" />
      </G>
      <G clipPath="url(#clip0_2588_47636)" >
        <Path
          d="M32.0251 19.2461C27.1607 19.2461 23.2031 23.2037 23.2031 28.0681C23.2031 32.9326 27.1607 36.8901 32.0251 36.8901C36.8896 36.8901 40.8472 32.9326 40.8472 28.0681C40.8472 23.2037 36.8896 19.2461 32.0251 19.2461ZM32.0251 35.9233C27.6939 35.9233 24.1699 32.3994 24.1699 28.0681C24.1699 23.7369 27.6939 20.2129 32.0251 20.2129C36.3564 20.2129 39.8804 23.7369 39.8804 28.0681C39.8804 32.3994 36.3564 35.9233 32.0251 35.9233Z"
          fill={color}
          stroke={color}
          strokeWidth={"0.3"}
        />
        <Path
          d="M32.0029 28.5726C31.8747 28.5726 31.7518 28.5217 31.6611 28.431C31.5705 28.3404 31.5195 28.2174 31.5195 28.0892V23.2559C31.5195 23.1277 31.5705 23.0047 31.6611 22.914C31.7518 22.8234 31.8747 22.7725 32.0029 22.7725C32.1311 22.7725 32.2541 22.8234 32.3447 22.914C32.4354 23.0047 32.4863 23.1277 32.4863 23.2559V28.0892C32.4863 28.2174 32.4354 28.3404 32.3447 28.431C32.2541 28.5217 32.1311 28.5726 32.0029 28.5726Z"
          fill={color}
          stroke={color}
          strokeWidth={"0.3"}
        />
        <Path
          d="M34.3193 18.3213H29.6787C29.5505 18.3213 29.4276 18.2704 29.3369 18.1797C29.2462 18.089 29.1953 17.9661 29.1953 17.8379C29.1953 17.7097 29.2462 17.5867 29.3369 17.4961C29.4276 17.4054 29.5505 17.3545 29.6787 17.3545H34.3193C34.4475 17.3545 34.5705 17.4054 34.6612 17.4961C34.7518 17.5867 34.8027 17.7097 34.8027 17.8379C34.8027 17.9661 34.7518 18.089 34.6612 18.1797C34.5705 18.2704 34.4475 18.3213 34.3193 18.3213Z"
          fill={color}
          stroke={color}
          strokeWidth={"0.3"}
        />
        <Path
          d="M32.0234 29.0342C32.5574 29.0342 32.9902 28.6013 32.9902 28.0674C32.9902 27.5334 32.5574 27.1006 32.0234 27.1006C31.4895 27.1006 31.0566 27.5334 31.0566 28.0674C31.0566 28.6013 31.4895 29.0342 32.0234 29.0342Z"
          fill={color}
          stroke={color}
          strokeWidth={"0.3"}
        />
      </G>


      <Defs>
        <filter id="filter0_d_2588_47636" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_2588_47636" />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.94902 0 0 0 0 0.94902 0 0 0 0 0.976471 0 0 0 0.8 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2588_47636" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2588_47636" result="shape" />

        </filter>

        <clipPath id="clip0_2588_47636" >
        <Rect width="18" height="21" fill="white" transform="translate(23 17)"/>
        </clipPath>
      </Defs>
    </Svg>
   */}
    </>
  );
};
