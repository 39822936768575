import { AxiosResponse } from "axios";
import { ApiService } from "./";
import { AuthInput, AuthResponse, PasswordChange } from "@/types";
import { TokenService } from "@/services";
import { UserAuth } from "../types/auth.types";
import AxiosHeaders from "./axiosheaders.service";

const endpoint = "auth";

class AuthService {
  //Authenticate user on Nucleus
  async login(
    input: AuthInput
  ): Promise<AxiosResponse<{ result: AuthResponse }>> {
    return await ApiService.api.post(endpoint, input);
  }

  //Log out of the platform.
  async Logout() {
    try {
      await ApiService.api.delete(endpoint, {
        data: {
          token: TokenService.getFullRefreshToken()?.token || "",
        },
      });
      TokenService.removeUser();
    } catch (error) {
      TokenService.removeUser();
    }
  }

  //Refresh user token
  async tokenrefresh(): Promise<
    AxiosResponse<{ result: Omit<AuthResponse, "user"> }>
  > {
    //Refresh token from local
    const refreshToken = TokenService.getLocalRefreshToken();

    return ApiService.api.put(endpoint, { token: refreshToken });
  }

  //Register user
  async signUp() {
    return ApiService.api.post(`${endpoint}/signup`);
  }

  async checkAuth(): Promise<AxiosResponse<{ result: UserAuth }>> {
    return ApiService.api.get(endpoint, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
}

const singleton = new AuthService();
export default singleton;
