import { AxiosError } from "axios";
//////Keep in sync with cust-account-manager
const errorCodes: { [key: string]: string } = {
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Proxy Authentication Required",
  "408": "Request Timeout",
  "409": "Conflict",
  "410": "Gone",
  "411": "Length Required",
  "412": "Precondition Failed",
  "413": "Request Entity Too Large",
  "414": "Request URI Too Long",
  "415": "Unsupported Media Type",
  "416": "Requested Range Not Satisfiable",
  "417": "Expectation Failed",
  "418": "I'm a teapot",
  "421": "Misdirect Request",
  "422": "Unprocessable Entity",
  "423": "Locked",
  "425": "Too Early",
  "426": "Upgrade Required",
  "428": "Precondition Required",
  "429": "Too Many Requests",
  "431": "Request Header Fields Too Large",
  "451": "Unavailable For Legal Reasons",
};

const prettyCodes: { [key: string]: string } = {
  INVALID_USER_OR_SECRET:
    "The provided validation credentials have expired and are no longer valid.",
  ERROR_BLACKLIST: "The provided data is not permitted",
};

const findValByKey = (object: any, key: any) => {
  var value;
  if (object) {
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }

      if (object[k] && typeof object[k] === "object") {
        value = findValByKey(object[k], key);
        return value !== undefined;
      }
    });
  }
  return value;
};

const findValByType = (object: any, type: any) => {
  var value;
  if (object) {
    Object.keys(object).some(function (k) {
      if (typeof object[k] === type) {
        value = object[k];
        return true;
      }

      if (object[k] && typeof object[k] === "object") {
        value = findValByType(object[k], type);
        return value !== undefined;
      }
    });
  }
  return value;
};

const checkPretty = (msg?: string) => {
  if (msg) {
    const pretty = prettyCodes[msg];
    if (pretty) return pretty;
  }
  return msg;
};

export const showFriendlyErrorFromHTMLCode = (error: any): string => {
  let embeddedMessage = findValByKey(
    findValByKey(error.response, "errorJsonObject"),
    "message"
  );

  if (!embeddedMessage)
    embeddedMessage = findValByKey(error.response, "message");

  if (typeof embeddedMessage === "object") {
    const nextMessage = findValByType(embeddedMessage, "string");
    return checkPretty(nextMessage) || "Unknown Error";
  } else if (typeof embeddedMessage === "string")
    return checkPretty(embeddedMessage) || "Unknown Error";
  //Validate it is an unexpected or expected error
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  //If unexpected show generic message
  if (!expectedError) {
    return "An unexpected error occurred.";
  }

  //If expected show message
  return errorCodes[error.response?.status.toString()!];
};
