import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Church = (props: { color?: string,style?: StyleProp<ViewStyle>; }) => {
  const { color = "white",style } = props;
  return (
    <Svg width="124" height="130" viewBox="0 0 124 130" fill="none" style={style}>
      <Path
        d="M120.657 3.34582C118.503 1.18817 115.636 0 112.585 0H69.1152C66.0658 0 63.1988 1.18769 61.0424 3.34437L3.34485 61.0419C1.18769 63.1988 0 66.0656 0 69.1143C0 72.1634 1.18817 75.0302 3.34485 77.1854L46.8136 120.654C48.9696 122.812 51.8364 124 54.8855 124C57.9344 124 60.8012 122.812 62.9581 120.655L120.656 62.9576C122.812 60.8012 124 57.9342 124 54.8848V11.415C124 8.36419 122.812 5.49717 120.657 3.34582ZM62.0002 28.5726C68.2945 28.5726 73.4153 33.6934 73.4153 39.9876C73.4153 46.2818 68.2945 51.4026 62.0002 51.4026C55.706 51.4026 50.5852 46.2818 50.5852 39.9876C50.5852 33.6934 55.706 28.5726 62.0002 28.5726ZM62.0002 95.427C55.706 95.427 50.5852 90.3062 50.5852 84.0119C50.5852 77.7177 55.706 72.5969 62.0002 72.5969C68.2945 72.5969 73.4153 77.7177 73.4153 84.0119C73.4153 90.3062 68.2945 95.427 62.0002 95.427ZM85.3469 65.6326H38.6536C36.6473 65.6326 35.0208 64.006 35.0208 61.9998C35.0208 59.9935 36.6473 58.3669 38.6536 58.3669H85.3469C87.3532 58.3669 88.9797 59.9935 88.9797 61.9998C88.9797 64.006 87.3534 65.6326 85.3469 65.6326ZM100.912 26.7213C98.9062 26.7213 97.2787 25.0938 97.2787 23.0885C97.2787 21.0831 98.9062 19.4556 100.912 19.4556C102.917 19.4556 104.544 21.0831 104.544 23.0885C104.544 25.0938 102.917 26.7213 100.912 26.7213Z"
        fill={color}
        stroke={color}
        strokeWidth={"0.1"}
        fillOpacity={"0.1"}
      />
         
    </Svg>
  );
};
