import React, { useState } from "react";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import { colors, tw } from "@/lib";
import { Text, Tooltip } from "../Commons";
import { Question } from "../Icons";

export interface StatusHistoryProps {
  serviceName?: string;
  tooltipInfo?: string;
  history?: boolean[];
  style?: StyleProp<ViewStyle>;
}

export const StatusHistory = (props: StatusHistoryProps) => {
  const { serviceName = "", tooltipInfo = "", history = [] } = props;

  const historyElements = history.map((e) => ({
    color: e ? colors.statusGoodBar : colors.statusBadBar,
  }));

  //Show/Hide content
  const [isExpanded, setisExpanded] = useState<boolean>(true);

  const activeCount = history.reduce((p, c) => (p += c ? 1 : 0), 0);

  return (
    <View
      style={[
        tw`px-[1.25rem] py-[1rem] border border-t-0 border-[${colors.statusBorder}]`,
        props.style,
      ]}
    >
      <View style={tw`flex-row justify-between items-center`}>
        <Pressable
          style={tw`flex flex-row items-center`}
          onPress={() => setisExpanded(!isExpanded)}
        >
          {!isExpanded && (
            <View style={tw`mr-1`}>
              <Icon name="plus-square-o" size={14} color={colors.gray} />
            </View>
          )}
          <Text weight="bold" style={tw`mr-2`}>
            {serviceName}
          </Text>
          {/* Show Tooltip */}
          {tooltipInfo.length > 0 && (
            <Tooltip tooltipInfo={tooltipInfo}>
              <Question />
            </Tooltip>
          )}
        </Pressable>
        <Text style={tw`text-[${historyElements.at(-1)!.color}]`}>
          Operational
        </Text>
      </View>
      {isExpanded && (
        <View style={{ zIndex: -10, ...tw`py-2` }}>
          <svg preserveAspectRatio="none" height="34" viewBox="0 0 298 34">
            {historyElements.map((e, index) => (
              <rect
                key={`${serviceName}-${index}`}
                height="34"
                width="3"
                fill={e.color}
                y="0"
                x={index * 5}
              />
            ))}
          </svg>
          <View style={tw`flex-row justify-between pt-1 items-center`}>
            <View style={tw`flex-grow-0`}>
              <Text style={[tw`text-[#72767d]`, { opacity: 0.5 }]}>
                60 days ago
              </Text>
            </View>
            <View
              style={[
                tw`flex-grow h-[1px] mx-[1rem] bg-[#72767d]`,
                ,
                { opacity: 0.2 },
              ]}
            ></View>
            <View style={tw`flex-grow-0`}>
              <Text style={[tw`text-[#72767d]`, { opacity: 0.8 }]}>
                {((activeCount * 100) / history.length).toFixed()}% uptime
              </Text>
            </View>
            <View
              style={[
                tw`flex-grow h-[1px] mx-[1rem] bg-[#72767d]`,
                ,
                { opacity: 0.2 },
              ]}
            ></View>
            <View style={tw`flex-grow-0`}>
              <Text style={[tw`text-[#72767d]`, { opacity: 0.5 }]}>Today</Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};
