import { TouchableOpacity, View, ActivityIndicator } from "react-native";
import React, { useState } from "react";
import { Modal, tw } from "@/lib";
import { Text, Button } from "@/components/Commons";
import { noop } from "lodash";

interface RemoveWorkspaceModalProps {
  onRemove?: () => void;
}

const RemoveWorkspaceModal: React.FunctionComponent<
  RemoveWorkspaceModalProps
> = ({ onRemove = noop }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const callOnRemove = async () => {
    await onRemove();
    setLoading(false);
  };
  return (
    <View>
      <View style={tw`flex-row justify-between mb-1`}>
        <Text type="h2" weight="bold">
          Remove Workspace?
        </Text>
        <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
      </View>

      <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
        <Text>Are you sure you want to remove this Workspace? </Text>
      </View>

      <View style={tw`flex flex-row justify-end mt-10`}>
        <View style={tw`flex flex-row w-2/3`}>
          <Button style={tw`flex-1`} type="outlined" onPressOut={Modal.dismiss}>
            <Text weight="semiBold">Cancel</Text>
          </Button>

          {loading ? (
            <ActivityIndicator size="small" />
          ) : (
            <Button
              style={tw`flex-1`}
              onPress={() => {
                setLoading(true);
                callOnRemove();
              }}
            >
              <Text weight="semiBold">Remove</Text>
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};

export default RemoveWorkspaceModal;
