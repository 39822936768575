import useSocket from "@/hooks/useSocket";
import { TokenService } from "@/services";
import { RootState } from "@/store";
import { useAppSelector } from "@/store/hooks";
import React from "react";
import { AdminBanner } from "./AdminBanner";

export function ShowAdminBanner() {
  const user = TokenService.getUser();
  const { connectAndAddCommonListeners } = useSocket();

  if (user?.user?.id) {
    connectAndAddCommonListeners({ jwt: user.accessToken.token });
  }

  const { isInside } = useAppSelector((state: RootState) => state.banner);
  return <>{isInside && <AdminBanner />}</>;
}
