import React from "react";
import Svg, { Path } from "react-native-svg";

export const LeftArrow = (props: {
  color?: string;
  width?: string;
  height?: string;
  strokeWidth?: number;
}) => {
  const { color = "white", width, height, strokeWidth = 1.5 } = props;
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <Path
        d="M7.25 14.5L0.75 8L7.25 1.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </Svg>
  );
};
