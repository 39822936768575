import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAppDispatch, useAppSelector } from "@/store/hooks";

import { RootState } from "@/store";
import { resetAlert } from "@/store/alert.store";

interface AlertKind {
  error: boolean;
  success: boolean;
  info: boolean;
  warning: boolean;
  danger: boolean;
}

export const Swal = () => {
  const { showAlert, type, msg } = useAppSelector(
    (state: RootState) => state.alert
  );

  const dispatch = useAppDispatch();

  const initialTypes: AlertKind = {
    error: false,
    success: false,
    info: false,
    warning: false,
    danger: false,
  };

  let alertType: AlertKind = initialTypes;

  switch (type) {
    case "danger":
      alertType = { ...initialTypes, danger: true };
      break;
    case "info":
      alertType = { ...initialTypes, info: true };
      break;
    case "success":
      alertType = { ...initialTypes, success: true };
      break;
    case "warning":
      alertType = { ...initialTypes, warning: true };
      break;
    default:
      alertType = { ...initialTypes, error: true };
      break;
  }

  const handleReset = () => dispatch(resetAlert());

  return (
    <SweetAlert
      error={alertType.error}
      warning={alertType.warning}
      danger={alertType.danger}
      success={alertType.success}
      info={alertType.info}
      title={msg}
      onConfirm={handleReset}
      show={showAlert}
    />
  );
};
