import React from "react";

export const Image = (props: { size?: number | string }) => {
  const { size = 40 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        
        d="M28.6054 1.49951H11.3014C5.27739 1.49951 1.49939 5.76751 1.49939 11.8075V28.0995C1.49939 34.1395 5.26139 38.4075 11.3014 38.4075H28.5954C34.6454 38.4075 38.4054 34.1395 38.4054 28.0995V11.8075C38.4134 5.76751 34.6514 1.49951 28.6054 1.49951Z"
        stroke="#C2C2CD"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.984 14.172C17.984 16.212 16.332 17.864 14.292 17.864C12.254 17.864 10.6 16.212 10.6 14.172C10.6 12.132 12.254 10.48 14.292 10.48C16.33 10.482 17.982 12.134 17.984 14.172Z"
        stroke="#C2C2CD"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.414 26.8982C36.568 24.9982 33.018 21.1602 29.158 21.1602C25.296 21.1602 23.07 29.6262 19.356 29.6262C15.642 29.6262 12.268 25.7982 9.292 28.2522C6.316 30.7042 4 34.0002 4 34.0002"
        stroke="#C2C2CD"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
