import { ScrollView, View } from "react-native";
import React, { useState } from "react";

import { Badge, Text } from "@/components/Commons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { colors, tw } from "@/lib";
import { useDeviceContext } from "twrnc";
import { DashboardList } from "@/screens/Support";
import { Divider } from "react-native-elements";

// Dummy Data
const data = [
  {
    id: 1,
    name: "Ashlynn Stanton",
    message: "Hello! I have a problem with invitation letter. ",
  },
  {
    id: 2,
    name: "Jaylon Dokidis",
    message:
      "In semper a gravida eu auctor rutrum et blandit aenean. Pellentesque aliquam duis ornare sit se ...",
  },
  {
    id: 3,
    name: "Adison Botosh",
    message:
      "Magna mi aliquam faucibus ultrices arcu hendrerit mi quisque ut. Scelerisque sed platea egestas ...",
  },
];

const viewedData = [
  {
    id: 1,
    name: "Ashlynn Stanton",
    message: "Hello! I have a problem with invitation letter. ",
    amount: 4,
  },
  {
    id: 2,
    name: "Jaylon Dokidis",
    message:
      "In semper a gravida eu auctor rutrum et blandit aenean. Pellentesque aliquam duis ornare sit se ...",
    amount: 5,
  },
  {
    id: 3,
    name: "Adison Botosh",
    message:
      "Magna mi aliquam faucibus ultrices arcu hendrerit mi quisque ut. Scelerisque sed platea egestas dansodinaosdnaosindoanoifnnosianfposindf fisapdfn sadpf sfasj dfoijasdpofijs paoifjsaod fjaposdf josdijf opsdijfposjdf poaisjdfpo saidjfposa djfposdjf posdjf sjdfposa jfosja fpjiaspdo fjas opfjasopdifjpoasidjfoisja fsaofpdj asopdfi",
    amount: 12,
  },
];

export const Dashboard = () => {
  //Use tailwind
  useDeviceContext(tw);

  //Types of the selection buttons
  const [tab, changeTab] = useState<"tickets" | "utickets" | "atickets">(
    "tickets"
  );

  //Get the style of the button active and inactive
  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();
    return isActive
      ? {
          btn: tw`bg-white rounded-3`,
          text: tw`text-primary`,
        }
      : {
          text: tw`text-dark-gray`,
        };
  };

  //Show the buttons and the styles
  const tabs = () => {
    const selectedTickets = getTabStyle(() => tab == "tickets");
    const selectedUTickets = getTabStyle(() => tab == "utickets");
    const selectedATickets = getTabStyle(() => tab == "atickets");
    return (
      <View style={tw`flex-row bg-[#EBEBF0]  h-10 rounded-3 flex-wrap`}>
        <TouchableOpacity
          style={tw`flex flex-row`}
          containerStyle={[
            tw`flex-grow-1 items-center justify-center px-4`,
            selectedTickets.btn,
          ]}
          onPress={() => {
            tab != "tickets" && changeTab("tickets");
          }}
        >
          <Text weight="semiBold" style={selectedTickets.text}>
            My tickets
          </Text>
          <Badge text={3} style={tw`ml-1`} />
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`flex flex-row`}
          containerStyle={[
            tw`flex-grow-1 items-center justify-center px-4`,
            selectedUTickets.btn,
          ]}
          onPress={() => {
            tab != "utickets" && changeTab("utickets");
          }}
        >
          <Text weight="semiBold" style={selectedUTickets.text}>
            Unassigned Tickets
          </Text>
          <Badge text={13} style={tw`ml-1`} />
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`flex flex-row`}
          containerStyle={[
            tw`flex-grow-1 items-center justify-center px-4`,
            selectedATickets.btn,
          ]}
          onPress={() => {
            tab != "atickets" && changeTab("atickets");
          }}
        >
          <Text weight="semiBold" style={selectedATickets.text}>
            All tickets
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  //Function to show content based on tab selected
  const showContent = () => {
    switch (tab) {
      case "tickets":
        return (
          <View style={tw`mt-5`}>
            {/* Tickets */}
            <DashboardList data={data} />
            {/* Division */}
            <View style={tw`flex flex-row items-center`}>
              <Divider style={tw`flex flex-1`} />
              <Text weight="semiBold" color={colors.gray}>
                Viewed
              </Text>
              <Divider style={tw`flex flex-1`} />
            </View>
            {/* Viewed Tickets */}
            <DashboardList data={viewedData} />
          </View>
        );
      case "utickets":
        return (
          <View style={tw`mt-5`}>
            {/* Tickets */}
            <DashboardList data={data} />
            {/* Division */}
            <View style={tw`flex flex-row items-center`}>
              <Divider style={tw`flex flex-1`} />
              <Text weight="semiBold" color={colors.gray}>
                Viewed
              </Text>
              <Divider style={tw`flex flex-1`} />
            </View>
            {/* Viewed Tickets */}
            <DashboardList data={viewedData} />
          </View>
        );
      case "atickets":
        return (
          <View style={tw`mt-5`}>
            {/* Tickets */}
            <DashboardList data={data} />
            <DashboardList data={data} />
            <DashboardList data={data} />
          </View>
        );
    }
  };

  return (
    <View style={tw`h-full`}>
      {/* Top Menu */}
      <View style={tw`lg:flex-row justify-between`}>
        <View>
          <Text type="h2" weight="bold">
            Dashboard
          </Text>
        </View>
        {/* Tabs */}
        {tabs()}

        {/* Right button */}
        <View style={tw`max-w-[172px] w-full`}></View>
      </View>

      {/* Show Content */}
      <ScrollView horizontal
       showsHorizontalScrollIndicator 
       contentContainerStyle={tw`md:w-full  flex-col md:min-w-[50px]`}>{showContent()}</ScrollView>
    </View>
  );
};
