import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  Linking,
  StatusBar,
  TouchableOpacity,
  View,
} from "react-native";

import QRCode from "react-qr-code";

import { Button, Text } from "@/components/Commons";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { colors, tw } from "@/lib";
import { useDeviceContext } from "twrnc";
//@ts-ignore
import { AuthService, FirebaseService, TokenService } from "@/services";
import { RootState } from "@/store";
import { setAlert } from "@/store/alert.store";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { AuthResponse } from "@/types";
import { DOWNLOAD_URL, FRONTEND_LOGIN_PAGE } from "@env";
import Sidelogin from "../../../components/Layouts/Sidelogin";
import { BUTTON_SUCCESS_ONBOARD } from "@/services/firebase.service";

const Divider = () => <View style={{ margin: 5 }}></View>;

export const RegisterSuccess = () => {
  useDeviceContext(tw);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { email, password } = useAppSelector(
    (state: RootState) => state.register.admin
  );

  const handleOnBoard = async () => {
    const existCredentials = email && password;
    if (!existCredentials) return;

    try {
      FirebaseService.logFirebaseEvent(BUTTON_SUCCESS_ONBOARD);

      setIsLoading(true);
      const res = await AuthService.login({
        email,
        password,
      });

      const user: AuthResponse = res.data.result;

      TokenService.removeUser();

      location.replace(
        `${FRONTEND_LOGIN_PAGE}/check/${user.refreshToken.token}`
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          msg: "We couldn't complete the onboard, Please contact the support",
          type: "error",
          showAlert: true,
        })
      );
    }
  };

  return (
    <KeyboardAvoidingView behavior="padding" style={tw`flex-grow-1`}>
      <StatusBar
        // @ts-ignore
        style="light"
      />
      <View
        style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}]`}
      >
        <Sidelogin title="Welcome to Nucleus!" hideSubtitle />
        <AuthLayout noBack>
          <View
            style={[
              tw`px-5 md:px-0 flex flex-grow-1 justify-center`,
              { zIndex: 1 },
            ]}
          >
            <View style={tw`flex items-center max-w-sm`}>
              <Text type="h2" weight="bold" color={colors.black}>
                Your Workspace Is Created!
              </Text>
              <Divider />
              <Text color={colors.rebrandGray} style={tw`text-center`}>
                Now you're ready to login to your account and
              </Text>
              <Text color={colors.rebrandGray} style={tw`text-center`}>
                ensure Nucleus is on all your devices.
              </Text>
              <Divider />
              <Text color={colors.rebrandGray}>
                Download Nucleus to all your devices:
              </Text>
              <Divider />
              <QRCode
                value={DOWNLOAD_URL}
                style={tw`w-[183px] h-[183px] rounded-sm`}
              />
              <Divider />
              <TouchableOpacity onPress={() => Linking.openURL(DOWNLOAD_URL)}>
                <Text color={colors.blue} type="caption">
                  {DOWNLOAD_URL}
                </Text>
              </TouchableOpacity>
              <Divider />
              <Text color={colors.rebrandGray}>
                Thank you, and welcome to Nucleus!
              </Text>
              <Divider />
              <Button onPress={handleOnBoard} loading={isLoading}>
                Login and onboard your team
              </Button>
            </View>
          </View>
        </AuthLayout>
      </View>
    </KeyboardAvoidingView>
  );
};
