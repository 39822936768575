import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Circle, Path, Rect } from "react-native-svg";

export const Users = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="21" height="18" viewBox="0 0 21 18" fill="none">
      <Path
        d="M16.315 14.4588C16.0705 14.4588 15.8565 14.2793 15.8205 14.0303C15.7815 13.7568 15.971 13.5033 16.2445 13.4638C17.2725 13.3158 18.2575 12.9938 19.1725 12.5053C19.418 12.3758 19.719 12.4683 19.8495 12.7113C19.9795 12.9548 19.887 13.2583 19.6435 13.3883C18.6255 13.9308 17.5295 14.2893 16.387 14.4543C16.3625 14.4573 16.3385 14.4588 16.315 14.4588Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M11.0847 9.96999C10.9907 9.96999 10.8962 9.94349 10.8122 9.88899C10.5807 9.73799 10.5152 9.42849 10.6662 9.19699C11.3747 8.11049 12.5717 7.46149 13.8688 7.46149C14.1453 7.46149 14.3688 7.68499 14.3688 7.96149C14.3688 8.23799 14.1453 8.46149 13.8688 8.46149C12.9107 8.46149 12.0267 8.94049 11.5042 9.74299C11.4087 9.88999 11.2487 9.96999 11.0847 9.96999Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M14.9775 8.46134C13.205 8.46134 11.708 6.96434 11.708 5.19234C11.708 3.42034 13.205 1.92334 14.9775 1.92334C16.7495 1.92334 18.2465 3.42034 18.2465 5.19234C18.2465 6.96434 16.7495 8.46134 14.9775 8.46134ZM14.9775 2.92334C13.7475 2.92334 12.708 3.96234 12.708 5.19234C12.708 6.42234 13.7475 7.46134 14.9775 7.46134C16.2075 7.46134 17.2465 6.42234 17.2465 5.19234C17.2465 3.96234 16.2075 2.92334 14.9775 2.92334Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M19.408 11.7854C19.1315 11.7854 18.908 11.5619 18.908 11.2854C18.908 9.7289 17.6415 8.4624 16.085 8.4624C15.8085 8.4624 15.585 8.2389 15.585 7.9624C15.585 7.6859 15.8085 7.4624 16.085 7.4624C18.193 7.4624 19.908 9.1779 19.908 11.2854C19.908 11.5614 19.6845 11.7854 19.408 11.7854Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M16.0846 8.46149H13.8691C13.5926 8.46149 13.3691 8.23799 13.3691 7.96149C13.3691 7.68499 13.5926 7.46149 13.8691 7.46149H16.0846C16.3611 7.46149 16.5846 7.68499 16.5846 7.96149C16.5846 8.23799 16.3606 8.46149 16.0846 8.46149Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M19.4082 13.4467C19.1317 13.4467 18.9082 13.2232 18.9082 12.9467V11.2852C18.9082 11.0087 19.1317 10.7852 19.4082 10.7852C19.6847 10.7852 19.9082 11.0087 19.9082 11.2852V12.9467C19.9082 13.2232 19.6847 13.4467 19.4082 13.4467Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M1.5 13.8153C1.2235 13.8153 1 13.5918 1 13.3153C1 10.5963 3.212 8.38477 5.9305 8.38477C6.207 8.38477 6.4305 8.60827 6.4305 8.88477C6.4305 9.16127 6.207 9.38476 5.9305 9.38476C3.763 9.38476 2 11.1478 2 13.3153C2 13.5918 1.776 13.8153 1.5 13.8153Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M8.88468 9.38476H5.93067C5.65417 9.38476 5.43066 9.16127 5.43066 8.88477C5.43066 8.60827 5.65417 8.38477 5.93067 8.38477H8.88468C9.16118 8.38477 9.38468 8.60827 9.38468 8.88477C9.38468 9.16127 9.16118 9.38476 8.88468 9.38476Z"
        fill={color}
      />
      <Path
        d="M1.5 16.0314C1.2235 16.0314 1 15.8079 1 15.5314V13.3159C1 13.0394 1.2235 12.8159 1.5 12.8159C1.7765 12.8159 2 13.0394 2 13.3159V15.5314C2 15.8079 1.776 16.0314 1.5 16.0314Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M7.40833 9.385C5.13583 9.385 3.21582 7.465 3.21582 5.1925C3.21582 2.92 5.13583 1 7.40833 1C9.68084 1 11.6008 2.92 11.6008 5.1925C11.6008 7.465 9.68084 9.385 7.40833 9.385ZM7.40833 2C5.67783 2 4.21582 3.462 4.21582 5.1925C4.21582 6.923 5.67783 8.385 7.40833 8.385C9.13884 8.385 10.6008 6.923 10.6008 5.1925C10.6008 3.462 9.13884 2 7.40833 2Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M13.3153 13.8153C13.0388 13.8153 12.8153 13.5918 12.8153 13.3153C12.8153 11.1478 11.0523 9.38476 8.88477 9.38476C8.60827 9.38476 8.38477 9.16127 8.38477 8.88477C8.38477 8.60827 8.60827 8.38477 8.88477 8.38477C11.6038 8.38477 13.8153 10.5968 13.8153 13.3153C13.8153 13.5918 13.5918 13.8153 13.3153 13.8153Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M13.3154 16.0314C13.0389 16.0314 12.8154 15.8079 12.8154 15.5314V13.3159C12.8154 13.0394 13.0389 12.8159 13.3154 12.8159C13.5919 12.8159 13.8154 13.0394 13.8154 13.3159V15.5314C13.8154 15.8079 13.5919 16.0314 13.3154 16.0314Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <Path
        d="M7.40789 17.5113C5.29789 17.5113 3.18839 16.9983 1.26489 15.9728C1.02139 15.8423 0.928888 15.5398 1.05889 15.2958C1.18939 15.0528 1.49039 14.9603 1.73589 15.0898C5.28789 16.9843 9.52889 16.9843 13.0804 15.0898C13.3254 14.9603 13.6274 15.0528 13.7574 15.2958C13.8874 15.5393 13.7949 15.8423 13.5514 15.9728C11.6274 16.9978 9.51789 17.5113 7.40789 17.5113Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </Svg>
  );
};
