import React, { Fragment, useState } from "react";
import { TouchableOpacity, View } from "react-native";

import { Icon as Icona } from "react-native-elements";
import { Dropdown } from "react-bootstrap";
import { useToast } from "react-native-toast-notifications";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { AxiosError } from "axios";

import {
  tw,
  Modal,
  colors,
  showFriendlyErrorFromHTMLCode,
  capitalize,
} from "@/lib";
import { Text, Button, InfinityScrollList } from "@/components/Commons";
import { SuperAdminUser, WorkspaceId } from "@/types";
import { SuperAdminService, UserService } from "@/services";
import { useAlert } from "@/hooks";
import { UserStatus } from "@/types";

const limit: number = 20;

export const SuperAdminPartners = () => {
  //initialize navigate
  const toast = useToast();

  const { showSwal } = useAlert();

  //Data to Iterate
  const [data, setData] = useState<SuperAdminUser[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const filterUserAndAdmin = (users: SuperAdminUser[]) => {
    return users.filter((user) => user.rol === "admin" || user.rol === "user");
  };

  const fetchItems = async (
    isScroll: boolean,
    currentPage: number,
    keyToSearch?: string
  ) => {
    try {
      if (!keyToSearch) {
        const { result } = (
          await SuperAdminService.getAllUsersWithPagination(
            currentPage * limit,
            limit
          )
        ).data;
        const { totalCount } = result;
        const list = filterUserAndAdmin(result.list);
        if (list.length > 0) {
          if (isScroll) setData([...data, ...list]);
          else setData(list);
          setTotalUsers(totalCount);
        }
      } else {
        const { result } = (
          await UserService.searchUser(currentPage * limit, limit, keyToSearch)
        ).data;
        const { totalCount } = result;
        const list = filterUserAndAdmin(result.list);
        if (list.length > 0) {
          if (isScroll) setData((prev) => [...prev, ...list]);
          else setData(list);
          setTotalUsers(totalCount);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      ErrorMessage(error);
    }
  };

  const showToast = (msg: string) => {
    toast.show(msg, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <FontAwesome name="check" size={18} color={colors.white} />,
    });
  };

  const ErrorMessage = (err: any) => {
    //Show error
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    showSwal({ msg, type: "error" });
  };

  //Input to validate dropdown

  const deleteUser = async (id: number) => {
    try {
      await UserService.deleteUser(id);
      fetchItems(true, 0);
      Modal.dismiss();
      showToast("User deleted successfully");
    } catch (error) {
      console.log(error);
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    }
  };

  const showModalForRemove = (id: number) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove User?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to remove this User? </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button
              style={tw`flex-1`}
              onPress={() => {
                return deleteUser(id);
              }}
            >
              <Text weight="semiBold">Remove</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const activateUser = async (user: SuperAdminUser) => {
    try {
      const { id, activationStatus } = user;

      const resp = await UserService.toggleUserActivation(
        id.toString(),
        activationStatus && activationStatus === UserStatus.ACTIVE
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE
      );

      setData((prev) => {
        return prev.map((user) => {
          if (user.id === resp.data.result.id) {
            return { ...user, ...resp.data.result };
          }
          return user;
        });
      });

      showToast(
        `User ${
          resp.data.result.activationStatus === UserStatus.INACTIVE
            ? "Deactivated"
            : "Activated"
        } succesfully`
      );
    } catch (err) {
      console.log(err);
    }
  };

  //@ts-ignore
  const renderItemLog = ({ item }: { item: SuperAdminUser }) => {
    const textColorByStatus: string =
      item.status === "suspended" ? colors.gray : colors.dark;

    return (
      <Fragment key={`${item.id}-${item.ownPhoneNumber}`}>
        {Number(item.id) !== 97 && (
          <View
            style={[
              tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`,
              { zIndex: "auto" },
            ]}
            key={item.id}
          >
            <View style={tw`flex flex-1 `}>
              <Text weight="semiBold" color={textColorByStatus}>
                {item.firstName + item.lastName}
              </Text>
            </View>
            <View style={tw`flex flex-1 px-2`}>
              <Text weight="semiBold" color={textColorByStatus}>
                {item.email}
              </Text>
            </View>
            <View style={tw`flex flex-1`}>
              <Text
                weight="semiBold"
                color={item.status === "suspended" ? colors.red : colors.blue}
                style={tw`pl-1`}
              >
                {capitalize(item.status || "")}
              </Text>
            </View>
            <View style={tw`flex flex-1 w-[4/12]`}>
              <Text
                weight="semiBold"
                color={textColorByStatus}
                style={tw`pl-4`}
              >
                {item.rol}
              </Text>
            </View>
            <View style={tw`flex flex-1`}>
              <Text
                weight="semiBold"
                color={textColorByStatus}
                style={tw`pl-6`}
              >
                {item.extension}
              </Text>
            </View>

            <View
              style={[tw`flex relative w-auto ml-auto`, { zIndex: "auto" }]}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  style={{ lineHeight: 0, ...tw`no-underline` }}
                  id="dropdown-basic"
                  bsPrefix="btn"
                >
                  <Icona
                    color="#C8C8C8"
                    name="more-vert"
                    size={20}
                    tvParallaxProperties={undefined}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ ...tw`rounded-3`, ...{ zIndex: 10 } }}
                  flip={false}
                >
                  <Dropdown.Item
                    as="button"
                    style={tw` text-btn-menu-item font-semibold`}
                    onClick={() => {
                      return activateUser(item);
                    }}
                  >
                    {item.activationStatus == UserStatus.ACTIVE
                      ? "Deactivate"
                      : "Activate"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={tw`text-btn-menu-item font-semibold text-red-500`}
                    onClick={() => {
                      showModalForRemove(item.id);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </View>
          </View>
        )}
      </Fragment>
    );
  };

  //Display View
  return (
    <InfinityScrollList
      title="Users List"
      data={data}
      Component={renderItemLog}
      fetchData={fetchItems}
      dataLimit={totalUsers}
    >
      <View style={tw`flex-row px-4 mb-4 `}>
        <View style={tw`flex flex-1`}>
          <Text color={colors.gray} weight="semiBold">
            Full Name{" "}
          </Text>
        </View>

        <View style={tw`flex flex-1`}>
          <Text color={colors.gray} weight="semiBold">
            Email{" "}
          </Text>
        </View>

        <View style={tw`flex flex-1`}>
          <Text color={colors.gray} weight="semiBold">
            Status{" "}
          </Text>
        </View>

        <View style={tw`flex flex-1`}>
          <Text color={colors.gray} weight="semiBold">
            Role{" "}
          </Text>
        </View>

        <View style={tw`flex flex-1 lg:mr-8`}>
          <Text color={colors.gray} weight="semiBold">
            Extension{" "}
          </Text>
        </View>
      </View>
    </InfinityScrollList>
  );
};
