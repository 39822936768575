import React, { useState } from "react";
import {
  StyleSheet,
  View,
  ImageBackground,
  Image,
  TouchableOpacity,
} from "react-native";

import tw from "@/lib/tailwind";
import { useDeviceContext } from "twrnc";
import { Text } from "@/components/Commons";
import { Check, Nucleus } from "@/components/Icons";
import { colors } from "@/lib";
import Sidelogin from "./Sidelogin";

export const SideRegistration = (props: { step: number }) => {
  useDeviceContext(tw);

  const { step = 0 } = props;

  const weights = [0, 1, 2, 3, 4, 5, 6].map((e) =>
    e == step ? "bold" : e < step ? "regular" : "light"
  );

  return (
    <Sidelogin
      title="You're just a few clicks"
      subtitle="away from using Nucleus"
      alignContent="items-start"
    >
      <View style={tw`flex w-full lg:max-w-full`}>
        <View style={tw`text-justify text-transparent py-2 `}>
          <Text
            type="bigger"
            color={colors.white}
            style={tw`mt-2 -ml-3 ${step === 2 ? `font-bold` : ``}`}
          >
            <View style={tw.style([step > 2 ? "ml-5 w-5" : "w-5"])}>
              {step > 2 && <Check />}
            </View>
            Your Account Details
          </Text>

          <Text
            type="bigger"
            color={colors.white}
            style={tw`mt-2 -ml-3 ${step === 3 ? `font-bold` : ``} `}
          >
            <View style={tw.style([step > 3 ? "ml-5 w-5" : "w-5"])}>
              {step > 3 && <Check />}
            </View>
            Your Company Address
          </Text>

          <Text
            type="bigger"
            color={colors.white}
            style={tw`mt-2 -ml-3 ${step === 4 ? `font-bold` : ``} `}
          >
            <View style={tw.style([step > 4 ? "ml-5 w-5" : "w-5"])}>
              {step > 4 && <Check />}
            </View>
            Verify Your Phone Number
          </Text>

          <Text
            type="bigger"
            color={colors.white}
            style={tw`mt-2 -ml-3 ${step === 5 ? `font-bold` : ``} `}
          >
            <View style={tw.style([step > 5 ? "ml-5 w-5" : "w-5"])}>
              {step > 5 && <Check />}
            </View>
            Choose Your Phone Numbers
          </Text>
        </View>
      </View>
    </Sidelogin>
  );
};
