import React, { FC, ReactNode } from "react";
import { View } from "react-native";

import { useDeviceContext } from "twrnc";

import { Text, InfinityScroll, Loading } from "@/components/Commons";
import { colors, tw } from "@/lib";
import { SearchBar } from "@/components/Inputs";
import { useInfinityScroll } from "@/hooks/useInfinityScroll";

import type { InfintyScrollType } from "./InfinityScroll";

type ChildOrChildren = ReactNode | ReactNode[];

interface Props<T> {
  data: T[];
  Component: FC<InfintyScrollType<T>>;
  fetchData: (
    isScroll: boolean,
    currentPage: number,
    keyToSearch?: string
  ) => Promise<void>;
  children: ChildOrChildren;
  title: string;
  dataLimit: number;
  leftHeaderAction?: ChildOrChildren;
}

const EmptyList = () => {
  return (
    <View
      style={tw`flex-row bg-white px-4 py-4 mb-1 rounded-3 items-center mt-2 `}
    >
      <Text style={tw`w-full text-center`} color={colors.red} weight="semiBold">
        There aren't items to show
      </Text>
    </View>
  );
};

export const InfinityScrollList = <T extends unknown>({
  data,
  Component,
  fetchData,
  children,
  title,
  dataLimit,
  leftHeaderAction,
}: Props<T>) => {
  useDeviceContext(tw);

  const {
    isLoading,
    search,
    changeSearchValue,
    fetchMoreData,
    isLoadingMore,
    focus,
  } = useInfinityScroll({ fetchData, data, dataLimit });

  const isListEmpty = data.length === 0;
  const showList = !isLoading && !isListEmpty;

  const SearchSection = () => (
    <View style={tw`flex flex-row w-[200px]`}>
      <SearchBar
        value={search}
        onelementChange={(query) => {
          changeSearchValue(query);
        }}
        isFocus={focus}
      />
    </View>
  );

  return (
    <View style={[tw`flex-grow-1 h-full mx-8 py-7 `]}>
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text type="h2">{title}</Text>
        </View>
        {!leftHeaderAction && <SearchSection />}
      </View>

      {leftHeaderAction && (
        <View style={tw`flex-row justify-between mt-8`}>
          {leftHeaderAction}
          <SearchSection />
        </View>
      )}

      {children}

      {isLoading && <Loading />}

      {!isLoading && isListEmpty && <EmptyList />}

      {showList && (
        <InfinityScroll
          data={data}
          style={tw`pb-10`}
          contentContainerStyle={tw`h-100`}
          Component={Component}
          getMoreData={fetchMoreData}
          showsVerticalScrollIndicator
          showSpinner={isLoadingMore}
        />
      )}
    </View>
  );
};
