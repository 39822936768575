import { StyleSheet, View } from 'react-native'
import React from 'react'
import { colors, tw } from '@/lib'
import { Text } from '@/components/Commons'

const ThirdTab = () => {
  interface IDiscount {
    _id: string;
    title: string;
    percent: string;
    discount: string;
    price: string;
  }
  const discountsPerPlan: IDiscount[] = [
    {
      _id: "1",
      title: "Proin ullamcorper",
      percent: "20%",
      discount: "10$",
      price: "8$",
    },
    {
      _id: "2",
      title: "Dis tortor et",
      percent: "20%",
      discount: "25$",
      price: "20$",
    },
    {
      _id: "3",
      title: "Dis tortor et",
      percent: "20%",
      discount: "12$",
      price: "10$",
    },
  ];
  return (
    <View style={tw`w-[680px]`}>
            <View>
              {discountsPerPlan.map((item) => (
                <View
                  style={{
                    backgroundColor: colors.blueLightest,
                    ...tw`flex flex-row justify-between py-5 px-8 mb-2 rounded-3 items-center`,
                  }}
                  key={item._id}
                >
                  <View style={tw`flex flex-row`}>
                    <Text type="h3" weight="bold" color={colors.blue}>
                      {item.title}
                    </Text>
                    <Text
                      weight="semiBold"
                      type="h3"
                      color={colors.gray}
                      style={tw`ml-4`}
                    >
                      {item.percent}
                    </Text>
                  </View>
                  <View style={tw`flex flex-row`}>
                    <Text
                      type="h2"
                      weight="semiBold"
                      color={colors.gray}
                      style={tw`mr-4 line-through`}
                    >
                      {item.discount}
                    </Text>
                    <Text type="h2" weight="extraBold" color={colors.dark}>
                      {item.price}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
  )
}

export default ThirdTab

const styles = StyleSheet.create({})