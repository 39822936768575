import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { name: string } = {
  name: "",
};

const workspaceSlice = createSlice({
  name: "workspaceStore",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<{ name: string }>) => {
      return {
        ...state,
        name: action.payload.name,
      };
    },
    clearName: (state) => {
      return { ...initialState };
    },
  },
});

export default workspaceSlice.reducer;
export const { setName, clearName } = workspaceSlice.actions;
