import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State { 
  zindex:number;
}

const initialState: State = {
 zindex:0
};

const zindexSlice = createSlice({
  name: "zindexSlices",
  initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<State>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearInfo: (state) => {
      return initialState;
    },
  },
});

export default zindexSlice.reducer;
export const { setInfo, clearInfo } = zindexSlice.actions;