import {tw} from "@/lib";

export function getTabStyle(pred: () => boolean) {
    const isActive = pred();
    return isActive
        ? {
            btn: tw`bg-white rounded-3`,
            text: tw`text-primary`,
        }
        : {
            text: tw`text-dark-gray`,
        };
}
