import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path, Circle } from "react-native-svg";

export const Search = (props: { color?: string, styles?: StyleProp<ViewStyle> }) => {
  const { color = "white", styles } = props;
  return (
    <Svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      style={styles}
    >
      <Circle
        cx="8"
        cy="8"
        r="7"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13 13L18 18"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </Svg>
  );
};
