import React from "react";
import { useDeviceContext } from "twrnc";
import tw from "@/lib/tailwind";

import { Text } from "react-native";
import { TextProps } from "react-native-elements";

import {
  useFonts,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from "@expo-google-fonts/roboto";

const fontFromWeight = {
  bold: {
    name: "Roboto_700Bold",
    font: Roboto_700Bold,
  },
  extraBold: {
    name: "Roboto_900Black",
    font: Roboto_900Black,
  },
  semiBold: {
    name: "Roboto_700Bold",
    font: Roboto_700Bold,
  },
  miniBold: {
    name: "Roboto_500Medium",
    font: Roboto_500Medium,
  },
  fontbold: {
    name: "Roboto_700Bold",
    font: Roboto_700Bold,
  },
  regular: {
    name: "Roboto_400Regular",
    font: Roboto_400Regular,
  },
  light: {
    name: "Roboto_300Light",
    font: Roboto_300Light,
  },
  minibold: {
    name: "Roboto_700Bold",
    font: Roboto_700Bold,
  },
  tinyBold: {
    name: "Roboto_400Regular",
    font: Roboto_400Regular,
  },
  openLogin: {
    name: "Roboto_300Light",
    font: Roboto_300Light,
  },
  openBold: {
    name: "Roboto_700Bold",
    font: Roboto_700Bold,
  },
};

export const CustomText = (
  props: TextProps & {
    type?:
      | "h1"
      | "h2"
      | "h3"
      | "regular"
      | "bigger"
      | "caption"
      | "openLogin"
      | "h4"
      | "h5"
      | "h6"
      | "labels"
      | "forgotLogin";
    weight?:
      | "bold"
      | "extraBold"
      | "semiBold"
      | "regular"
      | "light"
      | "minibold"
      | "openLogin"
      | "fontbold"
      | "tinyBold"
      | "openBold";
    color?: string;
  }
) => {
  useDeviceContext(tw);

  props.style;

  let [fontsLoaded] = useFonts({
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    Roboto_900Black,
  });

  /**
   * type h1, h2, regular, bigger, caption
   * weight bold, extraBold, semiBold, regular, light
   */
  const { type = "regular", weight = "regular", color } = props;

  let font = { weight, size: 18, lineHeight: 1.5, color };

  switch (type) {
    case "h1":
      font.size = 48;
      font.weight = weight == "regular" ? "bold" : weight;
      font.lineHeight = 1.2;
      break;
    case "h2":
      font.size = 24;
      font.weight = weight == "regular" ? "bold" : weight;
      font.lineHeight = 1.2;
      break;
    case "h3":
      font.size = 18;
      font.weight = weight == "regular" ? "bold" : weight;
      font.lineHeight = 1.2;
      break;
    case "h4":
      font.size = 37;
      font.weight = weight == "fontbold" ? "bold" : weight;
      font.lineHeight = 1.2;
      break;
    case "labels":
      font.size = 20;
      break;
    case "bigger":
      font.size = 18;
      break;

    case "forgotLogin":
      font.size = 15;
      font.weight = weight == "regular" ? "openLogin" : weight;
      font.lineHeight = 1.2;
      break;
    case "openLogin":
      font.size = 25;
      font.weight = weight == "regular" ? "minibold" : weight;
      font.lineHeight = 1.4;
      break;

    case "caption":
      font.size = 12;
      break;

    case "h5":
      font.size = 34;
      font.weight = weight == "regular" ? "bold" : weight;

      break;
    case "h6":
      font.size = 14;

      font.lineHeight = 1.4;

      break;
    default:
      font.size = 14;
      break;
  }

  return (
    <Text
      style={[
        {
          fontFamily: fontFromWeight[font.weight].name,
          fontSize: font.size,
          lineHeight: font.size * font.lineHeight,
          color: font.color,
        },
        tw`text-left`,
        props.style,
      ]}
      ellipsizeMode={props.ellipsizeMode}
      numberOfLines={props.numberOfLines}
    >
      {props.children}
    </Text>
  );
};
