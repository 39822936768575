import { useEffect, useRef } from "react";



//@ts-ignore
export const  useOnClickOutside =(ref ,handler)=> {
  useEffect(
    () => {
      const listener = (event: { target: any; }) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    
    [ref, handler]
  );
}
