import { ScrollView, View, TouchableOpacity } from 'react-native';
import React from "react";
import { Card, Icon } from "react-native-elements";
import { useDeviceContext } from "twrnc";

import { tw, colors } from "@/lib";
import { Text } from "@/components/Commons";
import { PlanResponse } from "@/types";
import { Pencil } from "@/components/Icons";
import { Dropdown } from 'react-bootstrap';

//Plans Array
const data: PlanResponse[] = [
  {
    id: "6164a44e4d9271674a01a193",
    name: "Pro",
    price_per_unit: "$8",
    unit: "per user",
    updated_at: "2022-04-18T16:59:50.562000Z",
    is_enabled: true,
    product_name: "newt_cloud",
    description: [
      "Please select desire number of users"
    ],
    display_order: 10,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 1,
      max: 10,
    },
    term: "month",
  },
  {
    id: "723b4751b6fa865e266585f2",
    name: "Enterprise",
    product_name: "newt_cloud",
    is_enabled: true,
    updated_at: "2022-04-14T18:20:09.969000Z",
    description: [
      "Enhanced admin controls and security settings for large organizations."
    ],
    display_order: 100,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 11,
      max: "unlimited",
    },
    price_per_unit: "$12",
    term: "month",
    unit: "per user",
  },
  {
    id: "623b4751b6fa865e266585f2",
    name: "Enterprise",
    product_name: "newt_cloud",
    is_enabled: false,
    updated_at: "2022-04-14T18:20:09.969000Z",
    description: [
      "Please select desire number of users"
    ],
    display_order: 100,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 11,
      max: "unlimited",
    },
    price_per_unit: "$3",
    term: "month",
    unit: "per user",
  },
  {
    id: "624b4751b6fa865e266585f2",
    name: "Enterprise",
    product_name: "newt_cloud",
    is_enabled: false,
    updated_at: "2022-04-14T18:20:09.969000Z",
    description: [
      "A lower price for those users, who don’t need access to team inbox."
    ],
    display_order: 100,
    enabled_features: "existing defined features at this time",
    license_range: {
      min: 11,
      max: "unlimited",
    },
    price_per_unit: "$8",
    term: "month",
    unit: "per user",
  },
];

//Props Interface
interface Props {
  showModalForEditAddOn: (id?: string) => void;
  showModalForRemoveAddOn?:(id?:string)=>void;
}

//Component
export const ProductAddOn = ({
  showModalForEditAddOn,
  showModalForRemoveAddOn,
}: Props) => {
  //Use tailwind
  useDeviceContext(tw);

  //Show View
  return (
    <View style={tw`flex flex-row w-full flex-wrap mt-5 ml-4`}>
      {data.map((addOn) => (
        <Card
          key={addOn.id}
          containerStyle={[
            tw`flex-col bg-white rounded-3 lg:w-[600px] px-6 z-0 relative`,
          ]}
        >
          <View style={tw`absolute top-[-2] right-[-4] z-10`}>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{ lineHeight: 0, ...tw`no-underline` }}
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <Icon name="more-vert" size={15} style={tw`text-gray-500`} tvParallaxProperties={undefined} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={tw`rounded-3`} align="end">
                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                  onClick={() => showModalForEditAddOn(addOn.id)}
                >
                  Edit
                </Dropdown.Item>

                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                  onClick={() => showModalForRemoveAddOn && showModalForRemoveAddOn(addOn.id)}
                >
                  Remove
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
          </View>
          <View style={tw`flex flex-row items-center mt-3`}>
            <Text style={tw` font-bold text-blue-800`} type="h3" weight="bold">
              {addOn.name}
            </Text>
            {!addOn.is_enabled && (
              <Text style={tw`ml-1 text-sm text-red-500`}>Disabled</Text>
            )}
          </View>
          <View style={tw`flex flex-row my-2`}>
            <Text type="h1" weight="extraBold">
              {addOn.price_per_unit}
            </Text>

            <View style={tw`flex justify-between my-2 ml-2`}>
              {addOn.term && (
                <Text style={tw`text-gray-400`} weight="semiBold">
                  /{addOn.term}
                </Text>
              )}
              {addOn.unit && (
                <Text style={tw`text-gray-400`} weight="semiBold">
                  /{addOn.unit}
                </Text>
              )}
            </View>
          </View>
          <ScrollView>
            <Text style={tw`text-left max-w-[266px] text-gray-500`}>{addOn.description} </Text>
          </ScrollView>
        </Card>
      ))}
    </View>
  );
};
