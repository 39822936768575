import { View, Text } from "react-native";
import React from "react";
import Svg, { Path, Circle } from "react-native-svg";

export const PaymentWallet = () => {
  return (
    <Svg
      width="109"
      height="108"
      viewBox="0 0 109 108"
      fill="none"
    >
      <Path
        d="M95.375 25.6453H10.2188V22.2138L85.1562 16.1745V22.2138H95.375V11.9195C95.375 4.37031 89.3051 -0.927844 81.8931 0.135904L13.4888 9.97729C6.06994 11.0479 0 18.0961 0 25.6453V94.2742C0 97.9145 1.43549 101.406 3.99067 103.98C6.54585 106.554 10.0114 108 13.625 108H95.375C98.9886 108 102.454 106.554 105.009 103.98C107.565 101.406 109 97.9145 109 94.2742V39.3711C109 35.7308 107.565 32.2396 105.009 29.6655C102.454 27.0914 98.9886 25.6453 95.375 25.6453ZM85.1562 73.7267C83.8139 73.7263 82.4847 73.4595 81.2447 72.9415C80.0046 72.4236 78.878 71.6647 77.9291 70.7081C76.9802 69.7516 76.2276 68.6161 75.7143 67.3666C75.201 66.117 74.937 64.7778 74.9375 63.4255C74.938 62.0732 75.2028 60.7342 75.7169 59.485C76.231 58.2358 76.9844 57.1008 77.9339 56.1449C78.8834 55.189 80.0106 54.4308 81.251 53.9137C82.4913 53.3966 83.8207 53.1307 85.1631 53.1312C87.8742 53.1321 90.4738 54.2179 92.3902 56.1497C94.3066 58.0816 95.3827 60.7012 95.3818 63.4324C95.3809 66.1635 94.3031 68.7824 92.3854 70.713C90.4677 72.6435 87.8673 73.7276 85.1562 73.7267Z"
        fill="#DFDFE6"
      />
    </Svg>
  );
};
