import React from "react";
import Icon from "react-native-vector-icons/MaterialIcons";
import { View } from "react-native";
import { useDeviceContext } from "twrnc";
import { Dropdown } from "react-bootstrap";
import { Text } from "@/components/Commons";
import { colors, tw } from "@/lib";
import { SupportListDialog } from "@/components/Icons";
import { Avatar } from "react-native-elements";

interface Info {
  id: number;
  name: string;
  message: string;
  amount?: number;
}

interface Props {
  data: Info[];
}

export const DashboardList = ({ data }: Props) => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View>
      {/* Tickes */}
      {data.map((response: Info) => {
        return (
          <View
            style={tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`}
            key={response.id}
          >
            {/* Avatar Image */}
            <View
              style={tw.style(
                "flex flex-row items-center",
                response.amount != null ? "w-40" : "w-50"
              )}
            >
              <Avatar rounded size={44} containerStyle={tw`bg-gray-400`} />
              <Text color={colors.gray} style={tw`ml-3`}>
                {response.name}
              </Text>
            </View>
            {/* Icon Messages */}
            {response.amount != null && <SupportListDialog text={response.amount} />}
            {/* Message */}
            <View style={tw.style(response.amount != null ? "flex-1 w-full ml-2 max-w-4/5":"flex-1 w-full")}>
              <Text style={tw`font-bold text-black`} ellipsizeMode={'tail'} numberOfLines={1}>
                {response.message}
              </Text>
            </View>
            {/* Dropdown */}
            <View style={[tw`flex w-auto ml-auto  `, { zIndex: "auto" }]}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  style={{ lineHeight: 0, ...tw`no-underline` }}
                  id="dropdown-basic"
                  bsPrefix="btn"
                >
                  <Icon color={colors.gray} name="more-vert" size={20} />
                </Dropdown.Toggle>
              </Dropdown>
            </View>
          </View>
        );
      })}
    </View>
  );
};
