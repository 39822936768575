import { ScrollView, View } from "react-native";
import React from "react";
import { Card } from "react-native-elements";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useDeviceContext } from "twrnc";
import { Dropdown } from "react-bootstrap";
import Svg, { Circle } from "react-native-svg";

import { tw } from "@/lib";
import { Text } from "@/components/Commons";
import { PlanResponse } from "@/types";

//Props Interface
interface Props {
  data: PlanResponse[];
  handleEditSuscription: (id?: string) => void;
  showModalForRemove: (id: string) => void;
}

//Component
export const ProductSuscription = ({
  data,
  handleEditSuscription,
  showModalForRemove,
}: Props) => {
  //Use tailwind
  useDeviceContext(tw);

  //Show View
  return (
    <View style={tw`flex flex-row flex-wrap mt-5`}>
      {data.map((plan) => (
        <Card
          key={plan.id}
          containerStyle={[
            tw`flex-col bg-white rounded-3 w-[320px] px-6 z-0 relative`,
          ]}
        >
          <View style={tw`absolute top-[-2] right-[-4] z-10`}>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{ lineHeight: 0, ...tw`no-underline` }}
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <Icon name="more-vert" size={15} style={tw`text-gray-500`} />
              </Dropdown.Toggle>
              <Dropdown.Menu style={tw`rounded-3`} align="end">
                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                  onClick={() => handleEditSuscription(plan.id)}
                >
                  Edit
                </Dropdown.Item>

                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                >
                  {plan.is_enabled ? "Disable" : "Enable"}
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={tw`text-red-500 text-btn-menu-item font-semibold`}
                  onClick={() => showModalForRemove(plan.id)}
                >
                  Remove
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </View>
          <View style={tw`flex flex-row items-center mt-3`}>
            <Text style={tw` font-bold text-blue-800`} type="h3" weight="bold">
              {plan.name}
            </Text>
            {!plan.is_enabled && (
              <Text style={tw`ml-1 text-sm text-red-500`}>Disabled</Text>
            )}
          </View>
          <View style={tw`flex flex-row my-2`}>
            <Text type="h1" weight="extraBold">
              {plan.price_per_unit}
            </Text>

            <View style={tw`flex justify-between my-2 ml-2`}>
              <Text style={tw`text-gray-400`} weight="semiBold">
                {plan.term && `/${plan.term}`}
              </Text>
              <Text style={tw`text-gray-400`} weight="semiBold">
                {plan.unit && `/${plan.unit}`}
              </Text>
            </View>
          </View>
          <ScrollView>
            <Text style={tw`text-left`}>{plan.description} </Text>
          </ScrollView>
          <View style={tw`mt-3 mb-6`}>
            {plan.description?.slice(1).map((feature, index) => (
              <Text
                key={index}
                type="caption"
                style={tw`items-center justify-start flex-row`}
              >
                <Svg
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                  style={tw`mr-2`}
                >
                  <Circle cx="2" cy="2" r="2" fill="#1F1D1D" />
                </Svg>
                {feature}
              </Text>
            ))}
          </View>
        </Card>
      ))}
    </View>
  );
};
