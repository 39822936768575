import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import registerSlice from "@/store/register.store";
import alertSlice from "@/store/alert.store";
import workspaceSlice from "@/store/workspace.store";
import userSlice from "./user.store";
import bannerSlice from "./banner.store";
import accessSlice from "./accessRequest.store";
import notificationsSlice from "./notifications.store";
import zindexSlice from "./style.store";
/**
 * Register all store module.
 */
export const store = configureStore({
  reducer: {
    register: registerSlice,
    alert: alertSlice,
    workspace: workspaceSlice,
    user: userSlice,
    banner: bannerSlice,
    accessRequest: accessSlice,
    notifications: notificationsSlice,
    zindex:zindexSlice
  },
});

//Export Types
export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
