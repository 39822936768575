import { Modal } from "@/lib";
import { tw, useFieldControl } from "@/lib";
import { WorkspaceService } from "@/services";
import React, { useEffect } from "react";
import { View } from "react-native";
import { Button, Text } from "../Commons";

import { DropFileInput } from "../Inputs";

interface ChangeWSImageProp {
  image?: File | string;
  onChange?: (file?: File) => void;
}

export const ChangeWSImage = (props: ChangeWSImageProp) => {
  const image = useFieldControl<File | string | undefined>(props.image);

  useEffect(() => {
    image.handleInputValue(props.image);
  }, [props.image]);

  const onChange = async () => {
    props.onChange && props.onChange(image.value as File);
    Modal.dismiss();
  };

  return (
    <View>
      <Text type="h2">Upload Workspace Photo</Text>
      <DropFileInput
        style={tw`py-6`}
        value={image.value}
        onChange={image.handleInputValue}
      />
      <View style={tw`w-full flex-row justify-end`}>
        <View style={tw`w-[158px]`}>
          <Button disabled={image.invalid} onPress={onChange}>
            Save Changes
          </Button>
        </View>
      </View>
    </View>
  );
};
