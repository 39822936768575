import {
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import { colors, number, tw, useFieldControl } from "@/lib";
import { Text } from "../Commons";
import { FancyDropdown, TextInput } from "../Inputs";
import { Calendar } from "../Icons/Calendar/Calendar";
import { Clock } from "../Icons/Clock/Clock";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { RootState, store } from "@/store";
import { setAccess, setDurations } from "@/store/accessRequest.store";
import { DurationType } from "@/types";
import dayjs from "dayjs";
import { setInfo,clearInfo } from "@/store/style.store";
import { TouchableHighlight } from "react-native-gesture-handler";

interface Props {
  type?: string;
  onModalChange: () => void;
  isModalActive?: boolean;
}

const DurationPermition = ({ onModalChange, type, isModalActive }: Props) => {
  const dispatch = useAppDispatch();

  

  const {
    duration: durationAccess,
    durationType,
    from,
    from_global,
    to_global,
    durationGlobal,
    durationGlobalType,
    to,
  } = useAppSelector((state: RootState) => state.accessRequest);
  const {zindex} =useAppSelector((state:RootState)=>state.zindex)

  useEffect(() => {
    dispatch(setAccess({ type: type && type }));
  }, [type]);
 
  
  const [statehours, setStateHours] = useState<boolean>(false);
  const globalForm = {
    duration: useFieldControl<string>(durationAccess?.toString() || "", [
      number,
    ]),
    temporaryAccess: useFieldControl<string>(durationType || "days"),
    durationGlobalAccess: useFieldControl<string>(
      durationGlobal?.toString() || "",
      [number]
    ),
    temporaryAccessGlobal: useFieldControl<string>(
      durationGlobalType || "days"
    ),
  };

  const handleShowClockCalendar = (change: boolean) => {
    setStateHours(change);
  };

  

  return (
   
      <View style={tw`flex w-full flex-col lg:flex-row items-center `}>
         <Text
          color={colors.gray}
          style={tw`flex max-w-[170px] `}
        >
          Duration
        </Text>
        {/* // Aca va el primer recuadro        */}

        
        {!statehours ?  <>
          <View style={tw`flex w-full lg:max-w-[145px] h-[40px] mb-4 lg:mb-0 `}>
          <TouchableHighlight underlayColor={'none'}
             
              onPress={() => {
                handleShowClockCalendar(false);
                onModalChange();
              }}
            >
              <TextInput
            value={type === "local"
              ? from
                ? dayjs(from).format("MMM D, YYYY ")
                : ""
              : from_global
                ? dayjs(from_global).format("MMM D, YYYY ")
                : ""}
            placeholder="From:" />
            </TouchableHighlight>
        </View>
        <View style={tw`flex w-full lg:max-w-[145px] h-[40px]  `}>
        <TouchableHighlight underlayColor={'none'}
              
              onPress={() => {
                handleShowClockCalendar(false);
                onModalChange();
              }}
            ><TextInput
            value={type === "local"
              ? to
                ? dayjs(to).format("MMM D, YYYY ")
                : ""
              : to_global
                ? dayjs(to_global).format("MMM D, YYYY ")
                : ""}
            placeholder="To:" />
            </TouchableHighlight>
        </View>
        </> : <>
        
        <View
            style={tw`flex w-full lg:w-[145px] h-[40px]`}
          >
            <TextInput
              value={globalForm.duration.value}
              errorMessage={globalForm.duration.error}
              placeholder="12"
              type="number"
              onChangeText={(val) => {
                if (type === "local") {
                  globalForm.duration.handleInputValue(val);
                  dispatch(setDurations({ duration: Number(val) }));
                } else {
                  globalForm.durationGlobalAccess.handleInputValue(val);
                  dispatch(setAccess({ durationGlobal: +val }));
                }
              }}
            />
          </View>
          <View
            style={[
              tw` flex w-full flex-row w-[145px]  lg:max-w-[180px] h-[40px]    lg:-mt-11 mb-4 lg:mb-0 `             
            ,{zIndex:zindex}]}
          onTouchStart={()=>dispatch(clearInfo())}>
            <FancyDropdown
            
              autoCorrect={false}
              
              small={true}
              
              label=" "
              placeholder="Hours"
              value={globalForm.temporaryAccess.value}
              onSelect={(newVal) => {
                if (type === "local") {
                  globalForm.temporaryAccess.handleInputValue(newVal);
                  dispatch(
                    setDurations({ durationType: newVal as DurationType })
                  );
                } else {
                  globalForm.temporaryAccessGlobal.handleInputValue(newVal);
                  dispatch(
                    setAccess({
                      durationGlobalType: newVal as DurationType,
                    })
                 
                  );
                  
                }
              }}
              data={[
                { label: "Minutes", value: "minutes" },
                { label: "Hours", value: "hours" },
                { label: "Days", value: "days" },
              ]}
            />
          </View>


      
         
            </>}
          
        
       
        

        {/* Icons [Calendar, Clock] */}
        <View style={tw`flex  flex-row mt-4 lg:mt-0  `}>
          <View style={[tw`lg:top-[2px]`]}>
            <TouchableOpacity
              style={tw`flex flex-row pb-[45px] lg:pb-0 items-center justify-around bg-white`}
              onPress={() => {
                
                handleShowClockCalendar(false);
                onModalChange();
                if(zindex==0)
                dispatch(setInfo({zindex:0}))
                else dispatch(setInfo({zindex:456}))
                
                
              }}
            >
              <Calendar color={statehours ? colors.gray : colors.blue} />
            </TouchableOpacity>
          </View>
          <View style={tw`flex w-full lg:max-w-[50px] top-[-4px] lg:top-0 pl-3`}>
            <TouchableOpacity
              style={tw`flex flex-row `}
              onPress={() => {                
                handleShowClockCalendar(true);
                isModalActive && onModalChange ? onModalChange() : "";
                if(zindex==0)
                dispatch(setInfo({zindex:0}))
                else dispatch(setInfo({zindex:456}))
                
              }}
            >
              <Clock color={statehours ? colors.blue : colors.gray} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    
  );
};

export default DurationPermition;

const styles = StyleSheet.create({});