import { TokenService } from "@/services";
import { AxiosInstance } from "axios";

//Default headers
export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

//Headers with Bearer Auth
const headersWithCredentials = () => {
  //Get token to Log In
  const token = TokenService.getLocalAccessToken();

  return {
    ...defaultHeaders,
    Authorization: `Bearer ${token}`,
  };
};

//Headers with Bearer Auth
export const headersWithCredentialsFile = () => {
  const { Authorization } = headersWithCredentials();

  return {
    "Content-Type": "multipart/form-data",
    Authorization,
  };
};

const AxiosHeaders = {
  headersWithCredentials,
  headersWithCredentialsFile,
};

export default AxiosHeaders;
