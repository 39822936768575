import { Alert, setAlert } from "@/store/alert.store";
import { useAppDispatch } from "@/store/hooks";
import { AxiosResponse } from "axios";
import { Buffer } from "buffer";

export const convertToBase64 = (res: AxiosResponse) => {
  return Buffer.from(res.data, "binary").toString("base64");
};

//Returns the name of the plan without _Dev
export const splitName = (name: string | undefined) => {
  if (name === undefined) return;
  if (name.toLowerCase().includes("_dev")) {
    return name.split("_Dev")[0];
  }
};

export const debounce = (
  func: Function,
  debounceTimer: number = 500
): Function => {
  let timer: NodeJS.Timeout | null;
  return function (...args: any) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func(...args);
    }, debounceTimer);
  };
};

export function sortByHeader<T>(
  arr: T[],
  header: keyof T,
  ascending: boolean
): T[] {
  let result: T[] = [...arr];

  if (!(result.length > 0)) return result;

  const typeOfHeader: any = typeof result[0][header];

  if (typeOfHeader === "number") {
    result.sort((firstValue: T, secondValue: T) => {
      return ascending
        ? //@ts-ignore
          firstValue[header] - secondValue[header]
        : //@ts-ignore
          secondValue[header] - firstValue[header];
    });
  } else if (typeOfHeader === "string") {
    result.sort((firstValue: T, secondValue: T) => {
      return firstValue[header] < secondValue[header] && ascending ? 1 : -1;
    });
  }
  return result;
}

export const capitalize = (word: string): string => {
  return `${word.at(0)?.toUpperCase()}${word.substring(1, word.length)}`;
};

export const capitalizeAll = (word: string) => {
  return word
    .split(" ")
    .map((item) => capitalize(item))
    .join(" ");
};

export const formatRole = (role: string) => {
  if (!role) return "N/A";

  if (role.includes("_")) {
    const roleSplitted = role.split("_");
    let joinRole = "";

    roleSplitted.forEach((word) => {
      joinRole = `${joinRole} ${capitalize(word)}`;
    });

    return joinRole;
  }

  return capitalize(role);
};
