import React from "react";
import Svg, { Path } from "react-native-svg";

export const RightArrow = (props: { color?: string }) => {
  const { color = "white" } = props;

  return (
    <Svg width="25" height="20" viewBox="0 0 24 24" fill="none">
      <Path
        d="M9 5l7 7-7 7"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </Svg>
  );
};
