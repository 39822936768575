import React, { useEffect, useState } from "react";
// @ts-ignore
import {
  StyleSheet,
  Text as Texts,
  TouchableOpacity,
  View,
} from "react-native";
import { ClientLayout } from "@/components/Layouts/ClientLayout";
import { useLocation, useNavigate } from "react-router-dom";
import tw from "@/lib/tailwind";
import { Card } from "react-native-elements";
import Icon from "react-native-vector-icons/MaterialIcons";

import { Button, Text } from "@/components/Commons";
import { TextInput } from "@/components/Inputs/TextInput";
import { useDeviceContext } from "twrnc";
import {
  colors,
  max,
  Modal,
  showFriendlyErrorFromHTMLCode,
  splitName,
  useFieldControl,
} from "@/lib";
import { Dropdown } from "react-bootstrap";

import { Close, Back, Circle } from "@/components/Icons";
import { ManageSubscription } from "@/components/ClientPartners/ManageSubscription";
import { PlanService, SubscriptionService, WorkspaceService } from "@/services";
import { PlanResponse, PlanSubscription } from "@/types";
import SweetAlert from "react-bootstrap-sweetalert";
import Svg from "react-native-svg";
import { AxiosError } from "axios";

export const AfterPlan = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [users, setUser] = useState({ usuario: 0 });
  const [cancel, setCancel] = useState(false);
  const sumary = [
    {
      id: 1,
      name: "Cursus risus ",
      account: "3X$8",
      result: "$24",
    },
    {
      id: 2,
      name: "Cursus libero ",
      account: "3X$3",
      result: " $9",
    },
    {
      id: 3,
      name: "Sit Diam ",
      account: "",
      result: " $8",
    },
  ];

  const sumarySecond = [
    {
      id: 1,
      name: "Total add-ons ",
      result: "$41",
    },
    {
      id: 2,
      name: "Promotions",
      result: "-10%",
    },
  ];

  const {
    plan,
    plan_data,
    amount,
    month,
    user,
    text,
    min,
    plan_id,
    price,
    wId,
  } = location.state;

  const licence = useFieldControl<number>(1);

  const errorInitialState = { show: false, msg: "" };

  //Error indicator
  const [errorMsg, setErrorMsg] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );

  useDeviceContext(tw);

  //Loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [planId, setPlanId] = useState<string>("");
  const [licences, setLicences] = useState<number | undefined>();

  useEffect(() => {
    WorkspaceService.getCurrent().then((r) => {
      const id = r.data?.result?.id;
      SubscriptionService.subscriptionCurrent(id).then((s) => {
        setPlanId(s.data.result.planId);
        setLicences(s.data.result.plan?.license_range.min);
      });
    });
  }, []);

  const subscribeOptions = async () => {
    if (amount !== "unlimited") {
      if (!(licence.value >= min && licence.value <= amount)) {
        return setErrorMsg({
          show: true,
          msg: "Invalid amount of users",
        });
      }
    }

    try {
      setIsLoading(true);
      let workspaceId: number = 0;

      if (wId) {
        workspaceId = parseInt(wId);
      } else {
        workspaceId = (await WorkspaceService.getCurrent()).data.result.id;
      }
      const inputSubscription: PlanSubscription = {
        workspaceId: workspaceId,
        planId: plan_id,
        licenses: licence.value,
      };

      await PlanService.changePlanSubscriptions(inputSubscription);
      setIsLoading(false);
      navigate(-1);
    } catch (err) {
      console.log("error");
      setIsLoading(false);
      //Show error
      const error = err as AxiosError;
      setErrorMsg({
        show: true,
        msg: showFriendlyErrorFromHTMLCode(error),
      });
    }
  };

  const cancelSubscription = async () => {
    const workspaceId = (await WorkspaceService.getCurrent()).data.result.id;
    PlanService.cancelSubscription(workspaceId).then((r) => {
      !cancel && setCancel(true);
    });
    Modal.dismiss();
  };

  const handleSelect = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Cancel Subscription?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>
            All features will be available until 15 August. Are you sure you
            want to cancel subscription?
          </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">No</Text>
            </Button>

            <Button style={tw`flex-1`} onPress={() => cancelSubscription()}>
              <Text weight="semiBold">Yes</Text>
            </Button>
          </View>
        </View>
      </View>,
      { width: 460 }
    );
  };

  const showUnlimited = () => {
    if (price && price.length > 0) {
      const result = Number(price.split("$")[1]);
      return result > 0;
    }
    return false;
  };

  return (
    <View style={tw`flex-grow-2 h-full mx-8 py-7 `}>
      <View style={tw`flex flex-row items-center`}>
        <Text style={tw`text-h2 font-bold pr-4`}>
          <TouchableOpacity
            style={tw`bg-white p-2 rounded-3`}
            onPress={() => navigate(-1)}
          >
            <Back color="black" />
          </TouchableOpacity>{" "}
          {plan}
        </Text>
      </View>
      <View style={tw`flex  sm:w-full  sm:flex-col lg:flex-row xl:flex-row   `}>
        <View style={tw`flex-initial flex-col w-full sm:w-full  `}>
          <Card
            containerStyle={[
              tw` w-full  pt-6  lg:h-auto sm:h-auto bg-white rounded-3   `,
            ]}
          >
            <View style={tw`z-30 absolute right-0 top-0`}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  style={{ lineHeight: 0, ...tw`no-underline` }}
                  id="dropdown-basic"
                  bsPrefix="btn"
                >
                  {plan_id === planId && (
                    <Icon color="#C8C8C8" name="more-vert" size={20} />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    onClick={handleSelect}
                    style={tw` text-red-400 text-btn-menu-item text-center font-semibold     `}
                  >
                    Stop Plan
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </View>
            <View
              style={tw`flex w-full items-start justify-start sm:flex    z-10 `}
            >
              <Text type="h3" color={colors.blue} style={tw`ml-2`}>
                {splitName(plan)}
                {cancel ? (
                  <Text style={tw` font-bold text-red-500 text-sm `}>
                    {" "}
                    Cancelled
                  </Text>
                ) : (
                  ""
                )}
              </Text>
              {
                <Text style={tw`pl-2`} weight="semiBold">
                  from {min} to {amount} users
                </Text>
              }

              <View style={tw`flex pl-2  flex-row my-2 items-center`}>
                <Text weight="extraBold" style={{ fontSize: 48 }}>
                  {price}
                </Text>
                {
                  <View
                    style={tw`flex  flex-col items-start justify-center ml-3`}
                  >
                    <Text weight="semiBold" color={colors.gray}>
                      /{month}
                    </Text>
                    <Text weight="semiBold" color={colors.gray}>
                      /{user}
                    </Text>
                  </View>
                }
              </View>
              <Text style={tw`text-xs`}>
                {" "}
                {text}{" "}
                <TouchableOpacity>
                  <Text style={tw`text-blue-600`}>Show more</Text>
                </TouchableOpacity>
              </Text>
            </View>
          </Card>

          {/* <Card
            containerStyle={[
              tw`flex w-full  flex-col  pt-6 h-auto bg-white rounded-3 `,
            ]}
          >
            <View style={tw`flex w-full lg:flex-row  `}>
              <View style={tw`flex flex-row w-full lg:max-w-1/2 `}>
                <Text
                  type="h2"
                  weight="semiBold"
                  style={tw`  text-blue-600 pl-2`}
                >
                  Amet,lectus 10 %
                </Text>
              </View>

              <View
                style={tw`flex flex-row w-full lg:max-w-1/2 lg:items-center lg:justify-end`}
              >
                <Text style={tw`font-bold text-xl`}>-10%</Text>
              </View>
            </View>
          </Card> */}

          {/* <View style={tw`flex my-6 pl-5`}>
            <Text style={tw`text-gray-500 `}>Add options if you need more</Text>
            <View
              style={{
                borderStyle: "dashed",
                borderWidth: 1,
                shadowColor: "#778899",
                borderRadius: 0,
                borderColor: "#778899",
              }}
            ></View>
          </View> */}

          <Card
            containerStyle={[
              tw` w-full flex-col pt-6 h-auto bg-white rounded-3 `,
            ]}
          >
            {/* <Text style={tw` font-bold text-blue-600 pl-2`}>Commodo eget</Text>

            <View style={tw`flex pl-2 flex-row my-2`}>
              <Text
                style={tw`flex  font-bold sm:flex  lg:text-4xl xl:text-5xl`}
              >
                $3 {""}
              </Text>
              <View style={tw`flex  flex-col items-start justify-around `}>
                <Text style={tw`flex text-xs `}>/month</Text>
                <Text style={tw`hidden text-xs sm:flex `}>
                  /per additional card
                </Text>
              </View>
            </View> */}

            <View
              style={tw`flex w-full lg:flex-row items-center justify-around`}
            >
              <View style={tw`flex w-full lg:max-w-1/2 lg:flex-row`}>
                <Text style={tw`text-md`}>
                  {" "}
                  Please select desire number of users
                </Text>
              </View>
              <View
                style={tw`flex w-full lg:max-w-1/2 lg:flex-row items-end justify-end`}
              >
                <ManageSubscription
                  color={"gray"}
                  planLicenceMinus={plan_data}
                  onChange={(chain) => {
                    licence.handleInputValue(+chain);
                  }}
                />
              </View>
            </View>
          </Card>

          {/* <Card containerStyle={[tw`w-full pt-6 h-auto bg-white rounded-3 `]}>
            <Text style={tw` font-bold text-blue-600 pl-2`}>Sit gravida</Text>

            <View style={tw`flex pl-2 flex-row my-2`}>
              <Text
                style={tw`flex  font-bold sm:flex  lg:text-4xl xl:text-5xl`}
              >
                $12 {""}
              </Text>
              <View style={tw`flex  flex-col items-start justify-center `}>
                <Text style={tw`text-xs `}>/month</Text>
              </View>
            </View>
            <Text style={tw`text-xs `}>
              {" "}
              Enhance admin controls and security{" "}
            </Text>
            <Text style={tw`text-xs`}> setting for large organizations </Text>
            <View style={tw`flex flex-row items-end justify-end `}>
              <Button style={tw`flex w-full sm:flex sm:max-w-1/4 `}>
                Add to Plan
              </Button>
            </View>
          </Card> */}

          {/* <Card containerStyle={[tw`w-full pt-6 h-auto bg-white rounded-3 `]}>
            <Text style={tw` font-bold text-blue-600 pl-2`}>Sit lacus</Text>

            <View style={tw`flex pl-2 flex-row my-2`}>
              <Text
                style={tw`flex  font-bold sm:flex  lg:text-4xl xl:text-5xl`}
              >
                $8 {""}
              </Text>
              <View style={tw`flex  flex-col items-start justify-center `}>
                <Text style={tw`text-xs `}>/month</Text>
              </View>
            </View>
          </Card> */}
        </View>
        <View
          style={tw`flex-initial flex-col w-full sm:w-full sm:pl-2 lg:pl-4 relative z-20  `}
        >
          {/* <Card
            containerStyle={[tw`  w-full  pt-6 h-auto bg-white rounded-3  `]}
          >
            <Text type="bigger" weight="bold">
              Bill Summary{""}{" "}
            </Text>
            <View style={tw`flex flex-col  justify-around`}>
              {sumary.map((dataSuma, index: number) => {
                return (
                  <View style={tw`flex flex-row w-full py-4 `} key={index}>
                    <View
                      style={tw`flex flex-row items-start w-full max-w-1/2 pl-2`}
                    >
                      <Text
                        type="regular"
                        weight="light"
                        color={colors.gray}
                        style={tw`text-sm font-semibold`}
                      >
                        {dataSuma.name}{" "}
                      </Text>
                    </View>
                    <View
                      style={tw`flex flex-row w-full max-w-1/2 items-center justify-end `}
                    >
                      <View style={tw`flex flex-col `}>
                        {dataSuma.account ? (
                          <View>
                            <Text
                              type="regular"
                              weight="light"
                              color={colors.gray}
                              style={tw`text-sm font-semibold`}
                            >
                              {dataSuma.account ? dataSuma.account : ``}{" "}
                            </Text>
                            <Text
                              type="regular"
                              weight="semiBold"
                              color={colors.black}
                              style={tw`pl-2`}
                            >
                              {dataSuma.result}{" "}
                            </Text>
                          </View>
                        ) : (
                          <Text
                            type="regular"
                            weight="semiBold"
                            color={colors.black}
                            style={tw`pl-2`}
                          >
                            {dataSuma.result}{" "}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                borderStyle: "dashed",
                borderWidth: 1,
                shadowColor: "#778899",
                borderRadius: 0,
                borderColor: "#778899",
              }}
            ></View>
            {sumarySecond.map((summarySecon: any, index: number) => {
              return (
                <View style={tw`flex flex-row w-full py-4  `} key={index}>
                  <View
                    style={tw`flex flex-row items-start w-full max-w-1/2 pl-2`}
                  >
                    <Text type="bigger" weight="semiBold">
                      {summarySecon.name}{" "}
                    </Text>
                  </View>
                  <View
                    style={tw`flex flex-row items-center justify-end  w-full max-w-1/2 pl-8`}
                  >
                    <Text type="bigger" weight="semiBold">
                      {summarySecon.result}{" "}
                    </Text>
                  </View>
                </View>
              );
            })}
            <View style={tw`flex flex-row w-full py-2`}>
              <View style={tw`flex flex-row items-start w-full max-w-1/2 pl-2`}>
                <Text weight="semiBold">Total payment per month </Text>
              </View>
              <View
                style={tw`flex flex-row items-center justify-end  w-full max-w-1/2 pl-2`}
              >
                <Text weight="extraBold" style={tw`font-bold  text-4xl`}>
                  {" "}
                  $53 {""}
                </Text>
              </View>
            </View>
          </Card> */}

          {/* <Card containerStyle={[tw` w-full    bg-white rounded-3 `]}>
            <View style={tw`flex flex-row w-full h-auto  justify-around  h-10`}>
              <View
                style={tw`flex w-full flex-row max-w-1/2 items-start justify-start `}
              >
                <View style={tw`flex w-full lg:max-w-[250px]`}>
                  <TextInput
                    placeholder="PROMOCODE"
                    inputStyle={tw`text-center `}
                  />
                </View>
              </View>
              <View
                style={tw`flex w-full max-w-1/2 flex-row items-center justify-end`}
              >
                <TouchableOpacity onPress={() => navigate("../../promocode")}>
                  <Text style={tw`font-bold text-md text-blue-600`}>
                    Apply Promocode{" "}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </Card> */}

          <View style={tw`flex flex-row items-end justify-end pt-4 `}>
            <Button
              style={tw`w-2/4 lg:w-1/4`}
              onPress={() => subscribeOptions()}
              loading={isLoading}
              disabled={isLoading}
            >
              Continue
            </Button>
          </View>
        </View>
      </View>

      {errorMsg.show && (
        <SweetAlert
          error
          title={errorMsg.msg}
          onConfirm={() => {
            setErrorMsg({ show: false, msg: "" });
          }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  Dropdown: {
    position: "absolute",
    margin: 0,
  },
});
