import { ScrollView, StyleSheet, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { colors, expireDate, number, required, tw, useFieldControl } from '@/lib'
import { SearchBar } from '@/components/Inputs'
import { CheckBox } from 'react-native-elements'
import { Text } from '@/components/Commons'
import { useDeviceContext } from 'twrnc'

export const SecondTab = () => {
 
  
  interface IFilter {
    filter: string;
    status: boolean;
    children?: IFilter[];
  }
  interface Properties{
    tab?:string;
  }
  
  
  
 
  
  const filtersArr: IFilter[] = [
    {
      filter: "Show to all clients",
      status: true,
    },
    {
      filter: "All Admins",
      status: false,
      children: [
        { filter: "James Culhane", status: false },
        { filter: "Lincoln Stanton", status: false },
        { filter: "Adison Gouse", status: false },
      ],
    },
    {
      filter: "All Partners",
      status: true,
      children: [
        { filter: "James Culhane", status: true },
        { filter: "Lincoln Stanton", status: false },
        { filter: "Adison Gouse", status: false },
        { filter: "James Culhane", status: true },
      ],
    },
  ];
  
  
    //Use tailwind
    
  
  
  
    //Form Inputs
    const codesForm = {
      name: useFieldControl<string>("", [required]),
      discount: useFieldControl<string>("", [number, required]),
      products: useFieldControl<string>("", [required]),
      expDate: useFieldControl<string>("", [required]),
      description: useFieldControl<string>("", [expireDate, required]),
    };
  
  
  
    //Form Inputs
    const search = useFieldControl<string>("", []);
  
    //Index of tabs
    const [index, setIndex] = useState<number>(0);
  
    //Index of tabs
    const [filters, setFilters] = useState<IFilter[]>(filtersArr);
  
    //Handle the status of checkboxes
    const handleCheckStatus = (index: number, childIndex?: number) => {
      const newFilters = [...filters];
  
      newFilters.map((filter, filIndex) => {
        if (filIndex == index) {
          if (childIndex != undefined) {
            filter.children?.map((child, index) => {
              if (index == childIndex) child.status = !child.status;
            });
          } else {
            filter.status = !filter.status;
            if (filter.children != undefined) {
              filter.children.map((child) => (child.status = filter.status));
            }
          }
        }
      });
  
      setFilters(newFilters);
    };
  
    //Filter by search
    const reduceFilters = () => {
      let newFilters = [...filters];
  
      newFilters = newFilters.filter((filter) => {
        if (filter.children != undefined) {
          filter.children = filter.children.filter((child) => {
            if (child.filter.toLowerCase().includes(search.value) == true)
              return child;
          });
        }
        return filter;
      });
  
      setFilters(newFilters);
    };
  
    //Watch search changes
    useEffect(() => {
      if (search.value.length == 0) {
        const oldValues = JSON.parse(JSON.stringify(filtersArr));
        setFilters(oldValues);
      } else {
        reduceFilters();
      }
    }, [search.value]);
  return (
    <View style={tw`w-[680px] lg:pr-4`}>
    <View>
      <SearchBar value={search} />
      <ScrollView
        style={tw`h-80 ml-5`}
        showsVerticalScrollIndicator={false}
      >
        {filters.map((item, iteration) => (
          <View key={iteration}>
            <CheckBox
              title={
                <Text
                  weight="semiBold"
                  color={colors.dark}
                  style={tw`ml-2`}
                >
                  {item.filter}
                </Text>
              }
              containerStyle={tw`bg-transparent border-0 p-0 m-0 mb-5`}
              checked={item.status}
              checkedIcon="square"
              uncheckedColor={colors.grayLightest}
              uncheckedIcon="square"
              checkedColor={colors.blue}
              onPress={() => handleCheckStatus(iteration)}
            />
            {item.children && (
              <View style={tw`ml-8`}>
                {item.children.map((child, childIndex) => (
                  <CheckBox
                    key={childIndex}
                    title={
                      <Text
                        weight="semiBold"
                        color={colors.dark}
                        style={tw`ml-2`}
                      >
                        {child.filter}
                      </Text>
                    }
                    containerStyle={tw`bg-transparent border-0 p-0 m-0 mb-5`}
                    checked={child.status}
                    checkedIcon="square"
                    uncheckedColor={colors.grayLightest}
                    uncheckedIcon="square"
                    checkedColor={colors.blue}
                    onPress={() =>
                      handleCheckStatus(iteration, childIndex)
                    }
                  />
                ))}
              </View>
            )}
          </View>
        ))}
      </ScrollView>
    </View>
  </View>
  )
}

