import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserAuth } from "@/types/auth.types";
import { AccessLevel, Status } from "@/types";

const initialState: UserAuth = {
  activationStatus: "",
  rol: null,
  avatar: null,
  email: "",
  firstName: "",
  id: 0,
  lastName: "",
  ownPhoneNumber: "",
  status: "",
  accessType: null,
  accessStatus: null,
};

const userSlice = createSlice({
  name: "userStore",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserAuth>) => {
      return { ...state, ...action.payload };
    },
    setAccessLevel: (
      state,
      action: PayloadAction<{
        accessType?: AccessLevel | null;
        accessStatus?: Status | null;
      }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearAccessLevel: (state) => {
      return {
        ...state,
        accessStatus: null,
        accessType: null,
      };
    },
    clearUser: () => {
      return initialState;
    },
  },
});

export default userSlice.reducer;

export const { setUser, setAccessLevel, clearAccessLevel, clearUser } =
  userSlice.actions;
