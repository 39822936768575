export const colors = {
  blue: "#5E6CB2",
  shadowblue: "#f1f2ff",
  blueLightest: "#F1F2F5",
  blueLightLightest: "#5B67EA",
  calendarLightBlue: "#F1F2FF",
  dark: "#1F1D1D",
  black: "#333333",
  gray: "#717185",
  gray_background: "#f7f7f7",
  grayLight: "#C2C2CD",
  grayLightest: "#EBEBF0",
  grayLightLightest: "#A1A1A9",
  green: "#8CC542",
  greenicon: "#19B665",
  red: "#E3153A",
  white: "#ffffff",
  statusBorder: "#E0E0E0",
  statusBadBar: "#979140",
  statusGoodBar: "#3ba55c",
  graphicLineBlue: "#2597E2",
  menuclr: "#333333",
  successAlert: "#80C542",
  rebrandGray: "#6C6C77",
  blackLogin: "#333",
  rebrandLayoutBGColor: "#F7F9FD",
  textColor: "#B7B7BE",
};
