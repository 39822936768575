import {
  FlatList,
  Image,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import {
  Button,
  InfinityScroll,
  InfinityScrollList,
  Loading,
  Text,
} from "@/components/Commons";
import { useToast } from "react-native-toast-notifications";
import FontAwesome from "react-native-vector-icons/FontAwesome";

import { useDeviceContext } from "twrnc";
import {
  capitalize,
  colors,
  formatRole,
  Modal,
  showFriendlyErrorFromHTMLCode,
  tw,
} from "@/lib";
import { Dropdown } from "react-bootstrap";
import { Icon } from "react-native-elements";

import { AddSuperAdminForm } from "./AddSuperAdminForm";
import { ChangeSuperAdminPassword } from "./ChangeSuperAdminPassword";
import { SuperAdminService, TokenService, UserService } from "@/services";
import type { SuperAdminListReponse } from "@/types/superAdmin.types";
import { AxiosError } from "axios";
import { useAlert } from "@/hooks";
import { UserStatus } from "@/types";

const limit: number = 20;

export const SuperAdminCustomers = () => {
  //Use tailwind
  useDeviceContext(tw);

  const { showSwal } = useAlert();

  //Initialize toasts
  const toast = useToast();

  const [data, setData] = useState<SuperAdminListReponse[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const user = TokenService.getUser();

  const filterNotUserAndAdmin = (users: SuperAdminListReponse[]) => {
    return users.filter(
      (user) => user.role !== "admin" && user.role !== "user"
    );
  };

  const handleAddedUser = (createdUser: any) => {
    toast.show("User added successfully", {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <FontAwesome name="check" size={18} color={colors.white} />,
    });

    const { userId, rol, avatar, email, firstName, lastName, ownPhoneNumber } =
      createdUser;

    setData([
      ...data,
      {
        id: userId,
        activationStatus: UserStatus.INACTIVE,
        status: "offline",
        role: rol,
        avatar: avatar,
        email,
        firstName,
        lastName,
        ownPhoneNumber: ownPhoneNumber ?? "",
        companyPhoneNumber: "",
      },
    ]);
  };

  //BEGIN: Functions to handle the options in the dropdown
  const addAdmin = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end mb-1`}>
          <Text style={tw`font-bold text-2xl`}>Add User</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <AddSuperAdminForm modal={Modal} onAddedSuperAdmin={handleAddedUser} />
      </View>
    );
  };

  const setError = (err: any) => {
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    showSwal({ msg, type: "error" });
  };

  const loadSuperAdmins = async (
    isScroll: boolean,
    currentPage: number,
    keyToSearch?: string
  ) => {
    try {
      if (!keyToSearch) {
        const { result } = (
          await SuperAdminService.getAllSuperAdmin(currentPage * limit, limit)
        ).data;
        const { totalCount } = result;
        const list = filterNotUserAndAdmin(result.list);
        if (list.length > 0) {
          if (isScroll) setData([...data, ...list]);
          else setData(list);
          setTotalUsers(totalCount);
        }
      } else {
        const { result } = (
          await UserService.searchUser(currentPage * limit, limit, keyToSearch)
        ).data;
        const { totalCount } = result;
        const list = filterNotUserAndAdmin(result.list);
        if (list.length > 0) {
          if (isScroll) setData((prev) => [...prev, ...list]);
          else setData(list);
          setTotalUsers(totalCount);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      ErrorMessage(error);
    }
  };

  const ErrorMessage = (err: any) => {
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    showSwal({ msg, type: "error" });
  };

  const changePassword = (id: number) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end mb-1`}>
          <Text style={tw`font-bold text-2xl`}>Change Password</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <ChangeSuperAdminPassword modal={Modal} id={id} />
      </View>
    );
  };

  const activateDeactivateSuperAdmin = async (user: SuperAdminListReponse) => {
    try {
      const { id, activationStatus } = user;

      const resp = await UserService.toggleUserActivation(
        id.toString(),
        activationStatus && activationStatus === UserStatus.ACTIVE
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE
      );

      setData((prev) => {
        return prev.map((user) => {
          if (user.id === resp.data.result.id) {
            return {
              ...user,
              activationStatus: resp.data.result.activationStatus,
            };
          }
          return user;
        });
      });

      showToast(
        `User ${
          resp.data.result.activationStatus === UserStatus.INACTIVE
            ? "Deactivated"
            : "Activated"
        } succesfully`
      );
    } catch (err) {
      setError(err);
    }
  };

  const deleteSuperAdmin = async (id: number) => {
    try {
      const res = await UserService.deleteUser(id);
      const { userId, deleted } = res.data.result;

      setData((prev) =>
        prev.filter((user) => user.id !== userId && deleted === true)
      );
      Modal.dismiss();
      showToast("User deleted successfully");
    } catch (error) {
      setError(error);
      Modal.dismiss();
    }
  };

  const showModalForRemove = (id: number) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove SuperAdmin?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to remove this SuperAdmin? </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button
              style={tw`flex-1`}
              onPress={() => {
                return deleteSuperAdmin(id);
              }}
            >
              <Text weight="semiBold">Remove</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const showToast = (msg: string) => {
    toast.show(msg, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <FontAwesome name="check" size={18} color={colors.white} />,
    });
  };

  //@ts-ignore
  const renderItem = ({ item }) => {
    const textColorByStatus: string =
      item.activationStatus == UserStatus.INACTIVE ? colors.gray : colors.dark;
    return (
      <View
        style={[
          tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`,
          ,
          { zIndex: "auto" },
        ]}
        key={item.id}
      >
        <View style={tw`w-3/12 `}>
          <Text weight="semiBold" color={textColorByStatus}>
            {item.email}
          </Text>
        </View>
        <View style={tw`w-3/12 `}>
          <Text weight="semiBold" color={textColorByStatus} style={tw`pl-1`}>
            {item.firstName + " " + item.lastName}
          </Text>
        </View>
        <View style={tw`w-2/12 `}>
          <Text weight="semiBold" color={textColorByStatus} style={tw`pl-1`}>
            {formatRole(item.role)}
          </Text>
        </View>
        <View style={tw`w-3/12 `}>
          <Text weight="semiBold" color={textColorByStatus} style={tw`pl-3`}>
            {item.companyPhoneNumber}
          </Text>
        </View>
        <View style={tw`w-auto  `}>
          <Text
            weight="semiBold"
            color={item.status === "offline" ? colors.red : colors.blue}
            key={item.id}
            style={tw`pl-4`}
          >
            {capitalize(item.status || "")}
          </Text>
        </View>

        <View style={[tw`flex w-auto ml-auto`, { zIndex: "auto" }]}>
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              style={{ lineHeight: 0, ...tw`no-underline` }}
              id="dropdown-basic"
              bsPrefix="btn"
            >
              <Icon
                color="#C8C8C8"
                name="more-vert"
                size={20}
                tvParallaxProperties={undefined}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ ...tw`rounded-3`, ...{ zIndex: 1 } }}
              flip={false}
            >
              <Dropdown.Item
                as="button"
                style={tw` text-btn-menu-item font-semibold`}
                onClick={() => changePassword(item.id)}
              >
                Change Password
              </Dropdown.Item>
              {user && user.user.id !== item.id && (
                <>
                  <Dropdown.Item
                    as="button"
                    style={tw` text-btn-menu-item font-semibold`}
                    onClick={() => activateDeactivateSuperAdmin(item)}
                  >
                    {item.activationStatus == UserStatus.ACTIVE
                      ? "Deactivate"
                      : "Activate"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={tw`text-btn-menu-item font-semibold text-red-500`}
                    onClick={() => {
                      return showModalForRemove(item.id);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </View>
      </View>
    );
  };

  return (
    <InfinityScrollList
      title="System users"
      data={data}
      Component={renderItem}
      fetchData={loadSuperAdmins}
      dataLimit={totalUsers}
    >
      <View style={tw`flex-row px-4 mb-4 my-4 `}>
        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            E-mail{" "}
          </Text>
        </View>
        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Full Name{" "}
          </Text>
        </View>

        <View style={tw`w-2/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Role{" "}
          </Text>
        </View>

        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Company Phone Number{" "}
          </Text>
        </View>

        <View style={tw`w-[150px] `}>
          <Text color={colors.gray} weight="semiBold">
            Status{" "}
          </Text>
        </View>
      </View>
    </InfinityScrollList>
  );
};
