import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import {
  Animated,
  StyleProp,
  View,
  ViewStyle,
  ImageBackground
} from "react-native";
import { Avatar } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";

import { tw } from "@/lib";
import { Text } from "../Commons";
import { Image, Trash } from "../Icons";

interface DropFileProp {
  value?: File | string;
  onChange?: (value: File | undefined) => void;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  children?: JSX.Element;
  fullScreen?: boolean;
}

function DropFileInput(props: DropFileProp) {
  const [innerFile, changeInnerFile] = useState<File | string | undefined>(
    props.value
  );

  const [imageHover, changeImageHover] = useState(false);

  const imageBackground = useRef(new Animated.Value(0)).current;

  const animateColor = Animated.timing(imageBackground, {
    toValue: imageHover ? 0.7 : 0,
    useNativeDriver: true,
    duration: 250,
  });

  useEffect(() => {
    animateColor.stop();
    animateColor.start();
  }, [imageHover]);

  const imageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    changeInnerFile(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange && props.onChange(innerFile as File);
  }, [innerFile]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    changeInnerFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    maxSize: 10 * 1024 * 1024,
    multiple: false,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  const click = async () => {
    var fileSelector = document.createElement("input");

    fileSelector.setAttribute("type", "file");
    fileSelector.accept = "image/*";
    fileSelector.multiple = false;
    fileSelector.max;

    fileSelector.addEventListener("input", (e) => {
      //@ts-ignore
      const files = e.target?.files[0];
      if (files.size < 10 * 1024 * 1024) onDrop([files]);
    });

    fileSelector.click();
  };

  const imageUri =
    typeof innerFile == "string"
      ? innerFile
      : innerFile
      ? URL.createObjectURL(innerFile!)
      : undefined;

  const input = (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <TouchableOpacity
        style={[
          tw`flex-col items-center pt-24 pb-16 rounded-lg border border-dashed border-light-gray`,
          props.containerStyle,
        ]}
        onPress={click}
      >
        {props.children ? (
          props.children
        ) : (
          <>
            <Image />
            <Text style={tw`py-2`}>Drop your image</Text>
            <Text type="caption" style={tw`text-gray-500`}>
              JPG or PNG, max size 10Mb{" "}
            </Text>
          </>
        )}
      </TouchableOpacity>
    </div>
  );

  const image = () => {
    if (props.fullScreen == true) {
      return (
        <TouchableOpacity
          style={tw`flex-col w-full h-45 rounded-3 overflow-hidden`}
          onPress={() => changeInnerFile(undefined)}
        >
          <ImageBackground source={{ uri: imageUri }} resizeMode="cover" style={tw`w-[100%] h-[100%]`} />
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={[tw`flex-col items-center`, props.style]}>
          <div
            style={{
              borderRadius: "100%",
              position: "relative",
              overflow: "hidden",
            }}
            onMouseEnter={() => changeImageHover(true)}
            onMouseLeave={() => changeImageHover(false)}
          >
            <Avatar source={{ uri: imageUri }} rounded size={140} />
            <Animated.View
              style={[
                {
                  backgroundColor: `rgb(0, 0, 0)`,
                  opacity: imageBackground,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                },
              ]}
            >
              <TouchableOpacity
                containerStyle={tw`h-full`}
                style={tw`h-full`}
                onPress={() => changeInnerFile(undefined)}
              >
                <View style={tw`h-full flex-col items-center justify-center`}>
                  <Trash />
                  <Text weight="semiBold" style={tw`text-white`}>
                    Delete
                  </Text>
                </View>
              </TouchableOpacity>
            </Animated.View>
          </div>
          <TouchableOpacity onPress={click}>
            <Text style={tw`py-2 text-btn`} weight="semiBold">
              Upload Photo
            </Text>
          </TouchableOpacity>
          <Text type="caption" style={tw`text-gray-500`}>
            JPG or PNG, max size 10Mb{" "}
          </Text>
        </View>
      );
    }
  };

  return <View style={props.style}>{(innerFile && image()) || input}</View>;
}

export { DropFileInput };
