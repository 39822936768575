const ZENDESK_KEY = "26e87ef2-a656-412b-9d88-b174c883263e";
const SCRIPT_ID = "ze-snippet";

const canUseDOM = () => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }
  return true;
};

export const mountZendesk = () => {
  if (!canUseDOM()) return;

  const script = document.createElement("script");
  script.async = true;
  script.id = SCRIPT_ID;
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
  document.body.appendChild(script);
};

export const unmountZendesk = () => {
  if (!canUseDOM()) {
    const script = document.getElementById(SCRIPT_ID);
    if (!script) return;
    document.body.removeChild(script);
  }
};
