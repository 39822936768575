import React, { useState } from "react";
import { Linking, View } from "react-native";

import tw from "@/lib/tailwind";
import { colors, showFriendlyErrorFromHTMLCode } from "@/lib";
import { Text } from "@/components/Commons";
import { useDeviceContext } from "twrnc";
import { ActivityIndicator } from "react-native";
import { PaymentService } from "@/services";
import { useEffect } from "react";
import { useAlert } from "@/hooks";
import { useNavigate } from "@/routes";

export const WhmcsStore = () => {
  useDeviceContext(tw);
  const navigate = useNavigate();
  const { showSwal } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [frameUrl, setFrameUrl] = useState<string>();
  const loadStoreFrame = async () => {
    setIsLoading(true);
    try {
      const url = await PaymentService.getStoreUrl();
      await Linking.openURL(url);
      navigate("clientCompany");
    } catch (e) {
      setError(e);
      setFrameUrl(undefined);
    }
    setIsLoading(false);
  };

  const setError = (error: any) => {
    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    setIsLoading(false);
  };

  useEffect(() => {
    loadStoreFrame();
  }, []);

  return (
    <View
      style={tw`flex-grow-1 flex items-center justify-center bg-[${colors.rebrandLayoutBGColor}]`}
    >
      {isLoading ? (
        <ActivityIndicator size="large" />
      ) : frameUrl ? (
        <iframe style={tw`w-full h-full`} src={frameUrl} />
      ) : (
        <View style={tw`flex md:flex-row`}>
          <Text style={tw`text-2xl text-center md:text-3xl`}>
            The store cannot be loaded at this time. Please try again later.
          </Text>
        </View>
      )}
    </View>
  );
};
