import { ErrorResponse } from "@/types";

let result: string[] = [];

export const formatErrors = (errors: ErrorResponse[]): any => {
  errors.forEach((error) => {
    if (typeof error === "object") {
      for (const [key, value] of Object.entries(error)) {
        formatErrors(value as ErrorResponse[]);
      }
    } else if (typeof error === "string") {
      result.push(error);
    }
  });
  return result;
};

export const cleanErrors = () => (result = []);
