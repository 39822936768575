import React, { ReactElement } from "react";

import { useNavigate } from "react-router-dom";

import { useAppSelector } from "@/store/hooks";
import { RootState } from "@/store";
import { FC } from "react";

interface Props {
  children: ReactElement | ReactElement[];
  roleArray: string[];
}

export const AllowByRoleGuard: FC<Props> = ({ children, roleArray }) => {
  const { rol } = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  if (rol && roleArray.includes(rol)) {
    navigate(-1);
  }

  return <>{children}</>;
};
