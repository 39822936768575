import {
  ActivityIndicator,
  KeyboardAvoidingView,
  StatusBar,
  View,
} from "react-native";

import { Button, Text } from "@/components/Commons";
import Sidelogin from "@/components/Layouts/Sidelogin";
import { useAlert } from "@/hooks";
import { colors, tw } from "@/lib";
import { useNavigate } from "@/routes";
import { FirebaseService, MailVerification } from "@/services";
import { useAppDispatch } from "@/store/hooks";
import { setAccountInfo } from "@/store/register.store";
import { FRONTEND_LOGIN_PAGE } from "@env";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDeviceContext } from "twrnc";
import { ERROR_AUTH_CHECK } from "@/services/firebase.service";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const WorkspaceCheck = () => {
  useDeviceContext(tw);
  let { email, secret } = useParams();
  const { showSwal } = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  let query = useQuery();
  if (!email) email = query.get("email") || undefined;
  if (!secret) secret = query.get("secret") || undefined;

  const checkWorkspace = async () => {
    try {
      if (email && secret) {
        await MailVerification.confirmWorkspaceVerifyCode(email, secret);
        dispatch(
          setAccountInfo({
            email: email,
            account_name: email.slice(email.indexOf("@") + 1, email.length),
            secret: secret,
          })
        );
        navigate("registerAdmin");
      } else {
        FirebaseService.logFirebaseEvent(ERROR_AUTH_CHECK);
      }
    } catch (e) {
      console.log(e);
      FirebaseService.logFirebaseEvent(ERROR_AUTH_CHECK);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkWorkspace();
  }, []);

  const redirectToLogin = () => {
    window.location.href = FRONTEND_LOGIN_PAGE;
  };

  const redirectToCreateWorkspace = () => {
    navigate("register");
  };

  return (
    <KeyboardAvoidingView behavior="padding" style={tw`flex-grow-1`}>
      <StatusBar
        // @ts-ignore
        style="light"
      />
      <View
        style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}]`}
      >
        <Sidelogin />
        <View
          style={tw`flex flex-col flex-grow-1 justify-between sm:justify-center items-center my-auto`}
        >
          {loading ? (
            <ActivityIndicator size="large" />
          ) : (
            <View
              style={tw`flex flex-grow-1 flex-col justify-center w-full max-w-[340px] items-center`}
            >
              <Text type="h2" weight="bold">
                {" "}
                This link has expired
              </Text>
              <Text style={tw`my-5 text-gray-500 max-w-[320px]`}>
                We&rsquo;re sorry, but this link has expired. If you already
                have an account, please log in. If you were not able to finish
                signing up, please go back to the sign up page to get a new
                verification link.
              </Text>
              <View style={[tw`w-full`, { maxWidth: 340 }]}>
                <Button style={tw`mb-5`} onPress={redirectToLogin}>
                  Back to Login
                </Button>
              </View>
              <View style={[tw`w-full`, { maxWidth: 340 }]}>
                <Button
                  style={tw`sm:pb-6 pb-2`}
                  type="outlined"
                  onPress={redirectToCreateWorkspace}
                >
                  Create Workspace
                </Button>
              </View>
            </View>
          )}
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};
