import { ApprovedEmail, EmailResponse, SendEmail } from "@/types";
import { AxiosResponse } from "axios";
import { ApiService } from "./";
import { AxiosHeaders } from "@/services";

const endpoint = "waitinglist";

class WaitingService {
  //Check if the email can be used for register
  async addEmail(
    email: SendEmail
  ): Promise<AxiosResponse<{ result: ApprovedEmail }>> {
    return ApiService.api.post(`${endpoint}/add`, email);
  }
}
const singleton = new WaitingService();
export default singleton;
