import { colors } from "@/lib";
import React, { FC } from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

interface Props {
  color?: string;
  styles?: StyleProp<ViewStyle>
}

export const Success:FC<Props> = ({ color = colors.greenicon, styles }) => {
  return (
    <Svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      style={styles}
    >
      <Path
        d="M16.8804 23.9999L21.6284 28.7459L31.1204 19.2539"
        stroke={color}
        strokeWidth="2.70833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49927 23.9998C5.49927 37.8738 10.1253 42.4998 23.9993 42.4998C37.8733 42.4998 42.4993 37.8738 42.4993 23.9998C42.4993 10.1258 37.8733 5.49976 23.9993 5.49976C10.1253 5.49976 5.49927 10.1258 5.49927 23.9998Z"
        stroke={color}
        strokeWidth="2.70833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
