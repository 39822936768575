import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { FacebookPixelService } from "@/services";

export const FacebookAnalytics = () => {
  const router = useLocation();
  const logPage = async () => {
    const page_path = router.pathname;
    FacebookPixelService.logPage(page_path);
  };

  useEffect(() => {
    logPage();
  }, [router]);

  return null;
};
