import React, { useState, useRef } from "react";
import { View, Platform, Pressable } from "react-native";
import { useDeviceContext } from "twrnc";

import MaskInput from "react-native-mask-input";

import { colors, tw } from "@/lib";
import { Close } from "../Icons";

import { useFonts, Roboto_400Regular } from "@expo-google-fonts/roboto";
import { Text } from "../Commons";

type methods = {
  padding?: boolean;
  clearable?: boolean;
  clear?: () => void;
};

const MaskInputs = (props: any & methods) => {
  useDeviceContext(tw);
  const [state, setState] = useState({ isFocused: false });
  const focus = () => {
    if (props.onFocus)
      //@ts-ignore
      props.onFocus();
    setState({ isFocused: true });
  };

  const icon = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement | undefined>();

  if (icon.current && icon.current.children?.length > 0) {
    icon.current.children[0].setAttribute("tabindex", "-1");
  }

  const { clearable = true } = props;
  const { padding = true } = props;

  const blur = () => {
    if (props.onBlur)
      //@ts-ignore
      props.onBlur();
    setState({ isFocused: false });
  };

  const onClear = () => {
    (props.clear && props.clear()) || props.onChangeText?.("");
    inputRef.current?.focus();
  };

  const appendIcon = (
    <View ref={icon} focusable={false} style={tw`flex justify-between    `}>
      {props.rightIcon ||
        (props.clearable && (
          <View style={tw`relative flex items-end bottom-8`}>
            <Pressable
              style={tw`w-10 h-10 px-2 absolute`}
              onPress={onClear}
              focusable={false}
            >
              <Close />
            </Pressable>
          </View>
        ))}
    </View>
  );

  // @ts-ignore
  useFonts(Roboto_400Regular);

  return (
    <View
      style={tw.style("w-full", "min-h-[88px]", {
        "px-2": padding,
      })}
    >
      <Text color={colors.rebrandGray} style={{ fontSize: 15 }}>
        {props.label}
      </Text>

      <MaskInput
        {...{
          ...props,
          style: null,
          label: undefined,
          errorMessage: undefined,
          children: undefined,
        }}
        value={props.value}
        ref={inputRef}
        onFocus={focus}
        onBlur={blur}
        mask={props.mask}
        style={[
          tw.style(
            "w-full",
            "h-[40px]",
            "border",
            "border-gray-lightest",
            "rounded-3",
            "px-2",
            "bg-white"
          ),
          props.style,
          Platform.select({
            web: {
              zIndex: "auto",
              outline: "none",
              fontFamily: "Roboto_400Regular",
              fontSize: 14,
            },
          }),
          tw`overflow-hidden`,
        ]}
        errorStyle={tw`m-0 p-0`}
      />
      {clearable && appendIcon}

      <View style={tw`flex items-start justify-start bottom-0 z-0`}>
        <Text type="caption" color={colors.red}>
          {props.errorMessage}
        </Text>
        {props.children}
      </View>
    </View>
  );
};

export default MaskInputs;
