import { Crisp } from "crisp-sdk-web";
import React, { ReactElement, useEffect } from "react";
import { ConfigService, TokenService } from "@/services";

export default function Crispa(): ReactElement {
  const loadCrisp = async () => {
    const config = await ConfigService.getAll();
    const crispId = config.crispWebsiteId;
    if (crispId) {
      Crisp.configure(crispId);
    }
  };

  useEffect(() => {
    loadCrisp();
  }, []);

  const updateUserId = async () => {
    const currentUser = TokenService.getUser()?.user;
    Crisp.user.setEmail(currentUser?.email || "");
    Crisp.user.setNickname(
      `${currentUser?.firstName} ${currentUser?.lastName}`
    );

    Crisp.session.setData({
      user_id: currentUser?.id,
    });
  };

  useEffect(() => {
    updateUserId();
  }, [TokenService.getUser()?.user]);

  return <></>;
}
