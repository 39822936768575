import React, { useEffect } from "react";

import { useNavigate } from "@/routes";
import { Loading } from "../Commons";
import { useUserLogged } from "@/hooks";

export const CheckAuth = () => {
  const navigate = useNavigate();
  const { navigateTo } = useUserLogged();

  useEffect(() => {
    navigate(navigateTo);
  }, []);

  return <Loading />;
};
