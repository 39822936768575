import React from "react";
import Svg, { Path } from "react-native-svg";

export const Check = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="14" height="10" viewBox="0 0 14 10" fill="none">
      <Path
        d="M1.45962 4.04038C1.20578 3.78654 0.794221 3.78654 0.540381 4.04038C0.28654 4.29422 0.28654 4.70578 0.540381 4.95962L1.45962 4.04038ZM5.5 9L5.04038 9.45962C5.16485 9.58409 5.33448 9.65275 5.51048 9.64992C5.68648 9.64708 5.85381 9.57298 5.9742 9.44456L5.5 9ZM13.4742 1.44456C13.7197 1.18267 13.7065 0.771325 13.4446 0.525801C13.1827 0.280276 12.7713 0.293545 12.5258 0.555438L13.4742 1.44456ZM0.540381 4.95962L5.04038 9.45962L5.95962 8.54038L1.45962 4.04038L0.540381 4.95962ZM5.9742 9.44456L13.4742 1.44456L12.5258 0.555438L5.0258 8.55544L5.9742 9.44456Z"
        fill={color}
      />
    </Svg>
  );
};
