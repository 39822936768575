import React, { useState, useEffect } from "react";
import { StyleSheet, Dimensions, View } from "react-native";
import { useDeviceContext } from "twrnc";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Avatar } from "react-native-elements";
import { AxiosError } from "axios";
import { useLocation, useParams } from "react-router-dom";

import tw from "@/lib/tailwind";
import { Pencil, Image } from "@/components/Icons";
import { Loading, Text } from "@/components/Commons";
import {
  colors,
  convertToBase64,
  Modal,
  showFriendlyErrorFromHTMLCode,
} from "@/lib";
import { CompanyInfoForm } from "@/components/ClientCompanyInfo";
import {
  MediaService,
  WorkspaceService,
  UserService,
  TokenService,
} from "@/services";
import { UserDetails, WorkspaceResponse } from "@/types";
import { BillingResponse } from "@/types";
import { useAlert, usePhoneNumber, useUserLogged } from "@/hooks";
import Back from "@/components/Commons/Back";
import { useAppDispatch } from "@/store/hooks";
import { setName } from "@/store/workspace.store";

export const CompanyInfoDetails = () => {
  const location = useLocation();
  const { state } = location;
  useDeviceContext(tw);

  const { userLogged } = useUserLogged();

  const dispatch = useAppDispatch();

  const { showSwal } = useAlert();

  const { workspaceId } = useParams();

  //Loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Handle User Image
  const [userImage, setUserImage] = useState<string>("");

  //Handle company info
  const [companyInfo, changeCompanyInfo] = useState<WorkspaceResponse>();

  const [user, changeUser] = useState<UserDetails>();

  const { formattedNumber: phoneNumber } = usePhoneNumber({
    phone: companyInfo?.phoneNumber || "",
  });

  const [actualWorkspaceId, setActualWorkspaceId] = useState<number>();

  const onEditClick = () => {
    //@ts-ignore
    const editedValue: WorkspaceResponse = {
      ...companyInfo,
      admin: {
        userId: user?.id || 0,
        email: user?.email || "",
        extension: companyInfo?.admin?.extension || "",
        first_name: user?.firstName || "",
        id: user?.id || 0,
        last_name: user?.lastName || "",
        password: companyInfo?.admin?.password || "",
        companyPhoneNumber: user?.companyPhoneNumber || "",
      },
    };
    Modal.show(
      <CompanyInfoForm
        workspaceId={parseInt(workspaceId || "0")}
        currentWorkspaceId={actualWorkspaceId}
        value={editedValue}
        profileImg={userImage}
        onChange={(input) => {
          changeCompanyInfo(input);
          dispatch(setName({ name: input?.name || "" }));
        }}
        onChangeImg={(updatedImg) => {
          setUserImage(updatedImg);
        }}
        onError={(errorMessage) => {
          showSwal({ msg: errorMessage, type: "error" });
        }}
        onAdminInfo={(input) => {
          //@ts-ignore
          const resp: UserDetails = {
            ...user,
            firstName: input.firstName,
            lastName: input.lastName || "",
            email: input.email || "",
          };
          changeUser(resp);
        }}
      />,

      {
        onTouchOutside: () => {},
        noClose: true,
        width:
          Dimensions.get("window").width >= 728
            ? 728
            : Dimensions.get("window").width - 30,
      }
    );
  };

  const setProfileImg = (workspace: WorkspaceResponse) => {
    //Set user image
    MediaService.getMediaFromType(
      "image",
      typeof workspace.coverPhoto === "string"
        ? workspace.coverPhoto
        : workspace.coverPhoto?.m
    )
      .then((res) => {
        let base64ImageString: string = convertToBase64(res);
        setUserImage(`data:image/png;base64,${base64ImageString}`);
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false);
      });
  };

  const handleWorkspaceInfo = (res: WorkspaceResponse) => {
    let workspace = res;

    changeCompanyInfo({ ...workspace });

    //Change user image
    if (workspace.coverPhoto) setProfileImg(workspace);

    if (workspaceId && res?.admin?.userId) {
      const wu = workspace.users.find((u) => u.id == res?.admin?.userId);
      UserService.getUserById(res?.admin?.userId).then((r) => {
        changeUser({
          ...wu,
          ...r.data.result,
        });
      });
    } else {
      const tokenID = TokenService.getUser()?.user?.id;

      if (tokenID != undefined) {
        const wu = workspace.users.find((u) => u.id == tokenID);
        UserService.getUserById(tokenID).then((r) => {
          changeUser({
            ...wu,
            ...r.data.result,
          });
        });
      }
    }

    setIsLoading(false);
  };

  const callRightService = async () => {
    let res: WorkspaceResponse;

    try {
      const response = await WorkspaceService.getCurrent();
      setActualWorkspaceId(response?.data?.result?.id);

      if (workspaceId) {
        const id = parseInt(workspaceId);
        const workspaceResult = await WorkspaceService.getWorkspaceInfo(id);
        const workspaceById = { ...workspaceResult.data.result };
        const { workspace, admin } = workspaceById;
        res = {
          name: workspace.company_name,
          domain: workspace.domain_name,
          accountName: "",
          address: workspace.address,
          admin,
          city: workspace.city,
          company_address: "",
          users: workspace.users,
          coverPhoto: workspace.cover_photo,
          createdAt: "",
          id: workspace.id,
          phoneNumber: "",
          postalCode: workspace.postal_code,
          province: workspace.province,
          street_name: "",
          street_number: "",
          unit_number: "",
          user_access: { ...workspace.user_access },
        };
      } else {
        res = response.data.result;
      }
      handleWorkspaceInfo(res);
    } catch (err) {
      //Show error
      const error = err as AxiosError;
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    }
  };

  //Get the current workspace once
  //the component is loaded

  useEffect(() => {
    callRightService();
  }, []);

  const showEditWorkspace = () => {
    if (
      userLogged.rol === "admin" ||
      userLogged.rol === "super_admin" ||
      (userLogged.accessType === "read and write" &&
        userLogged.accessStatus === "granted")
    )
      return (
        <TouchableOpacity
          style={tw`bg-white p-2 rounded-3`}
          onPressOut={onEditClick}
        >
          <Pencil color={colors.blue} />
        </TouchableOpacity>
      );
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
          <View style={tw`flex flex-row items-center`}>
            {state && (
              <Back
                color={[colors.white, colors.blue]}
                width={"30"}
                height={"32"}
                showShadow={false}
              />
            )}

            <Text style={tw`text-h2 font-bold pr-4 px-4 lg:px-4`}>Details</Text>
            {showEditWorkspace()}
          </View>

          <View style={tw`flex lg:flex-row mt-3`}>
            <View
              style={tw` flex-initial w-full flex-col pt-6 bg-white rounded-3 max-w-[240px] items-center`}
            >
              <Avatar
                rounded
                source={userImage.length > 0 ? { uri: userImage } : undefined}
                size={128}
                renderPlaceholderContent={
                  <View
                    style={tw`bg-gray-500 h-full w-full justify-center items-center`}
                  >
                    <Image />
                  </View>
                }
              />
              <Text style={tw`text-h2 mb-2 font-bold`}>
                <Text style={styles.breakAll}>{companyInfo?.name}</Text>
              </Text>
            </View>
            <View
              style={tw`flex-1 w-full lg:ml-4 mt-4 lg:mt-0 bg-white rounded-3`}
            >
              <View style={tw`flex-col pt-6 min-h-[210px] px-8`}>
                {/* Company */}
                <Text style={tw`text-h2 mb-5 font-bold`}>My Company</Text>
                <View style={tw`flex md:flex-row`}>
                  <Text style={tw`flex-1`} color={colors.rebrandGray}>
                    Phone number
                  </Text>
                  <Text
                    style={tw`flex-1`}
                    color={colors.black}
                    weight="semiBold"
                  >
                    {phoneNumber}
                  </Text>
                  <Text style={tw`flex-1`} color={colors.rebrandGray}>
                    Company address
                  </Text>
                  <Text
                    style={tw`flex-1`}
                    color={colors.black}
                    weight="semiBold"
                  >
                    {companyInfo?.address}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  breakAll: {
    overflowWrap: "anywhere",
  },
});
