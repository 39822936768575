import React, { FC } from "react";

import { useAppSelector } from "@/store/hooks";
import { RootState } from "@/store";
import { useNavigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
}

export const SuperAdminGuard: FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const user = useAppSelector((state: RootState) => state.user);

  if (user.rol !== "super_admin" || user === null) {
    navigate("/super-admin/workspaces");
  }

  return children;
};
