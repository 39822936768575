import {
  StyleSheet,
  View,
  Text as Texts,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import React, { useState } from "react";
import { EditProfile, Text } from "@/components/Commons";
import { Modal, tw } from "@/lib";
import { Avatar, Card } from "react-native-elements";
import { Back, Image, Pencil } from "@/components/Icons";
import { useDeviceContext } from "twrnc";
interface NameInfo {
  names?: string;
  phoneNumber?: string;
  image?: File | string;
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  cellphone?: string;
  companyName?: string;
  companyNumber?: string;
  billingInfo?: string;
  direct?: string;
}

const Profile = () => {
  useDeviceContext(tw);
  const [partnerInfo, changePartnerInfo] = useState<NameInfo>({
    firstName: "Jody Schnarr",

    email: "jodyschnarr@mail.com",
    phoneNumber: "+1 (999) 878-77-66",
    lastName: "CompanySocial",
    address: "Lorem ipsum dolor sit amet.",
    cellphone: "+1 (999) 878-7766",
    companyName: "manuel",
    companyNumber: "+1 (999) 878-7766",
    billingInfo: "*** *** *** 999",
    direct: "+1 (999) 878-7766",
  });

  const editProfile = () => {
    Modal.show(
      <EditProfile
        value={partnerInfo}
        onChange={(input) => {
          changePartnerInfo(input);
        }}
      />,
      { width: Dimensions.get("window").width >= 728 ? 728 : "" }
    );
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex flex-row items-center`}>
        <Text style={tw`text-h2 font-bold pr-4`}>Profile</Text>
        <TouchableOpacity
          style={tw`bg-white p-2 rounded-3`}
          onPress={() => editProfile()}
        >
          <Pencil color="#0E2989" />
        </TouchableOpacity>
      </View>

      <View style={tw`flex w-full lg:flex-row  lg:flex-row  my-8 `}>
        <View style={tw`flex lg:flex-col lg:min-w-[430px] `}>
          <View
            style={tw` flex w-full flex-row pt-6 min-h-[150px] bg-white rounded-3 max-w-auto justify-center`}
          >
            <View style={tw`flex  items-center justify-center  pl-8 mt-2`}>
              <Avatar
                rounded
                size={95}
                source={
                  partnerInfo.image
                    ? { uri: partnerInfo.image.toString() }
                    : undefined
                }
                renderPlaceholderContent={
                  <View
                    style={tw`bg-gray-500 h-full w-full justify-center items-center`}
                  >
                    <Image />
                  </View>
                }
              />
              <View style={tw`flex flex-row  justify-around bottom-18 pl-16`}>
                <Text
                  style={tw`text-green-400 font-bold text-7xl`}
                  type="h1"
                  weight="bold"
                >
                  .
                </Text>
              </View>
            </View>

            <View style={tw`flex-1  my-8  items-start justify-start lg:pl-8`}>
              <Text type="h2" weight="bold">
                {partnerInfo.firstName}
              </Text>
              <Text style={[tw`flex text-gray-400 my-2`, styles.reveneu]}>
                {partnerInfo.email}{" "}
              </Text>
              <Text style={[tw` text-sm text-black font-bold`]}>
                {partnerInfo.cellphone}
              </Text>
            </View>
          </View>

          <View
            style={tw` flex w-full flex-row pt-6 min-h-[150px] bg-white rounded-3 max-w-auto justify-center my-2`}
          >
            <View style={tw`flex items-center justify-center ml-8`}>
              <Text type="bigger" weight="semiBold">
                Promoter
              </Text>
              <Avatar
                rounded
                size={50}
                source={
                  partnerInfo.image
                    ? { uri: partnerInfo.image.toString() }
                    : undefined
                }
                renderPlaceholderContent={
                  <View
                    style={tw`bg-gray-500 h-full w-full justify-center items-center`}
                  >
                    <Image size={20} />
                  </View>
                }
              />
            </View>

            <View style={tw`flex-grow-1  my-8  items-start justify-start pl-8`}>
              <Text type="regular">{partnerInfo.firstName}</Text>
              <Text style={[tw`flex text-gray-400`, styles.reveneu]}>
                {partnerInfo.email}{" "}
              </Text>
              <Text style={[tw` text-sm text-black font-bold`]}>
                {partnerInfo.cellphone}
              </Text>
            </View>
          </View>
        </View>

        <View style={tw`flex-1 w-full lg:ml-4 mt-4 lg:mt-0 bg-white rounded-3`}>
          <View style={tw`flex-col pt-6 min-h-[100px] px-8`}>
            <Text style={tw`text-h2 mb-7 font-bold`}>Contacts</Text>
            <View style={tw`flex-row flex-wrap`}>
              <View style={tw`md:w-1/2 w-full pr-3`}>
                <View style={tw`flex-col`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Email</Text>
                  <Text weight="bold">{partnerInfo.email}</Text>
                </View>
              </View>
              <View style={tw`md:w-1/2 w-full pr-3 my-2`}>
                <View style={tw`flex-col`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Company name</Text>
                  <Text weight="bold">{partnerInfo.companyName}</Text>
                </View>
              </View>
              <View style={tw`md:w-1/2 w-full pr-3  my-2`}>
                <View style={tw`flex-col`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Company</Text>
                  <Text weight="bold">{partnerInfo.cellphone}</Text>
                </View>
              </View>
              <View style={tw`md:w-1/2 w-full pr-3  my-2`}>
                <View style={tw`flex-col`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Billing Info</Text>
                  <Text weight="bold">{partnerInfo.billingInfo} </Text>
                </View>
              </View>
              <View style={tw`md:w-1/2 w-full pr-3  my-2`}>
                <View style={tw`flex-bold`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Direct</Text>
                  <Text weight="bold">{partnerInfo.direct}</Text>
                </View>
              </View>
              <View style={tw`md:w-1/2 w-full pr-3  my-2`}>
                <View style={tw`flex-bold`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Address</Text>
                  <Text weight="bold">{partnerInfo.address}</Text>
                </View>
              </View>
              <View style={tw`md:w-1/2 w-full pr-3  my-2`}>
                <View style={tw`flex-col flex-wrap`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Direct</Text>
                  <Text weight="bold">{partnerInfo.direct}</Text>
                </View>
              </View>
              <View style={tw` md:w-1/2 w-full pr-3`}>
                <View style={tw`flex flex-col`}>
                  <Text style={tw`w-5/12 text-gray-400`}>Company Address</Text>
                  <Text weight="bold">
                    4951 Cottrill Lane, Saint Louis, MO,{"\n"} Missouri, 63141
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Profile;

const styles = StyleSheet.create({
  name: {
    fontFamily: "OpenSans_700Bold",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: 32.68,
  },
  reveneu: {
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: 19.07,
  },
});
