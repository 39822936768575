import dayjs from "dayjs";

import {
  regexPhoneNumberInvalid,
  expireDateInvalid,
} from "./regularExpression";

///Validate format phone number
export const formatPhoneNumber = (e: string) => {
  const match = e.match(regexPhoneNumberInvalid);
  if (match) {
    const data = match[1] + " " + match[2] + " " + match[3];
    return data;
  } else {
    return e;
  }
};

export const formatExpireDate = (word: string) => {
  const match = word.match(expireDateInvalid);
  if (match) {
    const matcheo = match[1] + "/" + match[2];
    return matcheo;
  } else {
    return word;
  }
};

export const toDayjs = (
  dateString: string,
  format: string = "DD.MM.YYYY hh:mm A"
) => {
  return dayjs(dateString).format(format);
};
