import { colors } from "@/lib";
import React from "react";
import Svg, { Path } from "react-native-svg";

export const Checkdasda = (props: { color?: string }) => {
  const { color = "#5E6CB2" } = props;
  return (
    <Svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={{ marginRight: 4 }}
    >
      <Path
        d="M9 0.5C11.2714 0.5 13.4043 1.3835 15.0104 2.98957C16.6165 4.59572 17.5 6.72863 17.5 9C17.5 11.2714 16.6165 13.4043 15.0104 15.0104C13.4043 16.6165 11.2714 17.5 9 17.5C6.72859 17.5 4.59568 16.6165 2.98957 15.0104C1.38346 13.4043 0.5 11.2714 0.5 9C0.5 6.72862 1.38347 4.59567 2.98957 2.98957C4.59564 1.3835 6.72859 0.5 9 0.5Z"
        fill={color}
        stroke={color}
      />
      <Path
        d="M13.299 6.11241C13.0931 5.90653 12.7592 5.90653 12.5533 6.11244L7.89649 10.7692L5.44515 8.31787C5.23924 8.11196 4.90533 8.11196 4.69938 8.31787C4.49344 8.52378 4.49344 8.85769 4.69938 9.06364L7.52359 11.8878C7.62656 11.9908 7.76153 12.0423 7.89646 12.0423C8.03138 12.0423 8.16638 11.9908 8.26932 11.8878L13.299 6.85818C13.5049 6.65227 13.5049 6.31835 13.299 6.11241Z"
        fill={colors.white}
      />
    </Svg>
  );
};
