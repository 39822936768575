import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "react-native-calendars";
import { colors, tw } from "@/lib";
import {
  OpenSans_400Regular,
  OpenSans_600SemiBold,
} from "@expo-google-fonts/open-sans";
import { DataCalendar } from "@/types/calendar.type";
import * as dateHelper from "date-fns";
import dayjs from "dayjs";
import { Obj } from "reselect/es/types";
import { useDeviceContext } from "twrnc";
import { CalendarHeader } from "@/components/Calendars";

interface Props {
  width: number | string;
  height: number | string;
  minDate?: string | undefined;
  fromDate?: string | undefined;
  toDate?: string | undefined;
  onEvent?: (from: string, to: string) => void;
}

interface ICalendarDays {
  [key: string]: ICalendarOptions;
}

interface ICalendarOptions {
  selected?: boolean;
  color?: string;
  textColor?: string;
  startingDay?: boolean;
  endingDay?: boolean;
  disabled?: boolean;
}

interface IDateRange {
  startDate?: DataCalendar;
  endDate?: DataCalendar;
}

const calendarTheme: any = {
  arrowColor: colors.blue,
  todayTextColor: colors.blue,
  textDayFontFamily: OpenSans_400Regular,
  textMonthFontFamily: OpenSans_600SemiBold,
  textDayHeaderFontFamily: OpenSans_600SemiBold,
  "stylesheet.calendar.header": {
    dayTextAtIndex0: {
      color: colors.red,
    },
    dayTextAtIndex1: {
      color: "black",
    },
    dayTextAtIndex2: {
      color: "black",
    },
    dayTextAtIndex3: {
      color: "black",
    },
    dayTextAtIndex4: {
      color: "black",
    },
    dayTextAtIndex5: {
      color: "black",
    },
    dayTextAtIndex6: {
      color: colors.red,
    },
    monthText: {
      fontWeight: 600,
      size: 14,
    },
    arrow: {},
    header: {
      flexDirection: "row",
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 6,
    },
  },
  "stylesheet.day.basic": {
    selected: {
      borderRadius: 8,
      backgroundColor: colors.blue,
    },
  },
  textDayFontSize: 12,
  textMonthFontSize: 16,
};

export function AppCalendar({
  height,
  width,
  fromDate,
  toDate,
  minDate,
  onEvent,
}: Props) {
  useDeviceContext(tw);

  const [calendarDays, setCalendarDays] = useState<ICalendarDays>({});
  const [dateRange, setDateRange] = useState<IDateRange>({});
  const dateRangeRef = useRef<IDateRange>();
  dateRangeRef.current = dateRange;

  const [currentDay, setCurrentDay] = useState<string>(
    fromDate || dayjs().toString()
  );

  const setDateRangeFromCalendar = (_requestedDays: DataCalendar) => {
    const newDateRange: IDateRange = {};

    const defineDates = (start: DataCalendar, end: DataCalendar) => {
      newDateRange["startDate"] = { ...start };
      newDateRange["endDate"] = { ...end };
    };

    if (dateRangeRef.current?.startDate === undefined) {
      defineDates(_requestedDays, _requestedDays);
    } else {
      if (
        dayjs(_requestedDays.dateString).diff(
          dateRangeRef.current?.startDate.dateString,
          "d"
        ) <= 0
      ) {
        defineDates(_requestedDays, _requestedDays);
      } else {
        defineDates(dateRangeRef.current?.startDate, _requestedDays);
      }
      if (
        dateRangeRef.current?.startDate.dateString ===
          _requestedDays.dateString ||
        dateRangeRef.current?.endDate?.dateString === _requestedDays.dateString
      ) {
        defineDates(_requestedDays, _requestedDays);
      }
    }
    setDateRange(newDateRange);
    generateDaysSelecteds(newDateRange);
  };

  const generateDaysSelecteds = (pDateRange: IDateRange) => {
    const newMarkedDates: ICalendarDays = {};
    let sYear: number = 0;
    let sMonth: number = 0;
    let sDay: number = 0;
    let eYear: number = 0;
    let eMonth: number = 0;
    let eDay: number = 0;
    let data: string[] = [];

    if (pDateRange.startDate && pDateRange.endDate) {
      const { year: stYear, month: stMonth, day: stDay } = pDateRange.startDate;
      const { year: enYear, month: enMonth, day: enDay } = pDateRange.endDate;
      sYear = stYear;
      sMonth = stMonth;
      sDay = stDay;
      eYear = enYear;
      eMonth = enMonth;
      eDay = enDay;
    } else if (pDateRange.startDate && pDateRange.endDate === undefined) {
      const { year: stYear, month: stMonth, day: stDay } = pDateRange.startDate;
      sYear = stYear;
      sMonth = stMonth;
      sDay = stDay;
      eYear = stYear;
      eMonth = stMonth;
      eDay = stDay;
    }

    const interval = {
      start: new Date(sYear, sMonth - 1, sDay),
      end: new Date(eYear, eMonth - 1, eDay),
    };
    const daysOfInterval = dateHelper.eachDayOfInterval(interval);
    daysOfInterval.map((item: Date, index: number) => {
      const options = formatToDays(index, daysOfInterval.length);
      const stringDate = dayjs(item).format("YYYY-MM-DD");
      data.push(stringDate);
      newMarkedDates[stringDate] = options;
    });
    onEvent && onEvent(data[0], data[data.length - 1]);
    setCalendarDays(newMarkedDates);
  };

  const formatToDays = (index: number, lenght: number) => {
    return {
      color:
        index > 0 && index < lenght - 1
          ? colors.calendarLightBlue
          : colors.blue,
      textColor: index > 0 && index < lenght - 1 ? colors.blue : "white",
      ...(index === 0 && {
        startingDay: true,
        customContainerStyle: {
          borderRadius: "8px",
          width: "35px",
        },
      }),
      ...(index === lenght - 1 && {
        endingDay: true,
        customContainerStyle: {
          borderRadius: "8px",
          width: "35px",
        },
      }),
    };
  };

  useEffect(() => {
    if (fromDate && toDate) {
      let from = dayjs(fromDate);
      let to = dayjs(toDate);

      if (minDate) {
        const getFromOrToday = minDate && dayjs().diff(dayjs(fromDate)) <= 0;
        from = getFromOrToday ? dayjs(fromDate) : dayjs();
        to = getFromOrToday ? dayjs(toDate) : dayjs();
      }

      const range: IDateRange = {
        startDate: {
          day: from.date(),
          month: from.month() + 1,
          timestamp: from.unix(),
          dateString: from.format("YYYY-MM-DD"),
          year: from.year(),
        },
        endDate: {
          day: to.date(),
          month: to.month() + 1,
          timestamp: to.unix(),
          dateString: to.format("YYYY-MM-DD"),
          year: to.year(),
        },
      };

      setDateRange(range);
      generateDaysSelecteds(range);
    }
  }, [fromDate, toDate]);

  return (
    <Calendar
      initialDate={currentDay}      
      minDate={minDate}
      style={{ width, ...tw`shadow-md rounded-3 px-2 pt-3` }}
      theme={calendarTheme}
      markingType={dateRange?.startDate === undefined ? "dot" : "period"}
      markedDates={calendarDays as Obj<any>} //getMarkedDates()}
      onDayPress={(request) => setDateRangeFromCalendar(request)}
      enableSwipeMonths={true}
      renderHeader={(date) => (
        <CalendarHeader
          date={currentDay}
          onArrowPress={(month) => {
            setCurrentDay(dayjs(date).add(month, "month").toString());
          }}
        />
      )}
      hideArrows
    />
  );
}
