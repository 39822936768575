import React, { useState } from "react";
import { View, ScrollView } from "react-native";

import { toDayjs, tw } from "@/lib";

import { Button, Text } from "@/components/Commons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Avatar } from "react-native-elements";
import { Image } from "@/components/Icons";
import { useNavigate } from "@/routes";
import { useDeviceContext } from "twrnc";

interface Ticket {
  id: string;
  name: string;
  creationDate: string;
  number: number;
  description: string;
}

export const TicketList = ({
  navigateToPersonalRoute = "clientNewTicket",
}: {
  navigateToPersonalRoute?: string;
}) => {
  useDeviceContext(tw);

  const [tab, changeTab] = useState<"my" | "all">("all");

  const navigate = useNavigate();

  const [tickets, changeTickets] = useState<Ticket[]>([
    {
      id: "ticket-1",
      name: "userName",
      creationDate: "2021-04-29T09:00:00.000Z",
      number: 10,
      description: "Ticket description",
    },
  ]);

  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();
    return isActive
      ? {
          btn: tw`bg-white rounded-3`,
          text: tw`text-primary`,
        }
      : {
          text: tw`text-dark-gray`,
        };
  };

  const tabs = () => {
    const myTickets = getTabStyle(() => tab == "my");
    const allTickets = getTabStyle(() => tab == "all");
    return (
      <View
        style={tw`flex-row bg-[#EBEBF0] max-w-[304px] w-full h-10 my-4 md:my-0 rounded-3`}
      >
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            myTickets.btn,
          ]}
          onPress={() => {
            tab != "my" && changeTab("my");
          }}
        >
          <Text weight="semiBold" style={myTickets.text}>
            My tickets
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            allTickets.btn,
          ]}
          onPress={() => {
            tab != "all" && changeTab("all");
          }}
        >
          <Text weight="semiBold" style={allTickets.text}>
            All Tickets
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`md:flex-row justify-between`}>
        <View>
          <Text type="h2">Tickets</Text>
        </View>

        {tabs()}

        <View style={tw`max-w-[172px] w-full`}>
          <Button
            type="outlined"
            onPress={() => navigate(navigateToPersonalRoute)}
          >
            New Ticket
          </Button>
        </View>
      </View>
      <ScrollView
        horizontal
        contentContainerStyle={tw`w-full flex-col pt-7 min-w-150`}
      >
        {tickets.map((e) => (
          <View
            style={[
              tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center`,
              ,
              { zIndex: "auto" },
            ]}
            key={e.id}
          >
            <View style={tw`w-1/12`}>
              <Avatar
                size={44}
                rounded
                renderPlaceholderContent={
                  <View
                    style={tw`bg-gray-400 w-full h-full items-center justify-center`}
                  >
                    <Image size={20} />
                  </View>
                }
              />
            </View>
            <View style={tw`w-2/12`}>
              <Text weight="semiBold">{e.name}</Text>
            </View>
            <View style={tw`w-3/12`}>
              <Text type="caption" style={tw`text-dark-gray`}>
                {toDayjs(e.creationDate)}
              </Text>
            </View>
            <View style={tw`w-1/12`}>
              <Text
                type="caption"
                style={[
                  tw`px-1 bg-light-gray text-dark-gray rounded`,
                  { width: "fit-content" },
                ]}
              >
                №{e.number}
              </Text>
            </View>
            <View style={tw`w-4/12`}>
              <Text weight="semiBold">{e.description}</Text>
            </View>
            <View style={tw`w-1/12`}></View>
          </View>
        ))}
      </ScrollView>
    </View>
  );
};
