import React from "react";
import {
  isValidPhoneNumber,
  Modal,
  phoneMasK,
  required,
  tw,
  useFieldControl,
  zipMask,
} from "@/lib";

import { Pressable, View } from "react-native";
import { Avatar } from "react-native-elements";

import { CustomButton as Button } from "./Button";
import { CustomText as Text } from "./Text";
import { TextInput } from "@/components/Inputs";
import { Image } from "@/components//Icons";
import { ChangeWSImage } from "@/components/ClientCompanyInfo";
import MaskInputs from "@/components/Inputs/MaskInput";

interface ProfileInfoForm {
  image?: File | string;
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  cellphone?: string;
  companyName?: string;
  companyNumber?: string;
  billingInfo?: string;
  direct?: string;
}
interface ProfileInfoFormProp {
  value?: ProfileInfoForm;
  onChange?: (input: ProfileInfoForm) => void;
}

export const EditProfile = (props: ProfileInfoFormProp) => {
  const form = {
    image: useFieldControl(props.value?.image),
    email: useFieldControl(props.value?.email),
    firstName: useFieldControl(props.value?.firstName || "", [required]),
    lastName: useFieldControl(props.value?.lastName || "", [required]),
    address: useFieldControl(props.value?.address || "", [required]),
    cellphone: useFieldControl(props.value?.cellphone || "", [
      required,
      isValidPhoneNumber("CA"),
    ]),
    companyName: useFieldControl(props.value?.companyName || "", [required]),
    companyNumber: useFieldControl(props.value?.companyNumber || "", [
      required,
      isValidPhoneNumber("CA"),
    ]),
    billingInfo: useFieldControl(props.value?.billingInfo || "", [required]),
    direct: useFieldControl(props.value?.direct || "", [
      required,
      isValidPhoneNumber("CA"),
    ]),
  };

  const changeImageAttempt = () => {
    Modal.show(
      <ChangeWSImage
        image={form.image.value}
        onChange={form.image.handleInputValue}
      />,
      { width: 480 }
    );
  };

  const imageUri =
    typeof form.image.value == "string"
      ? { uri: form.image.value }
      : form.image.value
      ? { uri: URL.createObjectURL(form.image.value!) }
      : undefined;

  const saveForm = () => {
    if (valid) {
      props.onChange &&
        props.onChange({
          firstName: form.firstName.value,
          email: form.email.value,
          lastName: form.companyName.value,
          address: form.address.value,
          cellphone: form.cellphone.value,
          companyName: form.companyName.value,
          companyNumber: form.companyNumber.value,
          image: imageUri?.uri,
          //billingInfo:form.billingInfo.value,
          direct: form.direct.value,
        });
      Modal.dismiss();
    }
  };

  const valid =
    form.firstName.valid &&
    form.lastName.valid &&
    form.address.valid &&
    form.companyName.valid &&
    form.companyNumber.valid &&
    form.direct.valid;

  return (
    <View>
      <Text type="h2">Edit Profile</Text>
      <View style={tw`flex-row items-center my-6 flex-wrap`}>
        <View style={tw`w-auto`}>
          <Avatar
            rounded
            source={imageUri}
            size={128}
            renderPlaceholderContent={
              <View
                style={tw`bg-gray-500 h-full w-full justify-center items-center`}
              >
                <Image />
              </View>
            }
          />
        </View>
        <View style={tw`pl-10 w-auto`}>
          <Pressable onPress={changeImageAttempt}>
            <Text style={tw`text-btn`} weight="semiBold">
              Upload Photo
            </Text>
          </Pressable>
          {form.image.value && (
            <Pressable onPress={() => form.image.handleInputValue(undefined)}>
              <Text style={tw`text-red-600`} weight="semiBold">
                Delete Photo
              </Text>
            </Pressable>
          )}
        </View>
      </View>
      <View style={tw`flex-row flex-wrap`}>
        <View style={tw`w-1/2 pr-2`}>
          <TextInput
            value={form.firstName.value}
            label="First Name"
            padding={false}
            onChangeText={form.firstName.handleInputValue}
            placeholder="First Name"
            type="letters"
          />
        </View>
        <View style={tw`w-1/2 pl-2`}>
          <TextInput
            value={form.address.value}
            label="Address"
            padding={false}
            onChangeText={form.address.handleInputValue}
            placeholder="Address"
          />
        </View>
        <View style={tw`w-1/2 pr-2`}>
          <TextInput
            value={form.lastName.value}
            label="Last Name"
            padding={false}
            onChangeText={form.lastName.handleInputValue}
            placeholder="Last Name"
            type="letters"
          />
        </View>
        <View style={tw`w-1/2 pl-2`}>
          <MaskInputs
            label="Company Number"
            errorMessage={form.companyNumber.error}
            value={form.companyNumber.value}
            onChangeText={form.companyNumber.handleInputValue}
            mask={phoneMasK}
          />
        </View>
        <View style={tw`w-1/2 pr-2`}>
          <TextInput
            value={form.companyName.value}
            label="Company Name"
            padding={false}
            onChangeText={form.companyName.handleInputValue}
            placeholder="Company Name"
            type="letters"
          />
        </View>
        <View style={tw`w-1/2 pl-2`}>
          <MaskInputs
            label="Direct"
            errorMessage={form.direct.error}
            value={form.direct.value}
            onChangeText={form.direct.handleInputValue}
            mask={phoneMasK}
          />
        </View>
      </View>
      <View style={tw`self-end w-full max-w-[156px]`}>
        <Button style={tw`px-0`} onPress={saveForm} disabled={!valid}>
          Save Changes
        </Button>
      </View>
    </View>
  );
};
