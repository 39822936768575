import React from "react";
import Svg, { Path } from "react-native-svg";

export const AvatarsLogo = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="34" height="34" viewBox="0 0 34 34" fill="none">
      <Path
        d="M16.9785 21.601C11.6606 21.601 7.11914 22.4051 7.11914 25.6252C7.11914 28.8453 10.7701 29.6782 16.9785 29.6782C23.187 29.6782 26.8366 28.8728 26.8366 25.654C26.8366 22.4352 22.3253 21.601 16.9785 21.601Z"
        
        fillRule="evenodd"
        clipRule="evenodd"
        stroke={color}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"

      />
      <Path
        d="M16.978 17.0081C20.4679 17.0081 23.2965 14.1783 23.2965 10.6884C23.2965 7.19851 20.4679 4.36993 16.978 4.36993C13.4881 4.36993 10.6582 7.19851 10.6582 10.6884C10.6465 14.1665 13.4567 16.9964 16.9335 17.0081H16.978Z"
        
        fillRule="evenodd"
        clipRule="evenodd"
        stroke={color}
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        
      />
    </Svg>
  );
};
