import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Warning = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { color = "white", style } = props;
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" style={style}>
      <Path
        d="M9.49991 11.862C9.17203 11.862 8.90625 11.5715 8.90625 11.2132V6.67216C8.90625 6.3139 9.17203 6.02344 9.49991 6.02344C9.8278 6.02344 10.0936 6.3139 10.0936 6.67216V11.2132C10.0936 11.5715 9.8278 11.862 9.49991 11.862Z"
        fill={color}
      />
      <Path
        d="M18.7487 15.1189L11.3579 1.65867C10.5193 0.130882 8.48243 0.127922 7.64224 1.65867L0.251405 15.1189C-0.447528 16.3918 0.393365 18.0248 1.7571 18.0248H17.2429C18.6069 18.0248 19.4474 16.3916 18.7487 15.1189V15.1189ZM17.2429 16.7273H1.75713C1.31478 16.7273 1.04354 16.1974 1.26958 15.7863L8.66038 2.32605C9.03955 1.63544 9.96007 1.63463 10.3397 2.32605L17.7305 15.7863C17.9563 16.197 17.6857 16.7273 17.2429 16.7273Z"
        fill={color}
      />
      <Path
        d="M9.49987 14.4559C9.99168 14.4559 10.3904 14.0203 10.3904 13.4829C10.3904 12.9454 9.99168 12.5098 9.49987 12.5098C9.00806 12.5098 8.60938 12.9454 8.60938 13.4829C8.60938 14.0203 9.00806 14.4559 9.49987 14.4559Z"
        fill={color}
      />
    </Svg>
  );
};
