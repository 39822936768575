import React, { useState, useEffect, useRef } from "react";
import {
  KeyboardAvoidingView,
  StatusBar,
  StyleSheet,
  View,
} from "react-native";
import { SideRegistration } from "@/components/Layouts/SideRegistration";
import { useDeviceContext } from "twrnc";
import tw from "@/lib/tailwind";
import { TextInput } from "@/components/Inputs/TextInput";
import { Button, Text } from "@/components/Commons";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { colors, showFriendlyErrorFromHTMLCode, useFieldControl } from "@/lib";
import { useNavigate } from "@/routes";
import { ApiService, MailVerification } from "@/services";
import { store } from "@/store";
import { AxiosError } from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAlert } from "@/hooks";
import { ResponsiveLayout } from "@/components/Layouts/ResponsiveLayout";

export const VerificationEmail = () => {
  useDeviceContext(tw);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const errorInitialState = { show: false, msg: "" };
  //Error indicator
  const [errorMsg, setErrorMsg] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );
  const { showSwal } = useAlert();

  const [seconds, setSeconds] = useState(60);
  let interval = useRef<any>();

  useEffect(() => {
    valueSeconds();
  }, []);

  //Hook to check if the seconds are greather than 0
  // and if it is less than 0 clear the interval
  useEffect(() => {
    if (seconds === 0) {
      clearSeconds();
    }
  }, [seconds]);

  const valueSeconds = () => {
    const decreaseNum = () => setSeconds((prev) => prev - 1);
    interval.current = setInterval(decreaseNum, 1000);
  };
  const clearSeconds = () => clearInterval(interval.current);

  const checkNewPassword = async () => {
    const email: string = store.getState().register.admin.email;
    try {
      //Start loading
      setIsLoading(true);
      //Check if email exists
      const emailResponse = await ApiService.checkEmail(email);
      //Get the availability answer
      const availability =
        emailResponse?.data?.result?.coreApiResponse?.available;

      //Send verification code if email exists
      if (availability) {
        await MailVerification.resendWorkspaceVerifyEmail(email);
        setSeconds(60);
        valueSeconds();
        navigate("registerMail");
      } else {
        setIsLoading(false);
        setErrorMsg({ show: true, msg: "The email doesn't exists" });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    }
  };

  const setError = (err: any) => {
    setIsLoading(false);
    //Show error
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    showSwal({ msg, type: "error" });
  };
  return (
    <KeyboardAvoidingView behavior="padding" style={tw`flex-grow-1`}>
      <StatusBar
        // @ts-ignore
        style="light"
      />
      <View
        style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}]`}
      >
        <SideRegistration step={1} />
        <AuthLayout>
          <View style={tw`flex sm:hidden my-4`}>
            <ResponsiveLayout />
          </View>
          <View
            style={tw`flex flex-grow-1 flex-col justify-center  w-full max-w-[360px] items-center`}
          >
            <View style={tw`flex flex-col items-center justify-center`}>
              <Text type="h2" weight="bold">
                {" "}
                Please Check Your Inbox!
              </Text>
              <Text style={tw`my-5 text-gray-500 w-[320px]`}>
                We have sent you a confirmation email. Please click on the
                included link to verify your email address and to continue the
                sign up.
              </Text>
            </View>

            {errorMsg.show && (
              <SweetAlert
                error
                show={errorMsg.show}
                title={errorMsg.msg}
                onConfirm={() => setErrorMsg({ show: false, msg: "" })}
              />
            )}
          </View>
        </AuthLayout>
      </View>
    </KeyboardAvoidingView>
  );
};

//export default Registration

const styles = StyleSheet.create({
  loginText: {
    fontWeight: "bold",
    fontSize: 30,
    fontFamily: "BarlowCondensed_700Bold",
  },
});
