import React, { FC } from "react";
import { useWindowDimensions } from "react-native";

import Modal, { ModalContent } from "react-native-modals";

interface Props {
  visible: boolean;
  onTouchOutside: () => void;
  width?: number;
}

export const AppModal: FC<Props> = ({
  children,
  visible = false,
  onTouchOutside,
  width = 380,
}) => {
  const { width: screenWidth } = useWindowDimensions();
  const modalWidth: number = screenWidth < width ? screenWidth : width;

  return (
    <Modal visible={visible} onTouchOutside={onTouchOutside} width={modalWidth}>
      <ModalContent>{children}</ModalContent>
    </Modal>
  );
};
