import React, { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  NativeSyntheticEvent,
  Platform,
  Pressable,
  TextInputKeyPressEventData,
  View
} from "react-native";
import { useDeviceContext } from "twrnc";
import Icon from "react-native-vector-icons/FontAwesome";
import { tw, colors } from "@/lib";
import { Input, InputProps } from "react-native-elements";
import { Text } from "@/components/Commons";
import { useAppDispatch } from "@/store/hooks";
import { clearInfo, setInfo } from "@/store/style.store";

export interface DropDownItem {
  label: string;
  value: string;
  metaInfo?: any;
}

export const SimpleDropdown = (
  props: InputProps & {
    lazy?: boolean;
    loading?: boolean;
    data?: DropDownItem[];
    onSelect?: (input: string, metaInfo?: any) => void;
    small?: boolean
  }
) => {
  useDeviceContext(tw);

  const menu = useRef<any>(null);
  const icon = useRef<any>(null);
  const input = useRef<any>(null);

  const { data } = props;
  const dispatch = useAppDispatch()

  const [state, setState] = useState({ isFocused: false, onEdit: false });

  const [value, setValue] = useState({ value: props.value || "" });

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    setValue({
      value:
        data?.find((e) => e.value == props.value)?.label || props.value || "",
    });
  }, [props.value]);

  const focus = () => {
    if(!props.disabled) {
      setState({ isFocused: true, onEdit: false });
      dispatch(setInfo({ zindex: 456 }))
    }
  };

  const blur = () => {
    if(!props.disabled) {
      setState({ isFocused: false, onEdit: false });
      dispatch(setInfo({ zindex: 0 }))
      input.current?.blur();
    }
  };

  const onEditValue = () => setState({ ...state, onEdit: true });

  const onUpdateValue = (input: string) => {
    setValue({ value: input });
    if (props.onChangeText) {
      props.onChangeText(input);
    }
  };

  const keyHandled = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    switch (e.nativeEvent.key) {
      case "ArrowDown":
        setSelectedIndex(Math.min(selectedIndex + 1, dataOptions!.length - 1));
        e.preventDefault();
        break;

      case "ArrowUp":
        setSelectedIndex(Math.max(selectedIndex - 1, 0));
        e.preventDefault();
        break;
      case "Enter":
        if (selectedIndex > -1) {
          onUpdateValue(dataOptions![selectedIndex].label);
        }
        setSelectedIndex(-1);

      default:
        e.preventDefault();
        break;
    }
  };

  const dataOptions = data;
  const values = dataOptions?.map((e) => e.value);

  const itemRenderer = ({
    item,
    onSelect,
  }: {
    item: DropDownItem;
    onSelect: (item: DropDownItem) => void;
  }) => {
    return (
      <Pressable
        key={item.value}
        style={tw.style(
          "py-2",
          "px-2"
          // {
          //   "bg-blue-300": item.value == values?.at(selectedIndex),
          // }
        )}
        onPress={(e) => (e.preventDefault(), onSelect(item))}
        focusable={false}
      >
        <Text >{item.label}</Text>
      </Pressable>
    );
  };

  return (
    <Pressable
      style={[tw`w-full `, { zIndex: "auto" }]}
      focusable={false}
      ref={icon}
    >
      <Input
        ref={input}
        autoCompleteType={undefined}
        {...{ ...props, style: null }}
        value={value.value}
        rightIcon={

          <Icon
            name={state.isFocused ? "chevron-up" : "chevron-down"}

            // @ts-ignore
            type="font-awesome"
            color="#9CA3AF"
            disabled={props.disabled}
            onPress={state.isFocused ? blur : focus}
          />
        }
        labelStyle={tw`font-normal text-input-label text-gray-500`}
        inputContainerStyle={[
          tw.style(
            "w-full",
            "h-input",
            "border",
            "border-gray-lightest",
            "rounded-3",
            "px-2",
            "bg-white",
            state.isFocused ? "rounded-b-0" : ""
          ),
          {
            backgroundColor: props.disabled
              ? colors.grayLightest
              : colors.white,
            borderColor: colors.grayLightest,
          },
          props.style,
        ]}
        // @ts-ignore
        inputStyle={Platform.select({
          web: {
            outline: "none",
            fontFamily: "Roboto_400Regular",
            fontSize: 14,
            cursor: "pointer",
            caretColor: "transparent",
            // Conditional width if small is true 
            ...(props.small && { width: 40 })
          },
        })}
        containerStyle={tw`h-[88px] px-2`}
        onFocus={focus}
        onBlur={(e) => {
          let noBlur = false;
          menu.current?.childNodes.forEach((i: any) => {
            //@ts-ignore
            if (i == e.relatedTarget) noBlur = true;
          });
          //@ts-ignore
          noBlur = noBlur || e.relatedTarget == icon.current;
          if (!noBlur) {
            e.preventDefault();
            blur();
          }
        }}
        onChangeText={onUpdateValue}
        onChange={onEditValue}
        onKeyPress={keyHandled}
      />

      <View style={tw`px-2 z-50`}>
        <View style={tw`relative`}>
          <View
            ref={menu}
            style={[
              tw.style([
                "bg-white",
                state.isFocused ? "relative max-h-[150px] " : "hidden",
                state.isFocused ? "absolute max-h-[250px] " : "hidden",
                "w-full",
                "border-blue-600",
                "border",
                "border-gray-lightest",
                "-top-7",
              ]),
              { overflowY: "scroll" },
            ]}
          >
            {(dataOptions?.length &&
              dataOptions.map((item: DropDownItem) =>
                itemRenderer({
                  item,
                  onSelect: (input) => {
                    setValue({ value: input.label });
                    if (props.onSelect)
                      props.onSelect(item.value, item.metaInfo);

                    blur();
                  },
                })
              )) ||
              (props.lazy && data == undefined && props.loading && (
                <View style={tw`my-4`}>
                  <ActivityIndicator size="large" />
                </View>
              )) || <Text weight="semiBold"> No matching options </Text>}
          </View>
        </View>
      </View>
    </Pressable>
  );
};
