import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Circle, Path, Rect } from "react-native-svg";

export const Dashboard = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none">
      <Rect
        x="0.6"
        y="0.6"
        width="7.80009"
        height="7.80009"
        rx="2.4"
        stroke="white"
        strokeWidth="1.2"
      />
      <Rect
        x="0.6"
        y="10.6"
        width="7.80009"
        height="7.80009"
        rx="2.4"
        stroke="white"
        strokeWidth="1.2"
      />
      <Rect
        x="10.6"
        y="0.6"
        width="7.80009"
        height="7.80009"
        rx="2.4"
        stroke="white"
        strokeWidth="1.2"
      />
      <Rect
        x="10.6"
        y="10.6"
        width="7.80009"
        height="7.80009"
        rx="2.4"
        stroke="white"
        strokeWidth="1.2"
      />
    </Svg>
  );
};
