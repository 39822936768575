import { TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Button, Loading, Text } from "@/components/Commons";
import { useToast } from "react-native-toast-notifications";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { useDeviceContext } from "twrnc";
import { colors, Modal, showFriendlyErrorFromHTMLCode, tw, useFieldControl } from "@/lib";
import { Dropdown } from "react-bootstrap";
import { Icon } from "react-native-elements";
import { UserService, WorkspaceService } from "@/services";
import { SuperAdminUser } from "@/types/superAdmin.types";
import { ScrollView } from "react-native-gesture-handler";
import { AxiosError } from "axios";
import { useAlert } from "@/hooks";
import { UserStatus, WorkspaceInterface } from "@/types";
import { FancyDropdown, SearchBar } from "@/components/Inputs";
import dayjs from "dayjs";

export const Users_Priviledge = () => {
  //Use tailwind
  useDeviceContext(tw);

  const {showSwal} = useAlert();

  //Initialize toasts
  const toast = useToast();

  const [data, setData] = useState<WorkspaceInterface[]>([]);
  //Form Inputs
  const search = useFieldControl<string>("", []);
  const permitionForm = useFieldControl<string>("", []);

  const [state, setState] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadUsers = async () => {
    try {
      const workspaceId = (await WorkspaceService.getCurrent()).data.result.id;
      const list = (await WorkspaceService.getAccessRequest(workspaceId)).data
        .result;

      console.log(list);
      setData(list);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const deleteUser = async (id: number) => {
    try {
      await UserService.deleteUser(id);
      loadUsers();
      Modal.dismiss();
      showToast("User deleted successfully");
    } catch (error) {
      setError(error);
      Modal.dismiss();
    }
  };

  const showModalForRemove = (id: number) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Remove User?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mt-5`}>
          <Text>Are you sure you want to remove this User? </Text>
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button
              style={tw`flex-1`}
              onPress={() => {
                return deleteUser(id);
              }}
            >
              <Text weight="semiBold">Remove</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const submitPermitions = (response: WorkspaceInterface) => {
    try {
      console.log(3);
    } catch (error) {
      setError(error);
    }
  };

  const grantPermition = (response: WorkspaceInterface) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between mb-1`}>
          <Text type="h2" weight="bold">
            Grant Permission?
          </Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View
          style={[
            tw`flex-grow-1 mb-4 w-full justify-between mt-5`,
            { zIndex: "auto" },
          ]}
        >
          <FancyDropdown
            autoCorrect={false}
            placeholder="Select Permissions"
            label="Permissions"
            value={permitionForm.value}
            onSelect={permitionForm.handleInputValue}
            data={[
              { label: "denied", value: "denied" },
              { label: "read only", value: "read only" },
              { label: "read and write", value: "read and write" },
            ]}
          />
        </View>

        <View style={tw`flex flex-row justify-end mt-10`}>
          <View style={tw`flex flex-row w-2/3`}>
            <Button
              style={tw`flex-1`}
              type="outlined"
              onPressOut={Modal.dismiss}
            >
              <Text weight="semiBold">Cancel</Text>
            </Button>

            <Button
              style={tw`flex-1`}
              onPress={() => submitPermitions(response)}
            >
              <Text weight="semiBold">Submit</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  };

  const activateUser = async (user: SuperAdminUser) => {
    try {
      const { id, activationStatus } = user;

      const resp = await UserService.toggleUserActivation(
        id.toString(),
        activationStatus && activationStatus === UserStatus.ACTIVE
          ? UserStatus.INACTIVE
          : UserStatus.ACTIVE
      );
      //@ts-ignore
      setData((prev) => {
        return prev.map((user) => {
          if (user.id === resp.data.result.id) {
            return { ...user, ...resp.data.result };
          }
          return user;
        });
      });

      showToast(
        `User ${
          resp.data.result.activationStatus === UserStatus.INACTIVE
            ? "Deactivated"
            : "Activated"
        } succesfully`
      );
    } catch (err) {
      setError(err);
    }
  };

  const setError = (err: any) => {
    setIsLoading(false);
    //Show error
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    if (error.response && error.response.status === 422) {
      msg = error.response.data?.error?.errorJsonObject?.message;
    }
    showSwal({ msg, type: "error" });
  };

  const showToast = (msg: string) => {
    toast.show(msg, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <FontAwesome name="check" size={18} color={colors.white} />,
    });
  };

  const searchUsers = async (search: string) => {
    console.log(search);
    try {
      const data = (await UserService.searchUser(0, 1000000, search)).data
        .result.list;
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ScrollView
          horizontal
          contentContainerStyle={[tw`lg:w-full  flex-col min-w-230 my-4 px-4 `]}
        >
          <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
            <View style={tw`flex-row justify-between`}>
              <View>
                <Text type="h2">Access Requests</Text>
              </View>
              <View style={tw`flex flex-row w-[200px]  `}>
                <SearchBar
                  value={search}
                  onelementChange={(string) => searchUsers(string)}
                />
              </View>
            </View>

            <View style={tw`flex-col w-full flex-initial `}>
              <View style={tw`flex-row px-4 mb-4 `}>
                <View style={tw`flex flex-1`}>
                  <Text color={colors.gray} weight="semiBold">
                    Full Name{" "}
                  </Text>
                </View>

                <View style={tw`flex flex-1`}>
                  <Text color={colors.gray} weight="semiBold">
                    Current Permition{" "}
                  </Text>
                </View>
                <View style={tw`flex flex-1`}>
                  <Text color={colors.gray} weight="semiBold">
                    Access Requested{" "}
                  </Text>
                </View>

                <View style={tw`flex flex-1`}>
                  <Text color={colors.gray} weight="semiBold">
                    Date{" "}
                  </Text>
                </View>

                <View style={tw`flex flex-1`}>
                  <Text color={colors.gray} weight="semiBold">
                    Status{" "}
                  </Text>
                </View>
              </View>

              {data?.map((response) => {
                /*const textColorByStatus: string =
                  response.activationStatus == UserStatus.INACTIVE
                    ? colors.gray
                    : colors.dark;*/
                return (
                  <View
                    style={[
                      tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`,
                      ,
                      { zIndex: "auto" },
                    ]}
                    key={response.id}
                  >
                    <View
                      style={tw`flex flex-1 w-[50px] lg:max-w-[350px] xl:max-w-[350px]`}
                    >
                      <Text weight="semiBold">
                        {response.user?.firstName} {response.user?.lastName}
                      </Text>
                    </View>

                    <View style={tw`flex flex-1`}>
                      <Text style={tw`sm:pl-8 `} weight="semiBold">
                        {response.status}
                      </Text>
                    </View>
                    <View style={tw`flex flex-1`}>
                      <Text style={tw`lg:pl-8`} weight="semiBold">
                        {response.accessType}
                      </Text>
                    </View>
                    <View style={tw`flex flex-1`}>
                      <Text style={tw`lg:pl-8`} weight="semiBold">
                        {dayjs(response.createdAt).format(
                          "ddd, MMM D, YYYY h:mm A"
                        )}
                      </Text>
                    </View>

                    <View style={tw`flex flex-1`}>
                      <Text style={tw`lg:pl-12`} weight="semiBold">
                        {response.user?.activationStatus}
                      </Text>
                    </View>

                    <View style={[tw`flex w-auto ml-auto`, { zIndex: "auto" }]}>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="link"
                          style={{ lineHeight: 0, ...tw`no-underline` }}
                          id="dropdown-basic"
                          bsPrefix="btn"
                        >
                          <Icon
                            color="#C8C8C8"
                            name="more-vert"
                            size={20}
                            tvParallaxProperties={undefined}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={tw`rounded-3`}>
                          <Dropdown.Item
                            as="button"
                            style={tw` text-btn-menu-item font-semibold`}
                            onClick={() => {
                              //return activateUser(response);
                              //return grantPermition(response)
                            }}
                          >
                            Allow
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            style={tw`text-btn-menu-item font-semibold text-red-500`}
                            onClick={() => {
                              //return showModalForRemove(response.id);
                            }}
                          >
                            Denied
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </View>
                  </View>
                );
              })}
              {data.length === 0 && (
                <View
                  style={tw`flex-row bg-white px-4 py-4 mb-1 rounded-3 items-center mt-2 `}
                >
                  <Text
                    style={tw`w-full text-center`}
                    color={colors.red}
                    weight="semiBold"
                  >
                    There aren't users to show
                  </Text>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      )}
    </>
  );
};
