import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path, G, Defs, ClipPath, Rect, Circle } from "react-native-svg";

export const NoneExistance = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { color = "white", style } = props;
  return (
    <Svg
      width="272"
      height="200"
      viewBox="0 0 272 250"
      fill="none"
      style={style}
    >
      <Path
        d="M128.189 0.655923C152.38 -3.44151 174.652 12.6237 197.297 22.0549C222.966 32.7451 260.248 33.7934 270.1 59.7583C280.481 87.1147 245.052 112.398 242.641 141.552C240.524 167.156 263.593 191.023 257.19 215.905C250.434 242.163 233.386 270.65 207.399 278.516C180.576 286.635 155.924 258.983 128.189 254.952C105.716 251.686 81.3604 267.831 61.2618 257.277C41.1557 246.719 35.7302 221.006 25.2361 200.891C15.174 181.605 2.96986 163.198 0.886411 141.552C-1.30439 118.791 -0.206118 93.8242 13.1421 75.2426C26.272 56.9648 52.5158 55.6273 71.411 43.3771C91.6315 30.2678 104.418 4.6821 128.189 0.655923Z"
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="white"
        stroke={color}
        strokeWidth={"0.1"}
      />

      <Path
        d="M147.019 175H125.404C122.42 175 120 176.392 120 178.108V184.325C120 189.467 127.273 193.65 136.212 193.65C145.151 193.65 152.423 189.467 152.423 184.325V178.108C152.423 176.392 150.004 175 147.019 175Z"
        fill={"url(#paint0_linear_2258_45891)"}
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        stroke={color}
        strokeWidth={"0.1"}
      />
      <Path
        d="M196.604 176.599C185.203 166.288 178.924 152.58 178.924 138V110.643C178.924 90.6972 162.201 74.2199 140.769 72.0675V69C140.769 66.6181 138.634 64.5 136 64.5C133.366 64.5 131.23 66.6181 131.23 69V72.0675C109.798 74.2199 93.0754 90.6972 93.0754 110.643V138C93.0754 152.58 86.7967 166.288 75.3957 176.598C72.4441 179.265 74.4596 183.962 78.7673 183.962H193.232C197.528 183.962 199.563 179.273 196.604 176.599Z"
        fill={"url(#paint1_linear_2258_45891)"}
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        stroke={color}
        strokeWidth={"0.1"}
      />
      <Path
        d="M157.115 126.693C157.048 127.756 157.061 128.12 155.709 129.298C154.622 129.984 152.831 130.43 151.36 130.338C149.89 130.245 148.565 130.051 147.573 129.234C146.581 128.416 146.022 127.818 146.089 126.755C146.089 126.755 147.292 128.155 151.307 128.189C155.193 128.222 157.115 126.693 157.115 126.693Z"
        fill="#333333"
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        stroke={color}
        strokeWidth={"0.1"}
      />
      <Path
        d="M157.115 126.693C157.048 127.756 157.061 128.12 155.709 129.298C154.622 129.984 152.831 130.43 151.36 130.338C149.89 130.245 148.565 130.051 147.573 129.234C146.581 128.416 146.022 127.818 146.089 126.755C146.089 126.755 147.292 128.155 151.307 128.189C155.193 128.222 157.115 126.693 157.115 126.693Z"
        fill="#333333"
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        stroke={color}
        strokeWidth={"0.1"}
      />
      <Path
        d="M115.895 126.627C115.956 127.69 115.94 128.054 117.286 129.241C118.368 129.933 120.157 130.39 121.628 130.306C123.098 130.222 124.424 130.036 125.421 129.225C126.418 128.414 126.981 127.819 126.92 126.755C126.92 126.755 125.709 128.148 121.694 128.158C117.808 128.168 115.895 126.627 115.895 126.627Z"
        fill={"#5E6CB2"}
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        stroke={color}
        strokeWidth={"0.1"}
      />
      <Circle
        cx="136.867"
        cy="144.867"
        r="2.49772"
        transform="rotate(21.5487 136.867 144.867)"
        fill="#333333"
      />
      <g filter="url(#filter0_f_2258_45891)">
        <Circle
          cx="151.962"
          cy="141.065"
          r="4.76923"
          transform="rotate(21.5487 151.962 141.065)"
          fill="#FFDDDD"
        />
      </g>

      <g filter="url(#filter1_f_2258_45891)">
        <Circle
          cx="119.188"
          cy="139.66"
          r="4.76923"
          transform="rotate(21.5487 119.188 139.66)"
          fill="#FFDDDD"
        />
      </g>

      <Defs>
        <filter
          id="filter0_f_2258_45891"
          x="143.191"
          y="132.294"
          width="17.541"
          height="17.541"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />

          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_2258_45891"
          />
        </filter>

        <filter
          id="filter1_f_2258_45891"
          x="110.417"
          y="130.889"
          width="17.541"
          height="17.541"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_2258_45891"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2258_45891"
          x1="113.5"
          y1="169.5"
          x2="143.795"
          y2="179.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="0.791667" stop-color="#BEC8FF" />
        </linearGradient>

        <linearGradient
          id="paint1_linear_2258_45891"
          x1="87"
          y1="51.5"
          x2="150.132"
          y2="211.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BEC8FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};
