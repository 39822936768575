import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import { Dropdown } from "react-bootstrap";

import { tw, Modal, useFieldControl, required, colors } from "@/lib";
import { Text, Button } from "@/components/Commons";
import { AddAdminForm } from "./AddAdminForm";
import { Dropdown as CustomDropdown } from "@/components/Inputs";
import { useNavigate } from "@/routes";
import SweetAlert from "react-bootstrap-sweetalert";

export const SuperAdminList = () => {
  //initialize navigate
  const navigate = useNavigate();

  //Data to Iterate
  const [data, setData] = useState({
    value: [
      {
        id: 1,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        phoneNumber: "+6 (999) 444-33-21",
        role: "Admin",
      },
      {
        id: 2,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        phoneNumber: "+6 (999) 444-33-21",
        role: "Super Admin",
      },
      {
        id: 3,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        phoneNumber: "+6 (999) 444-33-21",
        role: "Support",
      },
      {
        id: 4,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        phoneNumber: "+6 (999) 444-33-21",
        role: "Admin",
      },
    ],
  });

  //BEGIN: Functions to handle the options in the dropdown
  const addAdmin = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end mb-1`}>
          <Text style={tw`font-bold text-2xl`}>Add Admin</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <AddAdminForm modal={Modal} />
      </View>
    );
  };
  const errorInitialState = { show: false, msg: "" };

  const [errorMsg, setErrorMs] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );

  //Input to validate dropdown
  const dropDownControl = {
    item: useFieldControl<string>("", [required]),
  };

  //Assign Admin Popup
  const assignAdmin = (email: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end mb-1`}>
          <Text style={tw`font-bold text-2xl`}>Assign new admin</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>

        <View style={tw`flex-grow-1 mb-4 w-full justify-between mb-2`}>
          <Text>Please select new admin for clients of</Text>
        </View>

        <View style={tw`flex-row justify-start`}>
          <Text style={tw`font-normal text-sm text-gray-400`}>{email}</Text>
        </View>

        <View style={tw`flex w-full my-4 pb-10`}>
          <CustomDropdown
            placeholder="Partner"
            label="Select partner"
            value={dropDownControl.item.value}
            onChangeText={dropDownControl.item.handleInputValue}
          />
        </View>
        <View style={tw`flex flex-row items-end justify-end my-2 `}>
          <Button
            style={tw`max-w-1/2`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`max-w-1/2`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Delete
          </Button>
        </View>
      </View>
    );
  };

  //Delete Admin Popup
  const deleteAdmin = (email: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end mb-1`}>
          <Text style={tw`font-bold text-2xl`}>Delete admin</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex-row justify-start mb-2 `}>
          <Text style={tw`font-normal text-sm text-gray-400`}>{email}</Text>
        </View>
        <View style={tw`flex-grow-1 mb-4 w-full justify-between `}>
          <Text>Are you sure you want to delete this admin?</Text>
        </View>

        <View style={tw`flex w-full my-4`}></View>
        <View style={tw`flex flex-row items-end justify-end my-2 `}>
          <Button
            style={tw`max-w-1/2`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`max-w-1/2`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Delete
          </Button>
        </View>
      </View>
    );
  };

  //Block Admin Popup
  const blockAdmin = (email: string) => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end mb-1`}>
          <Text style={tw`font-bold text-2xl`}>Block Admin</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex-row justify-start mb-2`}>
          <Text style={tw`font-normal text-sm text-gray-400`}>{email}</Text>
        </View>
        <View style={tw`flex-grow-1 w-full justify-between my-2 `}>
          <Text>Are you sure you want to block this admin?</Text>
          <Text>
            Blocked Admin cannot log in and will not receive any compensation.
          </Text>
        </View>

        <View style={tw`flex w-full my-4`}></View>
        <View style={tw`flex flex-row items-end justify-end my-2 `}>
          <Button
            style={tw`max-w-1/2`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`max-w-1/2`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Delete
          </Button>
        </View>
      </View>
    );
  };

  //Edit Compensation Popup
  const editCompensation = () => { };

  //Display View
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text type="h2">Admin List</Text>
        </View>
        <View style={tw`max-w-[172px] w-full`}>
          <Button type="outlined" onPress={addAdmin}>
            Add Admin
          </Button>
        </View>
      </View>
      <View style={tw`flex-col mt-8  w-full flex-initial`}>
        <View style={tw`flex-row px-4 mb-4 `}>
          <View style={tw`w-3/12 `}>
            <Text color={colors.gray} weight="semiBold">Email </Text>
          </View>
          <View style={tw`w-3/12 `}>
            <Text color={colors.gray} weight="semiBold">Name </Text>
          </View>

          <View style={tw`w-3/12 `}>
            <Text color={colors.gray} weight="semiBold">Phone Number </Text>
          </View>

          <View style={tw`w-3/12 `}>
            <Text color={colors.gray} weight="semiBold">Role </Text>
          </View>
        </View>

        {data.value.map((response) => {
          return (
            <View
              style={[
                tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`,
                ,
                { zIndex: "auto" },
              ]}
              key={response.id}
            >
              <View style={tw`w-3/12 `}>
                <Text weight="semiBold" color={colors.dark}>
                  {response.email}
                </Text>
              </View>
              <View style={tw`w-3/12 `}>
                <Text weight="semiBold" color={colors.dark}>
                  {response.name}
                </Text>
              </View>
              <View style={tw`w-3/12 `}>
                <Text weight="semiBold" color={colors.dark}>
                  {response.phoneNumber}
                </Text>
              </View>
              <View style={tw`w-auto  `}>
                <Text weight="semiBold" color={colors.dark} key={response.id}>
                  {response.role}
                </Text>
              </View>

              <View style={[tw`flex w-auto ml-auto`, { zIndex: "auto" }]}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    style={{ lineHeight: 0, ...tw`no-underline` }}
                    id="dropdown-basic"
                    bsPrefix="btn"
                  >
                    <Icon color="#C8C8C8" name="more-vert" size={20} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={tw`rounded-3`}>
                    <Dropdown.Item
                      as="button"
                      style={tw` text-btn-menu-item font-semibold`}
                      onClick={() => {
                        return editCompensation();
                      }}
                    >
                      Change role
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      style={tw` text-btn-menu-item font-semibold`}
                      onClick={() => {
                        return assignAdmin(response.email);
                      }}
                    >
                      Assign Admin
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </View>
            </View>
          );
        })}
        {errorMsg.show && (
          <SweetAlert
            error
            title={errorMsg.msg}
            onConfirm={() => {
              setErrorMs(errorInitialState);
            }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dot: {
    fontSize: 40,
  },
});
