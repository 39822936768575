import { StyleSheet, View } from 'react-native'
import React, { useState } from 'react'
import { Text, Tooltip } from '@/components/Commons'
import { tw } from '@/lib'
//import { Dropdown } from '@/components/Inputs'
import { DownArrow } from '@/components/Icons/DownArrow'
import { Dropdown } from 'react-bootstrap'
import { Card } from 'react-native-elements'
import TreeDevices from '@/components/Tree/TreeDevices'
import { Question } from '@/components/Icons/Question'



const Devices = () => {

  const [dataTotal, setDataTotal] = useState({
    value: [
      {
        id: 1,
        name: "New registered last billing cycle",
        quantity: 122,
        children: [{
          name: "Licence fee total ",
          quantity: 120,
          children: [{
            name: "NEWT Cloud issues",
            quantity: 34
          },
          {
            name: "NEWT Cloud issues",
            quantity: 23
          }, {
            name: "NEWT Cloud issues",
            quantity: 12
          },
          {
            name: "NEWT Cloud issues",
            quantity: 45
          }, {
            name: "NEWT Cloud issues",
            quantity: 67
          },
          {
            name: "NEWT Cloud issues",
            quantity: 23
          },
          {
            name: "NEWT Cloud issues",
            quantity: 34
          },
          ]

        }]
      }

    ]
  })

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex flex-row justify-between `}>
        <View style={tw`flex flex-row items-center justify-around`}>
          <Text type="h2" weight='bold'>
            Devices
          </Text>
        </View>
      </View>




      <View style={[tw` items-start flex-row py-6 h-auto  `, { zIndex: "auto" }]}>
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            style={{
              lineHeight: 0,
              ...tw`no-underline bg-white py-1 px-4 rounded-2`,
            }}
            size="sm"
            id="dropdown-basic"
            bsPrefix="btn"
          >
            <View style={tw`flex-row items-center`}>
              <Text style={tw`pr-6`}>Month</Text>
              <DownArrow color='black' />
            </View>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              style={tw`text-black text-btn-menu-item font-semibold`}
            >
              Month
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </View>


      <Card containerStyle={tw`flex flex-col items-around w-full justify-around pl-4  rounded-3 lg:max-w-[436px]`}>
        <View style={tw`flex w-full flex-row py-4`}>
          <View style={tw`flex w-full items-start justify-around max-w-1/2 `}>
            <Text type='regular' weight='semiBold'>Total number of devices</Text>
          </View>
          <View style={tw`flex w-full items-center justify-around max-w-[330px] `}>
            <Text type='regular' weight='semiBold' style={[tw`flex text-lg`, { color: '#1F1D1D' }]}>137</Text>


          </View>
        </View>
        <View style={tw`flex-1 w-full flex-row py-2 `}>
          <View style={tw`flex w-full items-start justify-around max-w-1/2 `}>
            <Text type='regular' style={[tw`flex`, { color: '#717185' }]}>Registered</Text>
          </View>
          <View style={tw`flex w-full items-center justify-around max-w-[330px]`}>
            <Text type='regular' weight='semiBold' style={[tw`flex text-lg`, { color: '#1F1D1D' }]}>102</Text>
          </View>




        </View>

        
        <View style={tw`flex py-4`}>
          <TreeDevices data={dataTotal.value} />

        </View>













        <View style={tw`flex-grow-1 w-full flex-row `}>
          <View style={tw`flex w-full items-start justify-around max-w-1/2 `}>
            <Text type='regular' style={[tw`flex`, { color: '#717185' }]}>Not Registered</Text>
          </View>
          <View style={tw`flex w-full items-center justify-around max-w-[330px]`} >
            <Text type='regular' weight='semiBold' style={[tw`flex text-lg`, { color: '#1F1D1D' }]}>102</Text>
          </View>




        </View>






      </Card>
    </View>
  )
}

export default Devices

const styles = StyleSheet.create({})