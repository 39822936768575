import { ScrollView, View } from "react-native";
import React, { useState } from "react";
import { useDeviceContext } from "twrnc";

import { Text } from "@/components/Commons";
import { TextInput, Dropdown } from "@/components/Inputs";
import { CheckBox } from "react-native-elements";
import {
  tw,
  useFieldControl,
  number,
  required,
  colors,
  addPrefixToInput,
  regexNumbers,
} from "@/lib";

interface Feature {
  _id: string;
  isSelected: boolean;
  value: string;
}

let features: Feature[] = [
  {
    _id: "1",
    isSelected: false,
    value: "Access all NEWT Cloud Pro features on a monthly plan.",
  },
  { _id: "2", isSelected: false, value: "No limit on number of users" },
  {
    _id: "3",
    isSelected: true,
    value: "Unlimited calling in Canada and the U.S.*",
  },
  { _id: "4", isSelected: false, value: "Unlimited internet fax" },
  {
    _id: "5",
    isSelected: true,
    value: "Call recording with unlimited storage",
  },
  { _id: "6", isSelected: true, value: "Toll-free business phone number*" },
];

//@ts-ignore
export const ProductAddPlanForm = () => {
  //Use tailwind
  useDeviceContext(tw);

  //Data
  const [data, setData] = useState<Feature[]>(features);

  //Handle payment value
  const [paymentValue, setPaymentValue] = useState<string>("");

  //Form Inputs
  const planForm = {
    name: useFieldControl<string>("", [required]),
    paymentType: useFieldControl<string>("", [number]),
    paymentUser: useFieldControl<string>("", [number]),
    freeUser: useFieldControl<string>("", [number]),
    description: useFieldControl<string>("", []),
  };

  //Manage checked element
  const handlePress = (_id: string) => {
    let newData = [...data];
    newData.find((f) => {
      if (f._id == _id) f.isSelected = !f.isSelected;
    });
    setData(newData);
  };

  //Add $ symbol to the value
  const handlePaymentUChange = (value: string) => {
    //@ts-ignore
    const { cleanValue, prefixValue } = addPrefixToInput(value, "$");

    planForm.paymentUser.handleInputValue(cleanValue);

    setPaymentValue(prefixValue);
  };

  return (
    <>
      <ScrollView  contentContainerStyle={tw`flex-grow-1  lg:flex-row  `}>
        <View style={tw`flex-1`}>
          <TextInput
            label="Name"
            value={planForm.name.value}
            errorMessage={planForm.name.error}
            autoCapitalize="words"
            placeholder="Monthly"
            onChangeText={planForm.name.handleInputValue}
          />
          <Dropdown
            autoCorrect={false}
            placeholder="per month"
            label="Payment type"
            value={planForm.paymentType.value}
            onChangeText={planForm.paymentType.handleInputValue}
            data={[
              { label: "Month", value: "month" },
              { label: "3 months", value: "3months" },
              { label: "Year", value: "year" },
            ]}
          />
          <TextInput
            label="Payment for user"
            value={paymentValue}
            errorMessage={planForm.paymentUser.error}
            placeholder="$10"
            type="number"
            onChangeText={handlePaymentUChange}
          />
          <TextInput
            label="Free Users"
            value={planForm.freeUser.value}
            errorMessage={planForm.freeUser.error}
            placeholder="25"
            type={"number"}
            onChangeText={planForm.freeUser.handleInputValue}
          />
          <TextInput
            style={tw`h-20`}
            multiline={true}
            numberOfLines={4}
            label="Description"
            value={planForm.description.value}
            errorMessage={planForm.description.error}
            autoCapitalize="words"
            placeholder="Monthly"
            onChangeText={planForm.description.handleInputValue}
          />
        </View>
        <View style={tw`ml-5 flex   sm:my-6 lg:bottom-6`}>
          <Text style={tw`text-gray-500 ml-2`}>Features</Text>
          {data.map((feature, index) => (
            <CheckBox
              key={index}
              title={
                <Text weight="semiBold" style={tw`ml-2`}>
                  {feature.value}
                </Text>
              }
              containerStyle={tw`bg-transparent border-0 mx-0`}
              checked={feature.isSelected}
              checkedIcon="square"
              uncheckedColor={colors.grayLightest}
              uncheckedIcon="square"
              checkedColor={colors.blue}
              onPress={() => handlePress(feature._id)}
            />
          ))}
        </View>
      </ScrollView>
    </>
  );
};
