import { View } from "react-native";
import React from "react";
import { useDeviceContext } from "twrnc";

import { Text } from "@/components/Commons";
import { colors, tw } from "@/lib";

export const SupportListDialog = ({ text }: { text: string | number }) => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View>
      <svg
        width="32"
        height="22"
        viewBox="0 0 32 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V6.40211C28 7.08591 28.3494 7.72232 28.9262 8.08943L30.8485 9.31268C32.0835 10.0986 32.0835 11.9014 30.8485 12.6873L28.9263 13.9106C28.3494 14.2777 28 14.9141 28 15.5979V18C28 20.2091 26.2091 22 24 22H4C1.79086 22 0 20.2091 0 18V4Z"
          fill="#EBEBF0"
        />
      </svg>
      <View style={tw`absolute w-3/4`}>
        <Text
          style={{ fontSize: 12, ...tw`flex items-center justify-center` }}
          color={colors.gray}
          weight="semiBold"
        >
          {text}
        </Text>
      </View>
    </View>
  );
};
