import { View, TouchableOpacity } from "react-native";
import React, { FC, useState } from "react";
import Icon from "react-native-vector-icons/FontAwesome";
import { useDeviceContext } from "twrnc";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";

interface Props {
  children: any;
  title: string;
}

export const Accordion: FC<Props> = ({ children, title }) => {
  //Use tailwind
  useDeviceContext(tw);

  //Handle open/close
  const [isOpen, setIsOpen] = useState(true);

  //Toggle Function
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <View style={tw`bg-white rounded-3 p-5`}>
      <TouchableOpacity
        style={tw`flex flex-row justify-between pr-5 items-center`}
        onPress={handleToggle}
      >
        <Text weight="semiBold" type="h3" color={colors.black}>
          {title}
        </Text>
        <Icon
          name={isOpen ? "chevron-up" : "chevron-down"}
          // @ts-ignore
          type="font-awesome"
          color="#9CA3AF"
        />
      </TouchableOpacity>
      {isOpen && <View style={tw`mt-3`}>{children}</View>}
    </View>
  );
};
