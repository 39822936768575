import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { colors, tw } from "@/lib";
import { LeftArrow } from "../Icons/LeftArrow";

interface Props {
  color: string[];
  width?: string;
  height?: string;
  showShadow?: boolean;
}

const Back = ({ color, width, height, showShadow = true }: Props) => {
  return (
    <View style={tw`flex flex-row items-center justify-center`}>
      <TouchableOpacity
        style={tw.style(
          ` ml-3 bg-[${color[0]}]  rounded-3 pt-2`,
          showShadow ? "shadow-lg" : ""
        )}
        onPress={() => history.back()}
      >
        <View style={tw`flex w-full pl-3`}>
          <LeftArrow color={color[1]} width={width} height={height} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default Back;

const styles = StyleSheet.create({});
