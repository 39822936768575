import { StyleSheet, View, Text, Pressable } from "react-native";
import React, { useState } from "react";
import tw from "@/lib/tailwind";
import { useDeviceContext } from "twrnc";
import { Button, Text as Texts, UserProfile } from "@/components/Commons";
import { Back, Image, Pencil } from "@/components/Icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Avatar, Icon } from "react-native-elements";
import { Dropdown } from "react-bootstrap";
import { useFonts, OpenSans_700Bold } from "@expo-google-fonts/open-sans";
import { useLocation, useNavigate } from "react-router-dom";
import { DownArrow } from "@/components/Icons/DownArrow";
import { AdminCustomerList } from "@/components/AdminCustomer";
import { colors } from "@/lib";

interface NameInfo {
  image?: string;
  names?: string;
  monthlyReveneu: string;
}

export const PartnersDetails = () => {
  useDeviceContext(tw);
  const location = useLocation();
  const navigate = useNavigate();
  const { name, tabdata } = location.state;
  const [tab, changeTab] = useState<"details" | "users" | "statistics">(
    tabdata
  );

  const userNames: string[] = name.split(" ");

  const [data, setData] = useState({
    value: [
      {
        id: 1,
        email: "astanton@mail.com",
        name: "Ashlynn",
        lastName: "Stanton",
        subscription: "Monthly",
        status: true,
      },
      {
        id: 2,
        email: "astanton@mail.com",
        name: "Ashlynn",
        lastName: "Stanton",
        subscription: "Monthly",
        status: true,
      },
    ],
    fetching: true,
  });

  const [partnerInfo, changePartnerInfo] = useState<NameInfo>({
    names: name,
    monthlyReveneu: "%10",
  });

  let [fontsLoaded] = useFonts({
    OpenSans_700Bold,
  });

  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();
    return isActive
      ? {
          btn: tw`bg-white rounded-3`,
          text: tw`text-primary`,
        }
      : {
          text: tw`text-dark-gray`,
        };
  };

  const tabs = () => {
    const details = getTabStyle(() => tab == "details");
    const users = getTabStyle(() => tab == "users");
    const statistic = getTabStyle(() => tab == "statistics");
    return (
      <View
        style={tw`flex-row bg-[#EBEBF0] max-w-[304px] w-full h-10 rounded-3`}
      >
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            details.btn,
          ]}
          onPress={() => {
            tab != "details" && changeTab("details");
          }}
        >
          <Texts weight="semiBold" style={details.text}>
            Details
          </Texts>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            users.btn,
          ]}
          onPress={() => {
            tab != "users" && changeTab("users");
          }}
        >
          <Texts weight="semiBold" style={users.text}>
            User Details
          </Texts>
        </TouchableOpacity>

        <TouchableOpacity
          containerStyle={[
            tw`flex-grow-1 items-center justify-center`,
            statistic.btn,
          ]}
          onPress={() => {
            tab != "statistics" && changeTab("statistics");
          }}
        >
          <Texts weight="semiBold" style={statistic.text}>
            Statistics
          </Texts>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex-row justify-between`}>
        <View>
          <Texts type="h2" style={styles.name}>
            <TouchableOpacity
              style={tw`bg-white p-2 rounded-3`}
              onPress={() => navigate(-1)}
            >
              <Back color={colors.blueLightLightest} />
            </TouchableOpacity>{" "}
            {tab === "users" || tab === "statistics"
              ? `     `
              : `     ` + `Profile`}
          </Texts>
        </View>

        {tabs()}
        <View style={tw`max-w-[172px] w-full`}>
          {tab === "users"  && <Button type="outlined"> Add Client</Button> }
          {tab === "statistics"  && <Button type="outlined"> Add Client</Button> }
          
        </View>
      </View>

      {tab === "details" ? (
        <View style={tw`flex mt-16`}>
          <UserProfile />
        </View>
      ) : tab === "users" ? (
        <View style={tw`flex-grow-1 h-full mx-8 py-12 `}>
          <AdminCustomerList
            data={data.value}
            dropdown={
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  style={{ lineHeight: 0, ...tw`no-underline` }}
                  id="dropdown-basic"
                  bsPrefix="btn"
                >
                  <Icon
                    color="#C8C8C8"
                    name="more-vert"
                    size={20}
                    tvParallaxProperties={undefined}
                  />
                </Dropdown.Toggle>
              </Dropdown>
            }
          />
        </View>
      ) : tab === "statistics" ? (
        <View style={tw`flex-grow-1 h-full mx-8 py-12 `}>
          <Texts type="h2">{`${userNames[0]}'s ${userNames[1]} Statistics`}</Texts>

          <View style={[tw`flex-cols my-4`, { zIndex: "auto" }]}>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{
                  lineHeight: 0,
                  ...tw`no-underline bg-white py-2 px-4 rounded-3 `,
                }}
                size="sm"
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <View style={tw`flex-row items-center`}>
                  <Text style={tw`pr-6`}>Month</Text>
                  <DownArrow color="black" />
                </View>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                >
                  Month
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </View>

          <View style={tw`bg-white mt-4 px-8 py-6 mx-2 rounded-3 lg:max-w-2/3`}>
            <View style={tw`flex-row pt-2`}>
              <Texts style={tw`text-dark-gray w-[152px]`}>Customers</Texts>
              <Texts type="h2" weight="bold">
                154
              </Texts>
            </View>
            <View style={tw`w-1/2 flex-row py-4`}>
              <Texts style={tw`text-dark-gray w-[152px]`}>
                Monthly revenue
              </Texts>
              <Texts type="h2" weight="bold">
                %10
              </Texts>
            </View>
            <View style={tw`w-1/2 flex-row`}>
              <Texts style={tw`text-dark-gray w-[152px]`}>Upcoming comp</Texts>
              <Texts type="h2" weight="bold">
                $4521
              </Texts>
            </View>
          </View>
          <View
            style={tw`flex bg-white mt-4 px-8 py-6 mx-2 rounded-3  2xl:max-w-2/3  `}
          >
            <View style={tw`flex flex-row items-center    pl-16 mx-20  `}>
              <Texts weight="semiBold" style={tw`text-dark-gray lg:min-w-1/4 `}>
                Total Users
              </Texts>
              <Texts weight="semiBold" style={tw`text-dark-gray `}>
                Total Compensations
              </Texts>
            </View>

            <View
              style={tw`flex bg-white mt-4  py-6 mx-2 rounded-3  lg:max-w-full`}
            >
              <View style={tw`flex-row `}>
                <View style={tw`w-1/6`}>
                  <Texts weight="semiBold" style={tw`text-dark-gray`}>
                    From Subscriptions
                  </Texts>
                </View>
                <View style={tw`w-1/6 pl-8`}>
                  <Texts weight="semiBold">154</Texts>
                </View>
                <View style={tw`w-1/6 pl-16`}>
                  <Texts weight="semiBold">$12342</Texts>
                </View>
              </View>
              <View style={tw`border-l border-light-gray`}>
                <View style={tw`flex-row pt-6`}>
                  <View style={tw`w-1/6`}>
                    <Texts weight="semiBold" style={tw`text-dark-gray pl-5`}>
                      PRO Monthly
                    </Texts>
                  </View>
                  <View style={tw`w-1/6 pl-8`}>
                    <Texts weight="semiBold">132</Texts>
                  </View>
                  <View style={tw`w-1/6 pl-16`}>
                    <Texts weight="semiBold">$142</Texts>
                  </View>
                </View>
                <View style={tw`flex-row pt-6`}>
                  <View style={tw`w-1/6`}>
                    <Texts weight="semiBold" style={tw`text-dark-gray pl-5`}>
                      PRO Anual
                    </Texts>
                  </View>
                  <View style={tw`w-1/6 pl-8`}>
                    <Texts weight="semiBold">132</Texts>
                  </View>
                  <View style={tw`w-1/6 pl-16`}>
                    <Texts weight="semiBold">$142</Texts>
                  </View>
                </View>
                <View style={tw`flex-row pt-6`}>
                  <View style={tw`w-1/6`}>
                    <Texts weight="semiBold" style={tw`text-dark-gray pl-5`}>
                      FREE
                    </Texts>
                  </View>
                  <View style={tw`w-1/6 pl-8`}>
                    <Texts weight="semiBold">132</Texts>
                  </View>
                  <View style={tw`w-1/6 pl-16`}>
                    <Texts weight="semiBold">$142</Texts>
                  </View>
                </View>
              </View>

              <View style={tw`flex-row  my-2`}>
                <View style={tw`w-1/6`}>
                  <Texts weight="semiBold" style={tw`text-dark-gray`}>
                    From Subscriptions
                  </Texts>
                </View>
                <View style={tw`w-1/6 pl-8`}>
                  <Texts weight="semiBold">154</Texts>
                </View>
                <View style={tw`w-1/6 pl-16`}>
                  <Texts weight="semiBold">$12342</Texts>
                </View>
              </View>

              <View style={tw`border-l border-light-gray`}>
                <View style={tw`flex-row pt-6`}>
                  <View style={tw`w-1/6`}>
                    <Texts weight="semiBold" style={tw`text-dark-gray pl-5`}>
                      PRO Monthly
                    </Texts>
                  </View>
                  <View style={tw`w-1/6 pl-8`}>
                    <Texts weight="semiBold">132</Texts>
                  </View>
                  <View style={tw`w-1/6 pl-16`}>
                    <Texts weight="semiBold">$142</Texts>
                  </View>
                </View>
                <View style={tw`flex-row pt-6`}>
                  <View style={tw`w-1/6`}>
                    <Texts weight="semiBold" style={tw`text-dark-gray pl-5`}>
                      PRO Anual
                    </Texts>
                  </View>
                  <View style={tw`w-1/6 pl-8`}>
                    <Texts weight="semiBold">132</Texts>
                  </View>
                  <View style={tw`w-1/6 pl-16`}>
                    <Texts weight="semiBold">$142</Texts>
                  </View>
                </View>
                <View style={tw`flex-row pt-6`}>
                  <View style={tw`w-1/6`}>
                    <Texts weight="semiBold" style={tw`text-dark-gray pl-5`}>
                      FREE
                    </Texts>
                  </View>
                  <View style={tw`w-1/6 pl-8`}>
                    <Texts weight="semiBold">132</Texts>
                  </View>
                  <View style={tw`w-1/6 pl-16`}>
                    <Texts weight="semiBold">$142</Texts>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      ) : (
        ``
      )}
    </View>
  );
};

//export default PartnersDetails;

const styles = StyleSheet.create({
  name: {
    fontFamily: "OpenSans_700Bold",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: 32.68,
  },
  reveneu: {
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: 19.07,
  },
});
