import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Circle, Path, Rect } from "react-native-svg";

export const Status = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <Path
        d="M7.24512 15.2927L10.2383 11.4025L13.6524 14.0845L16.5815 10.3041"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle
        cx="19.9954"
        cy="4.71147"
        r="1.9222"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.9248 3.63138H7.65704C4.6456 3.63138 2.77832 5.7641 2.77832 8.77554V16.8579C2.77832 19.8694 4.60898 21.9929 7.65704 21.9929H16.2612C19.2726 21.9929 21.1399 19.8694 21.1399 16.8579V9.81902"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
