import React from "react";
import { View, Image } from "react-native";

import { useDeviceContext } from "twrnc";
import { tw } from "@/lib";

export const Banner = () => {
  useDeviceContext(tw);

  return (
    <View style={tw`bg-btn flex items-center justify-center py-8`}>
      <Image
        source={require("@/assets/images/LOGO/nucleus.png")}
        style={tw`w-[255px] h-[75px]`}
      />
    </View>
  );
};
