import { View, ActivityIndicator } from "react-native";
import React, { FC } from "react";
import { useDeviceContext } from "twrnc";

import { colors, tw } from "@/lib";

interface Props {
  color?: string;
}

export const Loading: FC<Props> = ({ color = colors.blue }) => {
  //Available responsive
  useDeviceContext(tw);

  return (
    <View style={tw`h-full w-full items-center justify-center`}>
      <ActivityIndicator size="large" color={color} />
    </View>
  );
};
