import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React, { useEffect } from "react";
import { TextInput } from "../Inputs";
import { required, tw, useFieldControl } from "@/lib";
import { useDeviceContext } from "twrnc";

interface Props {
  color?: string;
  style?: StyleProp<ViewStyle>;
  inputElement?: number;
  planLicenceMinus?: number;
  onChange?: (input: string) => void;
}

export const ManageSubscription = ({
  color = "gray",
  inputElement = 2,
  style,
  onChange,
  planLicenceMinus = 0,
}: Props) => {
  useDeviceContext(tw);

  const element1 = useFieldControl<string>(planLicenceMinus.toString());
  const minus = (element: string) => {
    const data = Number(element);
    if (data - 1 >= 0! || (0 && inputElement >= 1)) {
      element1.handleInputValue(Number(data - 1).toString());
    }
  };

  const plus = (element: string) => {
    const data = Number(element);
    if (data >= 0 && inputElement >= 1) {
      element1.handleInputValue(Number(data + 1).toString());
    }
  };
  useEffect(() => {
    if (element1.value && onChange) {
      onChange(element1.value || "");
    }
  }, [element1.value]);

  return (
    <View
      style={[
        tw`flex flex-row w-full max-w-auto mx-auto  justify-end max-h-[32px] sm:items-start`,
        style,
      ]}
    >
      <View style={tw`flex flex-row items-start justify-start`}>
        <TouchableOpacity
          onPress={() => {
            return inputElement >= 1 && minus(element1.value);
          }}
        >
          <View
            style={[
              tw`flex items-center justify-center ${
                color === "white" ? `bg-white` : `bg-gray-200`
              } rounded-3  `,
              {
                width: 41.05,
                height: 31,
              },
            ]}
          >
            <Text>-</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View>
        <TextInput
          type="number"
          style={tw`w-[43px] h-[32px]`}
          inputStyle={tw`text-center`}
          value={inputElement >= 1 ? element1.value : undefined}
          onChangeText={(s: string) => {
            if (inputElement >= 1) {
              return onChange!(s);
            }
          }}
        />
      </View>
      <View style={tw`flex flex-row `}>
        <TouchableOpacity
          onPress={() => {
            return inputElement >= 1 && plus(element1.value);
          }}
        >
          <View
            style={[
              tw`flex items-center justify-center ${
                color === "white" ? `bg-white` : `bg-gray-200`
              } rounded-3`,
              {
                width: 41.05,
                height: 31,
              },
            ]}
          >
            <Text>+</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

//export default ManageSubscription

const styles = StyleSheet.create({});
