import axios, { AxiosInstance, AxiosResponse } from "axios";
//@ts-ignore
import { API_SERVICE_TESTING } from "@env";
import { ResponseCheck } from "@/types";

class MailVerification {
  api: AxiosInstance;
  endpoint = "users";
  constructor() {
    this.api = axios.create({
      baseURL: API_SERVICE_TESTING,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  async sendEmail(email: string) {
    return this.api.post(`${this.endpoint}/password/init-reset`, {
      email: email,
      isAccountManager: true,
    });
  }

  async resendEmail(email: string) {
    return this.api.post(`${this.endpoint}/resend/verification`, {
      email: email,
      isAccountManager: true,
    });
  }

  async confirmCode(email: string, code: string) {
    return this.api.post("users/confirm", {
      email: email,
      code: code,
    });
  }

  async verifyCode(
    secrets: string
  ): Promise<AxiosResponse<{ result: ResponseCheck }>> {
    return this.api.get("users/password/check-reset-password-link", {
      params: { secret: secrets },
    });
  }

  async sendWorkspaceVerifyEmail(
    email: string,
    captcha?: string
  ): Promise<void> {
    return this.api.post(`${this.endpoint}/email-verify`, { email, captcha });
  }

  async resendWorkspaceVerifyEmail(email: string) {
    return this.api.post(`${this.endpoint}/email-reverify`, { email });
  }

  async confirmWorkspaceVerifyCode(email: string, code: string) {
    return this.api.get(`${this.endpoint}/email-verify`, {
      params: { email: email, code: code },
    });
  }

  async sendWorkspaceVerifySMS(sms: string) {
    return this.api.post(`${this.endpoint}/sms-verify`, { sms });
  }

  async confirmWorkspaceVerifyCodeSMS(sms: string, code: string) {
    return this.api.get(`${this.endpoint}/sms-verify`, {
      params: { sms: sms, code: code },
    });
  }
}

const singleton = new MailVerification();

export default singleton;
