import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { FirebaseService, TokenService } from "@/services";

export const FirebaseAnalytics = () => {
  const router = useLocation();
  const logPage = async () => {
    const page_path = router.pathname;
    FirebaseService.logPage(page_path);
  };

  useEffect(() => {
    logPage();
  }, [router]);

  useEffect(() => {
    FirebaseService.setFirebaseUserId(
      TokenService.getUser()?.user.id?.toString() || null
    );
  }, [TokenService.getUser()?.user]);

  return null;
};
