import React, { useEffect, useRef, useState } from "react";
import { Animated, Pressable, View, ViewProps } from "react-native";
import { tw } from "@/lib";

interface ExpandedPropsTypes extends ViewProps {
  open?: boolean;
  header?: JSX.Element;
  onOpenChange?: () => void;
}

export const Expanded = (props: ExpandedPropsTypes) => {
  let {
    open = false,
    header = <View></View>,
    onOpenChange,
    style,
    children,
  } = props;

  const [opened, setOpenValue] = useState(open);

  const height = useRef(new Animated.Value(0)).current;

  const expansion = useRef<HTMLElement>();

  let animation = Animated.timing(height, {
    //@ts-ignore
    toValue: opened ? expansion.current?.childNodes[0].offsetHeight : 0,
    duration: 1000,
    useNativeDriver: true,
  });

  useEffect(() => {
    animation.stop();
    animation.start();
  }, [opened]);

  const toggleExpand = () => (
    setOpenValue(!opened), onOpenChange && onOpenChange()
  );

  return (
    <Pressable style={style} onPress={toggleExpand}>
      {header}
      <Animated.View
        ref={expansion}
        style={[
          tw`w-full`,
          {
            height: height,
            overflow: "hidden",
          },
        ]}
      >
        {children}
      </Animated.View>
    </Pressable>
  );
};
