import { TouchableOpacity, View } from "react-native";
import React, { useEffect, useState } from "react";
import { useDeviceContext } from "twrnc";
import Svg, { Circle } from "react-native-svg";
import { Card } from "react-native-elements";
import { AxiosError } from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { Dropdown } from "react-bootstrap";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useToast } from "react-native-toast-notifications";
import { useLocation, useParams } from "react-router-dom";

import { Text, Button, Loading } from "@/components/Commons";
import { colors, showFriendlyErrorFromHTMLCode, splitName, tw } from "@/lib";
import {
  PlanService,
  SubscriptionService,
  SuperAdminService,
  WorkspaceService,
} from "@/services";
import { PlanResponse, SubscriptionResponse } from "@/types";
import { getRoute, useNavigate } from "@/routes";
import Back from "@/components/Commons/Back";
import { Pencil } from "@/components/Icons";
import { useUserLogged } from "@/hooks";

export const Subscriptiond = () => {
  useDeviceContext(tw);

  const { userLogged } = useUserLogged();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  //Initialize toasts
  const toast = useToast();

  const [workspace, setWorkspace] = useState<number>(0);

  const [basicPlan, setBasicPlan] = useState<PlanResponse>();

  const [allPlans, setAllPlans] = useState<PlanResponse[]>();

  const [subscribedPlanId, setSubscribedPlanId] = useState<string>("");

  const errorInitialState = { show: false, msg: "" };

  //Loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [plan, changePlan] = useState<SubscriptionResponse>();

  //Error indicator
  const [errorMsg, setErrorMsg] = useState<{ show: boolean; msg: string }>(
    errorInitialState
  );

  const { workspaceId } = useParams();

  const getBasicPlan = async () => {
    try {
      setIsLoading(true);
      const res = await PlanService.getPlanBasic();
      const plans = await PlanService.listPlans();
      const allPlansButBasic = plans.filter((plan) => plan.id != res?.id);

      let currentWorkspace;

      if (workspaceId) {
        currentWorkspace = await SuperAdminService.getWorkspace(
          parseInt(workspaceId)
        );
      } else {
        currentWorkspace = await WorkspaceService.getCurrent();
      }

      const myPlan = await SubscriptionService.subscriptionCurrent(
        currentWorkspace?.data?.result?.id
      );

      setBasicPlan(res);
      setAllPlans(allPlansButBasic);
      setSubscribedPlanId(myPlan?.data?.result?.planId);
      setWorkspace(currentWorkspace?.data?.result?.id);
      changePlan(myPlan.data.result);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      //Show error
      const error = err as AxiosError;
      setErrorMsg({ show: true, msg: showFriendlyErrorFromHTMLCode(error) });
    }
  };

  useEffect(() => {
    getBasicPlan();
  }, []);

  const showDropdown = (plan: PlanResponse) => {
    if (
      userLogged.rol === "admin" ||
      userLogged.rol === "super_admin" ||
      (userLogged.accessType === "read and write" &&
        userLogged.accessStatus === "granted")
    )
      return (
        <View style={tw`absolute top-[2] right-[-4] z-10`}>
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              style={{ lineHeight: 0, ...tw`no-underline` }}
              id="dropdown-basic"
              bsPrefix="btn"
            >
              <Icon name="more-vert" size={15} style={tw`text-gray-500`} />
            </Dropdown.Toggle>
            <Dropdown.Menu style={tw`rounded-3`} align="end">
              <Dropdown.Item
                as="button"
                style={tw` text-btn-menu-item font-semibold`}
                onClick={() => editSubscription(plan)}
              >
                Edit subscription
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                style={tw`text-red-500 text-btn-menu-item font-semibold`}
                onClick={() => cancelSubscription()}
              >
                Cancel subscription
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </View>
      );
  };

  const cancelSubscription = async () => {
    try {
      await PlanService.cancelSubscription(workspace);

      await getBasicPlan();

      toast.show("The plan was cancelled successfully", {
        placement: "bottom",
        type: "success",
        duration: 3000,
        successColor: colors.successAlert,
        successIcon: <Icon name="check" size={18} color={colors.white} />,
      });
    } catch (err) {
      //Show error
      const error = err as AxiosError;
      setErrorMsg({ show: true, msg: showFriendlyErrorFromHTMLCode(error) });
    }
  };

  const editSubscription = (order: PlanResponse | undefined) => {
    if (order == undefined) return;

    let url: string | undefined = getRoute("subscriptionDetailsplan")?.path;

    if (workspaceId) {
      url = `${url}/${workspaceId}`;
    }

    navigate(url || "", {
      state: {
        plan: order.name,
        plan_data: plan?.licenses,
        amount: order.license_range.max,
        min: order.license_range.min,
        plan_id: order.id,
        month: order.term,
        user: order.unit,
        text: order.description,
        price: order.price_per_unit,
        wId: workspaceId,
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <View style={tw`flex-grow-1 h-full py-7`}>
          <View style={tw`flex w-full flex-row items-center`}>
            {state && (
              <Back
                color={[colors.white, colors.blue]}
                width={"30"}
                height={"32"}
                showShadow={false}
              />
            )}

            <Text style={tw`text-h2 font-bold pr-4 px-4 lg:px-4`}>Details</Text>
            <View style={tw`flex w-full flex-row items-center  `}>
              <TouchableOpacity
                style={tw` p-2 rounded-3`}
                //onPressOut={onEditClick}
              >
                <Text weight="semiBold" color={colors.gray} style={tw`mt-2`}>
                  Subscription overview
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {/* Free Section */}
          <Card containerStyle={tw`rounded-3 px-8 mb-5`}>
            <View style={tw`flex flex-row items-center mt-3`}>
              <Text type="h3" color={colors.blue}>
                {splitName(basicPlan?.name)}
              </Text>
              {subscribedPlanId === basicPlan?.id && (
                <Text weight="semiBold" color={colors.blue} style={tw`ml-2`}>
                  Your plan
                </Text>
              )}
            </View>
            <View style={tw`mt-10 flex flex-row items-center mb-16 flex-wrap`}>
              {/* Price */}
              <View style={tw`flex flex-row items-center mr-20`}>
                <Text weight="extraBold" style={{ fontSize: 48 }}>
                  {basicPlan?.price_per_unit}
                </Text>
                <View style={tw`ml-2`}>
                  <View style={tw`ml-2`}>
                    {basicPlan?.term && (
                      <Text color={colors.gray} weight="semiBold">
                        /{basicPlan?.term}
                      </Text>
                    )}
                    {basicPlan?.unit && (
                      <Text color={colors.gray} weight="semiBold">
                        /{basicPlan?.unit}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              {/* Description */}
              <Text style={tw`max-w-[271px] mr-20`}>
                {basicPlan?.description}
              </Text>
              {/* Features */}
              <View>
                {basicPlan?.enabled_features
                  ?.split(",")
                  .map((feature, index) => (
                    <Text key={index}>
                      <Svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        style={tw`mr-2`}
                      >
                        <Circle cx="2" cy="2" r="2" fill="#1F1D1D" />
                      </Svg>
                      {feature}
                    </Text>
                  ))}
              </View>
            </View>
            <View
              style={tw` flex flex-col  justify-between lg:bottom-8 flex-wrap`}
            >
              <Text type="bigger" weight="semiBold">
                {plan?.licenses} {plan?.licenses! > 1 ? "users" : "user"}{" "}
                subscribed
              </Text>
            </View>
            {subscribedPlanId !== basicPlan?.id && (
              <View style={tw`flex flex-row justify-end`}>
                <Button
                  onPress={() => editSubscription(basicPlan)}
                  style={tw`w-[150px]`}
                >
                  Start Plan
                </Button>
              </View>
            )}
            {subscribedPlanId === basicPlan?.id && showDropdown(basicPlan)}
          </Card>
          {/* Other Plans */}
          <View
            style={tw`flex flex-row flex-wrap justify-center lg:justify-start`}
          >
            {false &&
              allPlans?.map((plan) => (
                <Card
                  containerStyle={tw`rounded-3 px-8 lg:w-[499px] pb-7`}
                  key={plan?.id}
                >
                  <View style={tw`flex lg:flex-row`}>
                    {/* Left Column */}
                    <View style={tw`flex-1`}>
                      {/* Header */}
                      <View style={tw`flex flex-row items-center mt-3`}>
                        <Text type="h3" color={colors.blue}>
                          {splitName(plan?.name)}
                        </Text>
                        {subscribedPlanId === plan?.id && (
                          <Text
                            weight="semiBold"
                            color={colors.blue}
                            style={tw`ml-2`}
                          >
                            Your plan
                          </Text>
                        )}
                      </View>
                      {/* Price */}
                      <View style={tw`flex flex-row items-center mt-10 mb-5`}>
                        <Text weight="extraBold" style={{ fontSize: 48 }}>
                          {plan?.price_per_unit}
                        </Text>
                        <View style={tw`ml-2`}>
                          {plan?.term && (
                            <Text color={colors.gray} weight="semiBold">
                              /{plan?.term}
                            </Text>
                          )}
                          {plan?.unit && (
                            <Text color={colors.gray} weight="semiBold">
                              /{plan?.unit}
                            </Text>
                          )}
                        </View>
                      </View>
                      {/* Description */}
                      <Text style={tw`max-w-[220px] mb-5`}>
                        {plan?.description}
                      </Text>
                      {/* Features */}
                      <View>
                        {plan?.enabled_features
                          ?.split(",")
                          .map((feature, index) => (
                            <Text key={index}>
                              <Svg
                                width="4"
                                height="4"
                                viewBox="0 0 4 4"
                                fill="none"
                                style={tw`mr-2`}
                              >
                                <Circle cx="2" cy="2" r="2" fill="#1F1D1D" />
                              </Svg>
                              {feature}
                            </Text>
                          ))}
                      </View>
                    </View>
                    {/* Right Column */}
                    <View
                      style={{ flex: 0.6, ...tw`justify-end mt-7 lg:mt-0` }}
                    >
                      {subscribedPlanId !== plan?.id && (
                        <Button onPress={() => editSubscription(plan)}>
                          Start Plan
                        </Button>
                      )}
                    </View>
                  </View>
                  {subscribedPlanId === plan?.id && showDropdown(plan)}
                </Card>
              ))}
          </View>
        </View>
      )}
      {errorMsg.show && (
        <SweetAlert
          error
          title={errorMsg.msg}
          onConfirm={() => {
            setErrorMsg(errorInitialState);
          }}
        />
      )}
    </>
  );
};
