import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Image,
  ScrollView,
  ViewProps,
  StyleProp,
  ViewStyle,
  Animated,
} from "react-native";
import { useDeviceContext } from "twrnc";
import { Outlet, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import { colors, convertToBase64, tw } from "@/lib";
import { getRoute, useNavigate } from "@/routes";

import { TouchableOpacity } from "react-native-gesture-handler";

import { Info, Stat, Wallet, Work, Image as Images } from "@/components/Icons";
import { Text } from "@/components/Commons";
import { mobile } from "@/lib";
import { Exit } from "../Icons/Exit";
import { Avatar } from "react-native-elements";
import { AuthService, MediaService, WorkspaceService } from "@/services";
import { CoverResponse } from "@/types";
import { useUserLogged } from "@/hooks";

interface MenuBar {
  name: string;
  icon?: (props: { color: string; style: StyleProp<ViewStyle> }) => JSX.Element;
  submenuTitle: string;
  hidden?: boolean;
  submenu: {
    title: string;
    routeName?: string;
  }[];
}

export const WorkspaceLayout = (props: ViewProps) => {
  useDeviceContext(tw);
  const navigate = useNavigate();
  const location = useLocation();
  const { workspaceId } = useParams();

  const [showSubmenu, changeShowSubmenu] = useState(false);
  const [userImage, setUserImage] = useState<string>("");

  const subMenuWidth = useRef(new Animated.Value(0)).current;

  const { logoutUser } = useUserLogged();

  useEffect(() => {
    Animated.timing(subMenuWidth, {
      toValue: showSubmenu ? 100 : 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [showSubmenu]);

  const menuBar: MenuBar[] = [
    {
      name: "workspaces",
      icon: Work,
      submenuTitle: "Company Info",
      submenu: [
        {
          title: "Details",
          routeName: "workspaceCompany",
        },
        {
          title: "Users",
          routeName: "workspaceCompanyUsers",
        },
      ],
    },
    {
      name: "profile",
      hidden: true,
      submenuTitle: "Profile",
      submenu: [
        {
          title: "Profile",
          routeName: "clientProfile",
        },
        {
          title: "Change email",
          routeName: "clientChangeEmail",
        },
        {
          title: "Change password",
          routeName: "clientChangePassword",
        },
        {
          title: "Log Out",
          routeName: "login",
        },
      ],
    },
  ];

  const [menuSelected, setMenuSelected] = useState(
    location.pathname.split("/")[1]
  );

  const sideBar = menuBar.find((e) => e.name == menuSelected);

  return (
    <View style={tw`flex flex-row justify-start w-[100vw] h-[100vh]`}>
      <View
        style={tw`w-[65px] sm:w-[88px] h-full bg-black flex flex-col justify-between`}
      >
        <Image
          style={tw`w-[51px] mx-auto mt-4 h-[51px]`}
          source={require("@/assets/images/LOGO/logo.png")}
        ></Image>
        <View style={tw`mx-auto`}>
          {menuBar
            .filter((e) => !e.hidden)
            .map((e) => {
              const active = e.name == menuSelected;
              return (
                <TouchableOpacity
                  key={e.name}
                  style={[
                    tw`w-menu-btn h-menu-btn rounded-4`,
                    tw.style(active ? "bg-btn" : ""),
                  ]}
                  onPress={() => (
                    setMenuSelected(e.name), changeShowSubmenu(true)
                  )}
                >
                  {
                    // @ts-ignore
                    <e.icon style={tw`mx-auto my-auto`} />
                  }
                </TouchableOpacity>
              );
            })}
        </View>
        <View style={tw`flex mx-auto lg:bottom-10`}>
          <TouchableOpacity
            // @ts-ignore
            onPressOut={(_) => setMenuSelected("profile")}
          >
            <Avatar
              rounded
              source={{ uri: userImage || undefined }}
              size={48}
              renderPlaceholderContent={
                <View
                  style={tw`bg-gray-500 h-full w-full justify-center items-center`}
                >
                  
                </View>
              }
            />
          </TouchableOpacity>
        </View>
      </View>
      <Animated.View
        style={[
          tw.style({
            hidden: !subMenuWidth,
          }),
          tw`h-full max-w-[272px] bg-white sm:flex`,
          {
            width: mobile()
              ? subMenuWidth.interpolate({
                  inputRange: [0, 100],
                  outputRange: ["0%", "100%"],
                })
              : "100%",
          },
        ]}
      >
        <View style={tw`flex-col flex w-[272px] px-[16px] py-[28px] h-full `}>
          <Text type="h2" style={tw`pl-2 pb-[24px]`}>
            {sideBar?.submenuTitle}
          </Text>
          {sideBar?.submenu.map((e, index: number) => {
            const routePath = getRoute(e.routeName)?.path;
            let active = routePath && location.pathname.includes(routePath);

            const linkPressed = () => {
              changeShowSubmenu(false);
              if (!active && routePath) {
                navigate(`${routePath}/${workspaceId}`);
              }
            };
            return (
              <View
                style={tw.style(
                  e.title === "Log Out" &&
                    `absolute inset-x-0 bottom-0 px-[16px] py-[28px]`
                )}
                key={index}
              >
                <TouchableOpacity
                  key={e.title}
                  style={[
                    tw`w-full h-[48px] rounded-3 `,
                    tw.style(active && "bg-btn"),
                  ]}
                  onPress={e.title === "Log Out" ? logoutUser : linkPressed}
                >
                  {e.title === "Log Out" ? (
                    <View style={tw` flex flex-row`}>
                      <Exit color="red" />
                      <Text
                        weight={active ? "regular" : "semiBold"}
                        style={tw`my-auto pl-2 `}
                        color={colors.red}
                      >
                        {e.title}
                      </Text>
                    </View>
                  ) : (
                    <Text
                      weight={active ? "semiBold" : "regular"}
                      style={[
                        tw`my-auto pl-2`,
                        tw.style(active ? "text-white" : "text-black"),
                      ]}
                    >
                      {e.title}
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
      </Animated.View>

      <ScrollView
        contentContainerStyle={{
          backgroundColor: colors.rebrandLayoutBGColor,
          ...tw`w-full min-h-[100vh]`,
          zIndex: 0,
        }}
      >
        <Outlet />
      </ScrollView>
    </View>
  );
};
