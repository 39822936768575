import React from "react";
import { View } from "react-native";

import { useDeviceContext } from "twrnc";

import { Banner, Stores, TermAndConditions } from "@/components/AppPage";
import { Text } from "@/components/Commons";
import { colors, tw } from "@/lib";

export const LandingPage = () => {
  useDeviceContext(tw);

  return (
    <View style={tw`flex h-full pb-5 bg-[${colors.rebrandLayoutBGColor}]`}>
      <Banner />
      <View style={tw`flex flex-1 items-center px-5`}>
        <Text
          weight="extraBold"
          color={colors.black}
          style={{ fontSize: 32, lineHeight: 48, ...tw`text-center md:p-10` }}
        >
          Download Nucleus to all your devices
        </Text>
        <Stores />
      </View>
      <View style={tw`px-5 mx-auto`}>
        <TermAndConditions />
      </View>
    </View>
  );
};
