import { WorkspaceInterface } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: WorkspaceInterface = {
  accessType: "read and write",
  createdAt: "",
  updatedAt: "",
  id: 0,
  type: "",
  status: "requested",
  workspaceId: 0,
  supportUserId: 0,
  user: undefined,
  duration: 0,
  durationType: "days",
  fromTemporal: "",
  from: "",
  to: "",
  from_global: "",
  to_global: "",
  endless: false,
  durationGlobal: 0,
  durationGlobalType: "days",
  message: "",
};

const accessSlice = createSlice({
  name: "accessStore",
  initialState,
  reducers: {
    setAccess: (state, action: PayloadAction<WorkspaceInterface>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setAccessType: (
      state,
      action: PayloadAction<Pick<WorkspaceInterface, "accessType">>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDateRange: (
      state,
      action: PayloadAction<Pick<WorkspaceInterface, "from" | "to">>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDurations: (
      state,
      action: PayloadAction<
        Pick<WorkspaceInterface, "duration" | "durationType">
      >
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setEndlessDuration: (
      state,
      actions: PayloadAction<Pick<WorkspaceInterface, "endless">>
    ) => {
      return {
        ...state,
        ...actions.payload,
      };
    },
    setDateGlobalRange: (
      state,
      action: PayloadAction<
        Pick<WorkspaceInterface, "from_global" | "to_global">
      >
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setMessage: (
      state,
      action: PayloadAction<
        Pick<WorkspaceInterface, "message" >
      >
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearInfo: (state) => {
      return {
        ...initialState,
        from_global: state.from_global,
        to_global: state.to_global,
      };
    },
  },
});

export default accessSlice.reducer;

export const {
  setAccess,
  setAccessType,
  setDateRange,
  setDurations,
  setMessage,
  setEndlessDuration,
  setDateGlobalRange,
  clearInfo,
} = accessSlice.actions;
