import { StyleSheet, View } from "react-native";
import React from "react";

import { FancyDropdown } from "../Inputs";
import { colors, tw, useFieldControl } from "@/lib";
import { AccessLevel } from "@/types";
import { Text } from "../Commons";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { RootState } from "@/store";
import { setAccessType } from "@/store/accessRequest.store";
import { DataType } from "@/screens/client/Info/ModalBody";

export interface Elment {
  onHover?: (e: string) => void;
  data: DataType[];
}

const Permitions = ({ onHover, data }: Elment) => {
  const dispatch = useAppDispatch();
  const { accessType } = useAppSelector(
    (state: RootState) => state.accessRequest
  );

  const accessLevel = {
    requested: useFieldControl<string>(accessType || "read only"),
  };
  return (
    <>
      <View
        style={[
          tw`flex sm:flex lg:flex-row  w-full sm:items-center lg:max-w-[420px] items-center justify-between `,
        ]}
      >
        <View style={tw`flex flex-row items-center justify-around  `}>
          <Text color={colors.gray}>Access Level{"    "}</Text>
        </View>
        <View style={tw`flex-1 sm:flex w-full lg:right-2  `}>
          <FancyDropdown
            autoCorrect={false}
            placeholder=""
            label={" "}
            value={accessLevel.requested.value}
            data={data}
            onSelect={(val) => {
              if (onHover) onHover(val);
              dispatch(setAccessType({ accessType: val as AccessLevel }));
            }}
          />
        </View>
      </View>
    </>
  );
};

export default Permitions;

const styles = StyleSheet.create({});
