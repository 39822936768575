import React from "react";
import { View } from "react-native";

import { useDeviceContext } from "twrnc";

import { Modal, tw } from "@/lib";
import { Button, Text } from "@/components/Commons";
import { useAppSelector } from "@/store/hooks";
import { RootState } from "@/store";
import { AccessResponse, Status } from "@/types";

interface Props {
  onCallBack?: (accessResponse: AccessResponse) => Promise<void>;
  status?: Status;
  text?: string;
  onButtonPress?: () => void;
}

export default function ModalButtons({
  onCallBack,
  status = "approved",
  text,
  onButtonPress,
}: Props) {
  useDeviceContext(tw);

  const accessStore = useAppSelector((state: RootState) => state.accessRequest);

  const handleCallback = () => {
    onCallBack && onCallBack({
      ...accessStore,
      status,
      name: accessStore.user?.firstName || "",
    });
    onButtonPress && onButtonPress();
    Modal.dismiss();
  };

  const hideModal = () => {
    onButtonPress && onButtonPress();
    Modal.dismiss();
  };

  return (
    <View style={[tw`flex flex-row justify-center mt-10`, { zIndex: 5 }]}>
      <View style={tw`flex flex-row w-2/3`}>
        <Button style={tw`flex-1`} onPress={handleCallback}>
          <Text weight="semiBold">{text}</Text>
        </Button>

        <Button style={tw`flex-1`} type="outlined" onPress={hideModal}>
          <Text weight="semiBold">Cancel</Text>
        </Button>
      </View>
    </View>
  );
}
