import {
  FlatList,
  StyleSheet,
  Text as Texts,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { Button, SuccessAndEmptyList, Text } from "@/components/Commons";
import { colors, Modal, password, required, tw, useFieldControl } from "@/lib";
import { Card, Icon } from "react-native-elements";
import { TextInput } from "@/components/Inputs";
import { CircleCheck } from "@/components/Icons/CircleCheck";
import { useNavigate } from "react-router-dom";
import {
  equalCredentials,
  equalPassword,
  invalidCredentials,
  passwordCredential,
} from "@/lib/errorMessage";
import SweetAlert from "react-bootstrap-sweetalert";
import { Success } from "@/components/Icons";

export const ChangePassword = () => {
  const [isSecureEntry, setIsSecureEntry] = useState(true);
  const [isSecureEntryn, setIsSecureEntryn] = useState(true);
  const [isSecureEntryc, setIsSecureEntryc] = useState(true);
  const navigate = useNavigate();
  const passwordInput = {
    password: useFieldControl<string>("", [required, password]),
    newPassword: useFieldControl<string>("", [required, password]),
    confirmPassword: useFieldControl<string>("", [required, password]),
    correct: useFieldControl(false),
    error: useFieldControl(""),
  };
  const oldPasswordEqual =
    passwordInput.password.value != passwordInput.newPassword.value;
  const equal =
    passwordInput.newPassword.value === passwordInput.confirmPassword.value;

  const validation = oldPasswordEqual && equal;

  const valid =
    passwordInput.password.valid &&
    passwordInput.newPassword.valid &&
    passwordInput.confirmPassword.valid;

  const newPassword = () => {
    if (validation) {
      passwordInput.correct.handleInputValue(true);
    } else if (!equal) {
      passwordInput.error.handleInputValue(passwordCredential);
    } else {
      passwordInput.error.handleInputValue(equalCredentials);
    }
  };
  const notification = [
    "6 characters",
    "1 uppercase letter",
    "1 lowercase letter",
    "1 digit",
  ];
  //@ts-ignore
  const renderList = ({ item }) => {
    return (
      <Text style={tw`pl-4`}>
        <Text type="h2" weight="bold">
          .{" "}
        </Text>
        {item}
      </Text>
    );
  };
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex-row justify-between`}>
        <View>
          <Text type="h2">Change password</Text>
        </View>
      </View>
      {!passwordInput.correct.value ? (
        <View style={tw`flex lg:flex-row mt-3`}>
          <Card
            containerStyle={tw`flex-initial w-full flex-col pt-6 min-h-[276px] rounded-3 max-w-[400px]`}
          >
            <View style={[tw`w-full`]}>
              <TextInput
                label="Password"
                placeholder="********"
                rightIcon={
                  <TouchableOpacity>
                    <Icon
                      // @ts-ignore

                      name={isSecureEntry ? "eye-slash" : "eye"}
                      size={20}
                      type="font-awesome"
                      color="#9CA3AF"
                      onPress={() => {
                        setIsSecureEntry((prev) => !prev);
                      }}
                      tvParallaxProperties={undefined}
                    />
                  </TouchableOpacity>
                }
                value={passwordInput.password.value}
                secureTextEntry={isSecureEntry}
                errorMessage={passwordInput.password.error}
                onChangeText={passwordInput.password.handleInputValue}
              />

              <TextInput
                label="New Password"
                placeholder="********"
                rightIcon={
                  <TouchableOpacity>
                    <Icon
                      // @ts-ignore

                      name={isSecureEntryn ? "eye-slash" : "eye"}
                      size={20}
                      type="font-awesome"
                      color="#9CA3AF"
                      onPress={() => {
                        setIsSecureEntryn((prev) => !prev);
                      }}
                      tvParallaxProperties={undefined}
                    />
                  </TouchableOpacity>
                }
                value={passwordInput.newPassword.value}
                secureTextEntry={isSecureEntryn}
                errorMessage={passwordInput.newPassword.error}
                onChangeText={passwordInput.newPassword.handleInputValue}
              />

              <TextInput
                label="Confirm Password"
                placeholder="********"
                rightIcon={
                  <TouchableOpacity>
                    <Icon
                      // @ts-ignore

                      name={isSecureEntryc ? "eye-slash" : "eye"}
                      size={20}
                      type="font-awesome"
                      color="#9CA3AF"
                      onPress={() => {
                        setIsSecureEntryc((prev) => !prev);
                      }}
                      tvParallaxProperties={undefined}
                    />
                  </TouchableOpacity>
                }
                value={passwordInput.confirmPassword.value}
                secureTextEntry={isSecureEntryc}
                errorMessage={passwordInput.confirmPassword.error}
                onChangeText={passwordInput.confirmPassword.handleInputValue}
              />

              <View style={tw`flex flex-cols pl-3 `}>
                <Text style={tw`text-black `}>
                  Make sure your new password has at least:
                </Text>
                <View style={tw`flex-grow-1 `}>
                  <FlatList data={notification} renderItem={renderList} />
                </View>
              </View>
              <View style={tw`flex w-full items-center justify-center`}>
                {passwordInput.error && (
                  <Text type="regular" color={colors.red}>
                    {passwordInput.error.value}
                  </Text>
                )}
              </View>

              <View style={tw`flex w-full mt-4`}>
                <Button disabled={!valid} onPress={() => newPassword()}>
                  Change Password
                </Button>
              </View>
            </View>
          </Card>
        </View>
      ) : (
        <SuccessAndEmptyList>
          <Success />
          <View style={tw`mt-4`}>
            <Text type="h3" color={colors.greenicon}>
              Success
            </Text>
          </View>
          <View>
            <Text color={colors.grayLightLightest}>
              {" "}
              Password was successfully changed{" "}
            </Text>
          </View>
        </SuccessAndEmptyList>
      )}
    </View>
  );
};

//export default ChangePassword

const styles = StyleSheet.create({});
