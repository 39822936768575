import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const Documents = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
  size?: number;
}) => {
  const { color = "#A1A1A9", style, size = 24 } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
       
        stroke={color}
        strokeLinecap={"round"}
        strokeWidth={2}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        fill="none"
      />
    </svg>
  );
};