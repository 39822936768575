import axios, { AxiosInstance, AxiosResponse } from "axios";
//@ts-ignore
import { ApiService } from "./";
const getCountryISO3 = require("country-iso-2-to-3");

class AddressService {
  api: AxiosInstance;
  token?: string

  constructor() {
    this.api = axios.create({
      baseURL: "https://api.precisely.com/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",

      },
    })
  }

  async getLocationToken():
    Promise<AxiosResponse<{ result: {accessToken: string} }>> {
    const endpoint = 'location'
    return ApiService.api.post(`${endpoint}/token`);
  }

  async auth() {
    const response = await this.getLocationToken()
    this.token = response.data.result.accessToken
    //@ts-ignore
    this.api.defaults.headers["Authorization"] = `Bearer ${this.token}`;
  }

  async checkAddress(search?: string, country?: string) {
    if (this.token == null) {
      await this.auth()
    }
    if (!country)
      country = "CA"
    return this.api?.get(`typeahead/v1/locations?searchText=${search}&country=${getCountryISO3(country)}`)
  }

}
const singleton = new AddressService();
export default singleton;