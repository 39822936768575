import React, { useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { Input, InputProps } from "react-native-elements";
import { useDeviceContext } from "twrnc";

import { tw } from "@/lib";
import { Text } from "@/components/Commons";

export const SeparatedTextInput = (props: { length: number } & InputProps) => {
  useDeviceContext(tw);

  const { length } = props;

  const inputs: {
    el: React.ReactElement;
    ref: React.MutableRefObject<HTMLInputElement | null>;
  }[] = [];

  const innerValue = useState(props.value || "");

  const [showError, setShowError] = useState(false);
  const [selectedInput, changeSelectedInput] = useState<null | number>(null);

  useEffect(() => {
    if (selectedInput == props.length) {
      setShowError(true);
    }
  }, [selectedInput]);

  useEffect(() => {
    innerValue[1](props.value || "");
  }, [props.value]);

  useEffect(() => {
    props.onChangeText && props.onChangeText(innerValue[0] || "");
  }, [[innerValue[0]]]);

  const onChangeInput = (value: string, index: number) => {
    if (value.length > 0) {
      if (innerValue[0].length > index) {
        innerValue[1](
          innerValue[0]?.slice(0, index) +
            value +
            innerValue[0]?.slice(index + 1)
        );
      } else {
        innerValue[1](
          innerValue[0]?.slice(0, index) + value + innerValue[0]?.slice(index)
        );
      }
      changeSelectedInput(index + 1);
      inputs[index].ref?.current?.blur();
      inputs[index + 1]?.ref?.current?.focus();
    } else {
      innerValue[1](
        innerValue[0]?.slice(0, index) + innerValue[0]?.slice(index + 1)
      );
    }
  };

  const onFocus = (index: number) => {
    if (innerValue[0].length < index) {
      inputs[innerValue[0].length].ref?.current?.focus();
      changeSelectedInput(innerValue[0].length);
    } else {
      changeSelectedInput(index);
    }
  };

  for (let i = 0; i < length; ++i) {
    const ref = useRef(null);
    const value = innerValue[0]?.at(i) || "";
    inputs.push({
      el: (
        <View style={tw`w-[50px]`} key={i}>
          <Input
            ref={ref}
            value={value}
            maxLength={1}
            autoCompleteType={undefined}
            inputContainerStyle={[
              tw.style(
                "w-full",
                "h-input",
                "border",
                selectedInput == i ? "border-blue-600" : "border-gray-300",
                "rounded-3",
                "px-2",
                "bg-white"
              ),
              props.style,
            ]}
            inputStyle={[
              Platform.select({
                web: {
                  outline: "none",
                  fontFamily: "BarlowSemiCondensed_400Regular",
                  fontSize: 14,
                },
              }),
              tw`overflow-hidden`,
            ]}
            errorStyle={tw`m-0 p-0`}
            containerStyle={tw`w-full px-2 h-auto`}
            onChange={(e) => {
              onChangeInput(e.nativeEvent.text, i);
            }}
            // @ts-ignore
            onClick={() => onFocus(i)}
          />
        </View>
      ),
      ref,
    });
  }

  const inputRef = useRef(null);

  return (
    <View style={tw`w-full`}>
      <View style={[tw`w-full flex-row items-center justify-center`]}>
        {inputs.map((e) => e.el)}
      </View>
      <Text type="caption" style={tw`pl-2 mt-2 text-red-500`}>
        {showError && props.errorMessage}
      </Text>
    </View>
  );
};
