import React, { FC, useEffect } from "react";
import { useUserLogged } from "@/hooks";
import { Navigate } from "react-router-dom";
import { useNavigate } from "../helper";

interface Props {
  children: JSX.Element;
  section: string;
}

export const AuthGuard: FC<Props> = ({ children, section }) => {
  const { isLogged, userRole, userLogged, checkUser } = useUserLogged();

  const goBack = () => {
    window.history.back();
  };

  if (!isLogged) {
    return <Navigate to="/login" />;
  } else if (!userRole || !userLogged || userLogged.id == 0) {
    checkUser();
  }

  if (
    !(
      userRole === "admin" ||
      userRole === "super_admin" ||
      userRole === "support" ||
      userRole === "operations"
    ) &&
    section === "admin"
  ) {
    goBack();
  }

  if (
    !(
      userRole === "super_admin" ||
      userRole === "support" ||
      userRole === "operations"
    ) &&
    section === "super-admin"
  ) {
    goBack();
  }

  return children;
};
