import * as React from 'react';
import { View } from 'react-native';
import dayjs from "dayjs";
import { capitalizeAll, colors,tw } from "@/lib";
import { useNavigate } from "@/routes";
import { AccessLevel, Status, WorkspaceInterface } from "@/types";
import { Text } from "@/components/Commons";
import { CheckBox } from 'react-native-elements';
import { Checkdasda } from "../Icons/Check/Check";
import { Hoverable } from "react-native-web-hover";
import { TouchableOpacity } from 'react-native-gesture-handler';

interface INoticationMessageProps {
  item: WorkspaceInterface,
}

const NoticationMessage: React.FunctionComponent<INoticationMessageProps> = ({item}) => {

  const {accessType = 'read and write', createdAt, status = 'requested',updatedAt,user } = item;
  const {firstName, lastName} = user ?? {firstName: "An", lastName: 'User'};
  const name = `${firstName} ${lastName}`;
  const isNewNotification = status === 'requested';
  const navigate = useNavigate();

  const getTimeMessage = (createdAt?: string, updatedAt?: string) => {
    let timeMessage = '';
    const daysDiff = dayjs().diff(dayjs(updatedAt),'hour');
    if ( daysDiff < 24 ){
      const minutesDiff = dayjs().diff(dayjs(updatedAt),'minute');
      if( minutesDiff < 60 ){  
        timeMessage = `${minutesDiff <= 1 ? 1 : minutesDiff } minute${minutesDiff <= 1 ? '' : 's' } ago`;
      }
      else{
        const hourDiff = dayjs().diff(dayjs(updatedAt),'hour');
        timeMessage = `${hourDiff} hour${hourDiff > 1 ? 's' : '' } ago`;
      }
    }
    else {
      timeMessage = dayjs(updatedAt).format('MMMM D, YYYY');
    }
    return timeMessage;
  }
  const getMainMessage = (status: Status , name: string, accessType: AccessLevel) => {
    let message = '';
    
    switch (status) {
      case 'granted':
      case 'approved':
        message = `Granted ${capitalizeAll(accessType)} access to ${capitalizeAll(name)} `;
        break;
      case 'denied':
      case 'rejected':
        message = `Rejected ${capitalizeAll(accessType)} access to ${capitalizeAll(name)} `;
        break;
      case 'requested':
        message = `${capitalizeAll(name)} has requested ${capitalizeAll(accessType)} Access.`;
        break;
      case 'revoked':
        message = `Revoked ${capitalizeAll(accessType)} access to ${capitalizeAll(name)} `;
        break;
      case 'expired':
        message = `Expired ${capitalizeAll(accessType)} access to ${capitalizeAll(name)} `;
        break;
      default:
        break;
    }
    return message;
  }; 

  return (
    <Hoverable>
      { ( { hovered } ) => <TouchableOpacity
        onPress={()=>navigate("clientInfoSupportAccess")}
      >
        <View style={tw`
            flex 
            flex-row 
            w-full 
            min-h-[30px]
            p-[24px]
            ${isNewNotification ? (hovered ?'bg-hov-not-new' : 'bg-not-new') : (hovered ? 'bg-[#F8F8F8]' : '')}
            hover:cursor-pointer
            `}
          >
            <View style={tw`flex flex-col w-full items-start justify-center`}>
              <Text type='h6' weight={`${isNewNotification ? 'openBold' : 'tinyBold'}`}>
                {getMainMessage(status, name, accessType)}
              </Text>
              <Text color='#A1A1A9' type='h6' weight='tinyBold'>
                {getTimeMessage(createdAt, updatedAt)}
              </Text>
            </View>  
          </View>
        </TouchableOpacity>}
    </Hoverable>
  );
};

export default NoticationMessage;
