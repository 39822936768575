import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Image,
  ScrollView,
  ViewProps,
  StyleProp,
  ViewStyle,
  Animated,
} from "react-native";
import { useDeviceContext } from "twrnc";
import { Outlet, useLocation } from "react-router-dom";

import { colors, tw } from "@/lib";
import { getRoute, useNavigate } from "@/routes";

import { TouchableOpacity } from "react-native-gesture-handler";

import {
  InfoTickets,
  Users,
  Status,
  Dashboard,
  Work,
  Info,
  PaymentWallet,
  Wallet,
} from "@/components/Icons";
import { Badge, Text } from "@/components/Commons";
import { mobile } from "@/lib";
import { Exit } from "../Icons/Exit";
import { Hands } from "../Icons/Hands";
import { AuthService, TokenService, WorkspaceService } from "@/services";
import { useUserLogged } from "@/hooks";
import { WorkspaceInterface } from "@/types";
import Notifications from "../Notifications/Notifications";
import { Bell } from "../Icons/Bell";
import { AvatarsLogo } from "../Icons/AvatarsLogo";
import { Avatar } from "react-native-elements";
import { Avatars } from "../Commons/Avatar";

interface MenuBar {
  name: string;
  icon?: (props: { color: string; style: StyleProp<ViewStyle> }) => JSX.Element;
  submenuTitle: string;
  hidden?: boolean;
  routeName?: string;
  submenu?: {
    title: string;
    routeName?: string;
  }[];
}

export const AdminLayout = (props: ViewProps & { states?: number }) => {
  useDeviceContext(tw);
  const location = useLocation();
  const navigate = useNavigate();

  const [showSubmenu, changeShowSubmenu] = useState(false);

  const subMenuWidth = useRef(new Animated.Value(0)).current;

  const { logoutUser, userLogged } = useUserLogged();
  const [state, setState] = useState<number>(props.states!);
  const [arrayLength, setArrayLength] = useState<number>(0);
  const token = TokenService.getLocalAccessToken();

  useEffect(() => {
    Animated.timing(subMenuWidth, {
      toValue: showSubmenu ? 100 : 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [showSubmenu]);

  const menuBar: MenuBar[] = [
    {
      name: "",
      icon: Dashboard,
      submenuTitle: "Company Info",
      //routeName: "Indexes",
    },
    {
      name: "clientInfoSupportAccess",
      icon: Info,
      submenuTitle: "Resources & Help",

      //routeName: "Resources",
      routeName: "clientInfoSupportAccess",
    },
    {
      name: "profile",
      hidden: true,
      submenuTitle: "Profile",
      submenu: [
        {
          title: "Log Out",
          routeName: "login",
        },
      ],
    },
  ];

  const [menuSelected, setMenuSelected] = useState(
    location.pathname.split("/")[2]
  );

  const sideBar = menuBar.find((e) => e.name == menuSelected);

  return (
    <View style={tw`flex flex-row justify-start w-[100vw] h-[100vh]`}>
      <View
        style={tw`w-[65px] sm:w-[88px] h-full bg-black flex flex-col justify-between`}
      >
        <Image
          style={tw`w-[51px] mx-auto mt-4 h-[51px]`}
          source={require("@/assets/images/LOGO/logo.png")}
        ></Image>

        <View style={tw`mx-auto`}>
          {menuBar
            .filter((e) => !e.hidden)
            .map((e) => {
              const active = e.name == menuSelected;
              return (
                <TouchableOpacity
                  key={e.name}
                  style={[
                    tw`w-menu-btn h-menu-btn rounded-4 items-center justify-center`,
                    tw.style(active && "bg-btn"),
                  ]}
                  onPress={() => {
                    setMenuSelected(e.name);
                    !e.submenu && navigate(e.name);

                    changeShowSubmenu(true);
                    //@ts-ignore
                    e.submenu?.length && navigate(e.submenu[0].routeName);
                  }}
                >
                  {
                    // @ts-ignore
                    <e.icon />
                  }
                </TouchableOpacity>
              );
            })}
        </View>

        <View style={tw`mx-auto`}>
          <TouchableOpacity
            // @ts-ignore
            onPressOut={(_) => {
              return state ? setState(0) : setState(1);
            }}
          >
            <Bell style={tw`ml-2 mb-8 md:bottom-[60px] md:ml-[20px]`} />
            {arrayLength != 0 && (
              <>
                <Badge
                  text={arrayLength || 0}
                  style={tw`ml-2 mb-4 bottom-[60px] md:mb-[60px] md:ml-[20px]`}
                />
              </>
            )}
          </TouchableOpacity>

          <TouchableOpacity
            // @ts-ignore
            onPressOut={(_) => setMenuSelected("profile")}
          >
            <View style={tw`w-[48px] h-[48px] rounded-full  mb-8 lg:ml-2 `}>
              {userLogged.avatar?.m ? (
                <Avatars
                  uri={userLogged.avatar.m || undefined}
                  width={"1/12"}
                  size={44}
                />
              ) : (
                <AvatarsLogo color={colors.white} />
              )}
            </View>
          </TouchableOpacity>
        </View>
      </View>

      <Notifications
        state={state}
        onhovered={(state) => {
          setArrayLength(state);
        }}
        onState={(states) => {
          setState(states);
        }}
      />

      {sideBar?.submenu && (
        <Animated.View
          style={[
            tw.style({
              hidden: !subMenuWidth,
            }),
            tw`h-full max-w-[272px] bg-white sm:flex`,
            {
              width: mobile()
                ? subMenuWidth.interpolate({
                    inputRange: [0, 100],
                    outputRange: ["0%", "100%"],
                  })
                : "100%",
            },
          ]}
        >
          <View style={tw`flex-col flex w-[272px] px-[16px] py-[28px] h-full `}>
            <Text type="h2" style={tw`pl-2 pb-[24px]`}>
              {sideBar?.submenuTitle}
            </Text>
            {sideBar?.submenu.map((e, index: number) => {
              const routePath = getRoute(e.routeName)?.path;
              let active =
                e.routeName &&
                routePath &&
                getRoute()?.path.includes(routePath);

              const linkPressed = () => {
                changeShowSubmenu(false);
                if (!active && e.routeName) {
                  navigate(e.routeName);
                }
              };
              return (
                <View
                  style={tw.style(
                    e.title === "Log Out" &&
                      `absolute inset-x-0 bottom-0 px-[16px] py-[28px]`
                  )}
                  key={index}
                >
                  <TouchableOpacity
                    key={e.title}
                    style={[
                      tw`w-full h-[48px] rounded-3 `,
                      tw.style(active && "bg-btn"),
                    ]}
                    onPress={e.title === "Log Out" ? logoutUser : linkPressed}
                  >
                    {e.title === "Log Out" ? (
                      <View style={tw` flex flex-row`}>
                        <Exit color="red" />
                        <Text
                          weight={active ? "regular" : "semiBold"}
                          style={tw`my-auto pl-2 `}
                          color={colors.red}
                        >
                          {e.title}
                        </Text>
                      </View>
                    ) : (
                      <Text
                        weight={active ? "semiBold" : "regular"}
                        style={[
                          tw`my-auto pl-2`,
                          tw.style(active ? "text-white" : "text-black"),
                        ]}
                      >
                        {e.title}
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
        </Animated.View>
      )}

      <ScrollView
        contentContainerStyle={{
          backgroundColor: colors.rebrandLayoutBGColor,
          ...tw`w-full min-h-[100vh]`,
          zIndex: 0,
        }}
      >
        <Outlet />
      </ScrollView>
    </View>
  );
};
