import { StatusBar } from "expo-status-bar";
import React, { useEffect, useMemo, useState } from "react";
import {
  View,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
} from "react-native";

import { SideRegistration } from "../../../components/Layouts/SideRegistration";
import tw from "@/lib/tailwind";
import { useDeviceContext } from "twrnc";
import { Button, Text } from "@/components/Commons";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { Dropdown, TextInput } from "@/components/Inputs";
import {
  colors,
  regexLetter,
  regexLettersThree,
  useFieldControl,
  zipMask,
} from "@/lib";
import { required, cadPostal } from "@/lib/Rules";
import { useDispatch } from "react-redux";
import { setAccountDetails } from "@/store/register.store";
import { useNavigate } from "@/routes";
import MaskInputs from "@/components/Inputs/MaskInput";
import { store } from "@/store";
import { AddressService, FirebaseService, MailVerification } from "@/services";
import { Address } from "@/types";
import { CheckBox } from "react-native-elements";
import { State } from "country-state-city";
import { SimpleDropdown } from "@/components/Inputs/SimpleDropdown";
import { CheckboxIcon } from "@/components/Icons/CheckboxIcon";
import {
  BUTTON_ADDRESS_INPUT,
  BUTTON_COMPANY_DETAIL,
} from "@/services/firebase.service";
const getCountryISO2 = require("country-iso-3-to-2");

interface DropAddress {
  value: string;
  label: string;
  metaInfo?: any;
}

export const CompanyDetails = () => {
  useDeviceContext(tw);

  const navigate = useNavigate();

  const registerStore = store.getState();

  const companyInput = {
    unit: useFieldControl(registerStore.register.companyInfo.unit_number, []),
    streetNumber: useFieldControl<string>(
      registerStore.register.companyInfo.street_number,
      [required]
    ),
    streetName: useFieldControl<string>(
      registerStore.register.companyInfo.street_name,
      [required]
    ),
    postalCode: useFieldControl<string>(
      registerStore.register.companyInfo.postal_code,
      [required, cadPostal]
    ),
    city: useFieldControl<string>(registerStore.register.companyInfo.city, [
      required,
    ]),
    province_state: useFieldControl<string>(
      registerStore.register.companyInfo.province,
      [required]
    ),
    country: useFieldControl<string>(
      registerStore.register.companyInfo.country,
      [required]
    ),
  };

  const [address, changeAddressValid] = useState<DropAddress[] | undefined>();
  const [emergencyCheckbox, changeEmergencyCheckbox] = useState<boolean>(false);

  const [state, setState] = useState({
    typing: false,
    typingTimeout: 0,
    element: false,
  });

  const checkAddress = (search?: string) => {
    //@ts-ignore
    const match = regexLetter.test(search);
    //@ts-ignore
    const matchThree = regexLettersThree.test(search);
    if (search == "") {
      changeAddressValid(undefined);
      clearTimeout(state.typingTimeout);
      return;
    }
    if (state.typingTimeout) {
      clearTimeout(state.typingTimeout);
    }

    setState({
      typing: true,
      element: match && !matchThree,
      //@ts-ignore
      typingTimeout: setTimeout(async () => {
        //search function
        if (companyInput.country.value && match && !matchThree) {
          const response = await AddressService.checkAddress(search);
          let dataInfo: DropAddress[] = [];
          response.data.location.map((l: any, index: number) => {
            dataInfo.push({
              value: `${index}`,
              label: l.address.formattedAddress,
              metaInfo: {
                ...l.address,
                country: getCountryISO2(l.address.country),
              },
            });
          });
          setState({ ...state, typing: false });
          changeAddressValid(dataInfo);
        }
      }, 1000),
    });
  };

  const valid =
    companyInput.unit.valid &&
    companyInput.streetName.valid &&
    companyInput.postalCode.valid &&
    companyInput.city.valid &&
    companyInput.province_state.valid &&
    companyInput.country.valid &&
    companyInput.streetNumber.valid &&
    emergencyCheckbox;

  const dispatch = useDispatch();
  const submit = async () => {
    if (valid) {
      FirebaseService.logFirebaseEvent(BUTTON_COMPANY_DETAIL);
      const input = {
        city: companyInput.city.value,
        province: companyInput.province_state.value,
        postal_code: companyInput.postalCode.value,
        unit_number: companyInput.unit.value || undefined,
        street_name: companyInput.streetName.value,
        street_number: companyInput.streetNumber.value,
        country: companyInput.country.value,
      };
      //@ts-ignore
      dispatch(setAccountDetails(input));
      const phone: string = store.getState().register.admin.phone;
      if (phone) {
        const response = await MailVerification.sendWorkspaceVerifySMS(phone);
        console.log(response);
      }
      navigate("registerSms");
    }
  };

  const inputFill = (metainfo: Address) => {
    const {
      streetName,
      postCode,
      unitType,
      areaName3,
      areaName1,
      addressNumber,
      country,
    } = metainfo;

    //fill up all the inputs with it's respective value
    companyInput.streetName.handleInputValue(streetName);
    companyInput.postalCode.handleInputValue(postCode);
    companyInput.unit.handleInputValue(unitType);
    companyInput.city.handleInputValue(areaName3);
    companyInput.province_state.handleInputValue(areaName1);
    companyInput.streetNumber.handleInputValue(addressNumber);
    companyInput.country.handleInputValue(country);

    FirebaseService.logFirebaseEvent(BUTTON_ADDRESS_INPUT);
  };

  /* For latter
  const countries = Country.getAllCountries().map((country) => {
    return { label: country.name, value: country.isoCode }
  })
  */
  const countries = [{ label: "Canada", value: "CA" }];

  const regions = useMemo(() => {
    return State.getStatesOfCountry(companyInput.country.value).map((state) => {
      return { label: state.name, value: state.isoCode };
    });
  }, [companyInput.country]);

  useEffect(() => {
    if (regions?.length > 0) {
      const foundRegion = regions.find(
        (item) => item.value === companyInput.province_state.value
      );
      if (!foundRegion)
        companyInput.province_state.handleInputValue(regions[0].value);
    }
  }, [regions]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={tw`flex-grow-1`}
    >
      <StatusBar style="light" />
      <View style={tw`flex flex-grow-1 flex-row`}>
        <SideRegistration step={3} />
        <AuthLayout>
          <View
            style={[
              tw`flex flex-grow-1 flex-col justify-center  w-full max-w-[50rem] items-center`,
            ]}
          >
            <Text type="h2" weight="bold" style={tw`my-10`}>
              {" "}
              Your Company Address
            </Text>

            <View style={[tw`w-full`, { zIndex: "auto" }]}>
              <View style={[tw`flex sm:justify-center `, { zIndex: "auto" }]}>
                <Dropdown
                  label="Address Search"
                  data={address}
                  onChangeText={checkAddress}
                  placeholder={
                    companyInput.streetName.value &&
                    "Start typing your address…"
                  }
                  //lazy={state.element?true:false}
                  lazy
                  loading={state.typing && state.element}
                  onSelect={(e, metaInfo) => inputFill(metaInfo)}
                />
              </View>
              {companyInput.streetName.value && (
                <>
                  <View
                    style={tw`flex flex-column md:flex-row md:items-center md:justify-between md:mt-2`}
                  >
                    <View
                      style={tw`flex flex-column md:flex-row md:w-full md:max-w-1/4`}
                    >
                      <TextInput
                        label="Suite"
                        value={companyInput.unit.value}
                        type="letternumbers"
                        errorMessage={companyInput.unit.error}
                        onChangeText={companyInput.unit.handleInputValue}
                      />
                      <TextInput
                        label="Street Number"
                        type="letternumbers"
                        value={companyInput.streetNumber.value}
                        errorMessage={companyInput.streetNumber.error}
                        onChangeText={
                          companyInput.streetNumber.handleInputValue
                        }
                      />
                    </View>
                    <View
                      style={tw`flex w-full flex-column md:flex-row md:max-w-2/4 `}
                    >
                      <TextInput
                        label="Street Name"
                        type="letters"
                        value={companyInput.streetName.value}
                        errorMessage={companyInput.streetName.error}
                        onChangeText={companyInput.streetName.handleInputValue}
                      />
                    </View>
                  </View>
                  <View
                    style={tw`flex w-full flex-column md:flex-row md:max-w-1/2`}
                  >
                    <MaskInputs
                      label="Postal Code/ZIP Code"
                      errorMessage={companyInput.postalCode.error}
                      value={companyInput.postalCode.value}
                      onChangeText={companyInput.postalCode.handleInputValue}
                      mask={zipMask}
                    />
                    <TextInput
                      label="City"
                      type="letters"
                      value={companyInput.city.value}
                      errorMessage={companyInput.city.error}
                      onChangeText={companyInput.city.handleInputValue}
                    />
                  </View>
                  <View
                    style={[
                      tw`flex  w-full flex-column md:flex-row md:max-w-1/2`,
                      { zIndex: "auto " },
                    ]}
                  >
                    {regions && regions.length > 0 && (
                      <SimpleDropdown
                        label="Province/State"
                        value={companyInput.province_state.value}
                        errorMessage={companyInput.province_state.error}
                        data={regions}
                        onSelect={companyInput.province_state.handleInputValue}
                      />
                    )}
                    <SimpleDropdown
                      label="Country"
                      containerStyle={[tw`flex `]}
                      value={companyInput.country.value}
                      data={countries}
                      onChangeText={(e) => {
                        return companyInput.country.handleInputValue(e);
                      }}
                      onSelect={companyInput.country.handleInputValue}
                    />
                  </View>

                  <View
                    style={tw`flex flex-row items-stretch justify-items-start	flex-nowrap`}
                  >
                    <View>
                      <CheckBox
                        center
                        containerStyle={[
                          tw`bg-transparent border-0 text-[${colors.blue}] m-0`,
                        ]}
                        checkedIcon={
                          <CheckboxIcon
                            backgroundColor={colors.blue}
                            color={colors.grayLightest}
                          />
                        }
                        uncheckedIcon={
                          <CheckboxIcon
                            backgroundColor={colors.white}
                            color={colors.grayLightest}
                          />
                        }
                        checked={emergencyCheckbox}
                        onPress={() => {
                          changeEmergencyCheckbox(!emergencyCheckbox);
                        }}
                      />
                    </View>
                    <View
                      style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        marginRight: "5px",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          changeEmergencyCheckbox(!emergencyCheckbox);
                        }}
                      >
                        <Text weight="regular" style={tw`text-center`}>
                          {" "}
                          Please ensure your company address is complete and
                          accurate for use with Emergency 911 Services
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </>
              )}
            </View>
            <View
              style={tw`flex mt-5 w-full max-w-[350px] items-center justify-center`}
            >
              <Button disabled={!valid} onPress={submit} style={tw` w-full `}>
                Continue
              </Button>
            </View>
          </View>
        </AuthLayout>
      </View>
    </KeyboardAvoidingView>
  );
};
