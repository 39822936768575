import * as React from 'react';
import { NoneExistance } from "../Icons/Logo/NoneExistance";
import { Text } from "@/components/Commons";
import { View } from "react-native";
import { tw } from "@/lib";

const NoNotifications: React.FunctionComponent = () => {
  return <View style={tw`flex w-full min-h-1/2 items-center justify-center`}>
  <NoneExistance/>
  <Text type="bigger" weight="openBold" style={[tw`w-[280px] text-center mb-[16px]`,]}> 
    There are no notifications yet.
  </Text>
  <Text type="h6" weight="tinyBold" color='#6C6C77' style={[tw`w-[249px] text-center`,]}>
    When you get notifications, they’ll show up here.
  </Text>
</View>;
};

export default NoNotifications;
