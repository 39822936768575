import { StyleSheet, View, ScrollView } from "react-native";
import React from "react";
import { tw } from "@/lib";
import { Text } from "../Commons";

const Usage_Report = (props: any) => {
  const { data, extension = "Extension" } = props;

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator
      contentContainerStyle={tw`w-full`}
    >
      <View style={tw`flex-col  w-full flex-initial min-w-200`}>
        <View style={tw`flex-row px-4 mb-4 shrink-0 flex-nowrap`}>
          <View style={tw`flex-1`}>
            <Text style={tw`text-gray-400`}>{extension}</Text>
          </View>
          <View style={tw`flex-1`}>
            <Text style={tw`text-gray-400`}>Calls </Text>
          </View>

          <View style={tw`flex-1`}>
            <Text style={tw`text-gray-400`}>Total Duration </Text>
          </View>

          <View style={tw`flex-1 `}>
            <Text style={tw`text-gray-400`}>Total Cost </Text>
          </View>
          <View style={tw`flex-1 `}>
            <Text style={tw`text-gray-400`}>Avg Duration </Text>
          </View>
          <View style={tw`flex-1 `}>
            <Text style={tw`text-gray-400`}>Avg Cost </Text>
          </View>
        </View>

        {data.value.map((response: any) => {
          return (
            <View
              style={[
                tw`flex-row bg-white px-4 py-4 mb-1 rounded-3 items-center justify-center`,
                ,
                { zIndex: "auto" },
              ]}
              key={response.id}
            >
              <View style={tw`flex-1 pl-2  `}>
                <Text type="regular" weight="regular">
                  {response.extension}
                </Text>
              </View>
              <View style={tw`flex-1 pl-2  `}>
                <Text type="regular" weight="regular">
                  {response.calls}
                </Text>
              </View>
              <View style={tw`flex-1  pl-2 `}>
                <Text type="regular" weight="regular">
                  {response.totalDuration}
                </Text>
              </View>
              <View style={tw`flex-1  pl-2 `}>
                <Text type="regular" weight="regular">
                  {response.totalDuration}
                </Text>
              </View>
              <View style={tw`flex-1  pl-2 `}>
                <Text type="regular" weight="regular">
                  {response.totalCost}
                </Text>
              </View>
              <View style={tw`flex-1 pl-2`}>
                <Text type="regular" weight="regular">
                  {response.avgDuration}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default Usage_Report;

const styles = StyleSheet.create({});
