import { StyleSheet, View, ScrollView } from "react-native";
import React, { useState } from "react";
import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { Dropdown } from "react-bootstrap";
import { DownArrow } from "@/components/Icons/DownArrow";
import { Card } from "react-native-elements";
import { useDeviceContext } from "twrnc";

const Cdr_Reports = () => {
  useDeviceContext(tw);

  const [dataTotal, setDataTotal] = useState({
    value: [
      {
        id: 1,
        name: "Duration",
        outCommingCalls: "02:02:43",
        incommingCalls: "06:54:33",
        Summary: "08:55:23",
      },
      {
        id: 2,
        name: "Charges",
        outCommingCalls: "$2112",
        incommingCalls: "$2327",
        Summary: "$4439",
      },
    ],
  });
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex flex-row justify-between `}>
        <View style={tw`flex flex-row items-center justify-around`}>
          <Text type="h2" weight="bold">
            CDR Report
          </Text>
        </View>
      </View>

      <View style={[tw`flex-row py-16 h-[150px] `, { zIndex: "auto" }]}>
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            style={{
              lineHeight: 0,
              ...tw`no-underline bg-white py-1 px-4 rounded-2`,
            }}
            size="sm"
            id="dropdown-basic"
            bsPrefix="btn"
          >
            <View style={tw`flex-row items-center`}>
              <Text style={tw`pr-6`}>Month</Text>
              <DownArrow color="black" />
            </View>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              style={tw`text-black text-btn-menu-item font-semibold`}
            >
              Month
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </View>

      <Card containerStyle={tw`w-full rounded-3 pt-5 lg:max-w-1/2`}>
        <ScrollView
          horizontal
          contentContainerStyle={tw`flex-col w-full min-w-80`}
        >
          <View style={tw`flex flex-col w-full  my-2 `}>
            <View style={tw`flex flex-row  `}>
              <View
                style={tw`flex-1 flex-row   lg:max-w-[110px]  py-2  items-center justify-start   `}
              ></View>

              <View
                style={tw`flex-1 flex-row   lg:max-w-[180px] py-2  items-center justify-start pl-2   `}
              >
                <Text type="regular" weight="semiBold" color={colors.gray}>
                  Outcoming Calls
                </Text>
              </View>
              <View
                style={tw`flex-1 flex-row   lg:max-w-[180px]  py-2  items-center justify-start pl-2  `}
              >
                <Text type="regular" weight="semiBold" color={colors.gray}>
                  Incoming Calls
                </Text>
              </View>

              <View
                style={tw`flex-1 flex-row   lg:max-w-[80px]  py-2  items-center justify-center   `}
              >
                <Text type="regular" weight="semiBold" color={colors.gray}>
                  Summary
                </Text>
              </View>
            </View>
          </View>

          <View
            style={tw`flex flex-col w-full border-t-2 border-gray-200 my-2 `}
          >
            {dataTotal.value.map((item, index: number) => {
              return (
                <View style={tw`flex flex-row  `} key={index}>
                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[110px]  py-2 border-r-2 border-gray-200 items-center justify-start   `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.gray}>
                      {item.name}
                    </Text>
                  </View>

                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[180px] py-2  items-center justify-start pl-2   `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.black}>
                      {item.outCommingCalls}
                    </Text>
                  </View>
                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[180px]  py-2  items-center justify-start pl-2  `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.black}>
                      {item.incommingCalls}
                    </Text>
                  </View>

                  <View
                    style={tw`flex-1 flex-row   lg:max-w-[80px]  py-2  items-center justify-center   `}
                  >
                    <Text type="regular" weight="semiBold" color={colors.black}>
                      {item.Summary}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        </ScrollView>
      </Card>
    </View>
  );
};

export default Cdr_Reports;

const styles = StyleSheet.create({});
