import "react-native-gesture-handler";
//import { StatusBar } from 'expo-status-bar';
import React from "react";
import { StyleSheet, SafeAreaView } from "react-native";
//import { NavigationContainer } from '@react-navigation/native';
// @ts-ignore
import { ModalPortal } from "react-native-modals";

import tw from "./lib/tailwind";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { ToastProvider } from "react-native-toast-notifications";
import { Swal } from "./components/Commons";
import { ShowAdminBanner } from "./components/Workspace/ShowAdminBanner";
import { FirebaseAnalytics } from "./components/Firebase/FirebaseAnalytics";
import { FacebookAnalytics } from "./components/Facebook/FacebookAnalytics";
import Crispa from "./components/crisp/crisp";

export default function App() {
  return (
    <Provider store={store}>
      <ShowAdminBanner />
      <ToastProvider>
        <React.Fragment>
          <SafeAreaView style={tw`flex-grow-1`}>
            <BrowserRouter>
              <FirebaseAnalytics />
              <FacebookAnalytics />
              <Routes />
            </BrowserRouter>
          </SafeAreaView>
          <ModalPortal />
        </React.Fragment>
      </ToastProvider>
      <Swal />
      <Crispa />
    </Provider>
  );
}

const styles = StyleSheet.create({});
