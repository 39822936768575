import React, { FC, useEffect, useState } from "react";
import { View, TouchableOpacity, Linking } from "react-native";

import { useDeviceContext } from "twrnc";
//@ts-ignore
import { CONDITIONS, PRIVACY } from "@env";
import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { useLocation } from "react-router-dom";

interface ConditionsProps {
  isLoggin?: boolean;
}

export const TermAndConditions: FC<ConditionsProps> = ({
  isLoggin = false,
}) => {
  useDeviceContext(tw);
  const { pathname } = useLocation();
  const [word, setWord] = useState("");

  useEffect(() => {
    let newWord = "logging in";
    if (pathname.includes("register")) newWord = "signing up";
    if (pathname.includes("download")) newWord = "downloading";
    setWord(newWord);
  }, [pathname]);

  return (
    <View style={{ zIndex: -10, ...tw`flex items-center md:flex-row` }}>
      <Text style={tw`tex-center`} color={colors.textColor}>
        By {word}, you agree to our
      </Text>
      <View style={tw`flex flex-row`}>
        <TouchableOpacity
          onPress={() => Linking.openURL(CONDITIONS)}
          style={tw`mx-1`}
        >
          <Text color={colors.blue}>Terms and Conditions</Text>
        </TouchableOpacity>
        <Text color={colors.textColor}>and</Text>
        <TouchableOpacity
          onPress={() => Linking.openURL(PRIVACY)}
          style={tw`ml-1`}
        >
          <Text color={colors.blue}>Privacy Policy</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
