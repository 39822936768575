import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  rol: string;
  isInside: boolean;
  name: string;
  accessLevel: string;
}

const initialState: State = {
  isInside: false,
  rol: "",
  name: "",
  accessLevel: "",
};

const bannerSlice = createSlice({
  name: "bannerStore",
  initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<State>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearInfo: (state) => {
      return initialState;
    },
  },
});

export default bannerSlice.reducer;
export const { setInfo, clearInfo } = bannerSlice.actions;
