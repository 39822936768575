import React from "react";

import { AllowByRoleGuard } from "@/routes/guards";

const COMPANY_ID_NOT_ALLOWED_ROLES = ["user", "admin"];

interface Props {
  children: React.ReactElement;
}

export const SystemUserCompanyGuard = ({ children }: Props) => {
  return (
    <AllowByRoleGuard roleArray={COMPANY_ID_NOT_ALLOWED_ROLES}>
      {children}
    </AllowByRoleGuard>
  );
};
