import { View, ScrollView, Pressable } from "react-native";
import React, { useState, useEffect } from "react";
import { useDeviceContext } from "twrnc";
import { CheckBox } from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";

import {
  tw,
  useFieldControl,
  required,
  number,
  colors,
  expireDate,
  expireDateLongMask,
  regexNumbers,
} from "@/lib";
import { TextInput, DropFileInput, SearchBar } from "@/components/Inputs";
import { Text } from "@/components/Commons";
import MaskInputs from "@/components/Inputs/MaskInput";

interface IFilter {
  filter: string;
  status: boolean;
  children?: IFilter[];
}

const filtersArr: IFilter[] = [
  {
    filter: "All clients",
    status: true,
  },
  {
    filter: "All Admins",
    status: false,
    children: [
      { filter: "James Culhane", status: false },
      { filter: "Lincoln Stanton", status: false },
      { filter: "Adison Gouse", status: false },
    ],
  },
  {
    filter: "All Partners",
    status: true,
    children: [
      { filter: "James Culhane", status: true },
      { filter: "Lincoln Stanton", status: false },
      { filter: "Adison Gouse", status: false },
      { filter: "James Culhane", status: true },
    ],
  },
];

//Flag to handle Focus/Blur
let flag = 0;

export const ProductPromotionForm = () => {
  //Use tailwind
  useDeviceContext(tw);

  //Initialize filters
  const [filters, setFilters] = useState<IFilter[]>(filtersArr);

  //SelectedItems
  const [selectedItems, setSelectedItems] = useState<string>("");

  //AccessFocus
  const [accessFocus, setAccessFocus] = useState<boolean>(false);

  //Form Inputs
  const promoForm = {
    code: useFieldControl<string>("", [required]),
    discount: useFieldControl<string>("", [number, required]),
    access: useFieldControl<string>("", [required]),
    maxUser: useFieldControl<string>("", [number, required]),
    products: useFieldControl<string>("", [required]),
    description: useFieldControl<string>("", [required]),
    expDate: useFieldControl<string>("", [expireDate, required]),
  };

  //Form Inputs
  const search = useFieldControl<string>("", []);

  //Handle the status of checkboxes
  const handleCheckStatus = (index: number, childIndex?: number) => {
    const newFilters = [...filters];

    flag = 1;

    newFilters.map((filter, filIndex) => {
      if (filIndex == index) {
        if (childIndex != undefined) {
          filter.children?.map((child, index) => {
            if (index == childIndex) child.status = !child.status;
          });
        } else {
          filter.status = !filter.status;
          if (filter.children != undefined) {
            filter.children.map((child) => (child.status = filter.status));
          }
        }
      }
    });

    setAccesValue(newFilters);

    setFilters(newFilters);
  };

  //Filter by search
  const reduceFilters = () => {
    let newFilters = [...filters];

    newFilters = newFilters.filter((filter) => {
      if (filter.children != undefined) {
        filter.children = filter.children.filter((child) => {
          if (child.filter.toLowerCase().includes(search.value) == true)
            return child;
        });
      }
      return filter;
    });

    setFilters(newFilters);
  };

  //Get all checked elements
  const setAccesValue = (newFilters: IFilter[]) => {
    const allSelected: string[] = [];

    newFilters.filter((selected) => {
      if (selected.status == true) allSelected.push(selected.filter);
    });

    setSelectedItems(allSelected.join(", "));
  };

  //Watch search changes
  useEffect(() => {
    if (search.value.length == 0) {
      const oldValues = JSON.parse(JSON.stringify(filtersArr));
      setFilters(oldValues);
    } else {
      reduceFilters();
    }
  }, [search.value]);

  //Set Acces value once component mount
  useEffect(() => {
    setAccesValue(filtersArr);
  }, []);

  //Handle Access Field onFocus
  const handleAccessFocus = () => {
    flag = 0;
    setAccessFocus(true);
  };

  //If press remove focus
  const handlePressContent = () => {
    flag++;
    if (flag == 2) {
      if (accessFocus == true) {
        setAccessFocus(false);
      }
      flag = 0;
    }
  };

  //Allow focus on SerachBar
  const handleSearchFocus = () => {
    flag = 0;
  };

  //Toggle Focus
  const toggleFocus = () => setAccessFocus(!accessFocus);

  return (
    <Pressable style={tw`flex flex-row mt-5`} onPress={handlePressContent}>
      {/* First Column */}
      <View style={tw`flex-1 px-2`}>
        <TextInput
          label="Promocode"
          value={promoForm.code.value}
          errorMessage={promoForm.code.error}
          autoCapitalize="words"
          placeholder="PROMOCODE10"
          onChangeText={promoForm.code.handleInputValue}
        />
        <TextInput
          label="Discount"
          value={promoForm.discount.value}
          errorMessage={promoForm.discount.error}
          placeholder="10%"
          type="number"
          onChangeText={promoForm.discount.handleInputValue}
        />
        <TextInput
          style={tw.style(
            "relative",
            accessFocus ? "border-b-0" : "",
            accessFocus ? "rounded-b-0" : "",
            accessFocus ? "border-blue-600" : "border-gray-300"
          )}
          label="Access"
          value={selectedItems}
          errorMessage={promoForm.access.error}
          autoCapitalize="words"
          autoCorrect={false}
          placeholder="PRO Monthly, Amet lectus"
          onFocus={handleAccessFocus}
          rightIcon={
            <Icon
              name={accessFocus ? "chevron-up" : "chevron-down"}
              // @ts-ignore
              type="font-awesome"
              color="#9CA3AF"
              onPress={toggleFocus}
            />
          }
        />
        {/* Container */}
        {accessFocus && (
          <View
            style={tw.style(
              "absolute z-100 bottom-[-5] bg-white rounded right-2 left-2 px-5 border rounded-3 border-t-0 mx-2 rounded-t-0",
              accessFocus ? "border-blue-600" : "border-gray-300"
            )}
          >
            <SearchBar
              onFocus={handleSearchFocus}
              value={search}
              textStyles={{
                elevation: 0,
                ...tw`border-0 border-b-gray-300 py-0 my-0 rounded-0`,
              }}
            />
            <ScrollView
              style={tw`h-35 ml-5`}
              showsVerticalScrollIndicator={false}
            >
              {filters.map((item, iteration) => (
                <View key={iteration}>
                  <CheckBox
                    title={
                      <Text
                        weight="semiBold"
                        color={colors.dark}
                        style={tw`ml-2`}
                      >
                        {item.filter}
                      </Text>
                    }
                    containerStyle={tw`bg-transparent border-0 p-0 m-0 mb-5`}
                    checked={item.status}
                    checkedIcon="square"
                    uncheckedColor={colors.grayLightest}
                    uncheckedIcon="square"
                    checkedColor={colors.blue}
                    onPress={() => handleCheckStatus(iteration)}
                  />
                  {item.children && (
                    <View style={tw`ml-8`}>
                      {item.children.map((child, childIndex) => (
                        <CheckBox
                          key={childIndex}
                          title={
                            <Text
                              weight="semiBold"
                              color={colors.dark}
                              style={tw`ml-2`}
                            >
                              {child.filter}
                            </Text>
                          }
                          containerStyle={tw`bg-transparent border-0 p-0 m-0 mb-5`}
                          checked={child.status}
                          checkedIcon="square"
                          uncheckedColor={colors.grayLightest}
                          uncheckedIcon="square"
                          checkedColor={colors.blue}
                          onPress={() =>
                            handleCheckStatus(iteration, childIndex)
                          }
                        />
                      ))}
                    </View>
                  )}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        <TextInput
          label="Products on sale"
          value={promoForm.products.value}
          errorMessage={promoForm.products.error}
          autoCapitalize="words"
          placeholder="PRO Monthly, Amet lectus"
          onChangeText={promoForm.products.handleInputValue}
        />
        <TextInput
          label="Maximum number of uses"
          value={promoForm.maxUser.value}
          errorMessage={promoForm.maxUser.error}
          placeholder="100"
          type="number"
          onChangeText={promoForm.maxUser.handleInputValue}
        />
      </View>
      {/* Second Column */}
      <View style={tw`flex-1 px-2`}>
        <TextInput
          style={tw`h-[96px]`}
          multiline={true}
          numberOfLines={4}
          label="Description"
          value={promoForm.description.value}
          errorMessage={promoForm.description.error}
          autoCapitalize="words"
          placeholder="Pay once a month and enjoy all benefits of Ucaas."
          onChangeText={promoForm.description.handleInputValue}
          maxLength={28}
        />
        <Text color={colors.gray} style={tw`ml-2 mt-2`}>
          Background Photo
        </Text>
        <View style={tw`border border-gray-300 rounded-3 mx-2 mt-1 mb-6`}>
          <DropFileInput
            fullScreen
            containerStyle={tw`border-0 py-14`}
            children={
              <View style={tw`flex items-center`}>
                <Text color={colors.dark} weight="semiBold">
                  Drop your file{" "}
                </Text>
                <Text>or</Text>
                <Text color={colors.blue} weight="semiBold">
                  Upload
                </Text>
              </View>
            }
          />
        </View>
        <MaskInputs
          label="Expiration date"
          placeholder="22.09.2021"
          errorMessage={promoForm.expDate.error}
          value={promoForm.expDate.value}
          onChangeText={promoForm.expDate.handleInputValue}
          mask={expireDateLongMask}
        />
      </View>
    </Pressable>
  );
};
