import React from "react";
import { StyleProp, ViewStyle } from "react-native";

import Svg, { Circle, Path } from "react-native-svg";

export const Plus = (props: {
  color?: string;
  style?: StyleProp<ViewStyle>;
}) => {
  const { color = "white", style } = props;

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <Path
        d="M12.0864 2.65405C11.7274 2.65405 11.4364 2.94507 11.4364 3.30405V11.5464H3.39401C3.03503 11.5464 2.74401 11.8375 2.74401 12.1964C2.74401 12.5554 3.03503 12.8464 3.39401 12.8464H11.4364V20.8888C11.4364 21.2478 11.7274 21.5388 12.0864 21.5388C12.4454 21.5388 12.7364 21.2478 12.7364 20.8888V12.8464H20.9788C21.3378 12.8464 21.6288 12.5554 21.6288 12.1964C21.6288 11.8375 21.3378 11.5464 20.9788 11.5464H12.7364V3.30405C12.7364 2.94507 12.4454 2.65405 12.0864 2.65405Z"
        fill={color}
      />
    </Svg>
  );
};
