import { StyleSheet, View } from "react-native";
import React from "react";
import { SideRegistration } from "@/components/Layouts/SideRegistration";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { tw } from "@/lib";
import { Text } from "@/components/Commons";

export const AccountActivation = () => {
  return (
    <View style={tw`flex-row flex-grow-1`}>
      <SideRegistration step={6} />
      <AuthLayout noBack>
        <View
          style={[
            tw`flex-grow flex-col items-center justify-around   w-full max-w-[400px]  `,
          ]}
        >
          <Text type="h2">Account Activation Request Submitted</Text>
          <Text style={tw`flex text-center`}>Thank you for signing up! </Text>
          <Text style={tw`flex text-center mb-64 text-sm`}>
            Your account activation request has been submitted to have one of
            our Service Delivery agents to review. They will contact you within
            2 business days to confirm your provided information prior to
            activating your account.
          </Text>
        </View>
      </AuthLayout>
    </View>
  );
};

//export default Aactivation

const styles = StyleSheet.create({});
