import React, { LegacyRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { tw, sortByHeader } from "@/lib";
import { Text, InfinityScroll, LineSeparator } from "@/components/Commons";
import { Close } from "@/components/Icons";
import { WorkspaceService } from "@/services";
import { WorkspaceInterface } from "@/types";
import { INotificationsProps } from "@/types/notification";
import MessageStatusBar from "./MessageStatusBar";
import NoNotifications from "./NoNotifications";
import NoticationMessage from "./NotificationMessage";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { setNotifications  as setNotificationsReduce, updateNotification} from "@/store/notifications.store";
import { RootState, store } from "@/store";
import { useOnClickOutside } from "@/hooks/useClickOutside";


export type MessageStatus = 'All' | 'Read' | 'Unread';

const LIMIT = 10;
const STEP = 10;
interface RefObject<T> {
  readonly current: T | null | undefined;
}




const Notifications = ({ state, onhovered, id, onState }: INotificationsProps) => {
  const notificationsStore = useAppSelector((state: RootState) => state.notifications);
  const dispatch = useAppDispatch();
  const [selectedStatus, setSelectedStatus] = useState<MessageStatus>('All');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedNotifications, setSelectedNotifications] = useState<WorkspaceInterface[]>([]);
  const [maxNumberOfNotifications, setMaxNumber] = useState<number>(0);
 let menuRef=useRef<RefObject<HTMLWebViewElement>>(null)
  

  
  const loadData = async () => {
    const workspaceId = (await WorkspaceService.getCurrent()).data.result.id;
    const data = (
      await WorkspaceService.getAccessRequest(
        !id || id === 0 ? workspaceId : id,
        true,
        2
      )
    ).data.result;
    const sortedData = sortByHeader<WorkspaceInterface>(data, 'updatedAt', true);
    dispatch(setNotificationsReduce(sortedData));
    onhovered && onhovered(data.length);
  };
  
  const getNotifications = useCallback(() => {
    const temp1 = notificationsStore.filter(item => {
      if (selectedStatus === "Read" && item.status)
        return item.status !== 'requested';
      else if (selectedStatus === "Unread" && item.status)
        return item.status === 'requested';
      else return true;
    });
    setMaxNumber((temp1.length/LIMIT) + 1); 
    const temp2 = temp1.slice(0, (LIMIT*currentPage+STEP))
    setSelectedNotifications(sortByHeader<WorkspaceInterface>(temp2, 'updatedAt', true));
    const unseenNots = notificationsStore.filter(item => item.status === 'requested');
    onhovered && onhovered(unseenNots.length);
  },[notificationsStore, currentPage, selectedStatus]);

  const getMoreNots = useCallback(()=>{
    if(currentPage < maxNumberOfNotifications) setCurrentPage(currentPage+1)
  },[currentPage, maxNumberOfNotifications]);
  useOnClickOutside(menuRef, () => onState && onState(0));

 

  useEffect(() => {
    loadData();
  }, []);


  

  useEffect(() => {
    if(notificationsStore.length>0) getNotifications();
  }, [notificationsStore, selectedStatus, currentPage]);
 

  
  

  
  if(state === 0) return <></>;
  
  return (
    <View
      style={[
        tw`flex-grow-1 
        absolute  
        lg:max-w-[422px]
        inset-x-0
        flex-cols
        ${state != 0 ? 'lg:shadow-xl':''}
        sm:flex
        sm:items-start 
        sm:max-w-[352px]
        ml-[90px]  `,
        { zIndex: 150 },
      ]}
    >
      <View
      //@ts-ignore
        ref={menuRef}
        style={[tw`flex w-full h-[100vh] bg-white items-start rounded-3 shadow-lg`,]}
      >
        <View style={[tw`flex flex-row w-full pl-[25px] pt-[25px]`,]}>  
          <Text type="h2" weight="openBold" style={[tw`w-5/6`,]}> Notifications</Text>
          <TouchableOpacity
            style={[tw`justify-between w-1/6`,]}
            onPress={() => {
              onState && onState(0);
            }}
          >
            <Close />
          </TouchableOpacity>
        </View>

        <MessageStatusBar status={selectedStatus} setStatus={setSelectedStatus}/>

        { selectedNotifications && selectedNotifications.length === 0 && <NoNotifications/> }
        { selectedNotifications && selectedNotifications.length > 0 && 
          <InfinityScroll
            style={tw`w-full`} 
            Component={NoticationMessage} 
            data={selectedNotifications} 
            getMoreData={()=>getMoreNots()}
            SeparatorComponent={LineSeparator}
          />  
        }
      </View>
    </View>
  );
};

export default Notifications;

