import { AxiosResponse } from "axios";
import { ApiService } from "./";
import { WorkSpace } from '../types/client.types';
import {
  ApiListResponse,
  ApiObjectResponse,
  ClientInfo,
  ClientInput,
} from "@/types";

const endpoint = "workspaces";

class ClientService {
  /**
   * List all users on the platform.
   *
   * @returns {AxiosResponse<ApiListResponse<ClientInfo>>}
   */
  async getAll(): Promise<AxiosResponse<ApiListResponse<ClientInfo>>> {
    return ApiService.api.get("/v1/clients");
  }

  /**
   * Create client on the platform
   *
   * @param {ClientInput} input client to be added to the platform
   * @returns {AxiosResponse<ApiObjectResponse<any>>}
   */
  async create(input: WorkSpace): Promise<AxiosResponse<ApiObjectResponse<any>>> {

    return ApiService.api.post(endpoint, input);
  }

  /**
   * Crate first admin for client
   *
   * @param {string} clientId Client id to add first admin
   * @param {object} input First admin information
   * @returns
   */
  async addAdmin(clientId: string, input: object) {
    return ApiService.api.post(
      `/v1/clients/${clientId}/first-extension`,
      input
    );
  }
}

const singleton = new ClientService();
export default singleton;
