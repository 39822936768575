import React from "react";
import { View } from "react-native";
import { useDeviceContext } from "twrnc";

import { Text } from "@/components/Commons"
import { colors, tw } from "@/lib";

export const Question = () => {

  //Use tailwind
  useDeviceContext(tw);


  return (
    <View style={tw`border border-gray-200 rounded-full w-3 h-3 p-[9px] items-center justify-center`}>
      <Text weight="bold" color={colors.gray}>?</Text>
    </View>
  );
};
