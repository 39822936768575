import {
  FlatList,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useState } from "react";
import { colors, Modal, required, tw, useFieldControl } from "@/lib";
import { Text, Button } from "@/components/Commons";
import Icon from "react-native-vector-icons/MaterialIcons";

import { Dropdown } from "react-bootstrap";
import { Card } from "react-native-elements/dist/card/Card";
import { ManageSubscription } from "@/components/ClientPartners/ManageSubscription";
import { Trash2 } from "@/components/Icons/Trash2";
import { CustomDropdown as DropDown } from "@/components/Inputs/Dropdown";
import { AdminCustomerList } from "@/components/AdminCustomer";

export const CustomerList = () => {
  const partnerInput = {
    partner: useFieldControl<string>("", [required]),
  };
  const [data, setData] = useState({
    value: [
      {
        id: 1,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        subscription: "Monthly",
        status: true,
      },
      {
        id: 2,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        subscription: "Monthly",
        status: true,
      },
      {
        id: 3,
        email: "astanton@mail.com",
        name: "Ashlynn Stanton",
        subscription: "Monthly",
        status: true,
      },
    ],
  });
  const [dataSubscriptionLeft, setDataSubscriptionLeft] = useState({
    value: [
      {
        id: "1",
        name: "Monthly",
        result: "$24",
        plan: "/month",
        description: "Pay once a month and enjoy all benefits of Ucaas.",
      },
      {
        id: "2",
        name: "Discount",
        result: "10%",
        plan: "",
        description: "",
      },
    ],
  });
  const [dataOns, setDataons] = useState({
    value: [
      {
        id: "1",
        name: "Facilisi eget",
        operation: "$8 x 3",
        result: "24",
      },
      {
        id: "2",
        name: "Enim cursus",
        operation: "$8 x 3",
        result: "$24",
      },
      {
        id: "3",
        name: "Massa diam",
        operation: "",
        result: "$24",
      },
    ],
  });

  //@ts-ignore
  const renderItemGraySubscription = ({ item }) => {
    return (
      <Card
        containerStyle={tw`flex flex-col bg-gray-100 rounded-3   w-full max-h-auto border-0 lg:max-w-[350px]
         border border-[${colors.blue}] border-1  shadow-md  `}
      >
        <View style={tw`flex flex-row w-full max-h-[50px] `}>
          <View style={tw`flex flex-row w-full max-w-1/2 my-2`}>
            <Text weight="bold" style={tw`pl-4`}>
              {!item.plan ? item.result + " " + item.name : item.name}
            </Text>
          </View>
          <View
            style={tw`flex flex-row w-full max-w-1/2 my-2 items-start justify-end   `}
          >
            <View
              style={[tw`flex pl-4 flex-row items-center justify-end my-1`]}
            >
              <TouchableOpacity>
                <Trash2 color="#E3153A" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {item.plan && (
          <View>
            <View style={tw`flex-1 flex-row pl-4 items-center  `}>
              <Text type="regular" weight="bold">
                {item.result}
              </Text>
              <Text type="regular" color={colors.dark} weight="semiBold">
                {" "}
                {item.plan}
              </Text>
            </View>
            <View style={tw`pl-4`}>
              <Text color={colors.dark} weight="semiBold">
                {item.description}
              </Text>
            </View>
          </View>
        )}
      </Card>
    );
  };
  //@ts-ignore
  const renderItemsAddsOns = ({ item }) => {
    return (
      <Card
        containerStyle={tw`flex flex-col bg-white rounded-3   w-full max-h-auto border-0 lg:max-w-[340px]
        border border-[${colors.blue}] border-1  shadow-md  `}
      >
        <View style={tw`flex flex-row w-full max-h-[50px] `}>
          <View style={tw`flex flex-row w-full max-w-1/2 my-2`}>
            <Text weight="bold" style={tw`pl-4`}>
              {item.name} {""}
              {!item.operation && item.result}
            </Text>
          </View>
          <View
            style={tw`flex flex-row w-full max-w-1/2 my-2 items-start justify-end  `}
          >
            {item.operation && (
              <View style={[tw`flex flex-row w-full lg:left-8       `]}>
                <ManageSubscription color={"grey"} inputElement={1} />
              </View>
            )}
            <View
              style={[tw`flex ml-12 flex-row items-center justify-end my-1 `]}
            >
              <TouchableOpacity>
                <Trash2 color="#E3153A" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {item.operation && (
          <View>
            <View
              style={tw`border border-blue-200 border-t-1 border-l-0 border-r-0 border-b-0`}
            />
            <View style={tw`flex-1 flex-row w-full justify-between `}>
              <Text type="bigger" style={tw`pl-4`} weight="bold">
                {item.operation}
              </Text>
              <View style={tw`flex flex-row items-end justify-end`}>
                <Text type="bigger" weight="bold">
                  {item.result}
                </Text>
              </View>
            </View>
          </View>
        )}
      </Card>
    );
  };

  const manageSubscription = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end`}>
          <Text style={tw`font-bold text-2xl`}>Manage Subscription</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex flex-col items-start justify-start`}>
          <Text style={tw`flex text-gray-400 `}>For astanton@mail.com</Text>
        </View>
        <View style={tw`flex w-full flex-col my-2 `}>
          <View style={tw`flex flex-row w-full   justify-between`}>
            <View style={tw`flex w-full  max-h-[450px]`}>
              <FlatList
                data={dataSubscriptionLeft.value}
                renderItem={renderItemGraySubscription}
                keyExtractor={(items) => items.id}
              />
            </View>
          </View>
        </View>
        <View style={tw`flex flex-row items-end justify-end my-8 top-8 `}>
          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/4`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/4`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Save
          </Button>
        </View>
      </View>,
      { width: 450 }
    );
  };

  const manageAddsOns = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end`}>
          <Text style={tw`font-bold text-2xl`}>Manage Add-ons</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex flex-col items-start justify-start`}>
          <Text style={tw`flex text-gray-400 `}>For astanton@mail.com</Text>
        </View>
        <View style={tw`flex w-full flex-col my-2 `}>
          <View style={tw`flex flex-row w-full   justify-between`}>
            <View style={tw`flex w-full  max-h-[450px]`}>
              <FlatList
                data={dataOns.value}
                renderItem={renderItemsAddsOns}
                keyExtractor={(items) => items.id}
              />
            </View>
          </View>
        </View>
        <View style={tw`flex flex-row items-end justify-end my-8 top-8 `}>
          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/4`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/4`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Save
          </Button>
        </View>
      </View>,
      { width: 450 }
    );
  };

  const changePartners = () => {
    Modal.show(
      <View>
        <View style={tw`flex-row justify-between items-end`}>
          <Text style={tw`font-bold text-2xl`}>Change partner</Text>
          <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
        </View>
        <View style={tw`flex flex-col items-start justify-start`}>
          <Text style={tw`flex text-gray-400 `}>For astanton@mail.com</Text>
        </View>
        <View style={tw`flex w-full flex-col my-8 `}>
          <View style={tw`flex flex-row w-full   justify-between `}>
            <DropDown
              placeholder="partner"
              label={"Select partner"}
              value={partnerInput.partner.value}
              onChangeText={partnerInput.partner.handleInputValue}
            />
          </View>
        </View>
        <View style={tw`flex flex-row items-end justify-end my-8 top-8 `}>
          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/4`}
            type="outlined"
            onPressOut={Modal.dismiss}
          >
            Cancel
          </Button>

          <Button
            style={tw`sm:pb-6 pb-2 max-w-1/4`}
            onPressOut={() => {
              Modal.dismiss();
            }}
          >
            Save
          </Button>
        </View>
      </View>
    );
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7 `}>
      <View style={tw`lg:flex-row justify-between`}>
        <View>
          <Text type="h2">Customers</Text>
        </View>
        <View style={tw`max-w-[172px] w-full`}>
          <Button type="outlined">Add Customer</Button>
        </View>
      </View>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator
        contentContainerStyle={tw`w-full h-full`}
      >
        <AdminCustomerList
          data={data.value}
          dropdown={
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{ lineHeight: 0, ...tw`no-underline` }}
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <Icon color="#C8C8C8" name="more-vert" size={20} />
              </Dropdown.Toggle>

              <Dropdown.Menu style={tw`rounded-3`}>
                <Dropdown.Item
                  as="button"
                  style={tw`  text-btn-menu-item font-semibold`}
                  onClick={manageSubscription}
                >
                  Manage Subscription
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                  onClick={manageAddsOns}
                >
                  Manage Adds-ons
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                >
                  Log In as a Cient
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={tw` text-btn-menu-item font-semibold`}
                  onClick={changePartners}
                >
                  Change Partner
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  style={tw`text-red-500 text-btn-menu-item font-semibold`}
                >
                  Cancel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  dot: {
    fontSize: 40,
  },
});
