import { AuthService, TokenService } from "@/services";
import { RootState } from "@/store";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { clearUser, setUser } from "@/store/user.store";
import { User } from "@/types";
import { useNavigate } from "react-router-dom";
import useSocket from "./useSocket";
import { unmountZendesk } from "@/lib";

export const useUserLogged = () => {
  const userLogged = useAppSelector((state: RootState) => state.user);
  let isLogged: boolean = false;

  let navigateTo: string = "login";
  const navigate = useNavigate();

  const { disconnectSocket } = useSocket();

  const dispatch = useAppDispatch();

  const user: User | undefined = TokenService.getUser()?.user;

  const setRoute = (role: string | null) => {
    switch (role) {
      case "admin":
        return (navigateTo = "/company");
      case "super_admin":
        return (navigateTo = "/super-admin/workspaces");
      case "support":
        return (navigateTo = "/super-admin/workspaces");
      case "operations":
        return (navigateTo = "/super-admin/workspaces");
    }
  };

  if (user) {
    isLogged = true;
    setRoute(user.rol);
  }

  const logoutUser = async () => {
    try {
      await AuthService.Logout();
      unmountZendesk();
      disconnectSocket();
      location.reload();
    } catch (error) {}
  };

  const checkUser = async () => {
    try {
      const res = await AuthService.checkAuth();
      const resUser = res?.data?.result;
      if (userLogged === null || resUser.id !== userLogged?.id) {
        dispatch(setUser(res?.data?.result));
      }
    } catch (error) {
      TokenService.removeUser();
      navigate("/login");
    }
  };

  const clearUser = () => {
    TokenService.removeUser();
  };

  return {
    isLogged,
    navigateTo,
    userRole: user?.rol,
    logoutUser,
    checkUser,
    userLogged,
    clearUser,
  };
};
