import { StyleSheet, View } from 'react-native'
import React from 'react'
import { colors, expireDate, expireDateLongMask, number, required, tw, useFieldControl } from '@/lib'
import { DropFileInput, TextInput } from '@/components/Inputs'
import MaskInputs from '@/components/Inputs/MaskInput'
import { Text } from '@/components/Commons'

const FirstTab = () => {
  const codesForm = {
    name: useFieldControl<string>("", [required]),
    discount: useFieldControl<string>("", [number, required]),
    products: useFieldControl<string>("", [required]),
    expDate: useFieldControl<string>("", [required]),
    description: useFieldControl<string>("", [expireDate, required]),
  };
  return (
    <View style={tw`w-[680px]`}>
      <View style={tw`flex flex-row`}>
        {/* Left form column */}
        <View style={tw`flex-1 px-2`}>
          <TextInput
            label="Name"
            value={codesForm.name.value}
            errorMessage={codesForm.name.error}
            autoCapitalize="words"
            placeholder="Monthly"
            onChangeText={codesForm.name.handleInputValue}
          />
          <TextInput
            label="Discount"
            value={codesForm.discount.value}
            errorMessage={codesForm.discount.error}
            placeholder="10%"
            type='number'
            onChangeText={codesForm.discount.handleInputValue}
          />
          <TextInput
            label="Products on sale"
            value={codesForm.products.value}
            errorMessage={codesForm.products.error}
            autoCapitalize="words"
            placeholder="PRO Monthly, Amet lectus"
            onChangeText={codesForm.products.handleInputValue}
          />
          <MaskInputs
            label="Expiration date"
            placeholder="22.09.2021"
            errorMessage={codesForm.expDate.error}
            value={codesForm.expDate.value}
            onChangeText={codesForm.expDate.handleInputValue}
            mask={expireDateLongMask}
          />
        </View>
        {/* Right form column */}
        <View style={tw`flex-1 px-2`}>
          <TextInput
            style={tw`h-[96px]`}
            multiline={true}
            numberOfLines={4}
            label="Description"
            value={codesForm.description.value}
            errorMessage={codesForm.description.error}
            autoCapitalize="words"
            placeholder="Pay once a month and enjoy all benefits of Ucaas."
            onChangeText={codesForm.description.handleInputValue}
            maxLength={28}
          />
          <Text color={colors.gray} style={tw`ml-2 mt-2`}>
            Background Photo
          </Text>
          <View style={tw`border border-gray-300 rounded-3 mx-2 mt-1`}>
            <DropFileInput
              fullScreen
              containerStyle={tw`border-0 py-14`}
              children={
                <View style={tw`flex items-center`}>
                  <Text color={colors.dark} weight="semiBold">
                    Drop your file{" "}
                  </Text>
                  <Text>or</Text>
                  <Text color={colors.blue} weight="semiBold">
                    Upload
                  </Text>
                </View>
              }
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default FirstTab

const styles = StyleSheet.create({})