import { AuthResponse, Token } from "@/types";

class TokenService {
  getLocalRefreshToken(): string | undefined {
    //@ts-ignore
    const user: AuthResponse = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken?.token;
  }

  getFullRefreshToken(): Token | undefined {
    //@ts-ignore
    const user: AuthResponse = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken(): string | undefined {
    //@ts-ignore
    const user: AuthResponse = JSON.parse(localStorage.getItem("user"));
    return user?.accessToken?.token;
  }

  updateLocalAccessToken(token: Token): void {
    //@ts-ignore
    let user: AuthResponse = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  updateLocalRefreshToken(token: Token): void {
    //@ts-ignore
    let user: AuthResponse = JSON.parse(localStorage.getItem("user"));
    user.refreshToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser(): AuthResponse | null {
    //@ts-ignore
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user: AuthResponse): void {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser(): void {
    localStorage.removeItem("user");
  }
}

const singleton = new TokenService();
export default singleton;
