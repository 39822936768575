export interface UserResponse {
  id: number;
  userId: number;
  workspaceRole: string;
  rol?: string;
  workspaceId: number;
  avatar: {
    m: "string";
    s: "string";
    l: "string";
    xl: "string";
    xs: "string";
  } | null;
  email: string;
  firstName: string;
  lastName: string;
  extension: number;
  ownPhoneNumber: string | null;
  updatedAt: number;
  directNumber: string | null;
}

export interface UserDetails {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  directNumber: string;
  createdAt: Date;
  avatar: null;
  companyPhoneNumber: string;
  status: string;
}

export interface UserCompleteProfile {
  token: string;
  firstName: string;
  lastName: string;
  ownPhoneNumber: string;
  extension: number;
  password: string;
}

export enum UserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface UserSearchList {
  list: [];
  totalCount: number;
}
