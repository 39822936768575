import { ApiService, AxiosHeaders } from "@/services";
import { CreateSSOResponse } from "@/types/payment.types";
import { AxiosResponse } from "axios";

const endpoint = "payment";

class PaymentService {
  async getStoreUrl(): Promise<string> {
    const response: AxiosResponse<{ result: CreateSSOResponse }> =
      await ApiService.api.get(`${endpoint}/store`, {
        headers: AxiosHeaders.headersWithCredentials(),
      });
    console.log(response);
    return response.data?.result?.redirectUrl;
  }

  async syncPurchases(workspaceId: number): Promise<Boolean> {
    const response: AxiosResponse<{ result: Boolean }> =
      await ApiService.api.get(`${endpoint}/sync`, {
        params: { workspaceId },
        headers: AxiosHeaders.headersWithCredentials(),
      });
    console.log(response);
    return response.data.result;
  }
}

const singleton = new PaymentService();
export default singleton;
