import React from "react";
import {
  TouchableOpacity,
  View,
  ViewProps,
  TouchableOpacityProps,
  ActivityIndicator,
} from "react-native";

import { useDeviceContext } from "twrnc";

import tw from "@/lib/tailwind";
import { CustomText as Text } from "./Text";
import { colors } from "@/lib";

export const CustomButton = (
  props: ViewProps &
    TouchableOpacityProps & {
      type?: "outlined" | "button";
      disabled?: boolean;
      loading?: boolean;
      color?: string;
    }
) => {
  useDeviceContext(tw);

  const { color = colors.blue } = props;

  let btnStyle;

  let textStyle;

  switch (props.type) {
    case "outlined": 
      if (props.disabled) {
        btnStyle = tw`border bg-gray-lightest bg-white`;
        textStyle = tw`text-btn-disabled text-base`;
      } else {
        btnStyle = tw`border border-[${color}] border-1`;
        textStyle = tw`text-[${color}] text-base`;
      }
      break;
    default:
      if (props.disabled) {
        btnStyle = tw`bg-gray-lightest`;
      } else {
        btnStyle = tw`bg-btn`;
      }
      textStyle = tw`text-white text-base`;
      break;
  }

  return (
    <View style={[tw`px-2 w-full`, props.style]}>
      <TouchableOpacity
        {...{ ...props, style: null }}
        style={[
          btnStyle,
          tw`rounded-3 h-input flex flex-col justify-center items-center`,
        ]}
      >
        <Text style={textStyle}>
          {props.loading && props.loading ? (
            <View style={tw`flex flex-row justify-center`}>
              <ActivityIndicator color={colors.white} style={tw`mr-2`} />
              {props.children}
            </View>
          ) : (
            props.children
          )}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
