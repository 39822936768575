import React from "react";
import { View, Dimensions } from "react-native";
import { useDeviceContext } from "twrnc";
import { LineChart } from "react-native-chart-kit";

import { Text } from "@/components/Commons";
import { colors, tw } from "@/lib";

interface Props {
  title: string;
}

export const SystemGraphics = ({ title }: Props) => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View>
      {/* Charts Section */}
      <View style={tw`mt-5 border border-[${colors.statusBorder}] p-5`}>
        <View style={tw`flex-row justify-between items-center`}>
          <Text type="h3" color={colors.dark}>
            {title}
          </Text>
          <Text type="h3" weight="semiBold" color={colors.grayLight}>
            100%
          </Text>
        </View>
        {/* The Chart */}
        <LineChart
          withDots={false}
          withShadow={false}
          withVerticalLines={false}
          data={{
            labels: [
              "21:00",
              "16.jul",
              "03:00",
              "06:00",
              "09:00",
              "12:00",
              "15:00",
              "18:00",
            ],
            datasets: [
              {
                data: [
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                  Math.random() * 100,
                ],
                color: () => colors.blueLightLightest,
                strokeWidth: 2,
              },
            ],
          }}
          width={800} // from react-native
          height={220}
          // yAxisLabel="$"
          // yAxisSuffix="k"
          yAxisInterval={1} // optional, defaults to 1
          chartConfig={{
            backgroundColor: colors.rebrandLayoutBGColor,
            backgroundGradientFrom: colors.rebrandLayoutBGColor,
            backgroundGradientTo: colors.rebrandLayoutBGColor,
            decimalPlaces: 2, // optional, defaults to 2dp
            color: (opacity = 1) => colors.grayLight,
            labelColor: (opacity = 1) => colors.gray,
          }}
          style={{
            marginLeft: -20,
            marginTop: 15,
          }}
        />
      </View>
    </View>
  );
};
