import { StyleSheet, View } from "react-native";
import React, { useEffect, useState, memo } from "react";
import { Avatar } from "react-native-elements";
import { Image } from "../Icons";
import { colors, convertToBase64, tw } from "@/lib";
import { MediaService } from "@/services";
import { AvatarsLogo } from "../Icons/AvatarsLogo";

interface Props {
  uri: string | undefined;
  width?: string;
  size: number;
}
export const Avatars = ({ uri, width, size }: Props) => {
  const [userImage, setUserImage] = useState<string | undefined>(undefined);

  const loadAvatar = async () => {
    try {
      if (uri !== undefined) {
        const res = await MediaService.getMediaFromType("image", uri);
        let base64ImageString: string = convertToBase64(res);
        setUserImage(`data:image/png;base64,${base64ImageString}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadAvatar();
  }, []);

  return (
    <View style={tw`w-${width || `1/12`}`}>
      <Avatar
        size={size}
        rounded
        source={{ uri: userImage }}
        renderPlaceholderContent={
          <View
            style={tw`bg-gray-400 w-full h-full items-center justify-center`}
          >
            <AvatarsLogo color={colors.blueLightest} />
          </View>
        }
      />
    </View>
  );
};

//export default Avatar

const styles = StyleSheet.create({});
