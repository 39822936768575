import React from "react";
import { View } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import { Text } from "@/components/Commons";
import { AccessResponse, Status } from "@/types";
import { capitalize, colors, tw } from "@/lib";

interface IRequestAccessItemProps {
  user: AccessResponse;
  grantOrDenyAccess: (user: AccessResponse) => Promise<void>;
  grantPermition: (user: AccessResponse, userFrom?: string) => void;
  approvePermition?: (user: AccessResponse) => void;
  revokeAccess: (user: AccessResponse, status: Status) => void;
  rejectAccess: (user: AccessResponse, status: Status) => void;
}

const RequestAccessItem: React.FunctionComponent<IRequestAccessItemProps> = ({
  user,
  grantOrDenyAccess,
  grantPermition,
  revokeAccess,
  rejectAccess,
  approvePermition,
}) => {
  const userStatus = user.status === "granted" || user.status === "approved" || user.status === "active";

  return (
    <View
      style={[
        tw`flex flex-row w-full  bg-white px-4 py-4 mb-4 rounded-3 items-center`,
        ,
        { zIndex: "auto" },
      ]}
    >
      <View style={tw`w-2/12 pr-1`}>
        <Text weight="tinyBold" color={colors.dark}>
          {user.name}
        </Text>
      </View>
      <View style={tw`w-1.8/12 px-1`}>
        <Text weight="tinyBold" color={colors.dark}>
          {user.accessType}
        </Text>
      </View>
      <View style={tw`w-2.2/12 px-1`}>
        <Text weight="tinyBold" color={colors.dark}>
          {user.createdAt
            ? dayjs(user.createdAt).format("MMM D, YYYY HH:mm")
            : "-"}
        </Text>
      </View>
      <View style={tw`w-2.2/12 px-1`}>
        <Text weight="tinyBold" color={colors.dark}>
          {user.from ? dayjs(user.from).format("MMM D, YYYY HH:mm") : "-"}
        </Text>
      </View>
      <View style={tw`w-2.2/12 px-1`}>
        <Text weight="tinyBold" color={colors.dark}>
          {user.to ? dayjs(user.to).format("MMM D, YYYY HH:mm") : "-"}
        </Text>
      </View>
      <View style={tw`w-1.3/12 px-1`}>
        <Text weight="tinyBold" color={colors.dark}>
          {capitalize(user.status!)}
        </Text>
      </View>

      <View style={[tw`w-auto ml-auto`, { zIndex: "auto" }]}>
        {user.status !== "rejected" && user.status !== "revoked" && (
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              style={{ lineHeight: 0, ...tw`no-underline` }}
              id="dropdown-basic"
              bsPrefix="btn"
            >
              <Icon name="ellipsis-v" size={12} color={colors.gray} />
            </Dropdown.Toggle>

            <Dropdown.Menu style={tw`rounded-3`}>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  userStatus
                    ? rejectAccess({ ...user }, "revoked")
                    : approvePermition && approvePermition(user);
                }}
                style={tw`text-btn-menu-item font-semibold`}
              >
                {userStatus ? "Revoke Access" : "Approve Access"}
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() =>
                  userStatus
                    ? grantPermition(user)
                    : revokeAccess({ ...user }, "rejected")
                }
                style={tw`text-btn-menu-item font-semibold`}
              >
                {userStatus ? "Change Access" : "Reject Access"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </View>
    </View>
  );
};

export default RequestAccessItem;
