import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeviceContext } from "twrnc";
import { StyleSheet, TouchableOpacity, View, Image, useColorScheme } from "react-native";
import { Icon } from "react-native-elements";

import { tw, useFieldControl, colors } from "@/lib";
import { Text } from "@/components/Commons";
import { SearchBar } from "@/components/Inputs";
import { Back } from "@/components/Icons";

export const DeviceDetails = () => {
  useDeviceContext(tw)
  const location = useLocation();

  const navigate = useNavigate();

  //Form Inputs
  const search = useFieldControl<string>("", []);

  const name = location.state;

  const [tab, changeTab] = useState<"my" | "all">("all");

  useDeviceContext(tw);
  const [data, setData] = useState({
    value: [
      {
        id: 1,
        no: 123,
        time: "153 4.425654",
        source: "192.168.9.198",
        destination: "192.168.0.120",
        protocol: "RTP",
        length: 214,
        info: ["Fames facilisi at velit ultrices amet", "pharetra non cu"],
      },
      {
        id: 2,
        no: 123,
        time: "153 4.425654",
        source: "192.168.9.198",
        destination: "192.168.0.120",
        protocol: "RTP",
        length: 214,
        info: ["Fames facilisi at velit ultrices amet", "pharetra non cu"],
      },
      ,
      {
        id: 3,
        no: 123,
        time: "153 4.425654",
        source: "192.168.9.198",
        destination: "192.168.0.120",
        protocol: "RTP",
        length: 214,
        info: ["Fames facilisi at velit ultrices amet", "pharetra non cu"],
      },
    ],
  });

  const getTabStyle = (pred: () => boolean) => {
    const isActive = pred();
    return isActive
      ? {
          btn: tw`bg-gray`,
          text: tw`text-primary`,
        }
      : {
          text: tw`text-dark-gray`,
        };
  };

  const tabs = () => {
    const myData = getTabStyle(() => tab == "all");
    const allData = getTabStyle(() => tab == "my");
    return (
      <View style={tw`flex-row  max-w-[304px] w-full z-auto`}>
        <TouchableOpacity
          style={[tw`flex w-35 items-center justify-center`, myData.btn]}
          onPress={() => {
            tab != "all" && changeTab("all");
          }}
        >
          <Text weight="semiBold" style={myData.text}>
            QOS Reports
          </Text>
          <View style={tw`flex mt-2`}>
            {tab == "all" && (
              <Image
                style={tw`w-[120px] h-0.5 shadow-lg rounded-sm`}
                source={require("@/assets/images/LOGO/rectangle.png")}
              />
            )}
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          style={[tw`flex w-35 items-center justify-center`, allData.btn]}
          onPress={() => {
            tab != "my" && changeTab("my");
          }}
        >
          <Text weight="semiBold" style={allData.text}>
            Registration Details
          </Text>
          <View style={tw`flex mt-2`}>
            {tab == "my" && (
              <Image
                style={tw`w-[120px] h-0.5 shadow-lg rounded-sm`}
                source={require("@/assets/images/LOGO/rectangle.png")}
              />
            )}
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  //Show Content
  const showContent = () => {
    switch (tab) {
      case "all":
        return (
          <View style={tw`flex-col mt-8  w-full flex-initial`}>
            <View style={tw`flex-row px-4 mb-4 `}>
              <View style={tw`flex w-1/12`}>
                <Text color={colors.gray}>No </Text>
              </View>
              <View style={tw`flex w-1/12 items-center justify-center`}>
                <Text color={colors.gray} style={tw`pl-4`}>Time </Text>
              </View>
              <View style={tw`flex w-2/12 items-center justify-center`}>
                <Text color={colors.gray} style={tw`pl-4`}>Source </Text>
              </View>

              <View style={tw`flex w-2/12 items-center  `}>
                <Text color={colors.gray}>Destination </Text>
              </View>
              <View style={tw`flex w-1/12 items-center  `}>
                <Text color={colors.gray}>Protocol </Text>
              </View>
              <View style={tw`flex w-1/12 items-center justify-center `}>
                <Text color={colors.gray}>Length </Text>
              </View>
              <View style={tw`flex w-1/12 items-center justify-center  `}>
                <Text color={colors.gray}>Info </Text>
              </View>
            </View>

            {data.value.map((response) => {
              return (
                <View
                  style={tw`flex-row bg-white px-4 py-3 rounded-3 items-between justify-between my-2 `}
                  key={response?.id}
                >
                  <View style={tw`flex w-1/12 items-start`}>
                    <Text color={colors.dark}>{response?.no}</Text>
                  </View>
                  <View style={tw`flex w-1/12 items-center justify-center `}>
                    <Text  color={colors.dark} style={tw`text-center `}>
                      {response?.time}
                    </Text>
                  </View>
                  <View style={tw`flex w-2/12 items-center justify-center  `}>
                    <Text  color={colors.dark} style={tw`pr-4  `}>
                      {response?.source}
                    </Text>
                  </View>
                  <View style={tw`flex w-1/12 items-center justify-center `}>
                    <Text  color={colors.dark} style={tw`pl-4 `}>
                      {response?.destination}
                    </Text>
                  </View>
                  <View style={tw`flex w-1/12 items-end justify-end `}>
                    <Text  color={colors.dark} style={tw`lg:pl-16 xl:pl-24    `}>
                      {response?.protocol}
                    </Text>
                  </View>
                  <View style={tw`flex w-full lg:max-w-1/12 items-end justify-end  `}>
                    <Text  color={colors.dark} style={tw`lg:pl-0   `}>
                      {response?.length}
                    </Text>
                  </View>

                  <View
                    style={tw`flex w-full lg:max-w-4/12  flex-row items-start justify-center lg:pr-12   `}
                  >
                    {response?.info.map((responseInfo, s: number) => {
                      return (
                        <Text style={tw`text-gray-800 text-sm `} key={s}>
                          {s === response?.info.slice(0, 2).length - 1
                            ? responseInfo + `...` + ` `
                            : responseInfo + `,` + ` `}
                        </Text>
                      );
                    })}
                  </View>

                  <View style={tw`flex relative items-end justify-end`}>
                    <TouchableOpacity>
                      <Icon
                        color="#C8C8C8"
                        name="more-vert"
                        size={20}
                        tvParallaxProperties={undefined}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              );
            })}
          </View>
        );
      case "my":
        return (
          <>
            <View style={tw`flex flex-row justify-center mt-16`}>
              <View style={tw`flex items-center`}>
                <Text weight="semiBold" color={colors.dark}>
                  page with device logs
                </Text>
                <Text weight="semiBold" color={colors.dark}>
                  example picture below
                </Text>
              </View>
            </View>
            <View style={tw`flex flex-row w-full justify-center items-center`}>
              <Image
                source={require("@/assets/images/InfoDiagnostic/InfoDiagnosticDetails.png")}
                style={tw`w-[800px] h-[567px]`}
              />
            </View>
          </>
        );
    }
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      {/* Header */}
      <View style={tw`flex-row justify-between items-center`}>
        <View>
          <Text type="h2" weight="bold">
            <TouchableOpacity
              style={tw`bg-white p-2 rounded-3 relative z-10`}
              onPress={() => navigate(-1)}
            >
              <Back color="black" />
            </TouchableOpacity>
            {"   "}
            {name}
          </Text>
        </View>

        {tabs()}

        <View style={tw`max-w-[200px] w-full h-10`}>
          {tab == "all" && (
            <SearchBar
              value={search}
              clearable={true}
              textStyles={tw`bg-transparent`}
            />
          )}
        </View>
      </View>

      {showContent()}
    </View>
  );
};

//export default DeviceDetails;

const styles = StyleSheet.create({
  devicename: {
    fontFamily: "OpenSans_400Regular",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: 32.68,
  },
});
