import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

export const CheckboxIcon = (props: { backgroundColor?: string, color?: string, style?: StyleProp<ViewStyle>; }) => {
  const { backgroundColor = "white", color = "white", style } = props;
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1.76562" width="22" height="22" rx="4" fill={backgroundColor} stroke={color} stroke-width="2" />
    </svg>
  );
};
