import { View, Text } from 'react-native'
import React, { FC } from 'react'
import { useDeviceContext } from 'twrnc';

import { tw } from '@/lib';

interface Props {
  children: any;
}

export const SuccessAndEmptyList:FC<Props> = ({ children }) => {

  //Use tailwind
  useDeviceContext(tw);

  return (
    <View style={tw`w-full flex-grow-1 items-center justify-center`}>
      { children }
    </View>
  )
}