import React, { useEffect, useState } from "react";

import { useDeviceContext } from "twrnc";
import { tw } from "@/lib";

import { View } from "react-native";
import { Dropdown } from "react-bootstrap";
import Icon from "react-native-vector-icons/FontAwesome";

import { Text, UserProfile } from "@/components/Commons";
import { WorkspaceService } from "@/services";
import { WorkspaceResponse } from "@/types";

export const Dashboard = () => {
  useDeviceContext(tw);
  const [stateArray,setStatArray]=useState<WorkspaceResponse>()



  const loadInfoAdmin=async ()=>{
    try {
      const data=(await WorkspaceService.getCurrent()).data.result
      setStatArray(data)  
    } catch (error) {
      console.log(error)
      
    }
    
    
  }

  useEffect(() => {
    loadInfoAdmin();
  }, [])
  

  return (
    <View style={tw`flex lg:ml-4`}>
      <Text type="h2">Dashboards</Text>
      <UserProfile />
      <View style={tw`bg-white mt-4 px-8 py-6 mx-2 rounded-3`}>
        <View style={[tw`flex-row`, { zIndex: "auto" }]}>
          <Text type="h2" style={tw`pr-9`}>
            Statistics
          </Text>
          <Dropdown>
            <Dropdown.Toggle
              variant="link"
              style={{
                lineHeight: 0,
                ...tw`no-underline bg-gray-100 py-1 px-4`,
              }}
              size="sm"
              id="dropdown-basic"
              bsPrefix="btn"
            >
              <View style={tw`flex-row items-center`}>
                <Text style={tw`pr-6`}>Month</Text>
                <Icon name="chevron-down" />
              </View>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                style={tw`text-black text-btn-menu-item font-semibold`}
              >
                Month
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </View>
        <View style={tw`flex-row pt-2`}>
          <Text style={tw`text-dark-gray w-[152px]`}>Customers</Text>
          <Text weight="bold">000</Text>
        </View>
        <View style={tw`w-1/2 flex-row py-4`}>
          <Text style={tw`text-dark-gray w-[152px]`}>Monthly revenue</Text>
          <Text weight="bold">$000</Text>
        </View>
        <View style={tw`w-1/2 flex-row`}>
          <Text style={tw`text-dark-gray w-[152px]`}>Upcoming comp</Text>
          <Text weight="bold">$0000</Text>
        </View>
      </View>
      <View style={tw` bg-white mt-4 px-8 py-6 mx-2 rounded-3 `}>
        <View style={tw`flex-row items-end`}>
          <View style={tw`flex flex-row   w-full  w-[120px]  lg:w-[350px] py-2  items-center justify-start`}>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                style={{
                  lineHeight: 0,
                  ...tw`no-underline bg-gray-100 py-1 px-4`,
                }}
                size="sm"
                id="dropdown-basic"
                bsPrefix="btn"
              >
                <View style={tw`flex-row items-center`}>
                  <Text style={tw`pr-6`}>Month</Text>
                  <Icon name="chevron-down" />
                </View>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  style={tw`text-red-500 text-btn-menu-item font-semibold`}
                >
                  Month
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </View>
          <View style={tw`flex flex-row  w-1/6 lg:max-w-1/6 py-2  items-center justify-start `}>
            <Text weight="semiBold" style={tw`text-dark-gray`}>
              Total Users
            </Text>
          </View>
          <View style={tw`flex flex-row   lg:w-1/6  py-2  items-center justify-start  `}>
            <Text weight="semiBold" style={tw`text-dark-gray`}>
              Total Compensations
            </Text>
          </View>
        </View>
        <View style={{ zIndex: -5, ...tw`pt-5 w-full` }}>
          <View style={tw`flex-row w-full `}>
            <View style={tw` w-[120px]  lg:w-[360px]  `}>
              <Text weight="semiBold" style={tw`text-dark-gray`}>
                From Subscriptions
              </Text>
            </View>
            <View style={tw` lg:w-1/6 min-w-[60px]   `}>
              <Text weight="semiBold">396</Text>
            </View>
            <View style={tw`lg:w-1/6 `}>
              <Text weight="semiBold">$412</Text>
            </View>
          </View>
          <View style={tw`border-l border-light-gray`}>
            <View style={tw`flex-row pt-6`}>
              <View style={tw`w-[120px]  lg:w-[360px]`}>
                <Text weight="semiBold" style={tw`text-dark-gray pl-5`}>
                  PRO Monthly
                </Text>
              </View>
              <View style={tw`lg:w-1/6 min-w-[60px] `}>
                <Text weight="semiBold">132</Text>
              </View>
              <View style={tw`lg:w-1/6`}>
                <Text weight="semiBold">$142</Text>
              </View>
            </View>
            <View style={tw`flex-row pt-6`}>
              <View style={tw`w-[120px]  lg:w-[360px] `}>
                <Text weight="semiBold" style={tw`text-dark-gray pl-5`}>
                  PRO Anual
                </Text>
              </View>
              <View style={tw`lg:w-1/6 min-w-[60px] `}>
                <Text weight="semiBold">132</Text>
              </View>
              <View style={tw`lg:w-1/6`}>
                <Text weight="semiBold">$142</Text>
              </View>
            </View>
            <View style={tw`flex-row pt-6`}>
              <View style={tw`w-[120px]  lg:w-[360px]`}>
                <Text weight="semiBold" style={tw`text-dark-gray pl-5`}>
                  FREE
                </Text>
              </View>
              <View style={tw`lg:w-1/6 min-w-[60px] `}>
                <Text weight="semiBold">132</Text>
              </View>
              <View style={tw`lg:w-1/6`}>
                <Text weight="semiBold">$142</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={tw`pt-5`}>
          <View style={tw`flex-row`}>
            <View style={tw`w-[120px]  lg:w-[360px] `}>
              <Text weight="semiBold" style={tw`text-dark-gray`}>
                From Add-ons
              </Text>
            </View>
            <View style={tw`lg:w-1/6 min-w-[60px]  `}>
              <Text weight="semiBold">396</Text>
            </View>
            <View style={tw`lg:w-1/6`}>
              <Text weight="semiBold">$412</Text>
            </View>
          </View>
          <View style={tw`border-l border-light-gray`}>
            <View style={tw`flex-row pt-6`}>
              <View style={tw`w-[120px]  lg:w-[360px] `}>
                <Text weight="semiBold" style={tw`text-dark-gray pl-5`}>
                  Add-on type 1
                </Text>
              </View>
              <View style={tw`lg:w-1/6 min-w-[60px]  `}>
                <Text weight="semiBold">132</Text>
              </View>
              <View style={tw`lg:w-1/6`}>
                <Text weight="semiBold">$142</Text>
              </View>
            </View>
            <View style={tw`flex-row pt-6`}>
              <View style={tw`w-[120px]  lg:w-[360px]  `}>
                <Text weight="semiBold" style={tw`text-dark-gray pl-5`}>
                  Add-on type 1
                </Text>
              </View>
              <View style={tw`lg:w-1/6 min-w-[60px] `}>
                <Text weight="semiBold">132</Text>
              </View>
              <View style={tw`lg:w-1/6`}>
                <Text weight="semiBold">$142</Text>
              </View>
            </View>
            <View style={tw`flex-row pt-6`}>
              <View style={tw`w-[120px] lg:w-[360px] `}>
                <Text weight="semiBold" style={tw`text-dark-gray pl-5`}>
                  Add-on type 1
                </Text>
              </View>
              <View style={tw`lg:w-1/6 min-w-[60px] `}>
                <Text weight="semiBold">132</Text>
              </View>
              <View style={tw`lg:w-1/6`}>
                <Text weight="semiBold">$142</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
