import { StyleSheet, TouchableOpacity, View } from "react-native";
import React from "react";
import { colors, tw } from "@/lib";
import { useNavigate } from "@/routes";
import { Back } from "@/components/Icons";
import { Badge, Button, Text } from "@/components/Commons";
import { promodata } from "./data";

const PromoCode = () => {
  const productsArray = ["Product 1", "Product 3"];
  const navigate = useNavigate();
  return (
    <View style={tw`flex-grow-2 h-full mx-8 py-7 `}>
      <View style={tw`lg:flex-row w-full justify-between`}>
        <View style={tw`flex w-full lg:max-w-1/2`}>
          <Text style={tw`text-h2 font-bold pr-4`}>
            <TouchableOpacity
              style={tw`bg-white p-2 rounded-3`}
              onPress={() => navigate(-1)}
            >
              <Back color={colors.blue} />
            </TouchableOpacity>{" "}
            {""} Amet, lectus 10%
          </Text>
        </View>

        <View
          style={tw`flex  lg:flex-row w-full  md:max-w-[172px]  lg:items-end lg:justify-end  `}
        >
          <Button>Apply</Button>
        </View>
      </View>
      <View style={tw`flex w-full   pl-8 justify-around my-5 flex-initial`}>
        <View
          style={[
            tw`flex items-center justify-center rounded-3 bg-white`,

            {
              width: 129,
              height: 32,
            },
          ]}
        >
          <Text color={colors.black} type={"h3"} weight={"semiBold"}>
            PROMOCODE
          </Text>
        </View>

        <View style={tw`flex flex-row items-center justify-start  my-6 `}>
          {productsArray.map((element: string, index: number) => {
            return (
              <TouchableOpacity key={index} onPress={() => {}}>
                <View
                  style={[
                    tw`flex items-center justify-around rounded-3 bg-white lg:m-2`,

                    {
                      width: 89,
                      height: 31,
                    },
                  ]}
                >
                  <Text
                    color={colors.gray}
                    type={"regular"}
                    weight={"semiBold"}
                  >
                    {element}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </View>

        <View style={tw`flex w-full    justify-around  `}>
          {promodata.map((element: string, indexa: number) => {
            return (
              <View style={tw`flex my-2`} key={indexa}>
                <Text type="caption" weight="regular" style={{ fontSize: 14 }}>
                  .{element}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default PromoCode;

const styles = StyleSheet.create({});
