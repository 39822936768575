import { StyleProp, View, ViewStyle } from "react-native";
import React from "react";

import { TextInput } from "@/components/Inputs";
import { colors, FieldControlType, tw } from "@/lib";
import { useDeviceContext } from "twrnc";
import { Search } from "@/components/Icons";

interface Props {
  value: FieldControlType<string>;
  clearable?: boolean;
  textStyles?: StyleProp<ViewStyle>;
  onFocus?: () => void;
  onelementChange?: (input: string) => void;
  isFocus?: boolean;
  placeholder?: string;
}

export const SearchBar = ({
  value,
  clearable = false,
  textStyles,
  onFocus,
  onelementChange,
  placeholder = "Type for search",
  isFocus = false,
}: Props) => {
  //Use tailwind
  useDeviceContext(tw);

  return (
    <View>
      <TextInput
        rightIcon={
          <Search color={colors.grayLightLightest} styles={tw`mr-3`} />
        }
        value={value.value}
        errorMessage={value.error}
        autoCapitalize="words"
        placeholder={placeholder}
        onChangeText={(input) => onelementChange && onelementChange(input)}
        clearable={clearable}
        style={textStyles}
        onFocus={onFocus}
        isFocus={isFocus}
      />
    </View>
  );
};
