import React, { useRef, useState } from "react";
import { View, Pressable, Animated } from "react-native";
import { useDeviceContext } from "twrnc";

import { tw } from "@/lib";
import { Text } from "@/components/Commons";

interface Props {
  children: JSX.Element;
  tooltipInfo: string;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export const Tooltip = ({
  children,
  tooltipInfo,
  left = 0,
  right = 0,
  top = 0,
  bottom = 0,
}: Props) => {
  //Add value to right
  if (right === 0 && left === 0) right = 5;
  //Add value to left
  if (bottom === 0 && top === 0) top = -10;

  //Initial value animation
  const fadeAnim = useRef(new Animated.Value(0)).current;

  //Show and hide popover
  const [show, setShow] = useState<boolean>(true);

  //Use tailwind
  useDeviceContext(tw);

  //Manage Animation
  const handleAnimation = (value: number) => {
    //@ts-ignore
    Animated.timing(fadeAnim, {
      toValue: value,
      duration: 500,
    }).start();
  };

  //Stop Animation
  const handleStopAnimation = () => {
    handleAnimation(0);
    setShow(true);
  };

  //Start Animation
  const handleStartAnimation = () => {
    if (show == true) {
      handleAnimation(1);
    } else {
      handleStopAnimation();
    }
    setShow(!show);
  };

  return (
    <View>
      <View style={tw`relative`}>
        <Animated.View
          style={{
            opacity: fadeAnim,
            ...tw`absolute w-35 flex-wrap bg-white rounded-3 border border-gray-200 left-${right} right-${left} top-${top} bottom-${bottom}`,
          }}
        >
          <Text style={tw`text-center py-2 px-4`}>{tooltipInfo}</Text>
        </Animated.View>
        <Pressable onPress={handleStartAnimation} onBlur={handleStopAnimation}>
          {children}
        </Pressable>
      </View>
    </View>
  );
};
