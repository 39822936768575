import React from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";
import { Button } from "@/components/Commons";
import { Icon } from "react-native-elements";

export interface FileInputProps extends TouchableOpacityProps {
  type?: "outlined" | "button" | "font-awesome";
  disabled?: boolean;
  multipleSelection?: boolean;
  onSelect?: (...input: File[]) => void;
}

export const AttachmentInput = (props: FileInputProps &
{ styles?: {} ,color?:string,sizes:number }) => {
  const click = async () => {
    var fileSelector = document.createElement("input");

    const {
      multipleSelection = true,
    } = props;

    fileSelector.setAttribute("type", "file");
    fileSelector.accept = "*";
    fileSelector.multiple = multipleSelection;

    fileSelector.addEventListener("input", (e) => {
      // @ts-ignore
      onSelect(...e.target?.files);
    });

    fileSelector.click();
  };

  const onSelect = props.onSelect;

  return (


    <TouchableOpacity onPress={click}>
      <Icon
        // @ts-ignore
        name={"paperclip"}
        size={props.sizes}
        style={props.styles}
        type={'font-awesome'}
        color={props.color}
                 
                
        
        tvParallaxProperties={undefined} />
    </TouchableOpacity>

  );
};
