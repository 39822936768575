import { colors } from "@/lib";
import * as React from "react";
import {
  FlatList,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  View,
} from "react-native";

export type InfintyScrollType<T> = {
  item: T;
};

type InfinityScrollProps<T> = {
  Component: React.FunctionComponent<InfintyScrollType<T>>;
  data: T[];
  getMoreData: () => void;
  showsVerticalScrollIndicator?: boolean;
  SeparatorComponent?: React.FunctionComponent<any>;
  FooterComponent?: React.FunctionComponent<any>;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  showSpinner?: boolean;
};

export const InfinityScroll = <T extends unknown>({
  Component,
  data,
  SeparatorComponent,
  FooterComponent,
  getMoreData,
  style,
  contentContainerStyle,
  showsVerticalScrollIndicator = false,
  showSpinner = false,
}: InfinityScrollProps<T>): JSX.Element => {
  const renderCell = ({
    index,
    style,
    ...props
  }: {
    index: number;
    style: StyleProp<ViewStyle>;
  }) => {
    return <View style={{ zIndex: -index }} {...props} />;
  };

  return (
    <>
      <FlatList
        style={style}
        data={data}
        contentContainerStyle={contentContainerStyle}
        renderItem={({ item }) => <Component item={item} />}
        keyExtractor={(_item, index) => String(index)}
        ItemSeparatorComponent={SeparatorComponent}
        ListFooterComponent={FooterComponent}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        onEndReachedThreshold={0.1}
        onEndReached={({ distanceFromEnd }) => {
          if (distanceFromEnd < 0) return;
          getMoreData();
        }}
        CellRendererComponent={renderCell}
      />
      {showSpinner && <ActivityIndicator size="small" color={colors.blue} />}
    </>
  );
};
