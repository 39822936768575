import { ApiService, AxiosHeaders } from "@/services";
import { BillingResponse } from "@/types";
import { AxiosResponse } from "axios";

const endpoint = "billing";

class BillingService {
  //Get the workspace billing
  async getBillingInfo(
    workspaceId: number
  ): Promise<AxiosResponse<{ result: BillingResponse }>> {
    return ApiService.api.get(`${endpoint}/info/${workspaceId}`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  //Set the workspace billing
  async setBillingInfo(workspaceId: number, billing: Partial<BillingResponse>) {
    return ApiService.api.patch(`${endpoint}/info/${workspaceId}`, billing, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
}

const singleton = new BillingService();
export default singleton;
