import React, { useState } from "react";
import {
  KeyboardAvoidingView,
  StatusBar,
  TouchableOpacity,
  View,
} from "react-native";
import { SideRegistration } from "@/components/Layouts/SideRegistration";
import { useDeviceContext } from "twrnc";
import tw from "@/lib/tailwind";
import { TextInput } from "@/components/Inputs/TextInput";
import { Button, Text } from "@/components/Commons";

import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { colors, useFieldControl } from "@/lib";
import { password, required, extensions, min, max } from "@/lib/Rules";
import { useNavigate } from "@/routes";
import { store } from "@/store";
import { setAdmin, setAccountDetails } from "@/store/register.store";

import { CheckBox } from "react-native-elements";
import { ErrorFixed } from "@/types";
import TreeError from "@/components/Tree/NestedListError";
import SweetAlert from "react-bootstrap-sweetalert";
import { EyeOpen } from "@/components/Icons/EyeOpen";
import { EyeClose } from "@/components/Icons/EyeClose";
import { CheckboxIcon } from "@/components/Icons/CheckboxIcon";

export const AdministrationAccount = () => {
  useDeviceContext(tw);

  const [stateError, setStateArError] = useState<ErrorFixed[] | string>([]);
  const navigate = useNavigate();
  const [isSecureEntry, setIsSecureEntry] = useState(true);
  //Loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const registerState = store.getState();

  const adminInput = {
    company_name: useFieldControl<string>(
      registerState.register.companyInfo.company_name!,
      [required, min(5), max(255)]
    ),
    first_name: useFieldControl<string>(
      registerState.register.admin.first_name!,
      [required]
    ),
    last_name: useFieldControl<string>(
      registerState.register.admin.last_name!,
      [required]
    ),
    passwords: useFieldControl<string>(registerState.register.admin.password!, [
      required,
      password,
    ]),
    extension: useFieldControl<number>(
      registerState.register.admin.extension!,
      [required, extensions]
    ),
  };

  const [autoAssign, setAutoAssign] = useState<boolean>(true);

  const valid =
    adminInput.company_name.valid &&
    adminInput.first_name.valid &&
    adminInput.last_name.valid &&
    adminInput.passwords.valid &&
    adminInput.extension.valid;

  const submit = async () => {
    if (valid) {
      let extenstiondefault = 100;
      const input = {
        first_name: adminInput.first_name.value,
        email: registerState.register.admin.email,
        last_name: adminInput.last_name.value,
        password: adminInput.passwords.value,
        extension: autoAssign ? extenstiondefault : adminInput.extension.value,
        secret: registerState.register.admin.secret,
      };
      store.dispatch(setAdmin(input));

      const companyInput = {
        company_name: adminInput.company_name.value,
      };
      //@ts-ignore
      store.dispatch(setAccountDetails(companyInput));
      navigate("registerCompany");
    }
  };

  const handleAutoAssign = () => {
    setAutoAssign(!autoAssign);
    adminInput.extension.handleInputValue(100);
  };

  return (
    <KeyboardAvoidingView behavior="padding" style={tw`flex-grow-1`}>
      <StatusBar
        // @ts-ignore
        style="light"
      />
      <View
        style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}]`}
      >
        <SideRegistration step={2} />
        <AuthLayout>
          <View
            style={[
              tw`flex flex-grow-1 flex-col justify-center  w-full max-w-[330px] items-center`,
            ]}
          >
            <View style={tw`flex flex-col items-center mt-[1rem] md:mt-[5rem]`}>
              <Text type="h2" weight="bold" style={tw`text-center`}>
                Just a few details to get you started with Nucleus
              </Text>
              <View style={[tw` flex  w-full my-8  `]}>
                <TextInput
                  label="Company Name"
                  value={adminInput.company_name.value}
                  errorMessage={adminInput.company_name.error}
                  onChangeText={adminInput.company_name.handleInputValue}
                ></TextInput>
                <TextInput
                  label="Username"
                  type="letters"
                  disabled
                  value={registerState.register.admin.email}
                ></TextInput>
                <TextInput
                  label="First Name"
                  type="letters"
                  value={adminInput.first_name.value}
                  errorMessage={adminInput.first_name.error}
                  onChangeText={adminInput.first_name.handleInputValue}
                ></TextInput>

                <TextInput
                  label="Last Name"
                  type="letters"
                  value={adminInput.last_name.value}
                  errorMessage={adminInput.last_name.error}
                  onChangeText={adminInput.last_name.handleInputValue}
                ></TextInput>

                <TextInput
                  label="Password"
                  rightIcon={
                    <TouchableOpacity
                      onPress={() => {
                        setIsSecureEntry((prev: any) => !prev);
                      }}
                    >
                      {isSecureEntry ? <EyeOpen /> : <EyeClose />}
                    </TouchableOpacity>
                  }
                  value={adminInput.passwords.value}
                  secureTextEntry={isSecureEntry}
                  errorMessage={adminInput.passwords.error}
                  onChangeText={adminInput.passwords.handleInputValue}
                />
                <Text
                  style={{ fontSize: 15, ...tw`ml-2` }}
                  weight="regular"
                  color={colors.rebrandGray}
                >
                  Extension Number
                </Text>
                <View style={tw`flex flex-column md:flex-row md:items-center`}>
                  <View style={tw`md:w-[115px] h-[42px]`}>
                    <TextInput
                      type="number"
                      value={adminInput.extension.value.toString()}
                      onChangeText={(chain) => {
                        adminInput.extension.handleInputValue(+chain);
                      }}
                      disabled={autoAssign}
                    />
                  </View>
                  <CheckBox
                    title={
                      <Text color={colors.rebrandGray} style={tw`px-2`}>
                        Auto-assign my extension
                      </Text>
                    }
                    style={tw`w-5 h-5`}
                    containerStyle={tw`-ml-0 bg-transparent border-0`}
                    checked={autoAssign}
                    checkedIcon={
                      <CheckboxIcon
                        backgroundColor={colors.blue}
                        color={colors.grayLightest}
                      />
                    }
                    uncheckedIcon={
                      <CheckboxIcon
                        backgroundColor={colors.white}
                        color={colors.grayLightest}
                      />
                    }
                    onPress={handleAutoAssign}
                  />
                </View>
                <View style={tw`mx-4`}>
                  <Text color={colors.red} type="caption">
                    {adminInput.extension.error}
                  </Text>
                </View>
                <Button
                  style={tw`sm:pb-6 pb-2 my-4`}
                  disabled={!valid || isLoading}
                  onPress={submit}
                  loading={isLoading}
                >
                  <Text>Continue</Text>
                </Button>
              </View>
            </View>
          </View>
        </AuthLayout>
      </View>
      <View style={[tw`flex items-center justify-center`, { zIndex: 20 }]}>
        <View>
          <SweetAlert
            error
            show={stateError.length > 0}
            title={typeof stateError == "string" && stateError}
            onConfirm={() => {
              setStateArError([]);
            }}
          >
            <View style={tw`flex items-start justify-start w-full`}>
              {typeof stateError !== "string" && (
                <>
                  <View style={tw`flex items-center justify-center ml-8`}>
                    <Text
                      color={colors.dark}
                      type={"bigger"}
                      weight={"semiBold"}
                    >
                      Errors:
                    </Text>
                  </View>
                  <TreeError error={stateError} />
                </>
              )}
            </View>
          </SweetAlert>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};
