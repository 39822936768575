import { ScrollView, View } from "react-native";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Loading, Text } from "@/components/Commons";
import { useDeviceContext } from "twrnc";
import { colors, debounce, tw, useFieldControl } from "@/lib";
import { FancyDropdown, SearchBar } from "@/components/Inputs";
import { AuditLogsService } from "@/services";
import {
  DateRangeFilter,
  Pagination,
  StatusFilter,
  UserEventLogs,
} from "@/types";
import { getTabStyle } from "./helpers";
import { TouchableOpacity } from "react-native-gesture-handler";
import dayjs from "dayjs";

export const AuditLogs = () => {
  useDeviceContext(tw);
  const [selectedType, setSelectedType] = useState<
    "all" | "workspace" | "user"
  >("all");
  const [filteredData, setFilteredData] = useState<UserEventLogs[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<StatusFilter>("");
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeFilter>("");
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    limit: 10,
  });
  const search = useFieldControl<string>("");
  const onSearchChange = useCallback(
    debounce((searchText: string) => search.handleInputValue(searchText), 1000),
    [search.value]
  );

  const fetchUserEventsFromBackendAndStore = async () => {
    const auditLogs = await AuditLogsService.getUserEventLogs(
      search.value ?? "",
      selectedType,
      selectedStatus,
      selectedDateRange,
      pagination
    );
    setFilteredData(auditLogs.data.result);
  };

  useEffect(() => {
    fetchUserEventsFromBackendAndStore().catch((e) => {
      console.log("error", e);
    });
  }, [
    search.value,
    selectedType,
    selectedStatus,
    selectedDateRange,
    pagination,
  ]);

  const tabs = () => {
    const allTickets = getTabStyle(() => selectedType == "all");
    const workspaceTickets = getTabStyle(() => selectedType == "workspace");
    const userTickets = getTabStyle(() => selectedType == "user");
    return (
      <View style={tw`flex-row bg-[#EBEBF0] h-10 rounded-3`}>
        <TouchableOpacity
          containerStyle={[
            tw`w-40 items-center justify-center`,
            allTickets.btn,
          ]}
          onPress={() => {
            selectedType != "all" && setSelectedType("all");
          }}
        >
          <Text weight="semiBold" style={allTickets.text}>
            All
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`w-40 items-center justify-center`,
            workspaceTickets.btn,
          ]}
          onPress={() => {
            selectedType != "workspace" && setSelectedType("workspace");
          }}
        >
          <Text weight="semiBold" style={workspaceTickets.text}>
            Workspace
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          containerStyle={[
            tw`w-40 items-center justify-center`,
            userTickets.btn,
          ]}
          onPress={() => {
            selectedType != "user" && setSelectedType("user");
          }}
        >
          <Text weight="semiBold" style={userTickets.text}>
            User
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View>
        <View style={tw`absolute pt-2`}>
          <Text type="h2">Reports</Text>
        </View>
        <View style={tw`items-center`}>{tabs()}</View>
      </View>
      <View style={tw`flex-col-reverse`}>
        <View style={tw`flex flex-row justify-center mt-4`}>
          <Button
            style={tw`w-[110px]`}
            disabled={pagination.page === 1}
            onPress={() =>
              setPagination({ ...pagination, page: pagination.page - 1 })
            }
          >
            <Text>Prev Page</Text>
          </Button>
          <Button
            style={tw`w-[110px]`}
            disabled={filteredData.length < pagination.limit}
            onPress={() =>
              setPagination({ ...pagination, page: pagination.page + 1 })
            }
          >
            <Text>Next Page</Text>
          </Button>
        </View>
        <ScrollView
          horizontal
          contentContainerStyle={tw`flex-col mt-8 w-full min-w-150`}
        >
          <View style={tw`flex-row px-4 mb-4`}>
            <View style={tw`w-40`}>
              <Text color={colors.gray} weight="semiBold">
                Date{" "}
              </Text>
            </View>
            <View style={tw`w-36`}>
              <Text color={colors.gray} weight="semiBold">
                User{" "}
              </Text>
            </View>

            <View style={tw`w-36`}>
              <Text color={colors.gray} weight="semiBold">
                Type{" "}
              </Text>
            </View>

            <View style={tw`w-36`}>
              <Text color={colors.gray} weight="semiBold">
                Status{" "}
              </Text>
            </View>

            <View style={tw`w-36`}>
              <Text color={colors.gray} weight="semiBold">
                Description{" "}
              </Text>
            </View>
          </View>
          {filteredData.map((response) => {
            return (
              <View
                style={tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`}
                key={response.id}
              >
                <View style={tw`w-40`}>
                  <Text color={colors.dark}>
                    {dayjs(response.date).format("MM/DD/YYYY HH:mm:ss")}
                  </Text>
                </View>
                <View style={tw`w-36`}>
                  <Text color={colors.dark}>{response.user}</Text>
                </View>
                <View style={tw`w-36`}>
                  <Text color={colors.dark} style={tw`capitalize`}>
                    {response.type}
                  </Text>
                </View>
                <View style={tw`w-36`}>
                  <Text color={colors.dark} style={tw`capitalize`}>
                    {response.status === "error"
                      ? "System Error"
                      : response.status}
                  </Text>
                </View>
                <View style={tw`flex-1`}>
                  <Text color={colors.dark}>{response.description}</Text>
                </View>
              </View>
            );
          })}
        </ScrollView>
        <View style={tw`flex-row justify-between mt-8 items-center`}>
          <Text type="h2" style={tw`capitalize`}>
            {selectedType}
          </Text>
          <View style={tw`flex-row justify-around items-center`}>
            <View style={tw`h-[40px]`}>
              <SearchBar
                value={search}
                placeholder={"Start typing name or email"}
                textStyles={tw`w-[320px]`}
                onelementChange={onSearchChange}
              />
            </View>
            <View style={tw`h-[40px] -mt-11`}>
              <FancyDropdown
                style={tw`w-[200px]`}
                autoCorrect={false}
                label=" "
                placeholder="Status"
                value={selectedStatus}
                onSelect={(value) => setSelectedStatus(value as StatusFilter)}
                data={[
                  { label: "All", value: "all" },
                  { label: "Info", value: "info" },
                  { label: "Warning", value: "warning" },
                  { label: "System Error", value: "error" },
                ]}
              />
            </View>
            <View style={tw`h-[40px] -mt-11`}>
              <FancyDropdown
                style={tw`w-[200px]`}
                autoCorrect={false}
                label=" "
                placeholder="Date"
                value={selectedDateRange}
                onSelect={(value) =>
                  setSelectedDateRange(value as DateRangeFilter)
                }
                data={[
                  { label: "Any Date", value: "any" },
                  { label: "Today", value: "today" },
                  { label: "Last 7 Days", value: "lastSevenDays" },
                  { label: "Last 4 Weeks", value: "lastFourWeeks" },
                  { label: "Previous Month", value: "previousMonth" },
                ]}
              />
            </View>
            <View style={tw`h-[40px] -mt-11`}>
              <FancyDropdown
                style={tw`w-[200px]`}
                autoCorrect={false}
                label="Rows per page"
                value={pagination.limit.toString()}
                onSelect={(value) =>
                  setPagination({ ...pagination, limit: +value })
                }
                data={[
                  { label: "10", value: "10" },
                  { label: "20", value: "20" },
                  { label: "50", value: "50" },
                ]}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
