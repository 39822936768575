import React, { useEffect, useRef, useState } from "react";
import {
  Animated,
  Image,
  ScrollView,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import { useDeviceContext } from "twrnc";
import { Outlet, useLocation, useParams } from "react-router-dom";
import {
  capitalizeAll,
  colors,
  mobile,
  Modal,
  showFriendlyErrorFromHTMLCode,
  tw,
} from "@/lib";
import { getRoute, useNavigate } from "@/routes";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Wallet, Work } from "@/components/Icons";
import { Text } from "@/components/Commons";
import { Exit } from "../Icons/Exit";
import { TokenService, WorkspaceService } from "@/services";
import {
  CoverResponse,
  PermitionRequest,
  Status,
  WorkspaceInterface,
  WorkspaceResponse,
} from "@/types";
import { useAlert, useUserLogged } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { RootState, store } from "@/store";
import { clearName, setName } from "@/store/workspace.store";
import { clearInfo } from "@/store/accessRequest.store";
import { useToast } from "react-native-toast-notifications";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { AxiosError } from "axios";
import { Banner } from "../Workspace/Banner";
import Notifications from "../Notifications/Notifications";
import { clearAccessLevel, setAccessLevel } from "@/store/user.store";
import ModalBody from "@/screens/client/Info/ModalBody";
import ModalButtons from "@/screens/client/Info/ModalButtons";

interface MenuBar {
  name: string;
  icon?: (props: { color: string; style: StyleProp<ViewStyle> }) => JSX.Element;
  submenuTitle: string;
  hidden?: boolean;
  routeName?: string;
  submenu?: {
    title: string;
    routeName?: string;
  }[];
}

export const ClientLayout = (props: ViewProps & { states?: number }) => {
  useDeviceContext(tw);
  const { name: workspace } = useAppSelector(
    (state: RootState) => state.workspace
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { workspaceId } = useParams();
  const { showSwal } = useAlert();
  const [showSubmenu, changeShowSubmenu] = useState(false);
  const [userImage, setUserImage] = useState<CoverResponse>();
  const [state, setState] = useState<number>(props.states!);

  const subMenuWidth = useRef(new Animated.Value(0)).current;
  const [arrayLength, setArrayLength] = useState<number>(0);
  const [stateArray, setStateArray] = useState<WorkspaceInterface[]>();
  const { logoutUser, userLogged } = useUserLogged();
  const dispatchs = useAppDispatch();

  const callRightService = async () => {
    let res: WorkspaceResponse;

    try {
      const response = await WorkspaceService.getCurrent();

      if (workspaceId) {
        const id = parseInt(workspaceId);
        const workspaceResult = await WorkspaceService.getWorkspaceInfo(id);
        const workspaceById = { ...workspaceResult.data.result };

        const { workspace, admin, user_access } = workspaceById;
        res = {
          name: workspace.company_name,
          domain: workspace.domain_name,
          accountName: "",
          address: workspace.address,
          admin,
          city: workspace.city,
          company_address: "",
          users: workspace.users,
          coverPhoto: workspace.cover_photo,
          createdAt: "",
          id: workspace.id,
          phoneNumber: "",
          postalCode: workspace.postal_code,
          province: workspace.province,
          street_name: "",
          street_number: "",
          unit_number: "",
          user_access,
        };
        setUserImage(workspace.cover_photo as CoverResponse);
      } else {
        res = response.data.result;
      }
      dispatch(setName({ name: res?.name || "" }));
      dispatch(
        setAccessLevel({
          accessType: res?.user_access?.accessType,
          accessStatus: res?.user_access?.status,
        })
      );
    } catch (err) {
      //Show error
      const error = err as AxiosError;
      showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    }
  };

  useEffect(() => {
    Animated.timing(subMenuWidth, {
      toValue: showSubmenu ? 100 : 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [showSubmenu]);

  //Cleaning
  useEffect(() => {
    callRightService();

    return () => {
      dispatch(clearName());
      dispatch(clearAccessLevel());
    };
  }, []);

  let menuBar: MenuBar[] = [
    {
      name: "company",
      icon: Work,
      submenuTitle: "Workspace Settings",
      submenu: [
        {
          title: "Company",
          routeName: "clientCompany",
        },
        {
          title: "Users",
          routeName: "clientCompanyUsers",
        },
        {
          title: "Billing",
          routeName: "store",
        },
      ],
    },
  ];

  if (workspaceId) {
    //@ts-ignore
    menuBar = menuBar.map((item) => {
      if (item.name === "profile") {
        const submenu = item.submenu?.filter(
          (subItem) => subItem.routeName === "login"
        );

        return {
          ...item,
          submenu,
        };
      } else {
        return item;
      }
    });
  }

  const [menuSelected, setMenuSelected] = useState(
    location.pathname.split("/")[1]
  );

  useEffect(() => {
    setMenuSelected(location.pathname.split("/")[1]);
  }, [location.pathname]);

  const sideBar = menuBar.find((e) => e.name == menuSelected);
  //Me voy por aca

  const showToast = (msg: string) => {
    toast.show(msg, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <FontAwesome name="check" size={18} color={colors.white} />,
    });
  };

  ///Request access from user to admin
  const accessRequested = async () => {
    if (workspaceId) {
      try {
        const id = TokenService.getUser()?.user.id;
        const accesstype = {
          accesstype: store.getState().accessRequest.accessType,
          status: store.getState().accessRequest.status,
          from: new Date(store.getState().accessRequest.from!),
          to: new Date(store.getState().accessRequest.to!),
          message: store.getState().accessRequest.message,
          endless: store.getState().accessRequest.endless,
        };

        //@ts-ignore

        const permition: PermitionRequest = {
          accessType: accesstype.accesstype!,
          workspaceId: +workspaceId,
          supportUserId: id,
          endless: accesstype.endless,
          status: accesstype.status as Status,
          from: new Date(accesstype.from!),
          to: new Date(accesstype.to!),
          message: accesstype.message,
        };
        await WorkspaceService.requestPermition(permition);
        dispatch(
          setAccessLevel({
            accessType: "read and write",
            accessStatus: "requested",
          })
        );
        showToast(
          "We have sent a notification to the admin for you to be granted"
        );
      } catch (error) {
        setErrorMsg(error);
      }
    }
  };

  const shootElement = () => {
    const { accessStatus, accessType } = userLogged;

    if (accessType === "read and write" && accessStatus === "requested") {
      showToast("Your request has been sent and waiting for the admin approve");
      return;
    }

    if (
      accessType === "read and write" &&
      (accessStatus === "granted" ||
        accessStatus === "active" ||
        accessStatus === "approved")
    ) {
      showToast("You already have read and write permission");
      return;
    }

    dispatchs(clearInfo());

    Modal.show(
      <View>
        <ModalBody
          data={[{ label: "Read and Write", value: "read and write" }]}
          text="Request Access"
          visible={false}
          textarea
          types="local"
        />
        <ModalButtons onCallBack={accessRequested} text={"Request"} />
      </View>,
      { width: 500, noClose: true }
    );
  };

  const setErrorMsg = (err: any) => {
    //setIsLoading(false);
    //Show error
    const error = err as AxiosError;
    if (error.response?.status === 403) {
      showSwal({
        msg:
          error.response.data?.error?.message ===
          "PERMISSION_REQUEST_EXISTS_FOR_THIS_USER"
            ? `Permission Requested Exist for this user`
            : ``,
        type: "error",
      });
      return;
    }
    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    //setErrorMs({ show: true, msg: showFriendlyErrorFromHTMLCode(error) });
  };

  const setButtonColor = (): string => {
    const { accessStatus, accessType } = userLogged;
    const isReqOrGrant =
      accessType === "read and write" &&
      (accessStatus === "granted" ||
        accessStatus === "requested" ||
        accessStatus === "active" ||
        accessStatus === "approved");
    return isReqOrGrant ? colors.gray : colors.blue;
  };

  return (
    <View
      style={{
        backgroundColor: colors.rebrandLayoutBGColor,
        ...tw`flex flex-row justify-start w-[100vw] h-[100vh]`,
      }}
    >
      <View
        style={tw`w-[65px] sm:w-[88px] h-full bg-black flex flex-col justify-between`}
      >
        <Image
          style={tw`w-[51px] mx-auto mt-4 h-[51px]`}
          source={require("@/assets/images/LOGO/logo.png")}
        ></Image>
        <View style={tw`mx-auto`}>
          {menuBar
            .filter((e) => !e.hidden)
            .map((e) => {
              const active = e.name == menuSelected;
              return (
                <TouchableOpacity
                  key={e.name}
                  style={[
                    tw`w-menu-btn h-menu-btn rounded-4`,
                    tw.style(active ? "bg-btn" : ""),
                  ]}
                  onPress={() => {
                    setMenuSelected(e.name);
                    changeShowSubmenu(true);
                    if (e.routeName) {
                      navigate(e.routeName);
                    } else if (e.submenu) {
                      navigate(
                        //@ts-ignore
                        workspaceId && e.submenu?.length >= 1
                          ? getRoute(e.submenu[0].routeName)?.path +
                              `/${workspaceId}`
                          : e.submenu[0].routeName
                      );
                    }
                  }}
                >
                  {
                    // @ts-ignore
                    <e.icon style={tw`mx-auto my-auto`} />
                  }
                </TouchableOpacity>
              );
            })}
        </View>

        <View style={tw`mx-auto`}></View>
      </View>

      {sideBar && sideBar.submenu && (
        <Animated.View
          style={[
            tw.style({
              hidden: !subMenuWidth,
            }),
            tw`h-full max-w-[272px] bg-white sm:flex`,
            {
              width: mobile()
                ? subMenuWidth.interpolate({
                    inputRange: [0, 100],
                    outputRange: ["0%", "100%"],
                  })
                : "100%",
            },
          ]}
        >
          <View style={tw`flex-col flex w-[272px] px-[16px] py-[28px] h-full `}>
            <Text type="h2" style={tw`pl-2 pb-[24px]`}>
              {sideBar?.submenuTitle}
            </Text>
            {sideBar?.submenu.map((e, index: number) => {
              const routePath = getRoute(e.routeName)?.path;
              let active = routePath && location.pathname.includes(routePath);

              //console.log(location.pathname)

              if (location.pathname.includes("/company")) {
                if (
                  routePath === "/company/details" &&
                  location.pathname == "/company"
                ) {
                  active = true;
                  navigate(routePath);
                }
              }

              //console.log(e.title ==='Details'?`Details` : e.title ==='Users'?`Users`:`Hdaisoj`)

              const linkPressed = () => {
                changeShowSubmenu(false);
                if (!active && e.routeName && routePath) {
                  if (workspaceId) {
                    navigate(`${routePath}/${workspaceId}`);
                  } else {
                    navigate(routePath);
                  }
                }
              };

              return (
                <View
                  style={tw.style(
                    e.title === "Log Out" &&
                      `absolute inset-x-0 bottom-0 px-[16px] py-[28px]`
                  )}
                  key={index}
                >
                  <TouchableOpacity
                    key={e.title}
                    style={[
                      tw`w-full h-[48px] rounded-3 `,
                      tw.style(active && "bg-btn"),
                    ]}
                    onPress={e.title === "Log Out" ? logoutUser : linkPressed}
                  >
                    {e.title === "Log Out" ? (
                      <View style={tw` flex flex-row`}>
                        <Exit color="red" />
                        <Text
                          weight={active ? "regular" : "semiBold"}
                          style={tw`my-auto pl-2 `}
                          color={colors.red}
                        >
                          {e.title}
                        </Text>
                      </View>
                    ) : (
                      <Text
                        weight={active ? "semiBold" : "regular"}
                        style={[
                          tw`my-auto pl-2`,
                          tw.style(active ? "text-white" : "text-black"),
                        ]}
                      >
                        {e.title}
                      </Text>
                    )}
                  </TouchableOpacity>
                </View>
              );
            })}
          </View>
        </Animated.View>
      )}

      <ScrollView
        contentContainerStyle={{
          ...tw`w-full min-h-[100vh]`,
          zIndex: 0,
        }}
      >
        {workspace && workspaceId && (
          <Banner
            buttonText="Request Edit Access"
            showButton={
              userLogged.rol !== "admin" && userLogged.rol !== "super_admin"
            }
            buttonTextColor={setButtonColor()}
            handlePress={shootElement}
          >
            <Text style={tw`text-white text-lg text-center`}>
              You are currently watching {workspace}{" "}
              {userLogged.rol !== "super_admin" ? "with " : ""}
            </Text>
            {userLogged.rol !== "super_admin" ? (
              <Text
                style={tw`text-white text-lg tracking-wide`}
                weight="semiBold"
              >
                <Text
                  style={tw`text-white text-lg tracking-wide`}
                  weight="semiBold"
                >
                  {userLogged.accessType === "read and write" &&
                  (userLogged.accessStatus === "granted" ||
                    userLogged.accessStatus === "active" ||
                    userLogged.accessStatus === "approved")
                    ? capitalizeAll(userLogged.accessType || "")
                    : "Read Only"}{" "}
                  access
                </Text>
              </Text>
            ) : (
              <></>
            )}
          </Banner>
        )}

        <Outlet />
      </ScrollView>
    </View>
  );
};
