import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import Svg, { Circle, Path, Rect } from "react-native-svg";

export const InfoTickets = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.334 3.26132H7.665C4.644 3.26132 2.75 5.40032 2.75 8.42732V16.5953C2.75 19.6223 4.635 21.7613 7.665 21.7613H16.333C19.364 21.7613 21.25 19.6223 21.25 16.5953V8.42732C21.25 5.40032 19.364 3.26132 16.334 3.26132Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.9941 16.5113V12.5113"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.9901 8.71552H12.0001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
