import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-dom";
//import { ClientLayout } from "@/components/Layouts/ClientLayout";
import tw from "@/lib/tailwind";
import { Back } from "@/components/Icons/Back";
import { colors } from "@/lib";
import { SuccessAndEmptyList, Text } from "@/components/Commons";
import { color } from "react-native-elements/dist/helpers";
import { PaymentWallet } from "@/components/Icons";

export const PaymentHistory = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    value: [
      {
        id: 1,
        plan: "Monthly",
        date: "12.07.2021",
        ammount: "10.00",
        status: "Paid",
      },

      {
        id: 2,
        plan: "Anual",
        date: "12.07.2021",
        ammount: "110.00",
        status: "Failed",
      },
    ],
    fetching: true,
  });
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex flex-row items-center`}>
        <Text style={tw`text-h2 font-bold pr-4`}>
          <TouchableOpacity
            style={tw`bg-white p-2 rounded-3`}
            onPress={() => navigate(-1)}
          >
            <Back color="black" />
          </TouchableOpacity>{" "}
          Billing history
        </Text>
      </View>

      {data.value.length > 0 ? (
        <View style={tw`flex-col mt-3  max-w-4/6`}>
          {/* Headers */}
          <View style={tw`flex-row px-4 mb-4 `}>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray} weight="semiBold">
                Plan
              </Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray} weight="semiBold">
                Date
              </Text>
            </View>

            <View style={tw`w-3/12 `}>
              <Text color={colors.gray} weight="semiBold">
                Ammount
              </Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.gray} weight="semiBold">
                Status
              </Text>
            </View>
          </View>
          {/* Map values */}
          {data.value.map((response) => {
            return (
              <View
                style={tw`flex-row bg-white px-4 py-3 rounded-3 items-center my-2 `}
                key={response.id}
              >
                <View style={tw`w-3/12 `}>
                  <Text color={colors.dark} weight="semiBold">
                    {response.plan}
                  </Text>
                </View>
                <View style={tw`w-3/12 `}>
                  <Text color={colors.dark} weight="semiBold">
                    {response.date}
                  </Text>
                </View>

                <View style={tw`w-3/12 `}>
                  <Text color={colors.dark} weight="semiBold">
                    {response.ammount}
                  </Text>
                </View>

                <View style={tw`w-3/12 `}>
                  <Text
                    weight="semiBold"
                    color={
                      response.status === "Paid" ? colors.dark : colors.red
                    }
                  >
                    {response.status}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      ) : (
        <SuccessAndEmptyList>
          <PaymentWallet />
          <View style={tw`mt-4`}>
            <Text type="h2" color={colors.grayLight} >
              Noting here yet
            </Text>
          </View>
        </SuccessAndEmptyList>
      )}
    </View>
  );
};

//export default Paymenthistory

const styles = StyleSheet.create({});
