import React, { Fragment, useState } from "react";
import {
  Linking,
  View,
} from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import { Dropdown } from "react-bootstrap";

import {
  tw,
  Modal,
  colors,
  showFriendlyErrorFromHTMLCode,
  capitalize,
} from "@/lib";
import { Text, InfinityScrollList } from "@/components/Commons";

import { useNavigate } from "@/routes";
import { AllWorkspaces, Status, WorkspaceId } from "@/types";

import {
  PaymentService,
  SuperAdminService,
  TokenService,
  WorkspaceService,
} from "@/services";

import { Avatars } from "@/components/Commons/Avatar";
import { clearInfo } from "@/store/accessRequest.store";
import { useToast } from "react-native-toast-notifications";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { AxiosError } from "axios";
import { useAlert, useUserLogged } from "@/hooks";
import { store } from "@/store";
import ModalButtons from "@/screens/client/Info/ModalButtons";
import { useAppDispatch } from "@/store/hooks";
import ModalBody from "@/screens/client/Info/ModalBody";
import { setAccessLevel } from "@/store/user.store";
import RemoveWorkspaceModal from "@/components/Modal/RemoveWorkspaceModal";

const limit: number = 20;

export const SuperAdminWorkspacesList = () => {
  //initialize navigate
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const { showSwal } = useAlert();

  const { userLogged } = useUserLogged();
  const dispatch = useAppDispatch();

  //Data to Iterate
  const [data, setData] = useState<AllWorkspaces[]>([]);
  const [totalWorkspaces, setTotalWorkspaces] = useState<number>(0);

  const fetchItems = async (
    isScroll: boolean,
    currentPage: number,
    keyToSearch?: string
  ) => {
    try {
      if (!keyToSearch) {
        const { result } = (
          await SuperAdminService.getAllWorkspaces(currentPage * limit, limit)
        ).data;
        const { list, totalCount } = result;
        if (list.length > 0) {
          if (isScroll) setData([...data, ...list]);
          else setData(list);
          setTotalWorkspaces(totalCount);
        }
      } else {
        const { result } = (
          await WorkspaceService.searchWorkspace(
            currentPage * limit,
            limit,
            keyToSearch
          )
        ).data;
        const { list, totalCount } = result;
        if (list.length > 0) {
          if (isScroll) setData([...data, ...list]);
          else setData(list);
          setTotalWorkspaces(totalCount);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      ErrorMessage(error);
    }
  };

  const showToast = (msg: string) => {
    toast.show(msg, {
      placement: "bottom",
      type: "success",
      duration: 3000,
      successColor: colors.successAlert,
      successIcon: <FontAwesome name="check" size={18} color={colors.white} />,
    });
  };

  const ErrorMessage = (err: any) => {
    //Show error
    const error = err as AxiosError;
    let msg: string = showFriendlyErrorFromHTMLCode(error);
    showSwal({ msg, type: "error" });
  };

  //Input to validate dropdown
  const syncPurchases = async (response: AllWorkspaces) => {
    const workspaceId: number = +response.id;
    await PaymentService.syncPurchases(workspaceId);
  };

  const deleteWorkspaces = async (id: number) => {
    const workspaceId: WorkspaceId = {
      workspace: id,
    };
    try {
      await SuperAdminService.deleteWorkspaces(workspaceId);
      Modal.dismiss();
      showToast("Workspace deleted successfully");
      setData((prev) => prev.filter((item) => Number(item.id) !== id));
    } catch (error) {
      ErrorMessage(error);
      Modal.dismiss();
    }
  };

  const workspaceActivate = async (response: AllWorkspaces) => {
    const workspaceId: WorkspaceId = {
      workspace: +response.id,
    };
    try {
      if (response.status === "suspended") {
        await SuperAdminService.activateWorkspace(workspaceId);
        setData((prev) => {
          return prev?.map((res) => {
            if (res.id === response.id) {
              return { ...res, status: "active" };
            } else {
              return res;
            }
          });
        });
        showToast("Workspace activated successfully");
      } else {
        await SuperAdminService.suspendWorkspace(workspaceId);
        setData((prev) => {
          return prev?.map((res) => {
            if (res.id === response.id) {
              return { ...res, status: "suspended" };
            } else {
              return res;
            }
          });
        });
        showToast("Workspace suspeded successfully");
      }
    } catch (error: any) {
      ErrorMessage(error);
    }
  };

  const showModalForRemove = (response: AllWorkspaces) => {
    Modal.show(
      <RemoveWorkspaceModal
        onRemove={() => {
          return deleteWorkspaces(+response.id);
        }}
      />
    );
  };

  const renderItemLog = ({ item }: { item: AllWorkspaces }) => {
    const textColorByStatus: string =
      item.status === "suspended" ? colors.gray : colors.dark;

    const equalizer =
      userLogged.rol != "super_admin" &&
      (item.global_access?.accessType === "no access" ||
        item.global_access?.accessType === "denied" ||
        item.permissions.accessType === "denied" ||
        item.permissions.accessType === "no access" ||
        item.permissions.status === "denied" ||
        item.permissions.status === "expired" ||
        item.permissions.status === "rejected" ||
        item.permissions.status === "revoked");

    return (
      <Fragment key={`${item.id}-${item.domain}`}>
        {Number(item.id) !== 97 && (
          <View
            style={[
              tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`,
              { zIndex: "auto" },
            ]}
            key={item.id}
          >
            <Avatars
              uri={item.coverPhoto?.m || undefined}
              width={"1/12"}
              size={44}
            />
            <View style={tw`w-3/12 `}>
              <Text weight="semiBold" color={textColorByStatus}>
                {item.domain}
              </Text>
            </View>
            <View style={tw`w-3/12 pl-2`}>
              <Text weight="semiBold" color={textColorByStatus}>
                {item.companyName}
              </Text>
            </View>
            <View style={tw`w-3/12 pl-4`}>
              <Text weight="semiBold" color={textColorByStatus}>
                {item.countOfUsers}
              </Text>
            </View>

            <View style={tw`w-auto pl-4`}>
              <Text
                weight="semiBold"
                color={item.status === "suspended" ? colors.red : colors.blue}
              >
                {capitalize(item.status || "")}
              </Text>
            </View>

            <View style={[tw`relative w-auto ml-auto`, { zIndex: "auto" }]}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  style={{ lineHeight: 0, ...tw`no-underline` }}
                  id="dropdown-basic"
                  bsPrefix="btn"
                >
                  <Icon color="#C8C8C8" name="more-vert" size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ ...tw`rounded-3`, ...{ zIndex: 1000 } }}
                  flip={false}
                >
                  {equalizer ? (
                    <Dropdown.Item
                      as="button"
                      style={tw` text-btn-menu-item font-semibold`}
                      onClick={() => shootElement(item)}
                    >
                      Request Access
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      as="button"
                      style={tw` text-btn-menu-item font-semibold`}
                      onClick={() => {
                        Linking.openURL(`/company/details/${item.id}`);
                      }}
                    >
                      Log in as Client
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    as="button"
                    style={tw` text-btn-menu-item font-semibold`}
                    onClick={() => syncPurchases(item)}
                  >
                    Sync Purchases
                  </Dropdown.Item>

                  <Dropdown.Item
                    as="button"
                    style={tw` text-btn-menu-item font-semibold`}
                    onClick={() => workspaceActivate(item)}
                  >
                    {item.status === "suspended" ? `Activate` : `Suspend`}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    style={tw`text-btn-menu-item font-semibold text-red-500`}
                    onClick={() => {
                      return showModalForRemove(item);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </View>
          </View>
        )}
      </Fragment>
    );
  };

  const accessRequested = async (workspace: AllWorkspaces) => {
    try {
      const id = TokenService.getUser()?.user.id;
      const accesstype = {
        accesstype: store.getState().accessRequest.accessType,
        status: store.getState().accessRequest.status,
        from: new Date(store.getState().accessRequest.from!),
        to: new Date(store.getState().accessRequest.to!),
        message: store.getState().accessRequest.message,
      };
      //@ts-ignore

      const permition: PermitionRequest = {
        accessType: accesstype.accesstype!,
        workspaceId: workspace.id,
        supportUserId: id,
        status: accesstype.status as Status,
        from: new Date(accesstype.from!),
        to: new Date(accesstype.to!),
        message: accesstype.message,
      };
      await WorkspaceService.requestPermition(permition);
      dispatch(
        setAccessLevel({
          accessType: "read and write",
          accessStatus: "requested",
        })
      );
      showToast(
        "We have sent a notification to the admin for you to be granted"
      );
    } catch (error) {
      setErrorMsg(error);
    }
  };
  const setErrorMsg = (err: any) => {
    //setIsLoading(false);
    //Show error
    const error = err as AxiosError;
    if (error.response?.status === 403) {
      showSwal({
        msg:
          error.response.data?.error?.message ===
          "PERMISSION_REQUEST_EXISTS_FOR_THIS_USER"
            ? `Permission Requested Exist for this user`
            : ``,
        type: "error",
      });
      return;
    }
    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    //setErrorMs({ show: true, msg: showFriendlyErrorFromHTMLCode(error) });
  };

  const shootElement = (workspace: AllWorkspaces) => {
    const { accessStatus, accessType } = userLogged;
    if (accessType === "read and write" && accessStatus === "requested") {
      showToast("Your request has been sent and waiting for the admin approve");
      return;
    }

    if (
      accessType === "read and write" &&
      (accessStatus === "granted" ||
        accessStatus === "active" ||
        accessStatus === "approved")
    ) {
      showToast("You already have read and write permission");
      return;
    }

    dispatch(clearInfo());

    Modal.show(
      <View>
        <ModalBody
          data={[{ label: "Read and Write", value: "read and write" }]}
          text="Request Access"
          visible={false}
          textarea
          types="local"
        />
        <ModalButtons
          onCallBack={() => accessRequested(workspace)}
          text={"Request"}
        />
      </View>,
      { width: 500, noClose: true }
    );
  };

  // const requestPermission = async (workspace: AllWorkspaces) => {
  //   try {
  //     const accesstype = {
  //       accesstype: store.getState().accessRequest.accessType,
  //       status: store.getState().accessRequest.status,
  //       workspaceId:workspace.id,
  //       from: new Date(store.getState().accessRequest.from!),
  //       to: new Date(store.getState().accessRequest.to!),
  //     };
  //     //@ts-ignore
  //     await WorkspaceService.requestPermition(accesstype);
  //     showToast("Permission requested successfully");
  //     setData((prev) =>
  //       prev.map((item) => {
  //         if (item.id === workspace.id) {
  //           return {
  //             ...item,
  //             permissions: {
  //               accessType: "read and write",
  //               status: "requested",
  //             },
  //           };
  //         }
  //         return { ...item };
  //       })
  //     );
  //   } catch (error) {
  //     ErrorMessage(error);
  //   }
  // };

  //Display View
  return (
    <InfinityScrollList
      title="Workspaces"
      data={data}
      Component={renderItemLog}
      fetchData={fetchItems}
      dataLimit={totalWorkspaces}
    >
      <View style={tw`flex-row px-4 mb-4 `}>
        <View style={tw`w-1/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Photo{" "}
          </Text>
        </View>
        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Account Name{" "}
          </Text>
        </View>

        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Company Name{" "}
          </Text>
        </View>

        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            User Quantity{" "}
          </Text>
        </View>

        <View style={tw`w-2/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Status{" "}
          </Text>
        </View>
      </View>
    </InfinityScrollList>
  );
};
