import React from "react";
import { TouchableOpacityProps } from "react-native";
import { Button } from "@/components/Commons";

export interface FileInputProps extends TouchableOpacityProps {
  type?: "outlined" | "button";
  disabled?: boolean;
  multipleSelection?: boolean;
  onSelect?: (...input: File[]) => void;
}

export const FileInput = (props: FileInputProps) => {
  const click = async () => {
    var fileSelector = document.createElement("input");

    const {
      multipleSelection = false,
      disabled = false,
      type = "button",
    } = props;

    fileSelector.setAttribute("type", "file");
    fileSelector.accept = ".csv";
    fileSelector.multiple = multipleSelection;

    fileSelector.addEventListener("input", (e) => {
      // @ts-ignore
      onSelect(...e.target?.files);
    });

    fileSelector.click();
  };

  const onSelect = props.onSelect;

  return (
    
    <Button {...props} onPress={click}>
      {props.children}
    </Button>
  );
};
