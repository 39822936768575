import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import { tw } from "@/lib";
import { Text } from "@/components/Commons";
import { Dropdown } from "react-bootstrap";
import { DownArrow } from "@/components/Icons/DownArrow";
import { Card } from "react-native-elements";
import TreeDevices from "@/components/Tree/TreeDevices";
import { useDeviceContext } from "twrnc";

const QosOverview = () => {
  useDeviceContext(tw);
  const [data, setData] = useState({
    value: [
      {
        id: 1,
        name: "Summary of avg. MOS score",
        quantity: 6457,
      },
      {
        id: 2,
        name: "Summary % all impaired devices ",
        quantity: "8%",
        children: [
          {
            name: "Wi-fi issues",
            quantity: "5%",
          },
          {
            name: "Internet issues",
            quantity: "3%",
          },
          {
            name: "NEWT Cloud issues",
            quantity: "0%",
          },
        ],
      },
    ],
  });
  return (
    <View style={tw`flex-grow-1 h-full mx-8 py-7`}>
      <View style={tw`flex flex-row justify-between `}>
        <View style={tw`flex flex-row items-center justify-around`}>
          <Text type="h2" weight="bold">
            QoS Overview
          </Text>
        </View>
      </View>

      <View
        style={[tw` items-start flex-row py-6 h-auto  `, { zIndex: "auto" }]}
      >
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            style={{
              lineHeight: 0,
              ...tw`no-underline bg-white py-1 px-4 rounded-2`,
            }}
            size="sm"
            id="dropdown-basic"
            bsPrefix="btn"
          >
            <View style={tw`flex-row items-center`}>
              <Text style={tw`pr-6`}>Month</Text>
              <DownArrow color="black" />
            </View>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              style={tw`text-black text-btn-menu-item font-semibold`}
            >
              Month
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </View>
      <Card
        containerStyle={tw`flex w-full flex-col items-around  justify-around pl-4  rounded-3 lg:max-w-[383px] `}
      >
        <TreeDevices data={data.value} element={"QOS"} position={"top"} />

        <View
          style={[
            tw`flex sm:relative absolute lg:left-[200px] border-l-2 border-gray-100 h-full items-center lg:justify-around`,
            { zIndex: 3 },
          ]}
        ></View>
      </Card>
    </View>
  );
};

export default QosOverview;

const styles = StyleSheet.create({});
