import React, { FC } from "react";
import { Image, StyleProp, TextStyle, View } from "react-native";

import { useDeviceContext } from "twrnc";
import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { Nucleus } from "../Icons/NucleusLogin/NucleusLogin";

const styles: StyleProp<TextStyle> = {
  fontSize: 32,
  lineHeight: 48,
};

interface SideloginProps {
  title?: string;
  subtitle?: string;
  hideSubtitle?: boolean;
  alignContent?: string;
}

export const Sidelogin: FC<SideloginProps> = ({
  title,
  subtitle,
  children,
  hideSubtitle,
  alignContent = "items-center",
}) => {
  useDeviceContext(tw);

  return (
    <View
      style={tw`hidden md:flex flex-column bg-btn w-[400px] p-[32px] h-full overflow-hidden fixed top-0 left-0`}
    >
      <View style={tw`w-[100%] flex-1`}>
        <Image
          source={require("@/assets/images/LOGO/nucleus.png")}
          style={tw`w-[130px] h-[38px]`}
        />
        <View style={tw`flex-1`}></View>
        <View style={tw`mb-[30px]`}>
          <Text weight="semiBold" style={styles} color={colors.white}>
            {title ? title : "Welcome to the center of"}{" "}
            {!hideSubtitle && (
              <>{subtitle ? subtitle : "your business communications"}</>
            )}
          </Text>
        </View>
      </View>
      <View style={tw`flex flex-1 ${alignContent} mt-[30px]`}>
        <View>
          {children ? (
            children
          ) : (
            <View style={tw`mx-auto`}>
              <Image
                source={require("@/assets/images/LOGO/puzzle.png")}
                style={tw`w-[300px] h-[300px]`}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default Sidelogin;
