import { setAlert, Alert } from "@/store/alert.store";
import { useAppDispatch } from "@/store/hooks";

export const useAlert = () => {
  const dispatch = useAppDispatch();

  const showSwal = ({ msg, type }: Alert) => {
    dispatch(setAlert({ msg, type }));
  };

  return {
    showSwal,
  };
};
