import React from "react";
import Svg, { Path } from "react-native-svg";

export const Exit = (props: { color?: string }) => {
  const { color = "white" } = props;
  return (
    <Svg width="25" height="20" viewBox="0 0 24 24" fill="none">
      <Path
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        stroke={color}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        strokeWidth={"2"}
      />
    </Svg>
  );
};
