import { View, ScrollView } from "react-native";
import React from "react";

import { colors, tw } from "@/lib";
import { Text } from "@/components/Commons";
import { Circle } from "@/components/Icons";

interface Info {
  id: number;
  email: string;
  name: string;
  subscription: string;
  status: boolean;
}

interface Props {
  data: Info[];
  dropdown: any;
}

export const AdminCustomerList = ({ data, dropdown }: Props) => {
  return (
    <ScrollView
      horizontal
      contentContainerStyle={tw`flex-col mt-8 w-full min-w-150`}
    >
      <View style={tw`flex-row px-4 mb-4 `}>
        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Email{" "}
          </Text>
        </View>
        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Name{" "}
          </Text>
        </View>

        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Subscription{" "}
          </Text>
        </View>

        <View style={tw`w-3/12 `}>
          <Text color={colors.gray} weight="semiBold">
            Status{" "}
          </Text>
        </View>
      </View>

      {data.map((response) => {
        return (
          <View
            style={[
              tw`flex-row bg-white px-4 py-3 mb-1 rounded-3 items-center my-2`,
              ,
              { zIndex: "auto" },
            ]}
            key={response.id}
          >
            <View style={tw`w-3/12 `}>
              <Text color={colors.dark} weight="semiBold">
                {response.email}
              </Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.dark} weight="semiBold">
                {response.name}
              </Text>
            </View>
            <View style={tw`w-3/12 `}>
              <Text color={colors.dark} weight="semiBold">
                {response.subscription}
              </Text>
            </View>
            <View style={tw`flex flex-row items-center`}>
              {response.status ? (
                <Circle color={colors.blue} />
              ) : (
                <Circle color={colors.red} />
              )}
              <Text
                style={tw`pl-2 text-gray-800 font-semibold`}
                key={response.id}
              >
                {response.status ? `Active` : `Desactive`}
              </Text>
            </View>

            <View style={[tw`flex w-auto ml-auto  `, { zIndex: "auto" }]}>
              {dropdown}
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};
