import { StyleSheet, TouchableOpacity, View, Image } from 'react-native'
import React from 'react'
import { colors, tw } from '@/lib'
import { Dropdown } from 'react-bootstrap'
import { Icon } from 'react-native-elements'
import { Text } from '../Commons'
//import { Image } from '../Icons'


interface PropsColor {
  color: string,
  objectBackground?: JSX.Element,
  enabled?: boolean,
  object?: JSX.Element,
  showModalForRemove?: (id: string) => void;
  showModalForEdit?: (id: string) => void,
  identifier?: string,
}
const CardPromotions = ({ color,
  objectBackground,
  object,
  enabled = false,
  identifier,
  showModalForRemove,
  showModalForEdit,
}: PropsColor) => {



  return (
    <View style={tw`rounded-3 w-[242px] bg-[${color}]  py-3 px-5 mr-5 relative mt-5`}>
      <View style={tw`absolute top-1 right-1 z-10`}>
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            style={{ lineHeight: 0, ...tw`no-underline` }}
            id="dropdown-basic"
            bsPrefix="btn"
          >
            <Icon name="more-vert" color={colors.white} size={15} style={tw`text-white`} tvParallaxProperties={undefined} />
          </Dropdown.Toggle>
          <Dropdown.Menu style={tw`rounded-3`} align="end">
            <Dropdown.Item
              as="button"
              style={tw` text-btn-menu-item font-semibold`}
              onClick={() => showModalForEdit &&
                showModalForEdit(identifier ? identifier?.toString() : ``)}
            >
              Edit
            </Dropdown.Item>

            <Dropdown.Item
              as="button"
              style={tw` text-btn-menu-item font-semibold`}
            >

            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              style={tw`text-btn-menu-item font-semibold`}
            // onClick={() => showModalForRemove(code._id)}
            >
              Enable
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              style={tw`text-red-500 text-btn-menu-item font-semibold`}
              onClick={() => showModalForRemove && showModalForRemove(identifier ? identifier?.toString() : ``)}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </View>



      {/* Text */}
      <Text type="bigger" weight="bold" style={tw`max-w-[149px] ml-1 text-white`}>
        Amet lectus 10% Aliquet nibh
      </Text>
      {/* Button and Emoji */}
      <View style={tw`flex-row justify-between items-center mt-3 `}>
        {enabled && (
          <TouchableOpacity style={[tw`rounded bg-white py-2 w-[83px]`,{zIndex:20}]}>
            <View style={tw` w-[83px] flex items-center justify-center `}>
              <Text weight="semiBold">ALIQUET</Text>
            </View>
          </TouchableOpacity>
        )} 
       
         
        
          <View style={[tw`absolute items-end justify-end mr-8 w-full`,{zIndex:10}]}>
            {object && object}

          </View>
          <View style={[tw`absolute items-end justify-end mr-2 w-full`,{zIndex:10}]}>
            {objectBackground && objectBackground}

          </View>

        </View>



      </View>

   


  )
}

export default CardPromotions

const styles = StyleSheet.create({})